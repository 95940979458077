import * as React from 'react';

import Image from '../Image';
import { ImageProps } from '../Image/Image';

export enum ETabType {
    NORMAL = 'normal',
    OPTIONAL = 'optimal',
}
export interface DragImageProps extends ImageProps {
    editType?: ETabType;
}
export enum ImageType {
    single = 'single',
    multi = 'multi',
}
const DragImage: React.FunctionComponent<DragImageProps> = (props) => {
    const { imgUrl } = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Image {...props} imgUrl={imgUrl?.includes('http') ? imgUrl : ''} />;
};

export default DragImage;
