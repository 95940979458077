import * as React from 'react';
import { Div } from '../Blank/Blank';

import { Type1 } from './Type1';
import { Type3 } from './Type3';

export interface ShufflingImageProps {
    ratio: number;
    imgs: { text?: string; imgUrl: string; targetUrl?: string }[];
    loadend?: () => void;
    renderType?: 1 | 2 | 3;
    playInterval: number;
    autoplay: boolean;
    randomHeight?: boolean;
    isVerticalCenter?: boolean;
}

const ShufflingImage: React.FunctionComponent<ShufflingImageProps> = (props) => {
    const { imgs, loadend, children, renderType: renderTypeProps } = props;
    const renderType = renderTypeProps ?? 1;

    React.useEffect(() => {
        const img = new window.Image();
        img.src = imgs[0].imgUrl; // 暂时取第一个图片加载时间为标准
        img.onload = () => {
            if (loadend) {
                loadend();
            }
        };
        img.onerror = () => {
            if (loadend) {
                loadend();
            }
        };
    }, [imgs, loadend]);
    return (
        <Div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {renderType !== 3 && <Type1 {...props} />}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {renderType === 3 && <Type3 {...props} />}
            {children}
        </Div>
    );
};

export default ShufflingImage;
