import { DIALOG_STYLE, PageMetaJson, stayStatusNum, WeChatStay, WeChatType } from 'omega-render';
import { invoke } from '@yoda/bridge';
import {
    fetchWechatId,
    wechatStayBeforClose,
    wechatActionSence,
} from '../components/WeiXinDrag/components/commonFun';
import { getPopupInfo } from './api';
import { getPageId } from './utils/getPageId';
import { bridgeGetDeviceInfo, exitWebview } from './bridge';
import { dataTrack } from './adTrack/util/track';
import { PageModal } from '../components/PageModal';
import { wechatGameStyleModal } from 'landingPage/components/WechatGame/components/wechatGameStyleModal';
import { isInApp, isLocalhost } from './utils/env';
import { getContainerType } from './utils/getContainerType';
import { CONTAINER_TYPE } from 'landingPage/components/Blank/utils/useContainerStatus';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { compareVersion } from './utils/compareVersion';
import { XiansuoFormStyleModal } from '../components/XiansuoFormDrag/XiansuoFormStayModal';

let maxCount = 0;
const MAX_COUNT = 10;

const handlePageMeta = async (params: {
    pageMeta: PageMetaJson;
    edit?: boolean;
    preview?: boolean;
    store?: any;
}) => {
    const { pageMeta, edit, store } = params;
    const { anchorForm, pageBackgroundColor, wechatStay, pageModal, needObserveMode } = pageMeta;

    if (pageBackgroundColor) {
        const rootNode = document.body;
        rootNode?.setAttribute(
            'style',
            `background-color: ${pageBackgroundColor as string};` + document.body.style.cssText,
        );
    }

    //处理表单锚点
    handleAnchorForm(anchorForm);
    // wechatGameStayOperation();

    //处理微信挽留弹窗
    handleTopLeftBackBtn(wechatStay, !!edit);

    // 如果需要展示弹窗
    if (pageModal?.isShowModal) {
        PageModal(pageModal, edit);
    }

    //是否需要监听系统暗黑模式
    if (needObserveMode) {
        const { darkMode } = await invoke('platform.getDarkMode');
        if (darkMode) {
            const rootNode = document.body;
            rootNode?.setAttribute(
                'style',
                `background-color: #000000;` + document.body.style.cssText,
            );
        }
    }

    XiansuoFormStyleModal(store);
};

const handleAnchorForm = async (anchorForm: PageMetaJson['anchorForm']) => {
    try {
        if (anchorForm?.checked) {
            const pageId = getPageId();
            if (pageId?.length) {
                const data = await getPopupInfo({ pageId });
                if (data) {
                    return;
                }
            }

            if (window.location.search?.includes('anchorClear=1')) {
                return;
            }

            const timeout = () => {
                if (maxCount < MAX_COUNT) {
                    setTimeout(() => {
                        if (document.querySelector('#lp-user-agreement')) {
                            const nodeList = document.querySelectorAll(`.com-form`);
                            const node = nodeList[Number(anchorForm?.formIndex ?? 0)];
                            // 获取表单距页面顶部高度
                            const top = node?.getBoundingClientRect().top;
                            window.scrollTo(0, top ?? 0);
                            maxCount += 1;
                            return;
                        }
                        timeout();
                    }, 500);
                }
            };

            timeout();
        }
    } catch (e) {
        console.log('error', e);
    }
};

/** 处理挽留弹窗，原则是用户配置高于一切
 * 当页面不处于对话表单的全屏状态时，且不处于兜底页面时，才执行挽留的判断逻辑
 * 当用户未配置挽留弹窗时，调ab实验获取用户状态，命中小游戏挽留实验则执行后续逻辑
 * 实验推全时保持默认下发值为0即可
 */
const handleTopLeftBackBtn = (wechatStay: WeChatStay | undefined, edit: boolean) => {
    if (isLocalhost || getContainerType() == CONTAINER_TYPE.halfScreen) return;
    if (
        ![stayStatusNum.SHOW_STAY, stayStatusNum.SHOW_WECHAT_GAME_STAY].includes(
            wechatStay?.stayStatus as stayStatusNum,
        )
    )
        return;

    const wechatStayInvoke = () => {
        dataTrack({
            eventType: 'EVENT_ELEMENT_CLICK',
            lp_element_type: 3,
        });
        if (!window.history?.state?.fullScreen) {
            const curUrl = window.location.href.includes('https://weixin.qq.com/');
            if (!curUrl) {
                switch (wechatStay?.stayStatus) {
                    case stayStatusNum.SHOW_STAY: {
                        handleStayBeforeClose(wechatStay, edit);
                        break;
                    }
                    case stayStatusNum.SHOW_WECHAT_GAME_STAY: {
                        wechatGameStyleModal({
                            ...wechatStay?.gameInfo,
                            description: wechatStay?.wechatGameInfo,
                        });
                        break;
                    }
                    default:
                        exitWebview();
                        break;
                }
            } else {
                // exitWebview();
            }
        } else {
            // window.history.back();
        }
    };
    if (!isInApp) {
        bridgeGetDeviceInfo()
            .then((res) => {
                if (compareVersion(res?.data?.SDKVersion, '3.3.65') >= 0) {
                    bridge.registerBackClickListener({
                        success: () => {
                            console.log('----register in lianmeng success');
                            wechatStayInvoke();
                        },
                        error: (e) => {
                            console.log('register error', e);
                        },
                    });
                }
            })
            .catch((e) => {
                console.log('----getDeviceInfo error', e);
            });
    } else {
        invoke('webview.setTopLeftBtn', {
            show: true,
            icon: 'back',
            onClick: () => {
                wechatStayInvoke();
            },
        });
    }
};

// const wechatGameStayOperation = () => {
//     // wechatGameStyleModal()
//     invoke('webview.setTopLeftBtn', {
//         show: true,
//         icon: 'back',
//         onClick: () => {
//             if (!window.history?.state?.fullScreen) {
//                 const curUrl = window.location.href.includes('https://weixin.qq.com/');
//                 if (!curUrl) {
//                     wechatGameStyleModal();
//                 } else {
//                     // exitWebview();
//                 }
//             } else {
//                 // window.history.back();
//             }
//         },
//     });
// };

export const handleStayBeforeClose = async (wechatStay: WeChatStay, edit: boolean) => {
    const { wechatType, applets, opt, dialogStyleType, info } = wechatStay;

    let showModel = false;

    if (wechatType === WeChatType.MINIAPP && applets) {
        wechatStayBeforClose({
            dialogStyleType: dialogStyleType as DIALOG_STYLE,
            info,
            edit,
            applets,
            opt,
            wechatType,
        });
        return;
    }

    const dialogInfo = {
        wechatId: opt.wechatId,
        packId: '0',
        itemId: opt.id,
        qrcode: '',
        appId: opt.wechatAppId,
        againstFlowPrefixSwitch: opt?.againstFlowPrefixSwitch,
        againstFlowPrefix: opt?.againstFlowPrefix,
        directSubscribeSwitch: opt?.directSubscribeSwitch, // 是否直达关注
        directSubscribeLink: opt?.directSubscribeLink, // 公众号链接
    };

    const res = await fetchWechatId({
        opt,
        wechatType,
        edit,
        dialogInfo,
        dialogStyleType: dialogStyleType as DIALOG_STYLE,
        showModal: () => {
            showModel = true;
        },
        onError: () => {
            exitWebview();
        },
    });
    dataTrack({
        eventType: 'EVENT_CLICK_WECHAT',
        wechatItemId: res?.itemId,
        wechatActionScene: wechatActionSence(dialogStyleType),
        lp_page_type: 1,
        diyExtraInfo: JSON.stringify({
            renderType: 'WEI_XIN_DRAG',
        }),
    });
    if (showModel && res) {
        wechatStayBeforClose({
            dialogStyleType: dialogStyleType as DIALOG_STYLE,
            info,
            edit,
            dialogInfo: res,
            applets,
            opt,
            wechatType,
        });
    }
};

export default handlePageMeta;
