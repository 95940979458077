import * as React from 'react';
import { WechatGameProps, BackgroundType } from '../WechatGame';
// import CardImg from '../img/cardImg.svg';
import CardImg from '../img/cardImage.png';
import cs from 'classnames';
import styles from '../style.module.less';
import { ClickPosition, openWechatGame } from '../utils';
import { useComContext } from 'omega-render';
import { useButtonTheme } from '@/landingPage/common/utils/useTheme';
import { getPicCDNSource } from '@/landingPage/common/utils/getUrlParam';

export interface DescIconStyleProps extends WechatGameProps {}

const DescIconStyle: React.FunctionComponent<DescIconStyleProps> = (props) => {
    const {
        buttonText,
        buttonStyle,
        name,
        // 小游戏名称的css属性
        nameStyle,
        // 小游戏描述
        description,
        // 小游戏描述的css属性
        descStyle,
        gameIcon,
        backgroundType,
        cardBackgroundColor,
        cardBackgroundImg,
        dynamicStatus,
        gameInfo,
        position,
    } = props;

    const { preview } = useComContext();
    const buttonTheme = useButtonTheme();
    return (
        <div
            className={styles.descIcon}
            style={
                backgroundType === BackgroundType.color
                    ? {
                          backgroundColor: cardBackgroundColor,
                      }
                    : { backgroundImage: getPicCDNSource(cardBackgroundImg) }
            }
        >
            {gameIcon?.length ? (
                <img src={getPicCDNSource(gameIcon)} alt="" className={styles.icon} />
            ) : (
                <div
                    style={{
                        backgroundImage: `url(${getPicCDNSource(CardImg)})`,
                        backgroundSize: 'cover',
                    }}
                    className={styles.icon}
                />
            )}

            <div className={styles.desc}>
                <div className={styles.name} style={{ ...nameStyle }}>
                    {name}
                </div>
                {description !== '请输入小游戏简介' && description !== '微信小游戏简介' && (
                    <div className={styles.description} style={{ ...descStyle }}>
                        {description}
                    </div>
                )}
            </div>
            <div
                className={cs(styles.button, {
                    [styles.dynamic]: dynamicStatus === 1,
                })}
                style={{ ...buttonStyle, ...buttonTheme }}
                onClick={() => {
                    openWechatGame({
                        gameInfo,
                        preview,
                        renderClickArea: ClickPosition[position] || ClickPosition.base,
                    });
                }}
            >
                {buttonText}
            </div>
        </div>
    );
};

export default DescIconStyle;
