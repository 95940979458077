import { getUrlParam } from 'omega-shared/utils';
/**
 * 添加聚星宏参数
 *
 * @param url - The URL to replace.
 * @returns The modified URL.
 */
export function appendWithJuXingMacroLinks(url: string) {
    // const
    const JuXingParams = ['missionId', 'orderId', 'supplementVersion', 'trafficSource'];
    JuXingParams.forEach((key) => {
        const value: string = getUrlParam(key);
        if (value && !value?.startsWith('__')) {
            url = `${url}${url?.includes('?') ? '&' : '?'}${key}=${value}`;
        }
    });
    return url;
}
