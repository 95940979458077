import { checkInWhiteList } from 'landingPage/common/api';
import { getUrlParam } from 'omega-shared/utils';
import React, { useEffect } from 'react';

export const useWhiteListHooks = () => {
    const [whiteList, setWhiteList] = React.useState<{
        status: boolean;
    }>({
        status: false,
    });
    useEffect(() => {
        getWhiteListData();
    }, []);
    const getWhiteListData = async () => {
        const pageId = getUrlParam('pageId');
        const res = await checkInWhiteList(pageId);
        setWhiteList(res.data);
    };
    return whiteList;
};
