import * as React from 'react';
import { useSetState } from 'react-use';
import { useComContext } from 'omega-render';
import { ShufflingImageProps } from './ShufflingImage';
import styles from './style.module.less';
import { Progress } from './Progress';
import { EnhanceImage } from '../ImageSingle/EnhanceImage';

export interface Type1Props extends ShufflingImageProps {}

export const Type1: React.FunctionComponent<Type1Props> = (props) => {
    const { edit } = useComContext();
    const { ratio, renderType = 1, imgs, randomHeight = false, isVerticalCenter = false } = props;
    const imgWidth = renderType === 1 ? 375 : 296;
    const imgHeight = imgWidth / ratio;
    const opt = React.useMemo(() => {
        const len = renderType === 1 ? 375 : 296 + 16;
        const time = 400;
        const delay = 4000;
        return {
            len,
            time,
            delay,
        };
    }, [renderType]);
    const { moved, renderImgs, events, index } = useAnimation(props, opt);
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div
            className={styles.type1}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...events}
            style={randomHeight ? {} : { height: imgHeight }}
        >
            {renderImgs.map((conf, index) => {
                const { imgUrl, targetUrl } = conf;

                return (
                    <>
                        {randomHeight ? (
                            <EnhanceImage
                                src={imgUrl}
                                className={styles.img}
                                style={{
                                    top: isVerticalCenter ? (edit ? 300 : '50vh') : 0,
                                    width: imgWidth,
                                    transform: `translate(${
                                        (index - (renderImgs.length - 1) / 2) * opt.len - moved
                                    }px,${isVerticalCenter ? '-50%' : '0px'})`,
                                }}
                            />
                        ) : (
                            <div
                                className={styles.img}
                                onClick={() => {
                                    if (targetUrl) {
                                        window.location.href = targetUrl;
                                    }
                                }}
                                style={{
                                    transform: `translate(${
                                        (index - (renderImgs.length - 1) / 2) * opt.len - moved
                                    }px,${0}px)`,
                                    backgroundImage: `url(${imgUrl})`,
                                    width: imgWidth,
                                    height: imgHeight,
                                }}
                            />
                        )}
                    </>
                );
            })}

            {renderType === 1 && !randomHeight && (
                <Progress
                    len={imgs.length}
                    transitionTime={`${opt.time / 1000}s`}
                    currentIndex={index}
                    moved={moved}
                />
            )}
        </div>
    );
};
function getEle<T>(arr: T[], index: number) {
    if (arr.length === 0) {
        throw new Error('数组长度不能为0');
    }
    if (arr.length === 1) {
        return arr[0];
    }
    if (index >= arr.length) {
        // 这里有可能算出-1，所以需要后面小于0的逻辑
        index = ((index + 1) % arr.length) - 1;
    }
    if (index < 0) {
        // 这里有可能算出arr.length，所以需要后面小于0的逻辑
        index = (index % arr.length) + arr.length;
    }
    if (index >= arr.length) {
        index = ((index + 1) % arr.length) - 1;
    }
    return arr[index];
}
interface AnimationOpt {
    len: number;
    time: number;
    delay: number;
}
export function useAnimation(props: Type1Props, opt: AnimationOpt) {
    const { imgs } = props;
    const { len, time, delay } = opt;
    const step = len / (time / 16);
    const [state, setState] = useSetState({ moved: 0, index: 0 });
    const that = React.useRef<{
        timmer?: number;
        delayTimer?: number;
        moved: number;
        index: number;
        autoplay?: boolean;
        touchState: {
            startXY: number[];
            moved?: number;
            moveOffset: number;
        };
    }>({
        index: 0,
        touchState: {
            startXY: [],
            moveOffset: 0,
        },
        moved: 0,
    });
    that.current.autoplay = props.autoplay;
    that.current.moved = state.moved;
    that.current.index = state.index;
    const stopAnimation = React.useCallback(() => {
        if (that.current.timmer != null) {
            cancelAnimationFrame(that.current.timmer);
        }
        if (that.current.delayTimer) {
            clearTimeout(that.current.delayTimer);
        }
    }, []);
    /**
     * reverse 是否倒着来
     */
    const startAnimation = React.useCallback(
        (reverse?: boolean) => {
            let { moved } = that.current;
            if (reverse) {
                moved -= step;
            } else {
                moved += step;
            }
            if (Math.abs(moved) >= len) {
                // 到头了
                const { index } = that.current;
                setState({
                    moved: 0,
                    index: reverse ? index - 1 : index + 1,
                });
                if (!that.current.autoplay) {
                    return;
                }
                that.current.delayTimer = window.setTimeout(() => {
                    startAnimation(false);
                }, delay);
                return;
            }
            setState({
                moved,
            });
            that.current.timmer = requestAnimationFrame(() => startAnimation(reverse));
        },
        [delay, len, setState, step],
    );

    React.useEffect(() => {
        let timmer: number;
        if (props.autoplay) {
            timmer = window.setTimeout(() => {
                startAnimation();
            }, delay);
        }

        return () => {
            stopAnimation();
            window.clearTimeout(timmer);
        };
    }, [delay, props.autoplay, startAnimation, stopAnimation]);

    const renderImgs = React.useMemo(() => {
        const { index } = state;
        if (props.renderType === 2) {
            return [
                getEle(imgs, index - 2),
                getEle(imgs, index - 1),
                getEle(imgs, index),
                getEle(imgs, index + 1),
                getEle(imgs, index + 2),
            ];
        }
        return [getEle(imgs, index - 1), getEle(imgs, index), getEle(imgs, index + 1)];
    }, [imgs, props.renderType, state.index]);
    // 触摸控制
    const onTouchStart = React.useCallback(
        (e: React.TouchEvent) => {
            const { pageX, pageY } = e.touches[0];
            that.current.touchState.startXY = [pageX, pageY];
            const { moved } = that.current;
            that.current.touchState = { startXY: [pageX, pageY], moved, moveOffset: 0 };
            stopAnimation();
        },
        [stopAnimation],
    );
    const onTouchMove = React.useCallback(
        (e: React.TouchEvent) => {
            e.preventDefault();
            const { pageX, pageY } = e.touches[0];
            const { startXY, moved, moveOffset } = that.current.touchState;
            if (startXY.length === 0) {
                return;
            }
            const [offsetX] = [pageX - startXY[0], pageY - startXY[1]];
            if (moved)
                setState({
                    moved: moved - offsetX + moveOffset,
                });
        },
        [setState],
    );
    const onTouchEnd = React.useCallback(() => {
        if (that.current.touchState.startXY.length) {
            const { moved } = that.current;
            that.current.touchState = {
                startXY: [],
                moveOffset: 0,
            };
            startAnimation(moved < 0);
        }
    }, [startAnimation]);
    // 触摸的时候，修正状态
    React.useEffect(() => {
        if (that.current.touchState.startXY.length) {
            const { index } = that.current;
            if (state.moved >= len) {
                that.current.touchState.moveOffset -= len;
                setState({
                    index: index + 1,
                    moved: state.moved - len,
                });
            } else if (state.moved <= -len) {
                that.current.touchState.moveOffset += len;

                setState({
                    index: index - 1,
                    moved: state.moved + len,
                });
            }
        }
    }, [len, setState, state.moved]);
    return {
        moved: state.moved,
        index: state.index,
        renderImgs,
        stopAnimation,
        events: {
            onTouchStart,
            onTouchMove,
            onTouchEnd,
        },
    };
}
