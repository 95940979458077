export enum ProductEnum {
    NEBULA = 13,
    KUAISHOU = 1,
    DEFAULT = 0,
}

/**
 * 判断产品类型
 */
export const getProduct = () => {
    if (/nebula/i.test(navigator.userAgent)) {
        // 极速版 NEBULA = 13
        return ProductEnum.NEBULA;
    }
    if (navigator.userAgent.includes('Kwai')) {
        // 快手主APP KUAISHOU = 1
        return ProductEnum.KUAISHOU;
    }
    return ProductEnum.DEFAULT;
};

export const senderPkgNameList = {
    [ProductEnum.NEBULA]: 'com.kuaishou.nebula',
    [ProductEnum.KUAISHOU]: 'com.smile.gifmaker',
    [ProductEnum.DEFAULT]: 'com.smile.gifmaker',
};
