/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { CUSTOM_PRIVACY_AGREEMENT_FLAG } from '../XiansuoFormDrag/components/FormUserAgreement';
import { getPrivacyAgreementUsingPOST } from 'landingPage/services/apis';
import { ICustmPrivacyAgreement } from '../XiansuoFormDrag/XiansuoForm';
import ReturnIcon from './icons/ReturnIcon';
import styles from './style.module.less';

interface IProps extends ICustmPrivacyAgreement {
    hasCunstomContent?: boolean;
}

const AgreeMent = (props: IProps) => {
    const { hasCunstomContent, agreementName, fileKey } = props;
    const [showCustom, setShowCustom] = useState(false);
    const [customContent, setCustomContent] = useState('');
    useEffect(() => {
        if (!fileKey) return;
        getCustomContent(fileKey);
    }, [fileKey]);
    const getCustomContent = async (fileKey: string) => {
        try {
            const res = await getPrivacyAgreementUsingPOST({ key: fileKey });
            setCustomContent(res?.data?.content);
        } catch (e) {
            console.log(e);
        }
    };
    if (!showCustom) {
        return (
            <div className={styles.agreement}>
                <h2>个人信息授权与保护声明</h2>

                <p>
                    《个人信息授权与保护声明》（以下简称“本协议“)是北京快手科技有限公司、北京晨钟科技有限公司、北京中博科远科技有限公司、淮安双馨文化传播有限公司及其关联公司
                    （以下简称“快手”或“我们”）与用户（以下简称“您”）就留资页面（“免费咨询”、“咨询底价”等预约服务表单信息填写页面）填写过程中您主动向我们提交个人信息的处理规则所订立的有效授权文件。
                    请您先仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有疑问，请勿向我们填写提交您的个人信息。您可通过快手在线客服或官方热线400126008进行咨询，以便我们为您解释和说明。
                    如您主动填写并提交您的信息即表示您已同意本协议。
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    1、您知悉且授权，在留资页面您主动向我们提供您的相关个人信息（如姓名、联系电话、城市等，具体以留资页面信息为准），我们将根据您的授权将您的相关信息提供给服务提供方，
                    {hasCunstomContent && (
                        <>
                            <span style={{ fontWeight: 'bold' }}>
                                请您务必仔细阅读服务提供方提供的
                            </span>
                            <a
                                id={CUSTOM_PRIVACY_AGREEMENT_FLAG}
                                onClick={() => setShowCustom(true)}
                                style={{ wordBreak: 'break-all' }}
                            >
                                {`《${agreementName || '隐私政策'}》，`}
                            </a>
                        </>
                    )}
                    或者您在其他服务提供方短视频/直播间/个人主页内通过评论/留言/外呼等形式表达对商品/服务的咨询、购买意向后，为了使您的需求得到更高效的响应，我们也将会把您留资的相关信息及我们综合其他信息分析处理后的相关信息（如姓氏、提交入口的服务方账号等）提供给服务提供方（具体服务提供方的名称及联系方式信息可在服务页面或者账号个人主页中点击查看)，用于服务提供方向您提供相关服务的免费咨询、活动邀请或需求沟通、后续回访，或在页面中说明的其他使用目的。如该等服务提供方基于地域限制等原因无法为您提供服务或者该等服务提供方未在我们要求的服务期限内及时向您提供高效率服务，您同意我们将您的相关信息提供给第三方服务提供方以满足您的需求
                    （如适用，且具体第三方服务提供方详细信息以及对您的个人信息处理内容详见以下相关行业所附链接
                    ）。如您对服务提供方不满意，您可以不回复私信或者回复暂无需求、拒接电话或者接通后告知服务提供方您不需要相关服务。您也可以主动与快手官方取得联系，我们将根据您的要求，对您留资的相关信息做出相应处理（如删除）。为了便于您更好的理解，我们结合不同服务方行业的特点，为您举例如下：
                </p>
                <p>
                    a)汽车行业服务提供方（包括但不限于汽车厂商、4S经销门店、汽车服务平台等）邀请您试驾、到
                    4s 店看车、购车、参与车友会活动；
                </p>
                <p>b)教育行业服务提供方，邀请您试听课程、沟通课程需求 ；</p>
                <p>c)旅游行业服务提供方，与您预约沟通自由行需求，或沟通定制游需求；</p>
                <p>d)家装行业服务提供方，与您预约上门测量、预约设计师沟通装修需求；</p>
                <p>
                    e)房产行业服务提供方，即您咨询的主播、楼盘开发商、房产经纪公司（具体信息详见页面展示）向您沟通购房需求；
                </p>
                <p>
                    f)旅游票务行业服务提供方，与您预约沟通旅游规划，为您提供定制化的旅游服务或相关自由行服务；
                </p>
                <p>g)法律咨询行业服务提供方，与您预约沟通相关法律服务，提供相关的解决方案；</p>
                <p>h)教育行业服务提供方，与您预约沟相关财商课程需求，或邀请您试听课程；</p>
                <p>
                    i）招商加盟行业服务提供方，与您预约沟通创业项目，按您的要求为您推荐合适的品牌商家。
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    需要向您说明的是，具体需要提供的信息可能会根据服务提供方提供的产品/
                    服务的不同而有所差异，请以向您展示的产品/服务提供相关个人信息，我们会在产品/服务页面或下文向您说明相关个人信息的收集和使用规则。
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    2、我们仅会出于留资页面及本协议声明的合法、正当、必要、特定、明确的目的要求该等服务提供方处理您的个人信息，服务提供方只能接触到其履行服务所需信息，且我们会要求其严格遵守相关法律法规与监管要求依法使用您的信息，不得将此信息用于其他任何本协议授权范围的目的。如果服务提供方将您的信息用于本协议未授权的用途，其需单独征得您的同意。如其发生超越本协议范围使用您的个人信息，进而侵害您合法权益的情形，我们将协助您追究该服务提供方的法律责任。
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    3、为了省去您手动输入的操作，您注册快手的手机号和您所在城市信息将在获得您的授权后展示在信息录入界面，但这并不代表第三方已经获取相关信息。当且仅当您同意《个人信息授权与保护声明》的前提下，我方才会向第三方传输您的相关信息。若您拒绝使用预填充功能，或者拟撤回使用预填充功能的授权，不影响您主动输入相关信息并继续使用第三方的产品/服务。
                </p>
                <p>
                    4、为了您省去您重复输入的操作，当您在留资页面留过咨询信息
                    <span style={{ fontWeight: 'bold' }}>
                        （如姓名、联系电话、城市等，具体以留资页面信息为准）
                    </span>
                    ，您可以快速点击“一键预约”按钮，由您选择的服务提供方为您提供相关咨询服务，无需再手动填写。如您需要更新咨询信息，您可以在留资页面更改，或者联系快手客服为您更改。若您拒绝使用一键预约功能，不影响您主动输入相关信息并继续使用第三方的产品/服务。
                </p>
                <p>
                    5、当您主动选择我们为您推荐的本地其他优质服务商时，您授权我们将您提交的上述咨询信息提供给页面上展示的服务提供者，如您不同意，可返回、取消或者关闭页面。
                </p>
                <p>
                    6、您知悉并授权我们及该等服务提供方可能定期或不定期向您发送有关产品、服务或相关活动的信息。如您拟撤回授权/删除您提交的个人信息的，您可以通过快手在线客服或官方热线400126008与我们联系，我们将尽快配合处理。对于具体服务提供方处理您个人信息的情形，您可通过我们及服务提供方提供的联系方式与其进行联系管理您的个人信息。
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    7、我们重视未成年人的个人信息保护，如您为未成年人，在您使用本服务之前，您应在您的父母或者其他监护人的监护、指导下仔细阅读本声明，并在征得您的父母或监护人同意的前提下向我们提供信息。如果我们发现在未事先获得父母或监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    8、如果您需要查询、修改、更正您的个人信息，或撤销对您个人信息使用的授权，或对个人信息保护问题有任何疑问或投诉，您可以通过以下方式联系我们：拨打电话4001260088。对于该等服务提供方处理您个人信息的情形，您可通过服务提供方提供的联系方式与其进行联系管理您的个人信息。
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    9、请您注意并确保提交的信息的真实、准确、合法有效，您为做出本协议项下的授权事项的适格权利主体或就做出前述授权事项已经取得相关权利主体的真实、合法、有效的授权，并承担您授权行为而引起的全部法律责任。
                </p>
                <p>
                    10、如我们对本协议进行变更，我们将通过公告或客户端消息等方式予以通知，该等变更自通知载明的生效时间开始生效。若您无法同意变更修改后的协议内容，您有权停止使用相关服务；双方协商一致的，也可另行变更相关服务和对应协议内容。
                </p>
                <p>
                    11、快手重视对您个人信息的保护。为更好地保障您的合法权益，您的个人信息将依快手《隐私权保护政策》得到保护与规范。本协议末约定事宜，均以快手网站公布的《快手软件许可及服务协议》、《隐私权保护政策》及相关规则为补充；本协议与《快手软件许可及服务协议》、《隐私权保护政策》及相关规则不一致的地方，以本协议为准。
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    12、本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，相关条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向北京市海淀区人民法院提起诉讼。
                </p>
            </div>
        );
    }
    return (
        <div className={styles.customagreement}>
            <div
                className={styles.backWrapper}
                onClick={() => {
                    setShowCustom(false);
                }}
            >
                <ReturnIcon fontSize={16} color="#9c9c9c" />
                <span>返回</span>
            </div>
            <div className={styles.agreementWrapper}>
                <div className={styles.agreementHeader}>{agreementName ?? '隐私政策'}</div>
                <div className={styles.agreementContent}>{customContent}</div>
            </div>
        </div>
    );
};
export default AgreeMent;
