/** 兼容审核页面调用，moli域名增加获取判断 */
export const getMoliDomainInAduit = () => {
    if (window.location.origin.includes('beta-moli-admin')) {
        return 'https://beta-moli.kuaishou.com';
    }
    if (window.location.origin.includes('moli-admin')) {
        return 'https://chenzhongkj.com';
    }
    return '';
};
