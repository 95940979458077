import { isInApp } from 'landingPage/common/utils/env';
import { getPrivateLetterInfo } from 'landingPage/services/apis';
import { useEffect } from 'react';
import { IM_TYPE, openServiceUrl, transformDeepLink } from '../CustomerServiceDrag/CustomerService';
import { PrivatePreview } from './components/PrivatePreview';
import { fetchConsultPageUrl } from '../CustomerServiceDrag/utils';
import { getCallbackInfo } from '@/landingPage/common/adTrack/util/sendData';
import { getPageId } from '@/landingPage/common/utils/getPageId';

export interface CustomerServicePageProps {
    type: IM_TYPE;
    //  快聊或者第三方的咨询页id，原生私信这个字段为空
    funcId?: string;
    //  快聊或者第三方的咨询页url，原生私信这个字段为空
    url?: string;
    //  原生私信的uid
    uid?: number;
    deepLink?: string;
}

const jumpToSiXinPage = (url: string) => {
    const a = window.document.createElement('a');
    a.href = url as string;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
/**
 * 咨询页组件
 * PRD:https://docs.corp.kuaishou.com/k/home/VM8CZ8GozCWo/fcADZzBtXTftYMRHgTx5yMBTx?from=https%3A%2F%2Fdocs.corp.kuaishou.com%2Fhome&channel=other
 * 目前该组件只用于咨询页预览，不会在生产环境中投放
 */
export default function CustomerServicePage(props: CustomerServicePageProps) {
    const { type, funcId, uid, deepLink } = props;
    const getPrivateLetterDeepLink = async () => {
        const res = await getPrivateLetterInfo({
            userId: uid as number,
        });
        if (isInApp) {
            return res?.data?.deepLink;
        } else {
            return res?.data?.h5Url;
        }
    };
    useEffect(() => {
        // 跳转原生私信
        async function init() {
            try {
                // 咨询页预览不需要用户协议
                const agreement = window.document.querySelector('#lp-user-agreement');
                agreement?.parentNode?.removeChild(agreement);
            } catch (error) {
                console.error(error);
            }

            if (type === IM_TYPE.PRIVATE_LETTER) {
                let target = deepLink;
                const res = await getPrivateLetterDeepLink();
                if (isInApp) {
                    if (!target) {
                        target = res as string;
                    }
                    const targetDeepLink = target;
                    const formatted = transformDeepLink(targetDeepLink, {
                        __CALLBACK_PARAM__: getCallbackInfo(),
                        __PAGE_FROM__: '2',
                        __PAGE_STYLE_PARAM__: '0',
                        __LP_ID__: getPageId(),
                    });
                    console.log('------formatted', formatted);
                    jumpToSiXinPage(formatted);
                    return;
                } else {
                    target = res as string;
                    if (target) {
                        jumpToSiXinPage(target);
                        return;
                    }
                }
            }
        }
        init();
    }, []);
    useEffect(() => {
        // 跳转快聊和三方
        async function init() {
            if (!funcId) return;
            const res = await fetchConsultPageUrl(funcId);

            const { switchStatus, consultPageUrl } = res;
            if (!consultPageUrl?.length) {
                return;
            }

            await openServiceUrl({
                comType: 'CUSTOMER_SERVICE_PAGE',
                url: consultPageUrl,
                funcId,
                csType: type,
                extraUrlParams: {
                    jumpSource: 1,
                },
                linkSwitch: switchStatus,
            });
        }
        init();
    }, []);
    return (
        <div>
            {type === IM_TYPE.PRIVATE_LETTER && !isInApp && <PrivatePreview uid={uid as number} />}
            {type !== IM_TYPE.PRIVATE_LETTER && <div></div>}
        </div>
    );
}
