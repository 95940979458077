import * as React from 'react';
import { useComContext } from 'omega-render';
import modelStyle from 'landingPage/common/components/Modal/Modal.module.less';
import { useEditHeight } from 'landingPage/common/hook/useEditHeight';
import { Div } from '../Blank/Blank';
import { BaseForm } from '../xiansuoFormPay/commonFun';
import { useCommonFormConfig } from '../XiansuoFormDrag/XiansuoForm';
import { IFormProps } from './Button';
import styles from './style.module.less';

export interface CopyDialogProps {
    onClose?: () => void;
    formProps: IFormProps;
}

export const formClass = 'com-form';
export const FormDialog: React.FunctionComponent<CopyDialogProps> = (props) => {
    const { onClose, formProps } = props;
    const {
        formOpt,
        showLabel,
        theme,
        funcId,
        placeholderColor,
        inputStyle,
        titleStyle,
        formStyle,
        submitStyle,
        optionStyle,
        optionActiveStyle,
        clueAccountId,
    } = formProps;

    const { edit, preview } = useComContext();

    const { config } = useCommonFormConfig({
        formOpt,
        edit,
        preview,
        showLabel,
        theme,
        formStyle,
        inputStyle,
        submitStyle,
        titleStyle,
        placeholderColor,
        optionStyle,
        optionActiveStyle,
        clueAccountId,
    });

    const { editHeight } = useEditHeight(true);
    const modelHeight = document.getElementsByClassName(modelStyle.layer)[0]?.clientHeight;

    return (
        <div className={styles.dialog} id="button-form" style={{ maxHeight: modelHeight * 0.75 }}>
            {/* <div className={styles.titlePart}> */}
            {/* <p>{formProps?.showLabel==='show' && formProps?.formOpt?.mainTitle}</p> */}
            <img
                style={{
                    width: 24,
                    height: 24,
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    zIndex: 999,
                }}
                src={
                    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-02-07/1675758063045.2ecfbcd81522ab5e.png'
                }
                onClick={onClose}
                alt=""
                data-target="formClose"
            />
            {/* </div> */}
            <Div
                className={formClass}
                data-form-id={funcId}
                // 48px是表单组件title的高度，modelHeight*0.75是表单内容弹窗的高度，减去48 跟title的高度加起来正好是3/4屏
                style={{
                    maxHeight: editHeight ?? modelHeight * 0.75 - 48,
                    overflowY: 'auto',
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                }}
            >
                <BaseForm
                    placeholderColor={placeholderColor}
                    formClass={formClass}
                    reRender={false}
                    config={config}
                    formStyle={formStyle}
                />
            </Div>
        </div>
    );
};
