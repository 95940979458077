import { AnyObj } from 'omega-shared/types';

const en = import('./end.js');

export async function sign(signInput: string) {
    const enFun = await en;
    console.log('----enfun', enFun);
    return new Promise<string>((resolve, reject) => {
        enFun.call('$encode', [
            signInput,
            {
                suc: function (res, c_info) {
                    // 如果c_info有值需要业务方用radar的自定义事件把c_info上报，如果环境正常，c_info返回"", 无需上报；雷达上报参数：name: "c_info", category: "每个业务名字的英文", message: "返回的c_info"
                    console.log(res, c_info);
                    resolve(res);
                    // if (c_info) {
                    //     radar.event({
                    //         name: 'c_info',
                    //         category: 'xx',
                    //         message: c_info,
                    //     });
                    // }
                },
                err: function (err) {
                    console.log(err);
                    reject(err);
                },
            },
        ]);
    });
}

interface ISignData {
    query?: AnyObj;
    body?: string;
    formData?: AnyObj;
    cookie?: string;
}

const formatCookie = (cookie?: string) => {
    if (!cookie) return;
    return cookie
        .split(';')
        .map((item) => {
            const [key, value] = item.split('=');
            return { [key.trim()]: value.trim() };
        })
        .reduce((pre, current) => {
            return { ...pre, ...current };
        });
};
export const signApi = async (data: ISignData) => {
    const cookieObj = formatCookie(data.cookie);
    const signData = {
        ...(data.query || {}),
        ...(data.formData || {}),
        ...(cookieObj?.userId ? { userId: cookieObj.userId } : {}),
    };
    const sortedData = Object.entries(signData).sort(([key1], [key2]) => key1.localeCompare(key2));
    const stringList = sortedData.map(([key, value]) => `${key}=${value}`);
    const result = stringList.join('');
    const signInput = result + (data.body || '');
    console.log('[signInput]', signInput);
    const singRes = await sign(signInput);
    return singRes;
};
