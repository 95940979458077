/**
 * 获取window对象。对landingpage里的组件来说，在开发环境直接加载在了iframe外面，获取不到iframe里的window
 */
export const getWindow = () => {
    const win = window.document.querySelector('iframe')?.contentWindow;
    if (win) {
        return win;
    }
    return window;
};
