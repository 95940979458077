/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Toast } from '@ad/sharpui';
import * as React from 'react';
import { useComContext } from 'omega-render';
import { IAllFormJson, IClueForm, IGlobalData } from '@ad/xiansuoSDK';
import qs from 'query-string';
import { invoke } from '@yoda/bridge';
import { trackEvent } from 'landingPage/common/adTrack/monitor';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { trackClick, trackFormAutoPhoneStatus } from 'landingPage/common/adTrack/util/zt';
import { useEditHeight } from 'landingPage/common/hook/useEditHeight';
import { isBeta, isSSR, isSSRHydrate, isStaging } from 'landingPage/common/utils/env';
import { getPageId } from 'landingPage/common/utils/getPageId';
// import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { getXianSuoDomain } from 'landingPage/common/utils/getXianSuoDomain';

import { geminiSaleSkuSelectUsingPost, getCommonConfig } from 'landingPage/common/api';
import { transformFormSetting } from '../XiansuoFormDrag/transformFormSetting';
import {
    assertRequiredStore,
    XiansuoFormProps,
    getOtherSubmitData as getBaseOtherSubmitData,
    doTrackStoreSubmit,
    formClass,
} from '../XiansuoFormDrag/XiansuoForm';
import { Div } from '../Blank/Blank';
import FormUserAgreement from '../XiansuoFormDrag/components/FormUserAgreement';
import styles from './style.module.less';
import { getPayUrl } from './utils';
import { BaseForm, ExtraForm } from './commonFun';
import { signApi } from 'landingPage/common/utils/encrypt';
import { getCallbackInfo } from 'landingPage/common/adTrack/util/sendData';
import { useTheme } from '@/landingPage/common/utils/useTheme';

export interface xiansuoFormPayProps extends XiansuoFormProps {
    activityId: string;
    activityName: string;
    formId: string;
    itemId: string;
    itemName: string;
}

/** 获取表单中已填写的姓名和电话 */
export function getNameAndPhone(data: IGlobalData): [string | undefined, string | undefined] {
    let nameValue: string | undefined;
    let phoneValue: string | undefined;
    const { components } = data.data.formJson;
    const { results } = data;
    const nameComponentId = components.find((comp) => comp.componentType === 'NAME_TEXT')?.id;
    const phoneComponentId = components.find((comp) => comp.componentType === 'PHONE_TEXT')?.id;
    if (nameComponentId) {
        const res = results.find((result) => result.componentId === nameComponentId);
        if (res) {
            nameValue = res.value;
        }
    }
    if (phoneComponentId) {
        const res = results.find((result) => result.componentId === phoneComponentId);
        if (res) {
            phoneValue = res.value.phone;
        }
    }
    return [nameValue, phoneValue];
}

const pageId = getPageId();
const XiansuoFormPay: React.FunctionComponent<xiansuoFormPayProps> = (props) => {
    const {
        formOpt,
        showLabel,
        theme,
        children,
        funcId,
        placeholderColor,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        titleStyle,
        mainTitleStyle,
        formStyle,
        submitStyle,
        formSetting,
        submitWrapperStyle,
        formItemWrapperStyle,
        itemId,
        activityId,
        showCustomPrivacyAgreement,
        formVersion,
    } = props;

    const { edit, preview } = useComContext();
    const [show, setShow] = React.useState(false);
    const [payUrl, setPayUrl] = React.useState(() => getPayUrl());
    const [skuId, setSkuId] = React.useState<string>();
    React.useEffect(() => {
        getSkuId(itemId, setSkuId);
    }, [itemId]);
    React.useEffect(() => {
        getJumpUrl(setPayUrl);
    }, []);

    const { config, modalSetting } = usePayFormConfig({
        formOpt,
        formSetting,
        edit,
        preview,
        showLabel,
        theme,
        placeholderColor,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        submitWrapperStyle,
        formItemWrapperStyle,
        submitStyle,
        titleStyle,
        mainTitleStyle,
        onSuccess: () => {
            setShow(true);
        },
        isSSR,
        activityId,
        itemId,
        skuId,
        setSkuId,
        payUrl,
        customSuccessPage: true,
        showCustomPrivacyAgreement,
        formVersion,
    });
    const { editHeight } = useEditHeight(true);
    const [reRender, setReRender] = React.useState<any>(false);
    React.useEffect(() => {
        // 强制reRender，解决hydrate表单初次渲染不触发styled-components render的问题
        if (isSSRHydrate) {
            setReRender(true);
            window.setTimeout(() => {
                setReRender(false);
            });
        }
    }, []);
    return (
        <Div className={formClass} data-form-id={funcId} style={{ height: editHeight }}>
            <BaseForm
                placeholderColor={placeholderColor}
                formClass={formClass}
                reRender={reRender}
                config={config}
                formStyle={formStyle}
            />
            <ExtraForm
                // eslint-disable-next-line react/no-children-prop
                children={children}
                edit={edit}
                preview={preview}
                show={show}
                modalSetting={modalSetting}
                onClose={() => {
                    setShow(false);
                }}
            />
        </Div>
    );
};

export default XiansuoFormPay;

export interface PayFormConfigProps {
    formOpt?: any;
    formSetting?: any;
    edit?: boolean;
    preview?: boolean;
    showLabel?: 'show' | 'hidden';
    theme?: string;
    formStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    optionStyle?: React.CSSProperties;
    optionActiveStyle?: React.CSSProperties;
    placeholderColor?: string;
    submitWrapperStyle?: React.CSSProperties;
    formItemWrapperStyle?: React.CSSProperties;
    submitStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    mainTitleStyle?: React.CSSProperties;
    onSuccess?: () => void;
    isSSR?: boolean;
    activityId: string;
    skuId?: string;
    itemId: string;
    payUrl: string;
    setSkuId: (id: string) => void;
    customSuccessPage?: boolean;
    showCustomPrivacyAgreement?: boolean;
    formVersion?: string;
}

export const usePayFormConfig = (props: PayFormConfigProps) => {
    const {
        formOpt,
        formSetting,
        edit,
        preview,
        theme,
        showLabel,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        submitWrapperStyle,
        formItemWrapperStyle,
        submitStyle,
        titleStyle,
        mainTitleStyle,
        placeholderColor,
        onSuccess,
        isSSR,
        activityId,
        skuId,
        itemId,
        payUrl,
        setSkuId,
        customSuccessPage,
        showCustomPrivacyAgreement,
        formVersion,
    } = props;
    const { color: activeBackground } = useTheme({
        path: 'optionActiveStyle.backgroundColor',
        options: {
            type: 'activeFill',
        },
    });
    const { color: activeBorderColor } = useTheme({
        path: 'optionActiveStyle.borderColor',
        options: {
            type: 'border',
        },
    });
    const { color: activeColor } = useTheme({
        path: 'optionActiveStyle.color',
    });
    return React.useMemo(() => {
        if (!formOpt) {
            return {
                config: null,
            };
        }
        let realFormOpt;
        let modalSetting;
        let cunstomAgreementSetting;
        if (formSetting) {
            const res = transformFormSetting(formOpt, formSetting);
            realFormOpt = res.realFormOpt;
            modalSetting = res.modalSetting;
            cunstomAgreementSetting = formSetting?.privacy?.itemConfig;
        } else {
            realFormOpt = formOpt;
        }
        // const { realFormOpt, modalSetting } = transformFormSetting(formOpt, formSetting);
        const config: IClueForm = {
            domConfs: {
                theme,
                isPreview: !!(!!edit || preview),
                hideProtocol: true,
                showLabel: showLabel === 'show',
                /** 自定义用户协议，插入到提交按钮之前 */
                // customProtocolBefore: FormUserAgreement,
                customProtocolBefore: () => {
                    return (
                        <FormUserAgreement
                            showCustom={showCustomPrivacyAgreement}
                            hasCunstomContent={formSetting?.privacy?.switch}
                            {...cunstomAgreementSetting}
                            color={theme}
                        />
                    );
                },
                /** 自定义提交按钮文案 */
                customSubmitText: (origin) => `同意并${origin as string}`,
                formTheme: {
                    formStyle,
                    inputStyle: {
                        borderRadius: 4,
                        opacity: 1,
                        ...inputStyle,
                    },
                    optionStyle,
                    optionActiveStyle: {
                        ...optionActiveStyle,
                        backgroundColor: activeBackground,
                        borderColor: activeBorderColor,
                        color: activeColor,
                    },
                    submitWrapperStyle,
                    formItemWrapperStyle,
                    submitStyle,
                    titleStyle,
                    mainTitleStyle,
                    placeholderColor,
                },
                customSuccessPage,
            },
            data: {
                type: 'sync',
                pageId,
                getOtherSubmitData: () => {
                    const activityData = {
                        extMap: {
                            saleActivityId: activityId,
                        },
                    };
                    const base = getBaseOtherSubmitData();

                    return {
                        ...base,
                        extMap: {
                            ...base.extMap,
                            ...activityData.extMap,
                        },
                    };
                },
                pubBizId: 1003,
                formJson: realFormOpt as IAllFormJson,
                callback: getCallbackInfo(),
                renderType: 'XIANSUO_FORM_PAY',
                formVersion: realFormOpt?.version || formVersion,
            },
            callBackHooks: {
                onAutoFillStatusChange(status) {
                    trackFormAutoPhoneStatus(status);
                },
                beforeSubmit: async (params) => {
                    let signRes = '';
                    try {
                        signRes = await signApi({
                            query: { sigCatVer: 1 },
                            body: JSON.stringify({ ...params.formSubmitParam }),
                            // cookie: document.cookie,
                        });
                    } catch (e) {}

                    return {
                        ...params,
                        __NS_sig3: signRes,
                    };
                },
                submitBtnClick: () => {
                    dataTrack({ eventType: 'EVENT_CLICK_PAYMENT_BUTTON' });
                    trackClick('EVENT_CLICK_PAYMENT_BUTTON');
                    trackEvent('EVENT_CLICK_PAYMENT_BUTTON');
                    if (!assertRequiredStore()) {
                        Toast.info({
                            content: '提交失败！请选择门店',
                            className: styles.assertRequiredToast,
                        });
                        return false;
                    }
                    return true;
                },
                onSubmitSuccess: () => {
                    onSuccess?.();
                    doTrackStoreSubmit();
                    trackClick('EVENT_SUBMIT_PAYMENT');
                },
                onRealSubmitSuccess: (data, clueId) => {
                    console.log('onRealSubmitSuccess', data, clueId);
                    trackEvent('EVENT_SUBMIT_PAYMENT');
                    goToLocalLifePayPage({
                        data: data as IGlobalData,
                        clueId,
                        skuId,
                        itemId,
                        payUrl,
                        setSkuId,
                    });
                },
            },
            ssr: isSSR,
            env: isBeta ? 'beta' : isStaging ? 'test' : 'production',
            url: getXianSuoDomain().replace('https:', ''),
        };

        return {
            config,
            modalSetting,
        };
    }, [
        activityId,
        edit,
        formItemWrapperStyle,
        formOpt,
        formSetting,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        placeholderColor,
        preview,
        showLabel,
        submitStyle,
        submitWrapperStyle,
        theme,
        titleStyle,
        mainTitleStyle,
        isSSR,
        onSuccess,
        itemId,
        skuId,
        setSkuId,
        payUrl,
        // showCustomPrivacyAgreement,
    ]);
};

export const getSkuId = async (itemId: string, setId: (skuId: string) => void) => {
    if (!itemId) return;
    try {
        const skuId = await geminiSaleSkuSelectUsingPost({ itemId });
        setId(skuId);
        return skuId;
    } catch (err) {
        console.error(err);
        return false;
    }
};

/** 去支付 */
const goToLocalLifePayPage = async (props: {
    data: IGlobalData;
    clueId: any;
    skuId?: string;
    itemId: string;
    payUrl: string;
    setSkuId: (skuId: string) => void;
}) => {
    const { data, clueId, skuId, itemId, payUrl, setSkuId } = props;
    const [name, phone] = getNameAndPhone(data);
    let skuIdRes = skuId;
    // 动态获取skuId，第一次获取在组件初始化的时候，如果提交的时候发现第一次没获取到，就在表单提交完再次请求一遍，这次没获取到，就toast提醒用户
    if (!skuIdRes) {
        const res = await getSkuId(itemId, setSkuId);
        if (!res) {
            console.error('获取商品信息失败');
            Toast.error({
                content: '获取商品信息失败',
                className: 'skuErrorToast',
            });
            // 动态的修改一下toast的z-index样式，提高到最高层级
            const toastParent = document.querySelector('.skuErrorToast')?.parentNode as Element;
            toastParent?.classList.add('sku-error-toast-wrapper');
            return;
        }
        skuIdRes = res;
    }
    const params = {
        buyNum: 1, // 购买数量
        layoutType: 4, // 沉浸式头部
        enableWk: 1,
        itemId,
        skuId,
        platformSource: 29,
        channelType: 1, // 来源
        hyId: 'll-transaction-coupon', // 离线包Id
        clueId,
        phone,
        name,
        backUrl: window.location.href,
    };
    const target = `${payUrl}?${qs.stringify(params)}#/add-order`;
    console.log('goToLocalLifePayPage', target);
    window.setTimeout(() => {
        invoke('tool.loadUrlOnNewPage', {
            url: target,
            type: 'back',
        });
    }, 200);
};

export async function getJumpUrl(setUrl: (url: string) => void) {
    try {
        const { payJumpUrl } = await getCommonConfig();
        setUrl(payJumpUrl);
    } catch (err) {
        console.error(err);
    }
}
