import { IResult } from '@ad/xiansuoSDK';
import { IFormInfoView } from 'landingPage/services/models';

export interface AggregateItemProps {
    data: AggregateItemData;
    submitted: boolean;
    onSubmitted: (val: boolean) => void;
    pageStyle: PageStyle;
    aggregateId: string;
    feedData: FeedView;
    index: number;
}

export interface AggregateItemData {
    avatar: string;
    title: string;
    subTitle: string;
    tags?: string[];
    reservationNum: string;
    titleLabel: string;
    h5Url: string;
    preFormData: { name: string; phone: string };
    feedData: FeedView;
    productName: string;
}

export interface AggregatePageProps {
    // show: boolean;
    onClose: () => void;
    /** 是否有推荐落地页 */
    onResult: (boolean) => void;
    preValue: IResult[];
    preFormOpt: IFormInfoView;
}

export interface EapiRequestParams {
    appInfo: unknown;
    geoInfo: unknown;
    networkInfo: unknown;
    deviceInfo: unknown;
    userInfo: unknown;
}

export interface FeedBaseInfo {
    productName: string;
    firstIndustryIdV3: string;
    licenceIdNum: string;
    secondIndustryIdV3: string;
    accountId: string;
}

/** 聚合落地页样式 */
export enum PageStyle {
    /** 75屏 */
    POPUP,
    /** 弹层 */
    DIALOG,
}

export interface FeedView {
    ad: {
        adDataV2: {
            h5Url: string;
            coverActionBarInfo: {
                icon: string;
                title: string;
                subTitle: string;
                tags?: string[];
                weakTitle: string;
            };
        };
        llsid: string;
        baseInfo: FeedBaseInfo;
        bizSwitchInfo: {
            enableLeadsAggregation: boolean;
        };
    };
}

export enum IMPRESSION_SOURCE {
    /** 表单填写完成，自动展示 */
    AUTO,
    /** 用户点击按钮展示 */
    BUTTON,
}
