import * as React from 'react';

import { useComContext, wrapperClass } from 'omega-render';
import cs from 'classnames';
import './style.less';

// export const wrapperClass = 'rolex_com_wrapper';
// scale布局方式，当非整数时，block之间会出现缝隙gap，暂时margin:-1px hack一下
export const fixScaleGapClass = 'rolex-fix-scale-gap';
type HtmlType = keyof React.ReactHTML;
type SvgType = keyof React.ReactSVG;
type DomType = HtmlType | SvgType;
// export const comWrapperSelector = (id: ComponentJson['id']) => `.${wrapperClass}[data-id='${id}']`;
interface Props {
    className?: string;
}

const wrapperHoc: (type: DomType) => React.FC<Props> = (htmlType) =>
    React.forwardRef((props, ref) => {
        const { children, className, ...rest } = props;
        const { id, type } = useComContext();
        return React.createElement(
            htmlType,
            {
                'data-id': id,
                'data-component-type': type,
                ...rest,
                className: cs(
                    className,
                    wrapperClass,
                    htmlType === 'div' ? fixScaleGapClass : '',
                    'wrapperHocClass',
                ),
                ref,
            },
            children,
        );
    });
// Thanks to ReactDOMFactories for this handy list!

const domElements: DomType[] = [
    'a',
    'abbr',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'base',
    'bdi',
    'bdo',
    'big',
    'blockquote',
    'body',
    'br',
    'button',
    'canvas',
    'caption',
    'cite',
    'code',
    'col',
    'colgroup',
    'data',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'div',
    'dl',
    'dt',
    'em',
    'embed',
    'fieldset',
    'figcaption',
    'figure',
    'footer',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'head',
    'header',
    'hgroup',
    'hr',
    'html',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'link',
    'main',
    'map',
    'mark',
    // 'marquee',
    'menu',
    'menuitem',
    'meta',
    'meter',
    'nav',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'picture',
    'pre',
    'progress',
    'q',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'script',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strong',
    'style',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'u',
    'ul',
    'var',
    'video',
    'wbr',

    // SVG
    'circle',
    'clipPath',
    'defs',
    'ellipse',
    'foreignObject',
    'g',
    'image',
    'line',
    'linearGradient',
    'marker',
    'mask',
    'path',
    'pattern',
    'polygon',
    'polyline',
    'radialGradient',
    'rect',
    'stop',
    'svg',
    'text',
    'tspan',
];

type IComWrapper = {
    [K in HtmlType]: React.FC<JSX.IntrinsicElements[K]>;
};

export const ComWrapper = {} as unknown as IComWrapper;
domElements.forEach((key) => {
    ComWrapper[key] = wrapperHoc(key);
});
