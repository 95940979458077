import { Toast } from '@ad/sharpui';
// import { BizId } from '../enums';
// import { checkVersion } from './compareVersion';
import { getAdBridge } from '../bridge';
import { getCallbackInfo } from '../adTrack/util/sendData';
import { getUrlParam } from '../utils/getUrlParam';
import { dataTrack } from '../adTrack/util/track';
import { DeeplinkAppType } from '../../components/ButtonDrag/Button';
import { trackDeepLinkInvoke, deepLinkInvokeError } from '../adTrack/monitor';
import { isInApp } from 'omega-shared/utils';
import { bridge } from '@ad/ks-ad-alliance-bridge';

// 将page的callback赋值给链接
function addCallback(link: string) {
    const callback = getCallbackInfo();
    const ksCampaignId = getUrlParam('ksCampaignId');
    const ksUnitId = getUrlParam('ksUnitId');
    const ksCreativeId = getUrlParam('ksCreativeId');
    const place = { AID: ksUnitId, CID: ksCreativeId, DID: ksCampaignId };
    if (callback) {
        let replaceLink = link;
        for (const item in place) {
            if (link.includes(`__${item}__`)) {
                replaceLink = link.replace(`__${item}__`, place[`${item}`]);
            }
        }

        if (link.includes('__CALLBACK__')) {
            return replaceLink.replace('__CALLBACK__', callback);
        }

        if (link.includes('?')) {
            return `${replaceLink}&callback=${callback}`;
        }
        return `${replaceLink}?callback=${callback}`;
    }
    return link;
}
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
/**
 * 满足一定版本，会使用客户端的bridge判断调起是否成功
 * @param link 调起的链接
 * @param url 调起失败的链接
 */
export const callQuickApp = async (
    deepLink: string,
    download: () => void,
    hasAppInfo?: boolean,
) => {
    //
    Toast.info({
        content: '正在打开第三方应用，请稍后',
        duration: 1000,
    });
    await sleep(500);
    // await preventWakeUpApp();
    const iframe = document.createElement('iframe');
    // if (bizId === BizId.lianmeng) {
    // }
    iframe.src = addCallback(deepLink);
    iframe.style.display = 'none';
    // const checkRes = await checkVersion({ appVersionLimit: '7.3.20', nebulaVersionLimit: '2.3.3' });
    const hasAdBridge = checkAdBridge();

    if (!hasAdBridge) {
        console.error(' callQuickApp check ad-bridge error ');
    }

    const callDeeplinkError = (errorMsg: string) => {
        console.error(errorMsg);
        dataTrack({
            eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
            deeplink_app_type: DeeplinkAppType.QUICK_APPLICATION,
            diyExtraInfo: JSON.stringify({
                renderType: 'APP_DOWNLOAD_DRAG',
            }),
        });
        deepLinkInvokeError(DeeplinkAppType.QUICK_APPLICATION, 'APP_DOWNLOAD_DRAG');
        if (hasAppInfo) {
            download?.();
        } else {
            dataTrack({
                eventType: 'EVENT_INSTALL_PACKAGE_GET_FAILED',
                diyExtraInfo: JSON.stringify({
                    renderType: 'APP_DOWNLOAD_DRAG',
                }),
            });
            Toast.error('快应用打开失败，请重试');
        }
    };
    // const deviceInfo = await ksBridge.getDeviceInfo({});
    // 信息流校验版本失败或者没有ad-bridge,走web跳转
    trackDeepLinkInvoke(DeeplinkAppType.QUICK_APPLICATION, 'APP_DOWNLOAD_DRAG');
    if (!hasAdBridge) {
        document.body.appendChild(iframe);
        window.setTimeout(() => {
            document.body.removeChild(iframe);
        }, 200);
        let timer: number | null = window.setTimeout(() => {
            callDeeplinkError('low version call quickApp deeplink error');
        }, 4000);
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                timer != null && clearTimeout(timer);
                timer = null;
            }
        });
    } else {
        const listenerSuccess = ({ result }) => {
            if (result !== 1) {
                // callH5();

                callDeeplinkError(
                    `registerDeeplinkListener success but result not 1${JSON.stringify(result)}`,
                );
                // throw new Error(errorMsg);
            } else {
                dataTrack({
                    eventType: 'EVENT_DEEPLINK_INVOKED',
                    deeplink_app_type: DeeplinkAppType.QUICK_APPLICATION,
                    diyExtraInfo: JSON.stringify({
                        renderType: 'APP_DOWNLOAD_DRAG',
                    }),
                });
            }
        };
        const listenerError = (err) => {
            callDeeplinkError(`registerDeeplinkListener error${JSON.stringify(err)}`);
        };
        if (!isInApp) {
            bridge.registerDeeplinkListener({
                success: listenerSuccess,
                error: listenerError,
            });
        } else {
            try {
                getAdBridge()?.registerDeeplinkListener({
                    success: listenerSuccess,
                    error: listenerError,
                });
            } catch (err) {
                listenerError(err);
            }
        }
        window.setTimeout(() => {
            document.body.appendChild(iframe);
            window.setTimeout(() => {
                document.body.removeChild(iframe);
            }, 500);
        }, 200);
    }
};

export function checkAdBridge() {
    const JsBridge = window.KwaiAd;
    return !!JsBridge;
}
