// 代码生成的文件，不要编辑
import * as SuggestionForFiction from 'landingPage/components/SuggestionForFiction';
import * as AGREEMENT from '../components/Agreement';
import * as APP_DOWNLOAD from '../components/AppDownload';
import * as APP_DOWNLOAD_DRAG from '../components/AppDownloadDrag';
import * as BLANK from '../components/Blank';
import * as BUTTON from '../components/Button';
import * as BUTTON_DRAG from '../components/ButtonDrag';
import * as COUNTDOWN_DRAG from '../components/CountdownDrag';
import * as COUPON_CARD from '../components/CouponCard';
import * as CUSTOMER_SERVICE from '../components/CustomerService';
import * as CUSTOMER_SERVICE_DRAG from '../components/CustomerServiceDrag';
import * as CUSTOMER_SERVICE_PAGE from '../components/CustomerServicePage';

import * as DRAG_ELEMENT from '../components/DragElement';
import * as DRAG_IMAGE from '../components/DragImage';
import * as DRAG_INTERACTION_RECORD from '../components/DragInteractionRecord';
import * as DRAG_TEXT from '../components/DragText';
// import * as FORM from '../components/Form';
import * as IMAGE from '../components/Image';
import * as MAP from '../components/Map';
import * as MAP_DRAG from '../components/MapDrag';
import * as MULTI_CONSULT from '../components/MultiConsult';
import * as MULTI_CONSULT_DRAG from '../components/MultiConsultDrag';
import * as SHUFFLING_IMAGE from '../components/ShufflingImage';
import * as SHUFFLING_IMAGE_DRAG from '../components/ShufflingImageDrag';
import * as STORE from '../components/Store';
import * as STORE_PAGE from '../components/StorePage';
import * as TEL from '../components/Tel';
import * as TEL_DRAG from '../components/TelDrag';
import * as TEXT from '../components/Text';
import * as VIDEO from '../components/Video';
import * as VIDEO_DRAG from '../components/VideoDrag';
import * as WEI_XIN from '../components/WeiXin';
import * as WEI_XIN_DRAG from '../components/WeiXinDrag';
import * as XIANSUO_FORM_BASE from '../components/XiansuoFormBase';
import * as XIANSUO_FORM_MULTI from '../components/XiansuoFormMulti';
import * as XIANSUO_FORM_PAY from '../components/xiansuoFormPay';
import * as WECHAT_GAME from '../components/WechatGame';
import * as Collapse from '../components/Collapse';
import * as PERMISSION_INFO from '../components/PermissionInfo';
import * as NAVBACK_FOR_MIDPAGE from '../components/NavBackForMidPage';
import * as PEC_DOWNLOAD_FOR_MIDPAGE from '../components/PecDownloadForMidpage';
import * as CORPORATE_INFO from '../components/CorporateInfo';
import * as ACTIVITY_CODE from '../components/ActivityCode';
import * as FUNCTION_INTRODUCTION from '../components/FunctionIntroduction';
import * as PrivacyForMidpage from '../components/PrivacyForMidPage';
import * as CollapseText from '../components/CollapseText';
import * as ScreenTopBar from '../components/ScreenTopBar';
import * as InteractionComment from '../components/InteractionComment';

export const componentsMap = {
    AGREEMENT: () => AGREEMENT,
    APP_DOWNLOAD: () => APP_DOWNLOAD,
    APP_DOWNLOAD_DRAG: () => APP_DOWNLOAD_DRAG,
    BLANK: () => BLANK,
    BUTTON: () => BUTTON,
    BUTTON_DRAG: () => BUTTON_DRAG,
    COUPON_CARD: () => COUPON_CARD,
    COUNTDOWN_DRAG: () => COUNTDOWN_DRAG,
    CUSTOMER_SERVICE: () => CUSTOMER_SERVICE,
    CUSTOMER_SERVICE_PAGE: () => CUSTOMER_SERVICE_PAGE,
    CUSTOMER_SERVICE_DRAG: () => CUSTOMER_SERVICE_DRAG,
    DRAG_ELEMENT: () => DRAG_ELEMENT,
    DRAG_IMAGE: () => DRAG_IMAGE,
    DRAG_INTERACTION_RECORD: () => DRAG_INTERACTION_RECORD,
    DRAG_TEXT: () => DRAG_TEXT,
    // FORM: () => FORM,
    IMAGE: () => IMAGE,
    MAP: () => MAP,
    MAP_DRAG: () => MAP_DRAG,
    MULTI_CONSULT: () => MULTI_CONSULT,
    MULTI_CONSULT_DRAG: () => MULTI_CONSULT_DRAG,
    SHUFFLING_IMAGE: () => SHUFFLING_IMAGE,
    SHUFFLING_IMAGE_DRAG: () => SHUFFLING_IMAGE_DRAG,
    STORE: () => STORE,
    STORE_PAGE: () => STORE_PAGE,
    TEL: () => TEL,
    TEL_DRAG: () => TEL_DRAG,
    TEXT: () => TEXT,
    VIDEO: () => VIDEO,
    VIDEO_DRAG: () => VIDEO_DRAG,
    WEI_XIN: () => WEI_XIN,
    WEI_XIN_DRAG: () => WEI_XIN_DRAG,
    XIANSUO_FORM_DRAG: () => XIANSUO_FORM_BASE,
    XIANSUO_FORM: () => XIANSUO_FORM_MULTI,
    XIANSUO_FORM_PAY: () => XIANSUO_FORM_PAY,
    WECHAT_GAME: () => WECHAT_GAME,
    Collapse: () => Collapse,
    SuggestionForFiction: () => SuggestionForFiction,
    PERMISSION_INFO: () => PERMISSION_INFO,
    NAVBACK_FOR_MIDPAGE: () => NAVBACK_FOR_MIDPAGE,
    PEC_DOWNLOAD_FOR_MIDPAGE: () => PEC_DOWNLOAD_FOR_MIDPAGE,
    CORPORATE_INFO: () => CORPORATE_INFO,
    ACTIVITY_CODE: () => ACTIVITY_CODE,
    FUNCTION_INTRODUCTION: () => FUNCTION_INTRODUCTION,
    PRIVACY_FOR_MIDPAGE: () => PrivacyForMidpage,
    COLLAPSE_TEXT: () => CollapseText,
    SCREEN_TOP_BAR: () => ScreenTopBar,
    INTERACTION_COMMENT: () => InteractionComment,
};
export type ComponentType = keyof typeof componentsMap;
