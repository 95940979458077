import { useEffect, useRef } from 'react';

/**
 * 用于获取上一次渲染时的值。
 * @param value 当前的值。
 * @returns 上一次渲染时的值。
 */
export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
