import "../chunk-5JCHA7OU.js";
import "../chunk-CLJEFBYP.js";

// utils/env.ts
var isDevelopment = process.env.NODE_ENV === "development";
var isTest = window.location.origin.includes("test") || window.location.origin.includes("jinx");
var isBeta = window.location.origin.includes("beta") || window.location.origin.includes("prt");
var isStaging = window.location.origin.includes("staging");
var isPrt = window.location.origin.includes("prt");
var isOnline = window.location.origin.includes("moli.kuaishou.com");
var isSSR = process.env.RUNTIME === "server";
var ua = navigator == null ? void 0 : navigator.userAgent;
var isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isSSRHydrate = !!window.INIT;
var _a;
var basePath = (_a = process.env.REACT_APP_BASE_PATH) != null ? _a : "";
function getCookie(objName) {
  const arrStr = document.cookie.split("; ");
  let temp;
  for (let i = 0; i < arrStr.length; i++) {
    temp = arrStr[i].split("=");
    if (temp[0] === objName) {
      return unescape(temp[1] || "");
    }
  }
  return "";
}
var isInApp = isSSR ? false : / (Kwai|Kwai_Lite|Kwai_Pro|ksthanos|ksNebula|livemate|kswv)\//i.test(ua) || getCookie("appver").length > 0;
var RenderEnv = class {
  static init(preview, edit) {
    preview && (this.preview = preview);
    edit && (this.edit = edit);
  }
};
RenderEnv.preview = false;
RenderEnv.edit = false;
function getUrlParam(name) {
  var _a2;
  const search = new URLSearchParams(window.location.search);
  if (typeof name === "string") {
    return (_a2 = search.get(name)) != null ? _a2 : "";
  }
  return name.map((n) => {
    var _a3;
    return (_a3 = search.get(n)) != null ? _a3 : "";
  });
}

// utils/zIndex.ts
var ComponentZIndex = /* @__PURE__ */ ((ComponentZIndex2) => {
  ComponentZIndex2[ComponentZIndex2["ButtonDragZIndex"] = 999] = "ButtonDragZIndex";
  ComponentZIndex2[ComponentZIndex2["SelectComponentZIndex"] = 9998] = "SelectComponentZIndex";
  ComponentZIndex2[ComponentZIndex2["IMComponentZIndex"] = 1999] = "IMComponentZIndex";
  ComponentZIndex2[ComponentZIndex2["HandleZIndex"] = 9999] = "HandleZIndex";
  ComponentZIndex2[ComponentZIndex2["WechatZIndex"] = 1e4] = "WechatZIndex";
  ComponentZIndex2[ComponentZIndex2["InteractivePopup"] = 3e3] = "InteractivePopup";
  ComponentZIndex2[ComponentZIndex2["CouponZIndex"] = 1e3] = "CouponZIndex";
  return ComponentZIndex2;
})(ComponentZIndex || {});

// utils/validate.ts
var parseUnion = (union) => {
  const rule = union;
  let [min, max] = rule.slice(1).split(":").map((s, index) => {
    const num = parseInt(s, 10);
    if (Number.isNaN(num)) {
      return index === 0 ? -Infinity : Infinity;
    }
    return num;
  });
  const firstStr = rule[0];
  const lastStr = rule[rule.length - 1];
  if (firstStr === "[") {
    min--;
  }
  if (lastStr === "]") {
    max++;
  }
  return [min, max];
};

// utils/appCenter.ts
var jumpNewAppCenter = () => {
  const [accountId] = getUrlParam(["__accountId__"]);
  const urlMap = {
    test: `https://master--kuaishou-frontend-app-platform.jinx.staging.kuaishou.com?__accountId__=${accountId}`,
    prod: `https://app-platform.e.kuaishou.com?__accountId__=${accountId}`
  };
  window.open(isOnline ? urlMap.prod : urlMap.test);
};

// utils/getFormComponent.ts
import _ from "lodash";
var WeChatType = /* @__PURE__ */ ((WeChatType2) => {
  WeChatType2[WeChatType2["SINGLE"] = 1] = "SINGLE";
  WeChatType2[WeChatType2["PACKAGE"] = 2] = "PACKAGE";
  WeChatType2[WeChatType2["MINIAPP"] = 3] = "MINIAPP";
  WeChatType2[WeChatType2["KEFU"] = 4] = "KEFU";
  WeChatType2[WeChatType2["ASSISTANT"] = 5] = "ASSISTANT";
  WeChatType2[WeChatType2["ASSISTANT_GROUP"] = 6] = "ASSISTANT_GROUP";
  return WeChatType2;
})(WeChatType || {});
var PlanType = /* @__PURE__ */ ((PlanType2) => {
  PlanType2[PlanType2["KEFU"] = 1] = "KEFU";
  PlanType2[PlanType2["HK_LINK"] = 2] = "HK_LINK";
  return PlanType2;
})(PlanType || {});
var ChatStyleTypeEnum = /* @__PURE__ */ ((ChatStyleTypeEnum2) => {
  ChatStyleTypeEnum2[ChatStyleTypeEnum2["FullScreen"] = 0] = "FullScreen";
  ChatStyleTypeEnum2[ChatStyleTypeEnum2["InsertScreen"] = 1] = "InsertScreen";
  return ChatStyleTypeEnum2;
})(ChatStyleTypeEnum || {});
var componentsForEach = (components, fun) => {
  function it(components2) {
    if (!components2) {
      return;
    }
    components2.forEach((com, index) => {
      fun(com, index);
      it(com.components);
    });
  }
  it(components);
};
var JumpType = /* @__PURE__ */ ((JumpType2) => {
  JumpType2["H5\u94FE\u63A5"] = "h5";
  JumpType2["DP\u94FE\u63A5"] = "deeplink";
  return JumpType2;
})(JumpType || {});
function pickFormType(wechatType, singleWechat, gongzhonghao, miniApp, kefu, huoke) {
  if (wechatType === 1 /* SINGLE */) {
    return singleWechat;
  } else if (wechatType === 2 /* PACKAGE */) {
    return gongzhonghao;
  } else if (wechatType === 3 /* MINIAPP */) {
    return miniApp;
  } else if (wechatType === 4 /* KEFU */) {
    return kefu;
  } else if (wechatType === 5 /* ASSISTANT */) {
    return huoke;
  }
}
function getWexinKefuType(params) {
  const { pageJson, isPageMeta } = params;
  if (!pageJson)
    return [];
  let pageJsonString = "";
  try {
    pageJsonString = JSON.stringify(pageJson);
  } catch (e) {
    console.error(e);
  }
  let arr = [];
  const hasKf = pageJsonString.match(/"wechatType":4/g);
  const stayIsOpen = pageJsonString.match(/"stayStatus":2/g);
  if (isPageMeta && !stayIsOpen) {
    return [];
  }
  const hashz = pageJsonString.match(/"wechatType":5/g) || pageJsonString.match(/"wechatType":6/g);
  const kefuWithHkzs = pageJsonString.match(/"planType":2/g);
  if (hasKf) {
    if (kefuWithHkzs) {
      arr.push(8 /* KEFU_WITH_HUOKE */);
    } else {
      arr.push(1 /* WEIXIN_KEFU */);
    }
  }
  if (hashz) {
    arr.push(2 /* WEIXIN_HUOKE */);
  }
  return arr;
}
var handleDuihuaFormCompType = (params) => {
  const { displayType, components, chatStyleType } = params;
  let hasValidOption = false;
  let formCompTypeList = [];
  components.forEach((item) => {
    var _a2, _b;
    (_b = (_a2 = item == null ? void 0 : item.desc) == null ? void 0 : _a2.options) == null ? void 0 : _b.forEach((opt) => {
      if (opt == null ? void 0 : opt.ifValid) {
        hasValidOption = true;
      }
    });
  });
  if (hasValidOption) {
    formCompTypeList.push(9 /* INTERTEST_FORM */);
  }
  if (displayType === "multi" /* MULTI */) {
    formCompTypeList.push(3 /* FENBU_FORM */);
  } else {
    if (chatStyleType === 1 /* InsertScreen */) {
      formCompTypeList.push(7 /* DUIHUA_FORM_INSERT */);
    } else {
      formCompTypeList.push(4 /* DUIHUA_FORM */);
    }
  }
  return formCompTypeList;
};
var getFormComponentType = (json) => {
  if (!json || !(json == null ? void 0 : json.components)) {
    return {
      formComponentType: [],
      subFormComponentType: []
    };
  }
  const newJson = _.cloneDeep({
    components: json.components,
    pageMeta: json.pageMeta
  });
  const component = newJson.components;
  let temp = {
    formComponentType: [],
    subFormComponentType: []
  };
  let componentWeixinTypeList = getWexinKefuType({
    pageJson: newJson.components
  });
  let metaWeixinTypeList = getWexinKefuType({
    pageJson: newJson.pageMeta,
    isPageMeta: true
  });
  const weixinTypeList = Array.from(/* @__PURE__ */ new Set([...componentWeixinTypeList, ...metaWeixinTypeList]));
  console.log("weixinTypeList===", weixinTypeList);
  if (weixinTypeList.length > 0) {
    temp.formComponentType = temp.formComponentType.concat(weixinTypeList);
  }
  componentsForEach(component, (com) => {
    var _a2, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _Y, _Z, __, _$, _aa, _ba, _ca;
    const { type, props } = com;
    if ((type === "XIANSUO_FORM_DRAG" || type === "XIANSUO_FORM") && props) {
      if (props.displayType === "single" /* SINGLE */ && (((_a2 = props.formOpt) == null ? void 0 : _a2.substepFormFollowActionType) === "" && ((_c = (_b = props.formOpt) == null ? void 0 : _b.substepFormFollowFormComponents) == null ? void 0 : _c.length) === 0 || !((_d = props.formOpt) == null ? void 0 : _d.substepFormFollowActionType)) && ((_f = (_e = props.formOpt) == null ? void 0 : _e.tierFormComponents) == null ? void 0 : _f.length) === 0) {
        temp.formComponentType.push(6 /* NORMAL_FORM */);
        if (JSON.stringify(props).indexOf("wechatType") < 0) {
          if (((_j = (_i = (_h = (_g = props.formSetting) == null ? void 0 : _g.submitTurn) == null ? void 0 : _h.itemConfig) == null ? void 0 : _i.submitTurnValue) == null ? void 0 : _j.jumpType) === "h5" /* H5链接 */) {
            temp.subFormComponentType.push(29 /* NORMAL_FORM_WITH_H5 */);
          } else if (((_n = (_m = (_l = (_k = props.formSetting) == null ? void 0 : _k.submitTurn) == null ? void 0 : _l.itemConfig) == null ? void 0 : _m.submitTurnValue) == null ? void 0 : _n.jumpType) === "deeplink" /* DP链接 */) {
            temp.subFormComponentType.push(
              30 /* NORMAL_FORM_WITH_DEEPLINK */
            );
          } else {
            temp.subFormComponentType.push(0 /* NEW_FORM */);
          }
        } else {
          const type2 = pickFormType(
            ((_r = (_q = (_p = (_o = props.formSetting) == null ? void 0 : _o.submitTurn) == null ? void 0 : _p.itemConfig) == null ? void 0 : _q.weChatOpt) == null ? void 0 : _r.wechatType) || ((_w = (_v = (_u = (_t = (_s = props.formSetting) == null ? void 0 : _s.submitTurn) == null ? void 0 : _t.itemConfig) == null ? void 0 : _u.weChatOpt) == null ? void 0 : _v.opt) == null ? void 0 : _w.wechatType),
            4 /* NORMAL_FORM_WITH_SINGLE_WECHAT */,
            5 /* NORMAL_FORM_WITH_GONGZHONGHAO */,
            6 /* NORMAL_FORM_WITH_MINIAPP */,
            ((_B = (_A = (_z = (_y = (_x = props.formSetting) == null ? void 0 : _x.submitTurn) == null ? void 0 : _y.itemConfig) == null ? void 0 : _z.weChatOpt) == null ? void 0 : _A.opt) == null ? void 0 : _B.planType) === 2 /* HK_LINK */ ? 25 /* NORMAL_FORM_WITH_KEFU_HUOKE */ : 7 /* NORMAL_FORM_WITH_KEFU */,
            8 /* NORMAL_FORM_WITH_HUOKE */
          );
          temp.subFormComponentType.push(type2);
        }
      } else if (props.displayType === "multi" /* MULTI */ && ((_C = props.formOpt) == null ? void 0 : _C.tag) === "STEP_FORM" && ((_E = (_D = props.formOpt) == null ? void 0 : _D.tierFormComponents) == null ? void 0 : _E.length) === 0) {
        const formTypeList = handleDuihuaFormCompType({
          displayType: props.displayType,
          components: (_F = props.formOpt) == null ? void 0 : _F.components
        });
        temp.formComponentType = temp.formComponentType.concat(formTypeList);
        if (((_H = (_G = props.formOpt) == null ? void 0 : _G.substepFormFollowFormComponents) == null ? void 0 : _H.length) > 0 && ((_I = props.formOpt) == null ? void 0 : _I.substepFormFollowActionType) === "form") {
          temp.subFormComponentType.push(1 /* ONLY_FENBU_FORM */);
        } else {
          const type2 = pickFormType(
            props.wechatType || props.wechatOpt.wechatType,
            9 /* FENBU_FORM_WITH_SINGLE_WECHAT */,
            10 /* FENBU_FORM_WITH_GONGZHONGHAO */,
            11 /* FENBU_FORM_WITH_MINIAPP */,
            ((_J = props == null ? void 0 : props.wechatOpt) == null ? void 0 : _J.planType) === 2 /* HK_LINK */ ? 26 /* FENBU_FORM_WITH_KEFU_HUOKE */ : 12 /* FENBU_FORM_WITH_KEFU */,
            13 /* FENBU_FORM_WITH_HUOKE */
          );
          temp.subFormComponentType.push(type2);
        }
      } else if (props.displayType === "chat" /* CHAT */ && ((_K = props.formOpt) == null ? void 0 : _K.tag) === "STEP_FORM" && ((_M = (_L = props.formOpt) == null ? void 0 : _L.tierFormComponents) == null ? void 0 : _M.length) === 0) {
        const formTypeList = handleDuihuaFormCompType({
          displayType: props.displayType,
          chatStyleType: props.chatStyleType,
          components: (_N = props.formOpt) == null ? void 0 : _N.components
        });
        temp.formComponentType = temp.formComponentType.concat(formTypeList);
        if (props.chatStyleType === 1 /* InsertScreen */) {
          if (((_O = props.formOpt) == null ? void 0 : _O.substepFormFollowActionType) === "form" && ((_P = props == null ? void 0 : props.formOpt) == null ? void 0 : _P.substepFormFollowFormComponents.length) > 0) {
            temp.subFormComponentType.push(
              14 /* DUIHUA_FORM_INSERT_WITH_FORM */
            );
          } else {
            const type2 = pickFormType(
              props.wechatType || props.wechatOpt.wechatType,
              20 /* DUIHUA_FORM_INSERT_WITH_SINGLE_WECHAT */,
              21 /* DUIHUA_FORM_INSERT_WITH_GONGZHONGHAO */,
              22 /* DUIHUA_FORM_INSERT_WITH_MINIAPP */,
              ((_Q = props == null ? void 0 : props.wechatOpt) == null ? void 0 : _Q.planType) === 2 /* HK_LINK */ ? 28 /* DUIHUA_FORM_INSERT_WITH_KEFU_HUOKE */ : 23 /* DUIHUA_FORM_INSERT_WITH_KEFU */,
              24 /* DUIHUA_FORM_INSERT_WITH_HUOKE */
            );
            temp.subFormComponentType.push(type2);
          }
        } else {
          if (((_R = props.formOpt) == null ? void 0 : _R.substepFormFollowActionType) === "form" && ((_S = props == null ? void 0 : props.formOpt) == null ? void 0 : _S.substepFormFollowFormComponents.length) > 0) {
            temp.subFormComponentType.push(2 /* DUIHUA_FORM */);
          } else {
            const type2 = pickFormType(
              props.wechatType || props.wechatOpt.wechatType,
              15 /* DUIHUA_FORM_WITH_SINGLE_WECHAT */,
              16 /* DUIHUA_FORM_WITH_GONGZHONGHAO */,
              17 /* DUIHUA_FORM_WITH_MINIAPP */,
              ((_T = props == null ? void 0 : props.wechatOpt) == null ? void 0 : _T.planType) === 2 /* HK_LINK */ ? 27 /* DUIHUA_FORM_WITH_KEFU_HUOKE */ : 18 /* DUIHUA_FORM_WITH_KEFU */,
              19 /* DUIHUA_FORM_WITH_HUOKE */
            );
            temp.subFormComponentType.push(type2);
          }
        }
      } else if (props.displayType === "single" /* SINGLE */ && ((_U = props.formOpt) == null ? void 0 : _U.substepFormFollowActionType) === "" && ((_V = props.formOpt) == null ? void 0 : _V.substepFormFollowFormComponents.length) === 0 && ((_W = props.formOpt) == null ? void 0 : _W.tierFormComponents.length) > 0) {
        switch ((_Z = (_Y = (_X = props.formSetting) == null ? void 0 : _X.submitTurn) == null ? void 0 : _Y.itemConfig) == null ? void 0 : _Z.afterSuccessDisplayType) {
          case 4 /* TIER_FORM */:
            temp.formComponentType.push(5 /* FENCENG_FORM */);
            temp.subFormComponentType.push(3 /* ONLY_FENCENG_FORM */);
            break;
          case 1 /* LINK */:
            if (((_ba = (_aa = (_$ = (__ = props.formSetting) == null ? void 0 : __.submitTurn) == null ? void 0 : _$.itemConfig) == null ? void 0 : _aa.submitTurnValue) == null ? void 0 : _ba.jumpType) === "deeplink" /* DP链接 */) {
              temp.subFormComponentType.push(
                30 /* NORMAL_FORM_WITH_DEEPLINK */
              );
            } else {
              temp.subFormComponentType.push(
                29 /* NORMAL_FORM_WITH_H5 */
              );
            }
            temp.formComponentType.push(6 /* NORMAL_FORM */);
            break;
          case 2 /* WE_CHAT */:
            const type2 = pickFormType(
              props.wechatType || props.wechatOpt.wechatType,
              15 /* DUIHUA_FORM_WITH_SINGLE_WECHAT */,
              16 /* DUIHUA_FORM_WITH_GONGZHONGHAO */,
              17 /* DUIHUA_FORM_WITH_MINIAPP */,
              ((_ca = props == null ? void 0 : props.wechatOpt) == null ? void 0 : _ca.planType) === 2 /* HK_LINK */ ? 27 /* DUIHUA_FORM_WITH_KEFU_HUOKE */ : 18 /* DUIHUA_FORM_WITH_KEFU */,
              19 /* DUIHUA_FORM_WITH_HUOKE */
            );
            temp.formComponentType.push(6 /* NORMAL_FORM */);
            temp.subFormComponentType.push(type2);
            break;
          default:
            temp.formComponentType.push(5 /* FENCENG_FORM */);
            temp.subFormComponentType.push(3 /* ONLY_FENCENG_FORM */);
            break;
        }
      }
    }
    return com;
  });
  return temp;
};
export {
  ChatStyleTypeEnum,
  ComponentZIndex,
  JumpType,
  PlanType,
  RenderEnv,
  WeChatType,
  basePath,
  componentsForEach,
  getCookie,
  getFormComponentType,
  getUrlParam,
  isBeta,
  isDevelopment,
  isIOS,
  isInApp,
  isOnline,
  isPrt,
  isSSR,
  isSSRHydrate,
  isStaging,
  isTest,
  jumpNewAppCenter,
  parseUnion,
  ua
};
