import { useComContext, useRenderState } from 'omega-render';
import { useEffect, useState } from 'react';
import { Div } from '../Blank/Blank';
import styles from './style.module.less';

const IconHeight = 80;
const MinHeight = 200;

interface IProps {
    backgroundColor: string;
    moreFontColor: string;
    continueImg: string;
    moreText: string;
}
const Collapse: React.FunctionComponent<IProps> = (props) => {
    const data = useComContext();
    const components = useRenderState(
        (state) => state.rolex.componentsMap[data.id]?.json?.components,
    );
    const { children, backgroundColor, moreFontColor, continueImg, moreText } = props;
    const [expand, setExtend] = useState(false);
    const [outheight, setOutHeight] = useState(MinHeight);
    useEffect(() => {
        if (expand) {
            // 这里的计算针对小说模板，有定制性
            const height = components?.reduce((last, next) => {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                return last + (next.props?.style?.height ?? 0);
            }, 0);
            setOutHeight(height as number);
        } else {
            setOutHeight(MinHeight);
        }
    }, [expand]);

    return (
        <Div className={styles.root} style={{ height: outheight, backgroundColor }}>
            <div
                className={styles.contentWrapper}
                style={{ height: !expand ? outheight - IconHeight : outheight }}
            >
                {children}
            </div>
            {!expand && (
                <div
                    className={styles.expandWrapper}
                    onClick={() => {
                        setExtend(!expand);
                    }}
                >
                    {/* {expand ? '收起' : '展开'} */}
                    <div style={{ color: moreFontColor }}>{moreText}</div>
                    <img src={continueImg} />
                </div>
            )}
        </Div>
    );
};

export default Collapse;
