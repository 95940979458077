var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// types/index.ts
var types_exports = {};
__export(types_exports, {
  AfterSuccessDisplayType: () => AfterSuccessDisplayType,
  BizId: () => BizId,
  ConsultType: () => ConsultType,
  DIALOG_STYLE: () => DIALOG_STYLE,
  EnumPageType: () => EnumPageType,
  IM_TYPE: () => IM_TYPE,
  MoveType: () => MoveType,
  gameStyle: () => gameStyle
});
module.exports = __toCommonJS(types_exports);

// types/components/form.ts
var AfterSuccessDisplayType = /* @__PURE__ */ ((AfterSuccessDisplayType2) => {
  AfterSuccessDisplayType2[AfterSuccessDisplayType2["NO"] = 0] = "NO";
  AfterSuccessDisplayType2[AfterSuccessDisplayType2["LINK"] = 1] = "LINK";
  AfterSuccessDisplayType2[AfterSuccessDisplayType2["WE_CHAT"] = 2] = "WE_CHAT";
  AfterSuccessDisplayType2[AfterSuccessDisplayType2["TIER_FORM"] = 4] = "TIER_FORM";
  return AfterSuccessDisplayType2;
})(AfterSuccessDisplayType || {});

// types/common.ts
var BizId = /* @__PURE__ */ ((BizId2) => {
  BizId2[BizId2["xinxiliu"] = 0] = "xinxiliu";
  BizId2[BizId2["lianmeng"] = 1] = "lianmeng";
  return BizId2;
})(BizId || {});
var EnumPageType = /* @__PURE__ */ ((EnumPageType2) => {
  EnumPageType2[EnumPageType2["\u95E8\u5E97\u843D\u5730\u9875"] = 3] = "\u95E8\u5E97\u843D\u5730\u9875";
  EnumPageType2[EnumPageType2["\u4F18\u9009"] = 4] = "\u4F18\u9009";
  EnumPageType2[EnumPageType2["\u667A\u80FD\u4E2D\u95F4\u9875"] = 5] = "\u667A\u80FD\u4E2D\u95F4\u9875";
  EnumPageType2[EnumPageType2["\u5927\u56FE\u4E2D\u95F4\u9875"] = 6] = "\u5927\u56FE\u4E2D\u95F4\u9875";
  EnumPageType2[EnumPageType2["\u5927\u767D\u4E2D\u95F4\u9875"] = 7] = "\u5927\u767D\u4E2D\u95F4\u9875";
  return EnumPageType2;
})(EnumPageType || {});
var MoveType = /* @__PURE__ */ ((MoveType2) => {
  MoveType2["TOP"] = "top";
  MoveType2["RIGHT"] = "right";
  MoveType2["BOTTOM"] = "bottom";
  MoveType2["LEFT"] = "left";
  MoveType2["NONE"] = "none";
  return MoveType2;
})(MoveType || {});
var DIALOG_STYLE = /* @__PURE__ */ ((DIALOG_STYLE2) => {
  DIALOG_STYLE2[DIALOG_STYLE2["DEFAULT"] = 0] = "DEFAULT";
  DIALOG_STYLE2[DIALOG_STYLE2["LONG_PRESS"] = 1] = "LONG_PRESS";
  DIALOG_STYLE2[DIALOG_STYLE2["QRCODE"] = 2] = "QRCODE";
  DIALOG_STYLE2[DIALOG_STYLE2["JUMPWX"] = 3] = "JUMPWX";
  DIALOG_STYLE2[DIALOG_STYLE2["QRCODEDISABLED"] = 4] = "QRCODEDISABLED";
  DIALOG_STYLE2[DIALOG_STYLE2["JUMPWXDISABLED"] = 5] = "JUMPWXDISABLED";
  DIALOG_STYLE2[DIALOG_STYLE2["DIRECT_ATTENTION"] = 6] = "DIRECT_ATTENTION";
  DIALOG_STYLE2[DIALOG_STYLE2["DIRECT_ATTENTION_DISABLED"] = 7] = "DIRECT_ATTENTION_DISABLED";
  DIALOG_STYLE2[DIALOG_STYLE2["KEFU"] = 8] = "KEFU";
  DIALOG_STYLE2[DIALOG_STYLE2["ACQUISITION_ASSISTANT"] = 9] = "ACQUISITION_ASSISTANT";
  DIALOG_STYLE2[DIALOG_STYLE2["ACQUISITION_ASSISTANT_GROUP"] = 10] = "ACQUISITION_ASSISTANT_GROUP";
  return DIALOG_STYLE2;
})(DIALOG_STYLE || {});
var IM_TYPE = /* @__PURE__ */ ((IM_TYPE2) => {
  IM_TYPE2[IM_TYPE2["KUAI"] = 1] = "KUAI";
  IM_TYPE2[IM_TYPE2["OTHER"] = 2] = "OTHER";
  return IM_TYPE2;
})(IM_TYPE || {});
var ConsultType = /* @__PURE__ */ ((ConsultType2) => {
  ConsultType2[ConsultType2["\u5728\u7EBF\u54A8\u8BE2"] = 1] = "\u5728\u7EBF\u54A8\u8BE2";
  ConsultType2[ConsultType2["\u7535\u8BDD\u62E8\u6253"] = 2] = "\u7535\u8BDD\u62E8\u6253";
  ConsultType2[ConsultType2["\u8868\u5355\u63D0\u4EA4"] = 3] = "\u8868\u5355\u63D0\u4EA4";
  return ConsultType2;
})(ConsultType || {});
var gameStyle = /* @__PURE__ */ ((gameStyle2) => {
  gameStyle2["button"] = "button";
  gameStyle2["descIcon"] = "descIcon";
  gameStyle2["picBtn"] = "picBtn";
  gameStyle2["hotArea"] = "hotArea";
  return gameStyle2;
})(gameStyle || {});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AfterSuccessDisplayType,
  BizId,
  ConsultType,
  DIALOG_STYLE,
  EnumPageType,
  IM_TYPE,
  MoveType,
  gameStyle
});
