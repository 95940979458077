import { invoke, addListener, removeListener } from '@yoda/bridge';
import { usePlcContainerActions } from 'landingPage/components/Blank/utils/registerPlcContainerState';
import { useEffect } from 'react';

export interface IYodaWebViewInfo {
    status: number;
    /**
     * 可展示尺寸
     */
    availableSize: {
        width: number;
        height: number;
    };
    /**
     * 容器位置信息
     */
    rect: {
        height: number;
        width: number;
        x: number;
        y: number;
    };
    /**
     * 屏幕安全区域
     */
    safeArea: {
        bottom: number;
        left: number;
        right: number;
        top: number;
    };
}

/**
 * 容器类型
 */
export enum CONTAINER_TYPE {
    /**
     * 全屏-历史保留
     */
    fullScreen = 0,
    /**
     * 半屏不可推全屏
     */
    fixationHalfScreen,
    /**
     * 半屏可推全屏
     */
    halfScreen,
    /**
     * 全屏透明容器
     */
    fullScreenTransparent,
}

export enum WEBVIEW_CONTAINER_STATUS {
    /**
     * 容器未开始滚动，75 屏
     */
    noScroll = 0,
    /**
     * 容器滑动过程中到达半屏高度 1
     */
    scrollHalf = 1,
    /**
     * 容器滑动过程中到达全屏高度 2
     */
    scrollFull,
    /**
     * 容器滑动中 3
     */
    scrolling,
    /**
     * 容器停止滑动时处于半屏状态 4
     */
    stopScrollHalf,
    /**
     * 容器停止滑动时处于全屏状态 5
     */
    stopScrollFull,
}

/**
 * 根据外面传入的屏幕计算规则（containerType），计算底部悬浮的具体定位
 * 目前适用于plc 75分屏容器变全屏的场景
 * 具体方案参考聚星 https://git.corp.kuaishou.com/ks-ad/ad-fe/eco/kuaishou-frontend-ad-business-card/-/blob/master/src/kwaitask/layouts/FixedBottom/index.tsx
 * https://plc-tools.corp.kuaishou.com/cookbook/container/scheme.html#containerstyle
 * @param
 * isIOS 监听切换
 * containerType: 监听适用类型
 * fixedBarElement: 需要捕获的dom元素
 * @returns
 * fixedBarBottomPixel: 吸底位置
 * fixedBarVisible: 吸底元素是否可见
 */
export function useFixedBarStatus({
    isIOS,
    containerType,
    fixedBarElement,
}: {
    isIOS: boolean;
    containerType?: CONTAINER_TYPE;
    fixedBarElement?: React.MutableRefObject<HTMLDivElement | null>;
}) {
    const actions = usePlcContainerActions();

    useEffect(() => {
        const listener = (res: IYodaWebViewInfo) => {
            console.log('tuna_cod_on_sliding_status_change', res);

            if (res) {
                const { availableSize, rect, safeArea, status } = res;
                // 半屏下容器展示位置是动态变化的，所以要动态计算按钮的位置来实现吸底
                // https://plc-tools.corp.kuaishou.com/cookbook/container/how_to_make_a_bottom_fixed_button.html#h5-%E7%A4%BA%E4%BE%8B%E9%A1%B5%E9%9D%A2
                const fixedBarBottomPixel =
                    rect.height + rect.y - availableSize.height + safeArea.bottom;

                if (
                    status === WEBVIEW_CONTAINER_STATUS.stopScrollHalf ||
                    status === WEBVIEW_CONTAINER_STATUS.stopScrollFull ||
                    status === WEBVIEW_CONTAINER_STATUS.scrollFull ||
                    status === WEBVIEW_CONTAINER_STATUS.scrollHalf ||
                    status === WEBVIEW_CONTAINER_STATUS.noScroll
                ) {
                    actions.setFixedBarBottomPixel(fixedBarBottomPixel);
                    actions.setFixedBarVisible(true);
                } else if (status === WEBVIEW_CONTAINER_STATUS.scrolling) {
                    actions.setFixedBarVisible(false);
                }

                if (
                    [
                        WEBVIEW_CONTAINER_STATUS.noScroll,
                        WEBVIEW_CONTAINER_STATUS.scrollHalf,
                        WEBVIEW_CONTAINER_STATUS.scrolling,
                        WEBVIEW_CONTAINER_STATUS.stopScrollHalf,
                    ].includes(status) &&
                    containerType === CONTAINER_TYPE.halfScreen
                ) {
                    actions.setShowTopBar(true);
                } else {
                    actions.setShowTopBar(false);
                }
            }
        };

        const addYodaListener = async () => {
            try {
                await addListener('tuna_cod_on_sliding_status_change', listener);
                const res = await invoke('plcCodContainer.getContainerStatus');
                listener(res?.data);
            } catch (error) {
                console.log('----error', error);
            }
        };

        const removeYodaListener = async () => {
            try {
                await removeListener('tuna_cod_on_sliding_status_change', listener);
            } catch (e) {
                console.log('------333', e);
            }
        };

        if (containerType === CONTAINER_TYPE.halfScreen) {
            addYodaListener();
        } else if (containerType === CONTAINER_TYPE.fullScreen) {
            actions.setFixedBarBottomPixel(0);
        }

        return () => {
            removeYodaListener();
        };
    }, [containerType, fixedBarElement, isIOS]);
}
/**
 * 从css background中抽取image url
 * @param str
 * @returns
 */
export const extractUrlFromBackgroundImage = (str?: string) => {
    if (!str) return;
    const reg = /url\((.*?)\)/;
    return str.match(reg)?.[1] || '';
};

/**
 * Formats a URL into a CSS `backgroundImage` style string.
 * @param url
 * @returns
 */
export const formatBackgroundImageFromUrl = (url?: string) => {
    return url ? `url(${url})` : '';
};
