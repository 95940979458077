import { DIALOG_STYLE, WeChatType, componentsForEach } from 'omega-render';
import { fetchBatchOpenList } from '../api';
import { getUrlParam } from './getUrlParam';
import { Toast } from '@ad/sharpui';
import { jumpHkzs, jumpWxkf } from '@/landingPage/components/WeiXinDrag/util';
import { invoke } from '@yoda/bridge';

export const isQwDirectCall = async (pageJson, components) => {
    let wxkfOpt;
    let hkzsOpt;
    let hkzsGroupOpt;
    componentsForEach(components, (com: any) => {
        const { type, props } = com;
        if (type === 'WEI_XIN_DRAG' || type === 'WEI_XIN') {
            const { wechatType } = props;
            if (wechatType === WeChatType.KEFU) {
                wxkfOpt = props.opt;
            }
            if (wechatType === WeChatType.ASSISTANT) {
                hkzsOpt = props.opt;
            }
            if (wechatType === WeChatType.ASSISTANT_GROUP) {
                hkzsGroupOpt = props.opt;
            }
        }
    });
    // 包含企微组件才会走是否直跳的逻辑判断
    let flag: boolean | undefined = false;
    if (hkzsOpt || hkzsGroupOpt) {
        // let openList: string[] = [];
        // try {
        //     openList = await fetchBatchOpenList();
        // } catch (e) {}
        // let pageId = getUrlParam('pageId');
        if (getUrlParam('isQWDirectCall') === 'true') {
            // 企微空白页直跳需求
            Toast.info({ content: '正在连接微信...', duration: 6000 });
            if (hkzsOpt) {
                flag = await jumpHkzs({
                    opt: hkzsOpt,
                    dialogStyleType: DIALOG_STYLE.ACQUISITION_ASSISTANT,
                    renderType: 'WEI_XIN_DRAG',
                    useSchema: false,
                    noLog: true,
                    wechatType: WeChatType.ASSISTANT,
                });
            } else if (hkzsGroupOpt) {
                flag = await jumpHkzs({
                    opt: hkzsGroupOpt,
                    dialogStyleType: DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP,
                    renderType: 'WEI_XIN_DRAG',
                    useSchema: false,
                    noLog: true,
                    wechatType: WeChatType.ASSISTANT_GROUP,
                });
            }
            // let flag = await jum(
            //     wxkfOpt,
            //     realMd5Cb.md5,
            //     DIALOG_STYLE.KEFU,
            //     'WEI_XIN_DRAG',
            //     true,
            // );
            // 只有条件都满足了才会return,同时关闭当前页面,如果跳转失败，继续往下渲染
            if (flag) {
                setTimeout(() => {
                    invoke('webview.exitWebView');
                }, 2000);
                return true;
            }
        }
    } else if (wxkfOpt) {
        let openList: string[] = [];
        try {
            openList = await fetchBatchOpenList();
        } catch (e) {}
        let pageId = getUrlParam('pageId');
        if (
            getUrlParam('isQWDirectCall') === 'true' &&
            (pageJson?.pageMeta?.qywxDirectCall || (openList || []).includes(pageId))
        ) {
            // 企微空白页直跳需求
            Toast.info({ content: '正在连接微信...', duration: 6000 });
            let flag = await jumpWxkf({
                opt: wxkfOpt,
                dialogStyleType: DIALOG_STYLE.KEFU,
                renderType: 'WEI_XIN_DRAG',
                noLog: true,
            });
            // 只有条件都满足了才会return,同时关闭当前页面,如果跳转失败，继续往下渲染
            if (flag) {
                setTimeout(() => {
                    invoke('webview.exitWebView');
                }, 2000);
                return true;
            }
        }
    }
};
