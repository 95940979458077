/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { CountdownDragProps } from './CountdownDrag';
import styles from './style.module.less';
import { useCountdownHook, getTimeDiffText } from './countdownHook';

const RichStyleCountdown: React.FunctionComponent<
    CountdownDragProps & ReturnType<typeof useCountdownHook>
> = (props) => {
    const {
        countdownFontColor,
        countdownFontSize,
        daydiff,
        hourdiff,
        minutediff,
        seconddiff,
        colors,
        textStyle,
        text,
        dateFormat,
    } = props;
    const numberBg = colors[5].includes('ffffff')
        ? '#FF2525'
        : colors[5].includes('000000')
        ? '#404040'
        : colors[6];
    const boxBg = colors[5].includes('ffffff')
        ? '#ffffff'
        : colors[5].includes('000000')
        ? '#000000'
        : `linear-gradient(to bottom right, ${colors[4]}, ${colors[5]})`;

    const renderTimeDiff = (timediff: number, unit: string) => {
        return (
            <div className={styles.timebox}>
                {getTimeDiffText(timediff).map((item, idx) => {
                    return (
                        <div
                            key={idx}
                            className={styles.timeText}
                            style={{
                                backgroundColor: numberBg,
                                fontSize: countdownFontSize,
                                color: countdownFontColor,
                            }}
                        >
                            {item}
                        </div>
                    );
                })}
                <div className={styles.unit}>{unit}</div>
            </div>
        );
    };

    return (
        <div className={styles.bgContainer} style={{ background: boxBg }}>
            <div
                className={styles.richStyle}
                style={{
                    fontSize: textStyle.textFontSize,
                    color: textStyle.textFontColor,
                }}
            >
                <div
                    className={styles.text}
                    style={{
                        fontSize: textStyle.textFontSize,
                        fontFamily: textStyle.fontFamily,
                        color: textStyle.textFontColor,
                    }}
                >
                    {text}
                </div>
                <div className={styles.countdown}>
                    {/d|h|s|m/.test(dateFormat) && renderTimeDiff(daydiff, '天')}
                    {/h|s|m/.test(dateFormat) && renderTimeDiff(hourdiff, '时')}
                    {/s|m/.test(dateFormat) && renderTimeDiff(minutediff, '分')}
                    {dateFormat.includes('s') && renderTimeDiff(seconddiff, '秒')}
                </div>
            </div>
        </div>
    );
};

export default RichStyleCountdown;
