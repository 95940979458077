import {
    ITraceLog,
    IConfig,
    IPerfLog,
    ITraceLogApi,
    IPerfLogApi,
    LevelType,
    LogInfoInterface,
    LoggerOptions,
    LoggerConfig,
} from './interface';

const STACK_MAX = 5; // 最多5条打包一起发
const THROTTLE_TIME = 50; // 50ms发送一次
const perfStack: IPerfLogApi[] = [];
const traceStack: ITraceLogApi[] = [];
const config: IConfig = { env: 'development', bizName: '' }; // 默认development环境

/**
 * 根据给定的 cookie 名称获取 cookie 值。
 *
 * @param name - 要获取的 cookie 名称。
 * @returns 匹配的 cookie 值，如果未找到则返回空字符串。
 */
export const getCookieByName = (name: string) => {
    try {
        if (!document.cookie) {
            return '';
        }
        const cookieObj = document.cookie.split(';').reduce((memo: any, item) => {
            const pair = item.split('=');
            if (pair[0]) {
                memo[pair[0].trim()] = pair[1] ? pair[1].trim() : '';
            }
            return memo;
        }, {});
        return cookieObj[name];
    } catch (e) {
        return '';
    }
};

const getPerfHost = () => {
    return 'https://frontend-perf-service.e.kuaishou.com';
};
const getTraceHost = () => {
    return config.env === 'production'
        ? 'https://webview-b.e.kuaishou.com'
        : 'https://ad-log-dispatch.test.gifshow.com';
};
const getLoggerUrl = () => `${getPerfHost()}/rest/trace`;

const getTraceUrl = () => `${getTraceHost()}/rest/logDispatch/h5/batchTraceLog`;
const getPerfUrl = () => `${getPerfHost()}/rest/perf`;

const request = (url: string, stack: ITraceLogApi[] | IPerfLogApi[]) => {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.timeout = 30000;
    xmlhttp.open('POST', url, true);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(stack));
    stack.splice(0, stack.length);
};

const request2 = (url: string, data: LoggerOptions) => {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.timeout = 30000;
    xmlhttp.open('POST', url, true);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(data));
};

const throttle = (func: Function, delay: number) => {
    let timer: any;
    return function (...args: any[]) {
        if (!timer) {
            timer = window.setTimeout(() => {
                timer = 0;
                func(...args);
            }, delay);
        }
    };
};

const throttleSend = throttle((url: string, stack: ITraceLogApi[] | IPerfLogApi[]) => {
    if (stack.length > STACK_MAX) {
        // 超过STACK_MAX条就分成几个请求;
        const loopNum = Math.ceil(stack.length / STACK_MAX);
        for (let i = 0; i < loopNum; i++) {
            request(url, stack.splice(0, 5));
        }
    } else {
        request(url, stack);
    }
}, THROTTLE_TIME);

export const init = (params: IConfig) => {
    try {
        const { env, bizName, logType } = params;
        if (env === 'production' || env === 'development') {
            config.env = env;
        }
        if (bizName) {
            config.bizName = bizName;
        } else {
            throw new Error('初始化必须要填bizName参数');
        }
        if (logType) {
            config.logType = logType;
        }
        console.log('adBaseLog init success:', config);
    } catch (e) {
        console.error('init error', e);
    }
};

export const perf = (e: IPerfLog) => {
    try {
        if (!config.bizName) {
            throw new Error('必须初始化bizName字段');
        }
        const newE = {
            namespace: config.bizName,
            ...e,
        };
        perfStack.push(newE);
        throttleSend(getPerfUrl(), perfStack);
    } catch (e) {
        console.error('perf error', e);
    }
};

export const trace = (e: ITraceLog) => {
    try {
        if (!config.bizName) {
            throw new Error('必须初始化bizName字段');
        }
        if (!config.logType) {
            throw new Error('通过trace上报到kibana必须初始化logType字段');
        } else {
            const newE: ITraceLogApi = {
                bizName: config.bizName,
                logType: config.logType,
                traceLogContent: JSON.stringify(e.traceLogContent),
            };
            traceStack.push(newE);
            throttleSend(getTraceUrl(), traceStack);
        }
    } catch (e) {
        console.error('trace error', e);
    }
};

export class FeLogger {
    private readonly serviceName: string;

    private readonly config: string[] = [];

    constructor(options: LoggerConfig) {
        if (!options.serviceName) {
            console.error('[ad-base-log]: 请传入 init的bizName参数! 否则将无法使用日志功能');
        }
        this.serviceName = options.serviceName;
        //   this.fetchConfig();
    }

    public info = this.LogggerFactory('info');
    // public debug(log: LogInfoInterface) {
    //   //TODO：读取firefly配置，通过userID去控制展示
    //   let userId = getCookieByName("userId") || getCookieByName("ud") || '123';
    //   if (!userId) {
    //     console.error('[ad-base-log]: 未获取到userId');
    //     return;
    //   }
    //   console.log("firefly this.config:::", this.config, "userId:::", userId);
    //   if (!this.config || this.config.indexOf(userId) < 0) {
    //     console.error("[ad-base-log]: 该用户不在日志上报白名单内");
    //     return;
    //   }

    //   this.sendData({
    //     log,
    //     level: 'debug',
    //     serviceName: this.serviceName
    //   });
    // }
    // private fetchConfig() {
    //   axios.get(getFireFlyHost()).then((data: any) => {
    //     console.log('从firefly 获取的数据::', data.data, '当前serviceName:::', this.serviceName);
    //     let ret = data.data;
    //     this.config = ret[this.serviceName] || [];
    //   });
    // }
    private LogggerFactory(level: LevelType) {
        return (log: LogInfoInterface) => {
            this.sendData({
                log,
                level,
                serviceName: this.serviceName,
            });
        };
    }

    private sendData(data: LoggerOptions) {
        request2(getLoggerUrl(), data);
    }
}

export const logger = () => {
    return new FeLogger({
        serviceName: config.bizName,
    });
};

// 导出type
export * from './interface';

const adBaseLog = {
    init,
    perf,
    trace,
    logger,
};
export default adBaseLog;
