/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Modal from '../../common/components/Modal';
import styles from './style.module.less';
import { AnyObj } from 'omega-render';
import close from './close.png';

enum StyleType {
    'whitePage' = 'whitePage', //大白兜底页样式
    'normal' = 'normal', // 正常页面样式
}
interface PermissionInfoProps {
    desc: string;
    color: string;
    btnColor: string;
    style?: AnyObj;
    btnTextColor: string;
    styleType?: StyleType;
    fontSize?: number;
}

export default function FunctionIntroduction(props: PermissionInfoProps) {
    const [show, setShow] = useState(false);
    const {
        desc,
        color,
        style,
        btnColor,
        btnTextColor,
        styleType = StyleType.normal,
        fontSize = 16,
    } = props;
    const newStyle: React.CSSProperties = Object.assign(
        {},
        {
            color,
            textAlign: 'left',
            fontSize: fontSize,
            letterSpacing: 0,
            lineHeight: '19px',
            marginLeft: 4,
        },
        style,
    ) as React.CSSProperties;
    if (!desc) return null;
    if (styleType === StyleType.whitePage) {
        return (
            <div>
                <div
                    style={{
                        fontSize: 16,
                        color: 'rgb(34, 34, 34)',
                        fontWeight: 'bold',
                        lineHeight: 1,
                        marginTop: 12,
                    }}
                >
                    功能介绍
                </div>
                <div
                    style={{
                        marginTop: 10,
                        fontSize: 14,
                        color: ' rgb(156, 156, 156)',
                        lineHeight: 1.2,
                        marginBottom: 100,
                    }}
                >
                    {desc}
                </div>
            </div>
        );
    }

    return (
        <>
            <div style={newStyle} onClick={() => setShow(true)}>
                功能介绍
                <span style={{ margin: '0 4px' }}>|</span>
            </div>
            {show && (
                <Modal
                    style={{
                        zIndex: 999999,
                    }}
                >
                    <div className={styles.modelContainer}>
                        <div className={styles.titleWrapper}>
                            <img
                                src={close}
                                onClick={() => setShow(false)}
                                alt=""
                                data-target="formClose"
                            />
                            功能介绍
                        </div>
                        <div className={styles.contentWrapper}>{desc}</div>
                        <div
                            className={styles.opWrapper}
                            style={{
                                width: '80%',
                                borderRadius: 22,
                                background: btnColor,
                                color: btnTextColor,
                                height: 44,
                                margin: '0 auto',
                            }}
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            知道了
                        </div>
                        {/* <div className={styles.opWrapper}>
                            {showDownloadBtn && <AppDownload
                                linkType={linkType}
                                appInfo={appInfo}
                                buttonStyle={{
                                    ...buttonStyle,
                                    // color: style.tagColor,
                                    // border: `solid 1px ${style.tagColor}`,
                                    width: 327,
                                    height: 44,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#FE3666',
                                    color: 'white',
                                    borderRadius: 24,
                                    fontSize: 16,
                                }}
                                text="立即下载"
                            />}
                        </div> */}
                    </div>
                </Modal>
            )}
        </>
    );
}
