import * as React from 'react';
import { fetchXiansuoFormInfoInPageJson } from 'landingPage/common/utils/fetchCompPropsInPageJson';
import { ClueForm } from '@ad/xiansuoSDK';
import { XiansuoFormProps, useCommonFormConfig } from '../XiansuoForm';
import { isSSR } from '@/landingPage/common/utils/env';
import styles from './styles.module.less';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { checkShowFormModal, recordFormModalInfo } from '@/landingPage/common/api';
import { ExtraForm } from '../../xiansuoFormPay/commonFun';
import { useComContext } from 'omega-render';
import { getUrlParam } from 'omega-shared/utils';
import { getRadar } from '@/landingPage/common/weblog';
import ReactDOM from 'react-dom';
import Modal from '@/landingPage/common/components/Modal';

export const XiansuoFormStyleModal = async (props) => {
    const modal = document.getElementById('xiansuoFormModal');
    if (modal) {
        return '';
    }
    let divElememt = document.createElement('div');
    divElememt.id = 'xiansuoFormModal';
    document.body.appendChild(divElememt);
    return ReactDOM.render(
        <Provider store={props}>
            <XiansuoFormStayModal />
        </Provider>,
        divElememt,
    );
};

export const formClass = 'com-form';

enum PatternEnum {
    headImg = 1,
    noHeadImg = 2,
}

const StayModalStyle = {
    1: {
        waitTimer: 3,
        pattern: PatternEnum.headImg,
    },
    2: {
        waitTimer: 5,
        pattern: PatternEnum.headImg,
    },
    3: {
        waitTimer: 10,
        pattern: PatternEnum.headImg,
    },
    4: {
        waitTimer: 3,
        pattern: PatternEnum.noHeadImg,
    },
    5: {
        waitTimer: 5,
        pattern: PatternEnum.noHeadImg,
    },
    6: {
        waitTimer: 10,
        pattern: PatternEnum.noHeadImg,
    },
};

export const XiansuoFormStayModal: React.FunctionComponent = () => {
    const [showModal, setShowModal] = useState(false);
    const [xiansuoFromInfo] = React.useState<XiansuoFormProps>(
        fetchXiansuoFormInfoInPageJson() as XiansuoFormProps,
    );
    const { edit, preview } = useComContext();
    const [showAppointment, setShowAppointment] = React.useState(false);
    const [show, setShow] = React.useState(false);

    const [neverOpen, setNeverOpen] = useState<number>(0);
    const [popupStyle, setPopupStyle] = useState<number>(0);
    const [canShowModal, setCanShowModal] = useState<boolean>(false);
    const [hasShowed, setHasShowed] = useState<boolean>(false);

    if (!xiansuoFromInfo || !xiansuoFromInfo?.formOpt) {
        return null;
    }

    const trackData = {
        formId: xiansuoFromInfo?.formOpt?.id,
        formType: 1,
        accountId: getUrlParam('ksSiteAccountId'),
        campaignId: getUrlParam('ksCampaignId'),
        unitId: getUrlParam('ksUnitId'),
        creativeId: getUrlParam('ksCreativeId'),
        url: window.location.href,
    };

    let timer;
    function resetFormStayModalTimer() {
        clearTimeout(timer);
        document.removeEventListener('click', resetFormStayModalTimer);
        document.removeEventListener('input', resetFormStayModalTimer);
    }
    useEffect(() => {
        if (xiansuoFromInfo.formOpt) {
            checkShowFormModal().then((res) => {
                setCanShowModal(res.showPopup);
                if (res.showPopup) {
                    setPopupStyle(res.popupStyleType);
                    if (StayModalStyle[res.popupStyleType]) {
                        // 监听用户交互
                        document.addEventListener('click', resetFormStayModalTimer);
                        document.addEventListener('input', resetFormStayModalTimer);
                        timer = setTimeout(() => {
                            setShowModal(true);
                        }, StayModalStyle[res.popupStyleType].waitTimer * 1000);
                    }
                }
            });
        }
    }, []);
    const logFormModalOpen = async () => {
        (await getRadar())?.event({
            name: 'FORM_MODAL_OPEN', // 必填
            event_type: 'FORM_MODAL_OPEN', // 【可选】
            category: 'traceLog', // 【可选】
            extra_info: JSON.stringify(trackData),
        });
    };
    useEffect(() => {
        if (showModal || hasShowed) {
            resetFormStayModalTimer();
        }
        if (showModal && !hasShowed) {
            // 记录打开了弹窗
            recordFormModalInfo(1, neverOpen);
            logFormModalOpen();
        }
    }, [showModal, hasShowed]);

    useEffect(() => {
        if (showModal) {
            recordFormModalInfo(0, neverOpen);
        }
    }, [neverOpen]);

    const {
        formOpt,
        formSetting,
        children,
        showLabel,
        theme,
        placeholderColor,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        submitWrapperStyle,
        formItemWrapperStyle,
        submitStyle,
        titleStyle,
        mainTitleStyle,
        mainTitleRich,
        clueAccountId,
        showCustomPrivacyAgreement,
        needCache,
        privacyTextColor,
        formVersion,
        isAppointment,
    } = xiansuoFromInfo;

    const { config, modalSetting } = useCommonFormConfig({
        formOpt,
        formSetting,
        edit: false,
        preview: true,
        showLabel,
        theme,
        placeholderColor,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        submitWrapperStyle,
        formItemWrapperStyle,
        submitStyle,
        titleStyle,
        mainTitleStyle,
        mainTitleRich,
        clueAccountId,
        onSuccess: async (value) => {
            console.log('[onSuccess]', value);
            setShowModal(false);
            setHasShowed(true);
            if (isAppointment) {
                setShowAppointment(true);
            } else {
                setShow(true);
            }
            (await getRadar())?.event({
                name: 'FORM_MODAL_SUBMIT_SUCCESS', // 必填
                event_type: 'FORM_MODAL_SUBMIT_SUCCESS', // 【可选】
                category: 'traceLog', // 【可选】
                extra_info: JSON.stringify(trackData),
            });
        },
        onError: () => {
            setShowModal(false);
            setHasShowed(true);
        },
        onSubmitClick: async () => {
            (await getRadar())?.event({
                name: 'FORM_MODAL_SUBMIT_CLICK', // 必填
                event_type: 'FORM_MODAL_SUBMIT_CLICK', // 【可选】
                category: 'traceLog', // 【可选】
                extra_info: JSON.stringify(trackData),
            });
        },
        isSSR,
        customSuccessPage: true,
        showCustomPrivacyAgreement,
        needCache,
        show: false,
        privacyTextColor,
        formVersion,
    });
    const formModalFooter = () => {
        return (
            <>
                <div className={styles.modalBody}>
                    <div className={styles.tip}>严格保护隐私，信息仅用于商家与您联系</div>
                    {config && <ClueForm {...config} />}
                </div>

                <div className={styles.noPromopt}>
                    {!Boolean(neverOpen) && (
                        <div
                            className={styles.whiteCircle}
                            onClick={async () => {
                                setNeverOpen(1);
                                (await getRadar())?.event({
                                    name: 'FORM_MODAL_NEVR_OPEN', // 必填
                                    event_type: 'FORM_MODAL_NEVR_OPEN', // 【可选】
                                    category: 'traceLog', // 【可选】
                                    extra_info: JSON.stringify(trackData),
                                });
                            }}
                        ></div>
                    )}
                    {Boolean(neverOpen) && (
                        <div
                            onClick={async () => {
                                setNeverOpen(0);
                                (await getRadar())?.event({
                                    name: 'FORM_MODAL_NEVR_OPEN_CANCEL', // 必填
                                    event_type: 'FORM_MODAL_NEVR_OPEN_CANCEL', // 【可选】
                                    category: 'traceLog', // 【可选】
                                    extra_info: JSON.stringify(trackData),
                                });
                            }}
                        >
                            <img
                                className={styles.checkedImage}
                                src="https://ali.a.yximgs.com/kos/nlav12119/jLeOljHt_2024-06-28-19-23-06.png"
                            />
                        </div>
                    )}
                    今日不再提示
                </div>
            </>
        );
    };
    return (
        <>
            {!hasShowed &&
                canShowModal &&
                showModal &&
                StayModalStyle[popupStyle].pattern === PatternEnum.headImg && (
                    <Modal
                        style={{ padding: 0 }}
                        parent={document.getElementById('preview-app') ?? document.body}
                    >
                        <div className={styles.modalWithHeadImg}>
                            <div className={styles.modalContent}>
                                <div className={styles.modalHeader}>
                                    <div
                                        className={styles.modalClose}
                                        onClick={async () => {
                                            setShowModal(false);
                                            setHasShowed(true);
                                            (await getRadar())?.event({
                                                name: 'FORM_MODAL_CLOSE', // 必填
                                                event_type: 'FORM_MODAL_CLOSE', // 【可选】
                                                category: 'traceLog', // 【可选】
                                                extra_info: JSON.stringify(trackData),
                                            });
                                        }}
                                    >
                                        <img
                                            className={styles.modalCloseImg}
                                            src="https://ali.a.yximgs.com/kos/nlav12119/PGOsOjws_2024-07-02-18-27-43.png"
                                        />
                                    </div>
                                    <div className={styles.modalTitle}>
                                        <div className={styles.titleH1}>我要咨询</div>
                                        <div className={styles.titleH2}>
                                            已有
                                            {formSetting?.count?.itemConfig?.props?.text?.[1]
                                                ? +formSetting?.count?.itemConfig?.props
                                                      ?.text?.[1] + formOpt?.clueCount
                                                : Math.floor(Math.random() * (1000 - 500 + 1)) +
                                                  500}
                                            人咨询
                                        </div>
                                    </div>
                                </div>
                                {formModalFooter()}
                            </div>
                        </div>
                    </Modal>
                )}
            {!hasShowed &&
                canShowModal &&
                showModal &&
                StayModalStyle[popupStyle].pattern === PatternEnum.noHeadImg && (
                    <Modal
                        style={{ padding: 0 }}
                        parent={document.getElementById('preview-app') ?? document.body}
                    >
                        <div className={styles.modalWithoutHeadImg}>
                            <div className={styles.modalContent}>
                                <div className={styles.modalHeader}>
                                    <div
                                        className={styles.modalClose}
                                        onClick={() => {
                                            setShowModal(false);
                                            setHasShowed(true);
                                        }}
                                    >
                                        <img
                                            className={styles.modalCloseImg}
                                            src="https://ali.a.yximgs.com/kos/nlav12119/hZLGvPPD_2024-07-02-18-46-03.png"
                                        />
                                    </div>
                                    <div className={styles.modalTitle}>我要咨询</div>
                                </div>
                                {formModalFooter()}
                            </div>
                        </div>
                    </Modal>
                )}
            <ExtraForm
                children={children}
                edit={edit}
                preview={preview}
                show={show}
                showAppointment={showAppointment}
                modalSetting={modalSetting}
                isAppointmentUser={isAppointment}
                onClose={() => {
                    setShow(false);
                }}
                onCloseAppointment={() => {
                    setShowAppointment(false);
                }}
            />
        </>
    );
};
