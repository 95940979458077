import { subscribe } from 'subscribe-ui-event';
// eslint-disable-next-line import/no-cycle
import { EVENT_TYPE } from './track';

/**
 * 下划线转驼峰
 * @param {string} key
 * @return {string}
 */
export function convertDataKey(key) {
    return key.replace(/_./g, chars => chars[1].toUpperCase());
}

/**
 * 判断元素是否是 data-ksad-track 监控元素
 * @param {Element} el
 * @return {boolean}
 */
export function isTrackEl(el) {
    if (el?.hasAttribute) {
        return el.hasAttribute('data-ksad-track');
    }

    return false;
}

export function getTrackedEl(el) {
    if (isTrackEl(el)) {
        return el;
    }

    let {parentNode} = el;
    while (parentNode) {
        if (isTrackEl(parentNode)) {
            return parentNode;
        }
        parentNode = parentNode.parentNode;
    }

    return null;
}

export function isTrackedEl(el) {
    return Boolean(getTrackedEl(el));
}

export function isEnableTrack(el) {
    const trakcEl = getTrackedEl(el);
    const isTracked = isTrackedEl(el);
    const isDisable = trakcEl?.hasAttribute('data-ksad-track-disable');
    const enable = isTracked && !isDisable;
    return enable;
}

export function isTrackAttr(attrName) {
    return /^data-ksad-/.test(attrName);
}

/**
 * 从元素上获取有效数据
 * @param {Element} el
 * @return {object} 当前只有 action_type
 */
export function getTrackDataFromEl(el) {
    const trackedEl = getTrackedEl(el);
    if (trackedEl) {
        const actionAttr = trackedEl.getAttribute('data-ksad-track');
        const eventType = actionAttr ? EVENT_TYPE[convertDataKey(actionAttr)] : '';
        const dataAttr = trackedEl.getAttribute('data-ksad-track-data');
        const data = dataAttr ? JSON.parse(dataAttr) : {};
        return {
            eventType,
            ...data,
        };
    }

    return {};
}

export function pageViewedProportion() {
    // 如果当前的总页面达到 < 总高那么设置100%
    let proportion = 0;
    subscribe('scroll', () => {
        const height =
            (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.clientHeight;
        const maxHeight = document.body.offsetHeight;
        const proportionNow = (height / maxHeight).toFixed(4);
        proportion = proportion > proportionNow ? proportion : proportionNow;
        return proportion;
    });
}
