import { DIALOG_STYLE, WeChatType } from 'omega-render';
import { DialogTypeEnum } from 'landingPage/components/XiansuoFormDrag/XiaoSuoModal';
import { AfterSuccessDisplayType } from 'omega-shared/types';
import { Applets } from '../WeiXinDrag/WeiXin';
import { IFormInfoView } from '../../services/models';
import { ICustmPrivacyAgreement, ASSISTANT_SOURCE } from './XiansuoForm';
import { IAppInfoView } from 'src/services/models';
import { IAppInfo } from '../AppDownloadDrag/AppDownload';

export enum IconStyle {
    FIRE = 1,
    NOTICE = 2,
    NONE = 3,
}
export enum ShowType {
    WALL = 1,
    STRIP = 2,
}
const getField = (field: string[], data: any) => {
    const result = field.reduce((x, y) => {
        return x ? x[y] : undefined;
    }, data);
    return result;
};

export interface FormSetting {
    submitTurn?: {
        // 最外面的开关
        switch?: boolean;
        visible?: boolean;
        itemConfig?: SubmitTurnConfig;
    };
    count?: {
        switch?: boolean;
        itemConfig?: {
            props?: {
                iconStyle?: IconStyle;
                orderBy?: string;
                text?: string[];
            };
        };
    };
    apply?: {
        switch?: boolean;
        itemConfig?: {
            props?: {
                showType?: ShowType;
                iconStyle?: IconStyle;
            };
        };
    };
    privacy?: {
        switch?: boolean;
        itemConfig?: ICustmPrivacyAgreement;
    };
    appointment?: {
        switch?: boolean;
        itemConfig?: AppointmentDownloadConfig;
    };
}

export interface AppointmentDownloadConfig {
    deepLink?: string;
    appInfo?: IAppInfoView;
}
export enum SystemType {
    '全局' = 'iosAndAndroid',
    '分端' = 'iosOrAndroid',
}
export enum JumpType {
    'H5链接' = 'h5',
    'DP链接' = 'deeplink',
}
export interface SubmitTurnLinkConfig {
    type?: 'taobao' | 'ios' | 'other';
    jumpType?: JumpType; // 跳转url类型 h5 / deeplink
    url?: string;
    dpBackupH5Url?: string; // deeplink跳转失败兜底h5链接
}
export interface SubmitTurnConfig {
    // 控制表单提交后设置的开关
    jumpSwitch?: boolean;
    // 是否开启转跳的开关
    switch?: boolean;
    visible?: boolean;
    afterSuccessDisplayType?: AfterSuccessDisplayType;
    weChatOpt?: {
        funcId: string;
        opt: any;
        wechatType: WeChatType;
        dialogStyle?: DialogTypeEnum;
        dialogStyleType?: DIALOG_STYLE;
        applets?: Applets; // 微信小程序的信息
        appletSwitchState?: {
            checked: boolean;
            disabled: boolean;
        }; // 小程序开关的状态
        info?: string;
        isShare?: ASSISTANT_SOURCE;
    };
    afterSubmitText?: string;
    submitTurnValue?: {
        type: 'taobao' | 'ios' | 'other';
        url: string;
        systemType?: SystemType; //跳转方式生效的系统范围  iosAndAndroid / iosOrAndroid
        jumpType?: JumpType; // 跳转url类型 h5 / deeplink
        dpBackupH5Url?: string; // deeplink跳转失败兜底h5链接
        iosConfig?: SubmitTurnLinkConfig;
        androidConfig?: SubmitTurnLinkConfig;
        harmonyConfig?: SubmitTurnLinkConfig;
    };
    applets?: { appletsId: number }; // 微信小程序的信息
    appletSwitchState?: {
        checked: boolean;
        disabled: boolean;
    }; // 小程序开关的状态
    isAppointment?: boolean; // 是否是预约下载
    appointmentInfo?: {
        deeplink: string;
        appInfo: IAppInfo;
        appType: 'app' | 'subapp' | 'legacy' | '';
        deepLink?: string;
    };
}

export function transformDialogStyleType(
    dialogStyle: DialogTypeEnum | undefined,
    dialogStyleType: DIALOG_STYLE | undefined,
    wechatType?: WeChatType,
) {
    if (wechatType === WeChatType.KEFU) return DIALOG_STYLE.KEFU;
    if (wechatType === WeChatType.ASSISTANT) return DIALOG_STYLE.ACQUISITION_ASSISTANT;
    if (wechatType === WeChatType.ASSISTANT_GROUP) return DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP;
    let newDialogStyleType = DIALOG_STYLE.DEFAULT;
    if (dialogStyleType) {
        newDialogStyleType = dialogStyleType;
    } else if (dialogStyle === DialogTypeEnum.DEFAULT) {
        newDialogStyleType = DIALOG_STYLE.LONG_PRESS;
    } else if (dialogStyle === DialogTypeEnum.QRCODE) {
        newDialogStyleType = DIALOG_STYLE.QRCODE;
    } else {
        newDialogStyleType = DIALOG_STYLE.DEFAULT;
    }

    return newDialogStyleType;
}
export function getSubmitTurnSetting(viewPropsDesc: any, formSetting: FormSetting) {
    const advanceInfo = viewPropsDesc?.advanceInfo;
    const newSetting = formSetting?.submitTurn;
    const oldSetting = advanceInfo?.submitTurn;
    let submitSetting: SubmitTurnConfig | undefined = newSetting?.itemConfig;
    const oldSubmitTurnValue = viewPropsDesc?.submitTurnValue;
    const oldAfterSubmitText = viewPropsDesc?.afterSubmitText;

    if (submitSetting?.weChatOpt) {
        const newWechatOpt = { ...submitSetting?.weChatOpt };
        newWechatOpt.dialogStyleType = transformDialogStyleType(
            submitSetting?.weChatOpt?.dialogStyle,
            submitSetting?.weChatOpt?.dialogStyleType,
        );

        submitSetting = { ...submitSetting, weChatOpt: newWechatOpt };
    }
    const modalSetting: SubmitTurnConfig = {
        ...submitSetting,
        jumpSwitch: newSetting?.switch,
        switch: submitSetting?.switch != null ? submitSetting?.switch : oldSetting?.switch,
        afterSuccessDisplayType:
            submitSetting?.afterSuccessDisplayType ?? AfterSuccessDisplayType.LINK,
        afterSubmitText: submitSetting?.afterSubmitText
            ? submitSetting.afterSubmitText
            : oldAfterSubmitText,
        submitTurnValue: submitSetting?.submitTurnValue
            ? submitSetting.submitTurnValue
            : oldSubmitTurnValue,
    };
    return modalSetting;
}
export function transformFormSetting(formOpt: IFormInfoView, formSetting: FormSetting) {
    const viewPropsDesc = JSON.parse(formOpt?.viewPropsDesc || '{}');
    // 给表单用的数据
    const resultSetting: any = {
        ...viewPropsDesc,
    };
    // 默认给个空对象，防止报错
    if (!resultSetting.advanceInfo) {
        resultSetting.advanceInfo = {};
    }
    // 给建站表单弹框用的数据
    // submitTurn 代表转跳后
    if (formSetting) {
        const keys = ['count', 'apply', 'submitTurn', 'privacy'] as const;
        /**
            formSetting 归类成三个数据
            count 
                代表计数统计 修改 count 的 switch props.orderBy props.text props.iconStyle三个字段
            apply 
                代表以报名用户展示 apply 的 switch props.showType props.iconStyle 
            submitTurn 最乱 
            afterSuccessDisplayType 提交后显示类型 新加的
            weChatOpt 微信相关配置 新加的
                weChatOpt.func
                weChatOpt.opt
                weChatOpt.type
            afterSubmitText 提交后文案 
            submitTurnValue.type 转跳方式
            submitTurnValue.url
         */
        for (const key of keys) {
            const oldSetting = resultSetting?.advanceInfo?.[key] || {};
            const newSetting = formSetting?.[key];
            const getValue = (field: string[]) => {
                const newValue = getField(field, newSetting);
                return newValue != null ? newValue : getField(field, oldSetting);
            };

            //   count
            //     代表计数统计 修改 count 的 switch props.orderBy props.text props.iconStyle三个字段
            if (key === 'count') {
                resultSetting.advanceInfo[key] = {
                    ...oldSetting,
                    switch: getValue(['switch']),
                    itemConfig: {
                        ...oldSetting?.itemConfig,
                        props: {
                            ...oldSetting?.itemConfig?.props,
                            iconStyle: getValue(['itemConfig', 'props', 'iconStyle']),
                            orderBy: getValue(['itemConfig', 'props', 'orderBy']),
                            text: getValue(['itemConfig', 'props', 'text']),
                        },
                    },
                };
            }
            // apply
            //  代表以报名用户展示 apply 的 switch props.showType props.iconStyle
            if (key === 'apply') {
                resultSetting.advanceInfo[key] = {
                    ...oldSetting,
                    switch: getValue(['switch']),
                    itemConfig: {
                        ...oldSetting?.itemConfig,
                        props: {
                            ...oldSetting?.itemConfig?.props,
                            showType: getValue(['itemConfig', 'props', 'showType']),
                            iconStyle: getValue(['itemConfig', 'props', 'iconStyle']),
                        },
                    },
                };
            }
            if (key === 'submitTurn' && newSetting) {
                const submitSetting: SubmitTurnConfig | undefined = (
                    newSetting as FormSetting['submitTurn']
                )?.itemConfig;
                const oldSubmitTurnValue = viewPropsDesc?.submitTurnValue;
                const oldAfterSubmitText = viewPropsDesc?.afterSubmitText;
                resultSetting.advanceInfo[key] = {
                    ...oldSetting,
                    switch: submitSetting != null ? submitSetting : oldSetting?.switch,
                };

                resultSetting.afterSubmitText = submitSetting?.afterSubmitText
                    ? submitSetting.afterSubmitText
                    : oldAfterSubmitText;
                resultSetting.submitTurnValue = submitSetting?.submitTurnValue
                    ? submitSetting.submitTurnValue
                    : oldSubmitTurnValue;
            }
        }
    }
    const modalSetting = getSubmitTurnSetting(viewPropsDesc, formSetting);
    return {
        realFormOpt: {
            ...formOpt,
            viewPropsDesc: JSON.stringify(resultSetting),
        },
        modalSetting,
        submitText: viewPropsDesc?.submitText,
    };
}
