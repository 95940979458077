import { bridgeGetDeviceInfo } from '../bridge';
import { getProduct } from './getProduct';
/**
 * 比较两个版本号的大小
 * @param {string} version1
 * @param {string} version2
 * @return {boolean} version1 > version2，则结果大于0，version1 === version2，则结果等于0
 * ，version1 < version2，则结果小于0
 */
export const compareVersion = (version1: string, version2: string) => {
    let result = 0;
    const numbersVer1 = version1?.split('.') || [];
    const numbersVer2 = version2?.split('.') || [];
    let index = 0;
    const compareLength =
        (numbersVer1?.length && numbersVer1?.length) >= 4 ? 4 : numbersVer1?.length || 0;
    while (result === 0 && index < compareLength) {
        const number1 = +numbersVer1[index] || 0;
        const number2 = +numbersVer2[index] || 0;
        result = number1 - number2;
        // eslint-disable-next-line no-plusplus
        index++;
    }

    return result;
};

/**
 * 判断版本是否满足要求
 * 不传就是不满足要求
 * @param limitInfo
 */
export const checkVersion = async (limitInfo: {
    appVersionLimit: string;
    nebulaVersionLimit?: string;
}) => {
    const { appVersionLimit, nebulaVersionLimit } = limitInfo;

    const appType = getProduct();
    // let res;
    const res = await bridgeGetDeviceInfo();
    if (!res?.data) {
        return true;
    }
    const { appVersion } = res?.data || {};
    // 是极速版但是没传
    if (appType === 13 && nebulaVersionLimit === undefined) {
        return false;
    }
    //   极速版
    if (appType === 13 && nebulaVersionLimit) {
        return compareVersion(appVersion, nebulaVersionLimit) >= 0;
    }
    //   主app
    if (appType === 1) {
        return compareVersion(appVersion, appVersionLimit) >= 0;
    }

    return false;
};
