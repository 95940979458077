import { Toast } from '@ad/sharpui';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { invoke } from '@yoda/bridge';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { getPageId } from 'landingPage/common/utils/getPageId';
import { checkVersion, compareVersion } from 'landingPage/common/utils/compareVersion';
import { getDeviceType, DEVICE_TYPE } from 'landingPage/common/utils/getDeviceInfo';
import {
    trackWechatGameWakeStatus,
    trackStartOpenWechatGame,
    trackUnusualPageInWechatGame,
    traceWechatGameInvokeError,
} from 'landingPage/common/adTrack/monitor';
import { isInApp } from 'landingPage/common/utils/env';
import { fetchWechatGameInfo, getCidCommonConfig, getNeedVersion } from '../../common/api/index';
import { DeeplinkAppType } from '../ButtonDrag/Button';
import { getProduct } from 'landingPage/common/utils/getProduct';
import { appendWithJuXingMacroLinks } from 'landingPage/common/utils/juXingMacro';
import { GameInfo } from 'omega-render';
import { WechatGameDetail, defaultWechatGameDetail } from './components/wechatGameStyleModal';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { transformUrlWithMacros } from '@/landingPage/common/utils/macro';
import { bridgeGetDeviceInfo } from '@/landingPage/common/bridge';

export enum FailNum {
    Version = 1,
    UnInstalled = 2,
    Other = 3,
    NoDetail = 4,
}

export enum ClickPosition {
    base = 0,
    topBlank = 1,
    bottomBlank = 2,
    hotArea = 3,
    stayModal = 4,
    else = 5,
}

export const fetchGameInfo = async (query: {
    gameInfo: GameInfo;
    defaultWechatDetail: defaultWechatGameDetail;
    updateWechatDetail: (params: WechatGameDetail) => void;
    failOperate?: () => void;
}) => {
    const { gameInfo, defaultWechatDetail, updateWechatDetail, failOperate } = query;
    try {
        const res = await fetchWechatGameInfo(gameInfo.wechatGameId || '');
        if (res.originalId) {
            updateWechatDetail({
                ...defaultWechatDetail,
                ...res,
                iconUrl: res?.iconUrl || defaultWechatDetail?.iconUrl,
                name: res?.name || defaultWechatDetail?.name,
                description: res?.description || defaultWechatDetail?.description,
            });
        }
    } catch (e) {
        failOperate?.();
    }
};

export const openWechatGame = async (params: {
    gameInfo: GameInfo;
    preview?: boolean;
    renderClickArea?: ClickPosition;
}) => {
    const { gameInfo, preview, renderClickArea } = params;
    trackStartOpenWechatGame(isInApp ? 'kwai' : 'lianmeng');
    dataTrack({
        eventType: 'EVENT_WECHAT_GAME_CLICK',
        diyExtraInfo: JSON.stringify({
            renderType: 'WECHAT_GAME',
            renderClickArea: renderClickArea || ClickPosition.base,
        }),
    });
    if (!isInApp) {
        trackUnusualPageInWechatGame(getUrlParam('pageId'));
    }
    try {
        const res = await fetchWechatGameInfo(gameInfo.wechatGameId || '');
        if (res.originalId) {
            if (preview) {
                openWechatGameUseBridge({
                    originalId: res.originalId,
                    path: res.path,
                });
                return;
            }

            const callback = getUrlParam('callback');
            const ksCampaignId = getUrlParam('ksCampaignId');
            const ksUnitId = getUrlParam('ksUnitId');
            const ksCreativeId = getUrlParam('ksCreativeId');
            const ksSiteAccountId = getUrlParam('ksSiteAccountId');
            const ksPageId = getUrlParam('pageId');

            let pathWithParam = `${String(res.path)}${
                res.path.includes('?') ? '&' : '?'
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            }callback=${callback}&ksCampaignId=${ksCampaignId}&ksUnitId=${ksUnitId}&ksCreativeId=${ksCreativeId}&ksPageId=${ksPageId}&ksSiteAccountId=${ksSiteAccountId}&ksChannel=kuaishou`;
            pathWithParam = appendWithJuXingMacroLinks(pathWithParam);
            if (getUrlParam('traceid')) {
                try {
                    const res = await getCidCommonConfig();
                    const macros = res.macroParamMap;
                    pathWithParam = transformUrlWithMacros({
                        url: pathWithParam,
                        macros,
                    });
                } catch (e) {
                    console.error(e);
                }
            }
            // TODO 上线前删除测试代码
            // fetch('https://joe-code.staging.kuaishou.com/messages', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         content: `[微信小游戏宏参数]： H5跳转deepLink: ${pathWithParam}, [落地页url]：${window.location.href}`,
            //     }),
            //     credentials: 'omit',
            // });
            console.log('[小游戏最终跳转path]: ', pathWithParam);
            // if (!isInApp) {
            //     openWechatGameInLianmeng({
            //         originalId: res.brightOriginalId,
            //         path: pathWithParam,
            //         renderClickArea,
            //     });
            //     return;
            // }

            const result = await getNeedVersion(getPageId());
            const { androidApp, nebula, iosApp, iosNebula } = result.data.data;
            const isIos = getDeviceType() === DEVICE_TYPE.IOS;
            checkVersion({
                appVersionLimit: isIos ? iosApp : androidApp,
                nebulaVersionLimit: isIos ? iosNebula : nebula,
            })
                .then(() => {
                    console.log('check version true');
                    openWechatGameUseBridge({
                        originalId: res.originalId,
                        path: pathWithParam,
                        renderClickArea,
                    });
                })
                .catch((e) => {
                    trackWechatGameWakeStatus('fail', 'kwai', JSON.stringify(e));
                    Toast.error(
                        `请将快手版本更新至${
                            // 判断是极速版还是主APP
                            getProduct() === 13
                                ? isIos
                                    ? iosNebula
                                    : nebula
                                : isIos
                                  ? iosApp
                                  : androidApp
                        }`,
                    );
                    dataTrack({
                        eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                        deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
                        diyExtraInfo: JSON.stringify({
                            renderType: 'WECHAT_GAME',
                            failType: FailNum.Version,
                            renderClickArea: renderClickArea || ClickPosition.base,
                        }),
                    });
                });
        } else {
            trackWechatGameWakeStatus('fail', 'kwai', 'no originalId');
        }
    } catch (e) {
        console.log('error', e);
        trackWechatGameWakeStatus('fail', 'kwai', JSON.stringify(e));
        Toast.error('小游戏信息获取异常，请稍后重试');
        dataTrack({
            eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
            deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
            diyExtraInfo: JSON.stringify({
                renderType: 'WECHAT_GAME',
                failType: FailNum.NoDetail,
                renderClickArea: renderClickArea || ClickPosition.base,
            }),
        });
    }
};

export const openWechatGameUseBridge = async (params: {
    originalId: string;
    path: string;
    renderClickArea?: ClickPosition;
}) => {
    const { originalId, path, renderClickArea } = params;

    // startOpenWechatGametime = new Date().getTime();
    try {
        let res;
        if (isInApp) {
            res = await invoke('platform.openWechatMiniProgram', {
                originalID: originalId,
                path,
            });
        } else {
            res = await bridge?.openWechatMiniProgram({
                originalID: originalId,
                path,
            });
        }
        console.log('success:', JSON.stringify(res));
        trackWechatGameWakeStatus('success', 'kwai');
        dataTrack({
            eventType: 'EVENT_DEEPLINK_INVOKED',
            deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
            diyExtraInfo: JSON.stringify({
                renderType: 'WECHAT_GAME',
                renderClickArea: renderClickArea || ClickPosition.base,
            }),
        });
    } catch (e) {
        console.error('failed: ', JSON.stringify(e));
        let failType = FailNum.UnInstalled;

        if (isInApp) {
            const { installed } = await invoke('tool.checkAppInstalled', {
                android: 'com.tencent.mm',
                ios: 'weixin://',
            });
            if (installed) {
                Toast.error('暂时无法跳转到小游戏，请稍后重试');
                failType = FailNum.Other;
            } else {
                Toast.error('请先安装微信，再重试');
            }
        } else {
            const res = await bridgeGetDeviceInfo();
            if (res?.data?.SDKVersion && compareVersion(res?.data?.SDKVersion, '3.3.65') >= 0) {
                const { data } = await bridge.checkAppInstalled({
                    android: 'com.tencent.mm',
                    ios: 'weixin://',
                });
                if (data?.isInstalled) {
                    failType = FailNum.Other;
                }
            }
        }

        dataTrack({
            eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
            deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
            diyExtraInfo: JSON.stringify({
                renderType: 'WECHAT_GAME',
                failType,
                renderClickArea: renderClickArea || ClickPosition.base,
            }),
        });

        trackWechatGameWakeStatus('fail', 'kwai');
        traceWechatGameInvokeError(JSON.stringify(e));
    }
};

// export const openWechatGameInLianmeng = (params: {
//     originalId: string;
//     path: string;
//     renderClickArea?: ClickPosition;
// }) => {
//     const { originalId, path, renderClickArea } = params;

//     // tips: 联盟webview默认会注入KwaiAd，无需引入，直接使用即可

//     // 注册window下面的callback事件
//     window.callback_openWechatMiniProgram = (res) => {
//         const jsonRes = JSON.parse(res);
//         if (jsonRes.result !== 1) {
//             console.error('failed: ', JSON.stringify(res));
//             dataTrack({
//                 eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
//                 deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
//                 diyExtraInfo: JSON.stringify({
//                     renderType: 'WECHAT_GAME',
//                     renderClickArea: renderClickArea || ClickPosition.base,
//                 }),
//             });
//             Toast.error('暂时无法跳转到小游戏，请升级APP或检查是否安装微信后再重试');
//             trackWechatGameWakeStatus('fail', 'lianmeng');
//             traceWechatGameInvokeError(JSON.stringify(res));
//         } else {
//             console.log('success: ', JSON.stringify(res));
//             trackWechatGameWakeStatus('success', 'lianmeng');
//             dataTrack({
//                 eventType: 'EVENT_DEEPLINK_INVOKED',
//                 deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
//                 diyExtraInfo: JSON.stringify({
//                     renderType: 'WECHAT_GAME',
//                     renderClickArea: renderClickArea || ClickPosition.base,
//                 }),
//             });
//         }
//     };

//     callLianmengBridge({
//         action: 'openWechatMiniProgram',
//         data: JSON.stringify({
//             originalID: originalId,
//             path,
//         }),
//         callback: 'callback_openWechatMiniProgram',
//     });
// };
