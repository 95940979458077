import { getABResult } from 'landingPage/common/api';
import React, { useEffect } from 'react';

export const useAbHooks = () => {
    const [abData, setAbData] = React.useState<{
        /**是否命中企微客服scheme跳转实验 */
        qywxCustomerAcquisitionSchemeType?: number;
        /**分布表单&对话表单企微弹窗实验 */
        formPopupQywxStyle?: number;
    }>({});
    useEffect(() => {
        getAbData();
    }, []);
    const getAbData = async () => {
        const res = await getABResult({
            adTypes: ['formPopupQywxStyle', 'qywxCustomerAcquisitionSchemeType'],
        });
        setAbData(res.data);
    };
    return abData;
};
