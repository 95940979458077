/* eslint-disable @typescript-eslint/restrict-template-expressions */
// import { importBridge } from '../../bridge';
import { ComponentType } from '../../componentsInfo.gen';
import { isProduction, isSSRHydrate, isInApp, isSSR, RenderEnv } from '../../utils/env';
import { getUrlParam } from '../../utils/getUrlParam';
import { getMobileOperatingSystem, ifWK } from '../../utils/getDeviceInfo';
import { STORE_LOC_STATUS } from '../../../components/Store/Store';
import { STORE_LIST_STATUS } from '../../../components/Store/StoreList';
import { getCallbackInfo, getPageId } from '../util/sendData';
import adBaseLog, { FeLogger, getCookieByName, IPerfLog, LogInfoInterface } from './adBaseLog';
import { bridgeGetDeviceInfo } from 'landingPage/common/bridge';
import { getProduct } from 'landingPage/common/utils/getProduct';
import { DeeplinkAppType } from 'landingPage/components/ButtonDrag/Button';

let initialized = false;
let logger: FeLogger;
function initBaseLog() {
    adBaseLog.init({
        env: isProduction ? 'production' : 'development',
        bizName: isProduction ? 'frontend.ad.moli' : 'frontend.ad.moli.dev',
    });
    logger = adBaseLog.logger();
    initialized = true;
}
export function sendPerfLogLog(params: IPerfLog, millis: number = -1) {
    if (isSSR) {
        // 定位一下哪里调用了
        console.trace();
        return;
    }
    if (!initialized) {
        initBaseLog();
    }
    // 预览情况下不打点
    if (!RenderEnv.preview && !RenderEnv.edit && isProduction) {
        // 默认打点统计
        adBaseLog.perf({ millis, ...params });
    }
}

export function sendTraceLog(params: LogInfoInterface) {
    if (!initialized) {
        initBaseLog();
    }
    logger.info(params);
}

const moliUa = navigator?.userAgent;
const isInLM = /KSADSDK/i.test(moliUa);

const UNUSUAL_PAGE = [
    '285583041147576320',
    '285591482272522240',
    '275415086526382080',
    '269944366334115840',
    '268145744244113408',
    '277527382099124224',
    '281148749927686144',
    '273214646926213120',
    '262045160487452672',
];

/** 不正常落地页 */
export function trackUnusualPage() {
    try {
        const pageId = getPageId() ?? '';
        if (UNUSUAL_PAGE.includes(pageId)) {
            sendPerfLogLog({
                subtag: 'MOLI_UNUSUAL_PAGE',
                extra1: pageId,
                extra2: isInApp ? '1' : '0',
                extra3: isInLM ? '1' : '0',
            });
            const userId = getCookieByName('userId') || getCookieByName('ud') || '0';
            if (!userId) {
                console.error('[ad-base-log]: 未获取到userId');
                return;
            }
            sendTraceLog({
                tags: {
                    pageId,
                    ua: moliUa,
                    uid: userId,
                    callback: getCallbackInfo(),
                },
            });
        }
    } catch (e) {
        console.error(e);
    }
}

export function trackEvent(eventType: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_EVENT',
        extra1: eventType,
        extra2: isSSRHydrate ? 'isSSR' : 'notSSR',
        // extra3: String(window.isPreLandingPage),
    });
}

export function trackIMCost(cost: number) {
    sendPerfLogLog(
        {
            subtag: 'MOLI_IM_COST',
        },
        cost,
    );
}

export function trackOldImage() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_OLD_IMAGE',
        extra1: getUrlParam('pageId'),
    });
}

export function trackOldForm() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_OLD_FORM',
        extra1: getUrlParam('pageId'),
    });
}

/** 建站组件曝光 */
export function componentImpression(componentType: ComponentType) {
    if (componentType === 'BLANK') {
        // 不统计blank组件
        return;
    }
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_COMPONENT_IMPRESSION',
        extra1: componentType,
    });
    // if (componentType === 'FORM') {
    //     trackOldForm();
    // }
}

/** 建站QQ组件组件曝光，单独埋点是因为，通用组件曝光只能到组件级别，不能监控子组件 */
export function trackQQcomponentImpression() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_COMPONENT_IMPRESSION',
        extra1: 'BUTTON_DRAG_QQ_MINI_GAME',
    });
}

export enum URL_TYPE {
    HTTP = '0',
    DEEP_LINK = '1',
}

/** 建站cid从url中获取宏参数失败 */
export function trackGetMacroError(macroName: string, pageId: string, urlType: URL_TYPE) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_GET_MACRO_ERROR',
        extra1: macroName,
        extra2: pageId,
        extra3: urlType,
    });
}

/** 建站环境信息 */
export async function trackEnv() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_IMPRESSION',
        extra1: isSSRHydrate ? 'isSSR' : 'notSSR',
        extra2: isInApp ? '1' : '0',
        // 离线包状态
        extra3: getUrlParam('hyId') ? '1' : '0',
        // 系统信息
        extra4: getMobileOperatingSystem(),
        // WK webview
        extra5: ifWK() ? '1' : '0',
    });
}

// export async function trackPageId() {
//     if (!getUrlParam('hyId')) {
//         sendPerfLogLog({
//             subtag: 'MOLI_CLIENT_PAGEID',
//             extra1: isSSRHydrate ? 'isSSR' : 'notSSR',
//             extra2: getUrlParam('pageId'),
//         });
//     }
// }

// export async function trackIsPre() {
//     await importBridge();
//     const [isPreLandingPage, getPreStatus] = await getIsPreLandingPage();
//     sendPerfLogLog({
//         subtag: 'MOLI_CLIENT_IS_PRE',
//         extra1: isSSRHydrate ? 'isSSR' : 'notSSR',
//         // 是否前置落地页
//         extra2: String(isPreLandingPage),
//         // 获取前置落地页状态
//         extra3: String(getPreStatus),
//     });
// }

// export async function trackIsPreAfter() {
//     sendPerfLogLog({
//         subtag: 'MOLI_CLIENT_IS_PRE_VALUE_AFTER',
//         extra1: String(window.originIsPreLandingPage),
//         extra2: String(window.getPreStatue),
//         extra3: getMobileOperatingSystem(),
//     });
// }

/* 用户停留时长 */
export function trackStayDuration(val: number) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STAY_DURATION',
        millis: val,
        extra1: isSSRHydrate ? 'isSSR' : 'notSSR',
        // extra2: String(window.isPreLandingPage),
    });
}

/* 用户停留时长, 从FMP开始计算 */
export function trackStayDurationFMP(val: number) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STAY_DURATION_FMP',
        millis: val,
        extra1: isSSRHydrate ? 'isSSR' : 'notSSR',
        // extra2: String(window.isPreLandingPage),
    });
}
// 校准fmp打点统计
export function trackFMPCalibrate() {
    sendPerfLogLog({
        subtag: 'MOLI_FMP_CALIBRATE',
    });
}

enum BRIDGE_STATUS {
    SUCCESS = '0',
    ERROR = '1',
}

/** bridge调用成功 */

export function trackBridgeSuccess(api: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_BRIDGE',
        extra1: api,
        extra2: BRIDGE_STATUS.SUCCESS,
        extra4: isInApp ? '1' : '0',
    });
}

/** 微信拉起失败走H5唤起 */
export function tractWeixinCallH5(link, url) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_WEIXIN_CALL_H5',
        extra1: link,
        extra2: url,
    });
}

/** bridge调用失败 */
export function trackBridgeError(
    api: string,
    e?: { result?: string; error_msg?: string; message?: string } | Error | string,
) {
    let errorMessage;
    if (typeof e === 'string') {
        errorMessage = e;
    } else if (e instanceof Error) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        errorMessage = e.message || e.toString();
    } else {
        errorMessage = `${e?.result},${e?.error_msg}`;
    }
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_BRIDGE',
        extra1: api,
        extra2: BRIDGE_STATUS.ERROR,
        extra3: `${errorMessage}`,
        extra4: isInApp ? '1' : '0',
    });
}

/** 监控异步加载依赖调用时未完成 */
export function trackAsyncModuleError(moduleName: string, apiName?: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_ASYNC_ERROR',
        extra1: moduleName,
        extra2: apiName,
    });
}

/** API请求 */
export function trackApi(api: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_API',
        extra1: api,
    });
}

/** API请求耗时 */
export function trackApiDuration(api: string, duration: number) {
    sendPerfLogLog(
        {
            subtag: 'API_TIME',
            extra1: 'MOLI_CLIENT_API_DURATION',
            extra2: api,
        },
        duration,
    );
}

/** API错误 */
export function trackApiError(api: string, message?: string) {
    sendPerfLogLog({
        subtag: 'API_ERROR',
        extra1: 'MOLI_CLIENT_API_ERROR',
        extra2: api,
        extra3: message,
        extra4: window.location.host,
    });
}

/** 门店组件获取经纬度失败 */
export function trackStoreNoLoc() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_NO_LOC',
    });
}

/** 门店组件获取城市失败 */
export function trackStoreNoCity() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_NO_CITY',
    });
}

/** 门店经纬度定位成功 */
export function trackStoreLocGPS() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_GPS',
    });
}

/** 门店经IP定位成功 */
export function trackStoreLocIP() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_IP',
    });
}

/** 门店经定位失败 */
export function trackStoreLocError() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_LOC_ERROR',
    });
}

/** 门店定位授权弹框曝光 */
export function trackStoreRequestModal() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_REQUEST_MODAL',
    });
}
/** 门店定位授权弹框曝光, 点击选择门店按钮 */
export function trackStoreRequestModalSelect() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_REQUEST_MODAL_SELECT',
    });
}

/** 门店定位授权弹框曝光, 点击选择门店按钮 */
export function trackStoreRequestModalClose() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_REQUEST_MODAL_CLOSE',
    });
}

export enum REQUEST_METHOD {
    CLOSE = '0',
    SYSTEM = '1',
}

/** 门店定位授权弹框曝光, 点击打开定位按钮 */
export function trackStoreRequestModalOpen(requestMethod: REQUEST_METHOD) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_REQUEST_MODAL_OPEN',
        extra1: requestMethod,
    });
}

/** 门店组件落地页，门店列表曝光 */
export function trackStoreList(listStatus?: STORE_LIST_STATUS) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_LIST',
        extra1: listStatus,
    });
}
/** 门店组件落地页，城市落地页曝光 */
export function trackStoreCity(locStatus: STORE_LOC_STATUS = STORE_LOC_STATUS.UNKNOWN) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_STORE_CITY',
        extra1: locStatus,
    });
}
/** 门店组件落地页，提交表单打点 */
export function trackStoreSubmit(
    stayDuration: number,
    locStatus: STORE_LOC_STATUS = STORE_LOC_STATUS.UNKNOWN,
) {
    sendPerfLogLog(
        {
            subtag: 'MOLI_CLIENT_STORE_SUBMIT',
            extra1: locStatus,
        },
        stayDuration,
    );
}
export function getComponentType() {
    let componentType = 'unknown';
    try {
        let pageJSON = JSON.parse(window.pageJsonString);
        componentType = pageJSON.pageConversionType;
    } catch (e) {
        console.log(e);
    }
    return componentType;
}
/** 前端监控打点 */
export function trackEventByFront(eventType: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_NOCALLBACK_EVENT',
        extra1: eventType,
        // extra2: getComponentType(),
        // extra2: String(window.isPreLandingPage),
    });
}

/**  获取 getLocation bridge返回结果 */
export function trackGetLocationResult(result: boolean, msg?: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_LOCATION_RES',
        extra1: result ? '1' : '0',
        extra2: msg,
    });
}

/** 门店更新定位按钮点击 */
export function trackUpdateLocation(locStatus: STORE_LOC_STATUS = STORE_LOC_STATUS.UNKNOWN) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_UPDATE_LOCATION',
        extra1: String(locStatus),
    });
}

/** 更新图片CDN错误 */
export function trackUpgradeCDNError(error?: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_UPGRADE_CDN_HOST_ERROR',
        extra1: error,
    });
}

export enum STORE_LOAD_STAG {
    /** 获取定位和城市的耗时 */
    getLocationAndCity,
    /** 从加载到获取完成门店详情的耗时 */
    totalGetDetail,
    /** requestLocationPermission调用耗时 */
    requestLocationPermission,
    /** syncLocation 耗时 */
    syncLocation,
}

/** 门店页组件加载耗时 */
export function trackStoreLoad(stage: STORE_LOAD_STAG, duration: number) {
    sendPerfLogLog(
        {
            subtag: 'MOLI_CLIENT_STORE_LOAD_DURATION',
            extra1: String(stage),
        },
        duration,
    );
}
/** 从bridge获取到动态元素数据的数量 */
export function trackDynamicElementNumber(pageId: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_DYNAMIC_ELEMENT_NUM',
        // extra1: pageId,
    });
    console.log(pageId);
}
export function trackGetDynamicElementNumber(pageId: string, count: number) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_DYNAMIC_ELEMENT_NUM',
        // extra1: pageId,
        // extra2: count.toString(),
    });
    console.log(pageId, count);
}
/** 从bridge获取到动态元素数据失败 */
export function trackGetDynamicFail(pageId: string, error: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_DYNAMIC_GET_FAIL',
        // extra1: pageId,
        // extra2: error,
    });
    console.log(pageId, error);
}
/** 从bridge获取到动态元素数据解析失败 */
export function trackParseDynamicFail(pageId: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_DYNAMIC_PARSE_FAIL',
        // extra1: pageId,
    });
    console.log(pageId);
}
/** 从bridge获取到动态元素数据替换成功 */
export function trackReplaceDynamicSuccess(pageId: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_DYNAMIC_REPLACE_SUCCESS',
        // extra1: pageId,
    });
    console.log(pageId);
}
/** 从bridge获取到动态元素数据替换失败 */
export function trackReplaceDynamicFail(pageId: string, error: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_DYNAMIC_REPLACE_FAIL',
        // extra1: pageId,
        // extra2: error,
    });
    console.log(pageId, error);
}
export function trackIntelligentUnknownError(pageId: string, error: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_INGELLIGENT_UNKNOWN_ERROR',
        // extra1: pageId,
        // extra2: error,
    });
    console.log(pageId, error);
}

export function trackIntelligentGetDataSuccess(pageId: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_INGELLIGENT_FETCH_SUCCESS',
        // extra1: pageId,
    });
    console.log(pageId);
}

export async function trackIntelligentGetDataError(pageId: string, error: string) {
    let appVersion = '';
    try {
        const deviceInfo = await bridgeGetDeviceInfo();
        appVersion = deviceInfo?.data?.appVersion;
    } catch (e) {
        console.log(e);
    }
    const appType = getProduct();
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_INGELLIGENT_FETCH_ERROR',
        // extra1: pageId,
        extra2: error,
        extra3: appVersion + ':' + appType,
    });
    console.log(pageId, error);
}

/** deepLink吊起失败详细监控 */
export async function deepLinkInvokeError(deepLinkType: DeeplinkAppType, renderType: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_DEEPLINK_INVOKE_ERROR',
        extra1: String(deepLinkType),
        extra2: renderType,
    });
}

export function imageLoadType(type: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_IMAGE_TYPE_Loaded',
        extra1: type,
    });
}

/** deepLink吊起监控 */
export async function trackDeepLinkInvoke(deepLinkType: DeeplinkAppType, renderType: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_DEEPLINK_INVOKE',
        extra1: String(deepLinkType),
        extra2: renderType,
    });
}
/** deepLink吊起失败详细日志 */
export async function traceDeepLinkInvokeError(params: {
    deepLinkType: DeeplinkAppType;
    renderType: string;
    targetUrl: string;
}) {
    sendTraceLog({
        tags: {
            pageUrl: window.location.href,
            targetUrl: params.targetUrl,
            deepLinkType: params.deepLinkType,
            renderType: params.renderType,
            ua: navigator?.userAgent,
            specTag: 'deep_link_invoke_error',
        },
    });
}

export function trackIntelligentReplaceDataSuccess(pageId: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_INGELLIGENT_REPLACE_DATA_SUCCESS',
        // extra1: pageId,
    });
    console.log(pageId);
}

export function trackIntelligentReplaceDataError(pageId: string, error: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_INGELLIGENT_REPLACE_DATA_ERROR',
        // extra1: pageId,
        extra2: error,
    });
    console.log(pageId, error);
}

export function trackIntelligentPageId(pageId: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_INGELLIGENT_PAGE',
        // extra1: pageId,
    });
    console.log(pageId);
}

export function trackGetData(data: any) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_GET_DATA',
        // extra1: data,
    });
    console.log(data);
}

export function trackWechatGameWakeStatus(
    status: 'success' | 'fail',
    source: 'kwai' | 'lianmeng',
    reason?: string,
) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_WECHAT_GAME_WAKE_STATUS',
        extra1: status,
        extra2: reason,
        extra3: source,
    });
}

/** 微信小游戏吊起失败详细日志 */
export async function traceWechatGameInvokeError(reason: string) {
    sendTraceLog({
        tags: {
            pageUrl: window.location.href,
            ua: navigator?.userAgent,
            errorReason: reason,
            specTag: 'wechat_game_invoke_error',
        },
    });
}

export function trackStartOpenWechatGame(source: 'kwai' | 'lianmeng') {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_START_OPEN_WECHAT_GAME',
        extra1: source,
    });
}

export function trackUnusualPageInWechatGame(pageId: string) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_UNUSUAL_PAGE_IN_WECHAT_GAME',
        // extra1: pageId,
    });
    console.log(pageId);
}

/**
 * 追踪整个中间页数据替换的时间（包括bridge数据获取）
 * @param time
 */
export function trackIntelligentReplaceDataTime(time: number) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_INGELLIGENT_REPLACE_DATA_TIME',
        millis: time,
    });
}

/**
 * 追踪中间页bridge数据获取的时间
 * @param time
 */
export function trackIntelligentFetchBridgeTime(time: number) {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_INGELLIGENT_FETCH_BRIDGE_TIME',
        millis: time,
    });
}

export function trackCopyActivityCodeSuccess() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_COPY_ACTIVITY_CODE_SUCCESS',
        extra1: 'h5',
    });
}

export function trackCopyActivityCodeFail() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_COPY_ACTIVITY_CODE_FAIL',
        extra1: 'h5',
    });
}

/** 监控获取咨询页面地址失败 */
export function trackGetCustomerURLError(params: {
    pageId: string;
    error?: string;
    openMode?: string;
    // auto?: boolean,
}) {
    const {
        pageId,
        error,
        openMode,
        // auto,
    } = params;
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_GET_CUSTOMER_URL_ERROR',
        extra1: pageId,
        extra2: error,
        extra3: openMode,
        // extra4: auto?.toString() || 'unknown',
    });
}

/** 监控点击咨询表单 */
export function trackCustomerClick(params: { pageId: string; openMode?: string; auto?: boolean }) {
    const { pageId, openMode, auto } = params;
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_CUSTOMER_CLICK',
        extra1: pageId,
        extra2: openMode,
        extra3: auto?.toString() || 'unknown',
    });
}
// 跳转企微客服
export function trackWXKFJump(
    subtag: KEFU_JUMP_SUBTAGS,
    comType: string,
    weixinScene: number,
    msg?: string,
) {
    sendPerfLogLog({
        subtag: subtag,
        extra1: comType,
        extra2: weixinScene + '',
        extra3: msg,
    });
}
export enum KEFU_JUMP_SUBTAGS {
    GET_MD5_FROM_API_ERROR = 'GET_MD5_FROM_API_ERROR', // 请求md5失败数量
    JUMP_WITHOUT_MD5 = 'JUMP_WITHOUT_MD5', // 跳转时未携带md5
    GET_SPEC_PARAM_ERROR = 'GET_SPEC_PARAM_ERROR', // 获取特殊参数失败
}

// 记录分布名单跳过提交表单的数量
export function trackSkipStepFormApi() {
    sendPerfLogLog({
        subtag: 'MOLI_CLIENT_SKIP_STEP_FORM_API',
        extra1: getPageId() || 'unknown',
    });
}
