import { IMsgValidateType, IResult } from '@ad/xiansuoSDK';
import { invoke } from '@yoda/bridge';
import { bridgeGetDeviceInfo } from 'landingPage/common/bridge';
import { getProduct } from 'landingPage/common/utils/getProduct';
import { IFormInfoView } from 'landingPage/services/models';
import { AnyObj } from 'omega-render';
import { isStaging } from 'src/utils/env';

export const openPage = async (url: string) => {
    try {
        await invoke('tool.loadUrlOnNewPage', {
            url,
            type: 'back',
        });
    } catch (e) {
        window.location.href = url;
    }
};

export function getUrlParams(url: string, key: string) {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.get(key) || ''; // "value1"
}

export function matchPageId(url: string) {
    const reg = /pageId=(\d+)/;
    const match = url.match(reg);

    if (match) {
        const pageId = match[1];
        console.log(pageId); // 输出：12345
        return pageId;
    } else {
        console.log('未匹配到数字');
    }
    return;
}

export function getFormData(preValue: IResult[], preFormOpt: IFormInfoView) {
    let phoneId = '';
    let nameId = '';
    let phone = '';
    let name = '';
    preFormOpt.components.forEach((com) => {
        if (com.componentType === 'NAME_TEXT') {
            nameId = com.id;
            return;
        }
        if (com.componentType === 'PHONE_TEXT') {
            phoneId = com.id;
        }
    });
    preValue.forEach((item) => {
        if (item.componentId === phoneId) {
            phone = item.value;
        }
        if (item.componentId === nameId) {
            name = item.value;
        }
    });
    return { phone, name };
}

/** 获取广告位ID，根据应用和环境 */
export function getPhotoPageId() {
    const appType = getProduct();
    if (isStaging) {
        if (appType === 1) {
            return {
                pageId: 100018550,
                subPageId: 100018551,
                posId: 20220715,
            };
        } else {
            return {
                pageId: 100018548,
                subPageId: 100018549,
                posId: 20220714,
            };
        }
    } else {
        if (appType === 1) {
            return {
                pageId: 100022339,
                subPageId: 100022533,
                posId: 17957,
            };
        } else {
            return {
                pageId: 100022340,
                subPageId: 100022532,
                posId: 17956,
            };
        }
    }
}

export class AggregateResultPromise {
    resultPromise: Promise<boolean>;

    resolver: (boolean) => void;

    constructor() {
        this.resultPromise = new Promise((resolve) => {
            this.resolver = resolve;
        });
    }
}

export function addParamsToURL(urlString: string, data: AnyObj) {
    const url = new URL(urlString);
    let params = new URLSearchParams(url.search);
    Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
    });
    // params.append('preLpPageId', getPageId());
    // params.append('preCreativeId', getUrlParam('ksCreativeId'));
    url.search = params.toString();
    return url.toString();
}

// const CONVERSION_COMPONENT = [
//     'TEL_DRAG',
//     'CUSTOMER_SERVICE_DRAG',
//     'WEI_XIN_DRAG',
//     'MULTI_CONSULT_DRAG',
//     'BUTTON_DRAG',
//     'APP_DOWNLOAD_DRAG',
//     'COUPON_CARD',
//     'WECHAT_GAME',
// ];
/** 获取表单的校验方式 */
const getCheckType = (formJson: IFormInfoView): IMsgValidateType => {
    let needCheckType: IMsgValidateType = 0;
    const isValidate = formJson.components.some(
        (item) => JSON.parse(item.otherDesc || '{}').validatePhone,
    );
    if (isValidate) {
        needCheckType = 1;
    }
    if (formJson.validateConfs) {
        formJson.validateConfs.forEach((e) => {
            if (e.validateConfDetails) {
                e.validateConfDetails.forEach((check) => {
                    if (check.validatorConf && check.validatorName === 'smartCheck') {
                        needCheckType = JSON.parse(check.validatorConf).type || 0;
                    }
                });
            }
        });
    }
    return needCheckType;
};

export async function canShowAggregatePage(formJson: IFormInfoView) {
    // const  components: ComponentJson[],
    let result = true;
    const res = await bridgeGetDeviceInfo();
    if (!res?.data) {
        return false;
    }
    const { appVersion } = res?.data || {};
    if (!appVersion) return false;
    const isValidateVersion = compareVersions(appVersion, TARGET_VERSION, '>=');
    console.log('isValidateVersion', isValidateVersion, appVersion);
    if (!isValidateVersion) {
        console.log(`[canShowAggregatePage]:${appVersion}小于版本${TARGET_VERSION}`);
        return false;
    }
    // try {
    //     /** TODO 使用传入的pageJSON参数 */
    //     const pageJSON: { components: ComponentJson[] } = JSON.parse(window.pageJsonString);
    //     /** 落地页只能包含表单一种营销组件 */
    //     componentsForEach(pageJSON.components, (com) => {
    //         if (CONVERSION_COMPONENT.includes(com.type)) {
    //             result = false;
    //             return;
    //         }
    //     });
    // } catch (e) {
    //     console.error(e);
    // }

    // if (!result) {
    //     console.log('[AggregatePage]: 落地页只能包含表单一种营销组件');
    //     return false;
    // }

    /** 必填项必须多于姓名电话 */
    let phoneRequired = false;
    let nameRequired = false;
    formJson.components.forEach((item) => {
        if (item.componentType === 'NAME_TEXT' && item.isRequire === true) {
            nameRequired = true;
        }
        if (item.componentType === 'PHONE_TEXT' && item.isRequire === true) {
            phoneRequired = true;
        }
    });
    if (!(phoneRequired && nameRequired)) {
        console.log('[AggregatePage]: 必填项必须多于姓名电话');
        return false;
    }
    const validateType = getCheckType(formJson);
    if (validateType === 0) {
        console.log('[AggregatePage]: 验证方式不能是无校验');
        return false;
    }

    return result;
}

/**
 * compareVersions函数接受可读性更好的的运算符，from: @susiyang
 *
 * compareVersions('10.1.8', '10.0.4', '>');  // true
 *
 * compareVersions('10.0.1', '10.0.1', '=');  // true
 *
 * compareVersions('10.1.1', '10.2.2', '<');  // true
 *
 * compareVersions('10.1.1', '10.2.2', '<='); // true
 *
 * compareVersions('10.1.1', '10.2.2', '>='); // false
 */
// export const compareVersions = compare;
/** 聚合落地页可支持的最低版本 */
export const TARGET_VERSION = '11.2.20';

type CompareOperator = '>' | '>=' | '=' | '<' | '<=';
const operatorResMap = {
    '>': [1],
    '>=': [0, 1],
    '=': [0],
    '<=': [-1, 0],
    '<': [-1],
};
const compareStrings = (ap: number, bp: number) => {
    if (ap > bp) return 1;
    if (ap < bp) return -1;
    return 0;
};
const compareSegments = (a: string[], b: string[]) => {
    for (let i = 0; i < Math.max(a.length, b.length); i++) {
        const r = compareStrings(+a[i] || 0, +b[i] || 0);
        if (r !== 0) return r;
    }
    return 0;
};
export function compareVersions(v1: string, v2: string, operator: CompareOperator) {
    // validate input and split into segments
    const n1 = v1.split('.');
    const n2 = v2.split('.');

    // validate numbers
    const res = compareSegments(n1, n2);
    return operatorResMap[operator].includes(res);
}
