export default function DownloadIcon(props: {
    fill: string;
    style: { width: number; height: number };
}) {
    const { fill, style } = props;
    const { width, height } = style;

    return (
        <svg
            width={`${width}px`}
            height={`${height}px`}
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_74_27813)">
                <g mask="url(#mask0_74_27813)">
                    <path
                        clip-rule="evenodd"
                        d="M11.5 16.6665V2.6665H13.5V16.6665H11.5ZM3.8335 20H21.167V22H3.8335V20Z"
                        fill={fill}
                    />
                    <path
                        clip-rule="evenodd"
                        d="M12.4899 15.5189L6.59732 9.62631L5.18311 11.0405L12.4899 18.3473L19.7966 11.0405L18.3824 9.62631L12.4899 15.5189Z"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
}
