import { getUrlParam } from './getUrlParam';
import { getAdBridge, importBridge } from '../bridge';
import { aggregateInfoReport } from '../adTrack/radarReport';
import qs from 'query-string';

let aggregationPageInfoListCache: AggregatePageInfoItem[] = [];

export enum CampaignType {
    //极简创编
    Light = 'AD_PRODUCT_SIMPLE_PROMOTION',
}

export interface AggregatePageInfoItem {
    //落地页id
    siteId: string;
    //callback
    callbackInfo: string;
    //广告计划类型
    ksCampaignType: string;
    //广告主账户id
    ksSiteAccountId: string;
    //原始落地页链接
    originUrl?: string;
}

const URL_PARAMS_KEYS = {
    siteId: 'pageId',
    callbackInfo: 'callback',
    ksCampaignType: 'ksCampaignType',
    ksSiteAccountId: 'ksSiteAccountId',
};

/** 是否是明测X
 * 目前是几个参数组合判断，等后期引擎加唯一字段后优化判断逻辑
 */
export const isAggergateCreativeX = () => {
    const [aggregateBiddingPageId, ksCampaignType, isAggregatePage, pageId] = getUrlParam([
        'aggregateBiddingPageId',
        'ksCampaignType',
        'isAggregatePage',
        'pageId',
    ]);

    if (
        isAggregatePage &&
        (!aggregateBiddingPageId?.length || aggregateBiddingPageId === pageId) &&
        ksCampaignType !== CampaignType.Light
    ) {
        return true;
    }
    return false;
};

// const debugData = {
//     data: {
//         aggregationInfo: {
//             aggrFeeds: [
//                 {
//                     url: 'https://aisite.wejianzhan.com/site/wjzsorv8/8fde5eff-530e-43ad-a8be-37ab96c77d4b?q=%E7%BE%8E%E5%AE%B9%E5%B8%88%E5%9F%B9%E8%AE%AD&pm_key=1947268&multi_key=2_30146674_70005&ts=__TS__&ua=__UA__&os=__OS__&ip=__IP__&model=__MODEL__&ac_id=__ACCOUNTID__&did=__DID__&aid=__AID__&cid=__CID__&oaid2=__OAID2__&imei2=__IMEI2__&idfa2=__IDFA2__&idfa3=__IDFA3__&androidid2=__ANDROIDID2__&callback=__CALLBACK__&llsid=__LLSID__&mac3=__MAC3__&page_scene=7',
//                     callback:
//                         'trIp6ERUEiF9esF3Jt8NcNma8tOUk4BolPhLianD_o61DaEYYFcMKPn9njZnF2j-P2KQeMQFTW7ZK8i12zyhJNhj4TH8pBCNFcqgi4205QLMUJ_BkSxzre7sYPfqa2rSO-HrFN238huJOXLOGYBpp8-uwJfa_9C8dS3bX7jau1V67_fMarJ-vxKivlHIyiwh',
//                 },
//                 {
//                     url: 'https://lp.staging.kuaishou.com/rest/n/lp/page/getHtml?hyId=landingPg&pageId=638117917008174953&ksCampaignId=1793994&ksUnitId=20527933&ksCreativeId=198543067&ksCSite=6&ksSiteAccountId=900015363&ksSiteAccountId=900015363&ksSiteOs=0&ksSiteTs=1723031052326&ksSiteIp=172.28.113.65&ksSiteIpV6=&ksSiteUa=Mozilla/5.0%20(Linux;%20Android%2012;%20PDYM20%20Build/SP1A.210812.016;%20wv)%20AppleWebKit/537.36%20(KHTML,%20like%20Gecko)%20Version/4.0%20Chrome/97.0.4692.98%20Mobile%20Safari/537.36&ksSiteModel=PDYM20&ksSiteAdrequestid=QVWPGM62ZNCDXGTIXDQJZCS3RU&ksSiteImei2=&ksSiteImei3=&ksSiteImei4=&ksSiteOaid2=10eab6008d5642cf42abd2aa41f847cb&ksSiteIdfa2=__IDFA2__&ksSiteIdfa3=__IDFA3__&ksSiteAndroidid2=8c0242cd3abc7a93b9c2d0ceceaaffcf&ksSiteAndroidid3=f485017e8d8614d88bae3b9240e794e67b313885&ksSiteKenyidCaa=__KENYID_CAA__&ksSiteDname=ysp%E6%B5%8B%E8%AF%95%E7%BA%BF%E7%B4%A2%20jj&ksSiteBackUrl=kwai://action/bringToFront&ksSiteLlsid=16729697170&ksXAccountId=900015364&callback=JuxnttJ3qmmeXW5WmMgYZNYXQtf1rDbRd5HfCoWJz0PWBD5FXQPXKByhssopNOtVMfmue0o044MSKhWQPbTjCWiVHnUeGFFFCWOuffobKEFGUtz3PdMc8Brb_RDY0Vg81C9PKf9xzy_767edQxAfuDjaKYamsYrgXxuqWkTQuKz14RByyODWeYpFzULYd5ndHrFs4ELvPKOCLgF_FldLpabxLzh86ypqxM444m5VeCHS9wtVvnBAlLvzEEpMCgYL&cityName=110108&isAggregatePage=true&aggregateBiddingPageId=543868023767001360&isAggregateFirst=true&ksCampaignType=SITE_PAGE',
//                     callback:
//                         'JuxnttJ3qmmeXW5WmMgYZNYXQtf1rDbRd5HfCoWJz0PWBD5FXQPXKByhssopNOtVMfmue0o044MSKhWQPbTjCWiVHnUeGFFFCWOuffobKEFGUtz3PdMc8Brb_RDY0Vg81C9PKf9xzy_767edQxAfuDjaKYamsYrgXxuqWkTQuKz14RByyODWeYpFzULYd5ndHrFs4ELvPKOCLgF_FldLpabxLzh86ypqxM444m5VeCHS9wtVvnBAlLvzEEpMCgYL',
//                 },
//                 {
//                     url: 'kwai://adtkpage/moli?midPageTemplateId=0&pageVersion=0&pageId=655150677594713366&templateId=adtk-page-moli-android&templateUrl=https://p3-ad.adukwai.com/kos/nlav11461/r_adtk-page-moli-android.1067.js&templateVersion=1067&templateMd5=d242f3decf73741bba5b7eb06957f7f9&backupUrl=https%3A%2F%2Fchenzhongkj.com%2Frest%2Fn%2Flp%2Fpage%2FgetHtml%3FhyId%3DlandingPg%26pageId%3D655150677594713366%26ksCampaignId%3D2495132805%26ksUnitId%3D5202983597%26ksCreativeId%3D63310449328%26ksCSite%3D1%26ksSiteAccountId%3D36892024%26callback%3DEfUbqFc7yd7nMqPnDeeBDpeq8CnmboZ2f602xoSz3dNrmGeETJom_pYmbZXDNmFxfrFOzinPpKWgL5sX9L1BU9_muQ0GNkj3dcV3K8d5sOS48ZrDk1vu_8vWVWJLQob-7P--Ln331V0erIGc89CwKjkFk8xYHo0FydMiwaraVvZ5wAXxkuth7f-J7X2s4rBhgwlSHgU6YHwpZzmhEJtZaw%26cityName%3D11%26ksCampaignType%3DSITE_PAGE%26downgradeSource%3Dmk&pageConversionType=-1',
//                     callback:
//                         'EfUbqFc7yd7nMqPnDeeBDpeq8CnmboZ2f602xoSz3dNrmGeETJom_pYmbZXDNmFxfrFOzinPpKWgL5sX9L1BU9_muQ0GNkj3dcV3K8d5sOS48ZrDk1vu_8vWVWJLQob-7P--Ln331V0erIGc89CwKjkFk8xYHo0FydMiwaraVvZ5wAXxkuth7f-J7X2s4rBhgwlSHgU6YHwpZzmhEJtZaw',
//                 },
//             ],
//             urls: [
//                 'kwai://adtkpage/moli?midPageTemplateId=0&pageVersion=0&pageId=655150677594713366&templateId=adtk-page-moli-android&templateUrl=https://p3-ad.adukwai.com/kos/nlav11461/r_adtk-page-moli-android.1067.js&templateVersion=1067&templateMd5=d242f3decf73741bba5b7eb06957f7f9&backupUrl=https%3A%2F%2Fchenzhongkj.com%2Frest%2Fn%2Flp%2Fpage%2FgetHtml%3FhyId%3DlandingPg%26pageId%3D655150677594713366%26ksCampaignId%3D2495132805%26ksUnitId%3D5202983597%26ksCreativeId%3D63310449328%26ksCSite%3D1%26ksSiteAccountId%3D36892024%26callback%3DEfUbqFc7yd7nMqPnDeeBDpeq8CnmboZ2f602xoSz3dNrmGeETJom_pYmbZXDNmFxfrFOzinPpKWgL5sX9L1BU9_muQ0GNkj3dcV3K8d5sOS48ZrDk1vu_8vWVWJLQob-7P--Ln331V0erIGc89CwKjkFk8xYHo0FydMiwaraVvZ5wAXxkuth7f-J7X2s4rBhgwlSHgU6YHwpZzmhEJtZaw%26cityName%3D11%26ksCampaignType%3DSITE_PAGE%26downgradeSource%3Dmk&pageConversionType=-1',
//                 'https://lp.staging.kuaishou.com/rest/n/lp/page/getHtml?hyId=landingPg&pageId=638117917008174953&ksCampaignId=1793994&ksUnitId=20527933&ksCreativeId=198543067&ksCSite=6&ksSiteAccountId=900015363&ksSiteAccountId=900015363&ksSiteOs=0&ksSiteTs=1723031052326&ksSiteIp=172.28.113.65&ksSiteIpV6=&ksSiteUa=Mozilla/5.0%20(Linux;%20Android%2012;%20PDYM20%20Build/SP1A.210812.016;%20wv)%20AppleWebKit/537.36%20(KHTML,%20like%20Gecko)%20Version/4.0%20Chrome/97.0.4692.98%20Mobile%20Safari/537.36&ksSiteModel=PDYM20&ksSiteAdrequestid=QVWPGM62ZNCDXGTIXDQJZCS3RU&ksSiteImei2=&ksSiteImei3=&ksSiteImei4=&ksSiteOaid2=10eab6008d5642cf42abd2aa41f847cb&ksSiteIdfa2=__IDFA2__&ksSiteIdfa3=__IDFA3__&ksSiteAndroidid2=8c0242cd3abc7a93b9c2d0ceceaaffcf&ksSiteAndroidid3=f485017e8d8614d88bae3b9240e794e67b313885&ksSiteKenyidCaa=__KENYID_CAA__&ksSiteDname=ysp%E6%B5%8B%E8%AF%95%E7%BA%BF%E7%B4%A2%20jj&ksSiteBackUrl=kwai://action/bringToFront&ksSiteLlsid=16729697170&ksXAccountId=900015364&callback=JuxnttJ3qmmeXW5WmMgYZNYXQtf1rDbRd5HfCoWJz0PWBD5FXQPXKByhssopNOtVMfmue0o044MSKhWQPbTjCWiVHnUeGFFFCWOuffobKEFGUtz3PdMc8Brb_RDY0Vg81C9PKf9xzy_767edQxAfuDjaKYamsYrgXxuqWkTQuKz14RByyODWeYpFzULYd5ndHrFs4ELvPKOCLgF_FldLpabxLzh86ypqxM444m5VeCHS9wtVvnBAlLvzEEpMCgYL&cityName=110108&isAggregatePage=true&aggregateBiddingPageId=543868023767001360&isAggregateFirst=true&ksCampaignType=SITE_PAGE',
//             ],
//         },
//     },
// };

const MOLI_HOST = [
    'lp.staging.kuaishou.com',
    'moli.prt.kuaishou.com',
    'moli.kuaishou.com',
    'chenzhongkj.com',
];

const isMoliSitePage = (url: string) => {
    if (url?.includes('kwai://adtkpage/moli')) {
        return true;
    }
    const pageUrl = new URL(url);
    const host = pageUrl.host;
    return Boolean(MOLI_HOST.filter((item) => item === host)?.length);
};

const handleSitePage = (res: AggregatePageInfoItem, url: string) => {
    let h5Url = url;
    const newResult = { ...res };
    if (url.includes('kwai://adtkpage')) {
        const urlParams = qs.parse(url.split('?')?.[1]);
        // decodeURIComponent(String(url.match(/backupUrl=([^&#]+)/)?.[1]));

        h5Url = (urlParams?.backupUrl || '') as string;
    }
    for (let key in newResult) {
        newResult[key] = getUrlParam(URL_PARAMS_KEYS[key], h5Url);
    }
    return newResult;
};

const handleSplitUrl = (urlItem: string | { url: string; callback: string }) => {
    let result: AggregatePageInfoItem = {
        siteId: '',
        callbackInfo: '',
        ksSiteAccountId: '',
        ksCampaignType: '',
    };

    let aggrSourceUrl = '';

    //兼容没有aggrFeeds结构时的数据
    if (typeof urlItem === 'string') {
        aggrSourceUrl = urlItem;
    } else {
        //aggrFeeds的结构，有callback和url字段
        result.callbackInfo = urlItem?.callback;
        aggrSourceUrl = urlItem?.url || '';
    }

    //如果是魔力建站的链接，则获取accountId、pageId等参数
    //如果是三方落地页，只保留url
    if (isMoliSitePage(aggrSourceUrl)) {
        const res = handleSitePage(result, aggrSourceUrl);
        result = { ...result, ...res };
    } else {
        result.originUrl = aggrSourceUrl;
    }

    return result;
};

/** 聚合竞价场景，获取二次请求的落地页信息
 * 聚合明测A时，获取的是BCD的落地页信息
 * 聚合明测X时，获取的是ABCD的落地页信息
 */
export const getAggregationInfoList = async () => {
    if (!getUrlParam('isAggregatePage')) {
        return [];
    }
    try {
        if (!aggregationPageInfoListCache?.length) {
            await importBridge();
            const adBridge = getAdBridge();
            //预埋了本地debug的能力，如果后面本地调试的时候可以用这部分
            // let res = getUrlParam('isDebug') ? debugData : await adBridge?.getClientData();
            let res = await adBridge?.getClientData();

            aggregateInfoReport({
                fetClientData: 'success',
                clientDataRes: JSON.stringify(res),
            });
            const { aggregationInfo } = res?.data as unknown as any;

            let originalAggrInfos = [];

            //如果上游bridge下发的有aggrFeeds结构，则使用aggrFeeds结构中的值进行赋值
            //否则使用原始urls字段中的值进行取数&赋值
            if (aggregationInfo.aggrFeeds) {
                originalAggrInfos = aggregationInfo?.aggrFeeds;
            } else {
                originalAggrInfos = aggregationInfo?.urls || [];
            }

            aggregationPageInfoListCache = originalAggrInfos?.map((item) => {
                const urlRes = handleSplitUrl(item);
                return urlRes;
            });
        }
        aggregateInfoReport({
            getAggPageInfoList: 'success',
            aggInfoList: JSON.stringify(aggregationPageInfoListCache),
        });
        return [...aggregationPageInfoListCache];
    } catch (e) {
        aggregateInfoReport({
            aggPageTrackImpression: 'fail',
            error: e,
        });
    }
};
