import React from 'react';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { AppletsModal, useComContext } from 'omega-render';
import { WechatActionSceneEnum, Applets, PopupType } from '../WeiXin';
import styles from '../style.module.less';
import { jumpToMiniApp } from './commonFun';

// 多转化事件 优化目标
// const multiConversion = getUrlParam('multiConversion') === 'true';
export interface AppletsConfirmModalProps {
    onClose?: () => void;
    appletsModal?: AppletsModal;
    applets?: Applets;
    wechatStay?: boolean;
}
export const AppletsConfirmModal: React.FC<AppletsConfirmModalProps> = (props) => {
    const { appletsModal, applets, onClose, wechatStay } = props;

    const {
        modalText,
        cancelText,
        confirmText,
        modalTextStyle,
        cancelTextStyle,
        confirmTextStyle,
    } = appletsModal ?? {};

    React.useEffect(() => {
        dataTrack({
            eventType: 'EVENT_VIEW_WECHAT',
            wechatItemId: '0',
            wechatActionScene: WechatActionSceneEnum.JUMP_WECHAT_APPLET_FROM_POPUP,
            lp_page_type: 1,
            diyExtraInfo: JSON.stringify({
                renderType: 'WEI_XIN_DRAG',
                popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
            }),
        });
    }, []);

    const { preview, edit } = useComContext();

    return (
        <div className={styles.appletsConfirm}>
            <div className={styles.modalText} style={modalTextStyle}>
                {modalText || (edit && '请输入弹窗文案')}
            </div>
            <div style={{ display: 'flex', marginTop: 24 }}>
                <div
                    className={styles.cancelText}
                    style={{ ...cancelTextStyle }}
                    onClick={() => {
                        dataTrack({
                            eventType: 'EVENT_VIEW_WECHAT_CANCEL',
                            wechatItemId: '0',
                            wechatActionScene: WechatActionSceneEnum.JUMP_WECHAT_APPLET_FROM_POPUP,
                            lp_page_type: 1,
                            diyExtraInfo: JSON.stringify({
                                renderType: 'WEI_XIN_DRAG',
                            }),
                        });
                        onClose?.();
                    }}
                >
                    {cancelText}
                </div>
                <div
                    className={styles.buttonText}
                    style={confirmTextStyle}
                    onClick={() => {
                        jumpToMiniApp({
                            applets,
                            preview,
                            wechatActionStatus: WechatActionSceneEnum.JUMP_WECHAT_APPLET_FROM_POPUP,
                            wechatStay,
                        });
                    }}
                >
                    {confirmText}
                </div>
            </div>
        </div>
    );
};
