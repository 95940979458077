import { isProduction } from './utils/env';
import { autoTrackAd, trackerAd } from './utils/trackAd';

class Config {
    private static instance: Config;

    private isInitialized: boolean;

    private readonly isPreview: boolean | undefined;

    constructor(isPreview: boolean | undefined) {
        this.isInitialized = false;
        this.isPreview = isPreview;
    }

    public init = () => {
        if (this.isInitialized) {
            return;
        }
        if (this.isPreview) {
            // 预览模式下不允许发 track
            trackerAd.canTrack = false;
            // autoTrackAd(false);
        } else {
            trackerAd.isDev = !isProduction;
            autoTrackAd(true);
        }
        this.isInitialized = true;
        // 渲染前移除bridge配置
    };

    public static getInstance(isPreview: boolean | undefined) {
        if (!Config.instance) {
            Config.instance = new Config(isPreview);
        }

        return Config.instance;
    }

    public static initializeConfig(isPreview: boolean | undefined) {
        Config.getInstance(isPreview).init();
    }

    public static leaveTrack() {
        trackerAd.leaveTrack();
    }
}

export default Config;
