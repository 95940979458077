import * as React from 'react';
import { Toast } from '@ad/sharpui';
import modelStyle from 'landingPage/common/components/Modal/Modal.module.less';
import Modal from 'landingPage/common/components/Modal/Modal';
import { useComContext } from 'omega-render';
import cs from 'classnames';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import { receiveUsingPOST } from 'landingPage/common/api/index';

// 11
import { getPageId } from 'landingPage/common/utils/getPageId';
import { getCallbackInfo } from 'landingPage/common/adTrack/util/sendData';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { verifyWithAuth } from 'landingPage/common/utils/verifyWithAuth';
import styles from '../style.module.less';
import { CouponDetail } from '../CouponCard';
import { getFuWuHaoInfo } from 'landingPage/common/utils/getFuWuHaoInfo';

export interface CouponModalProps
    extends Pick<
        CouponDetail,
        | 'couponId'
        | 'businessName'
        | 'businessLogo'
        | 'couponName'
        | 'instructs'
        | 'storeNames'
        | 'validity'
        | 'storeType'
    > {
    modalText?: string;
    modalTextStyle?: React.CSSProperties;
    countText?: {
        main?: string;
        sub?: string;
    };
    setModalVisible: (param: boolean) => void;
    timeInterval: number;
}

export enum STORE_TYPE {
    ALL = 1,
    SELF,
}

let enableRecive = true;
const CouponModal: React.FunctionComponent<CouponModalProps> = (props) => {
    const {
        modalText,
        modalTextStyle,
        countText,
        setModalVisible,
        businessName,
        businessLogo,
        couponName,
        instructs,
        storeNames,
        couponId,
        validity,
        storeType,
        timeInterval,
    } = props;

    const [state, setState] = React.useState({
        showRule: false,
        phoneNum: '',
        checkCode: '',
        validateNum: false,
        showCountDown: false,
        needResend: false,
        showSuccessCard: false,
        showCouponCard: true,
    });

    const [modalImage] = React.useState({
        modal: transformCDN2Escape(
            'https://static.yximgs.com/udata/pkg/ks-ad-fe/chrome-plugin-upload/2022-01-04/1641289777502.77b2221536e7b6be.png',
        ),
        success: transformCDN2Escape(
            'https://static.yximgs.com/udata/pkg/ks-ad-fe/chrome-plugin-upload/2022-01-04/1641289499585.0430049fbfb1e7e3.png',
        ),
        simpleModal: transformCDN2Escape(
            'https://static.yximgs.com/udata/pkg/ks-ad-fe/chrome-plugin-upload/2022-01-04/1641289741725.5f44b5791af15115.png',
        ),
    });

    console.log('modalImage', modalImage);

    const [countNum, setCountNum] = React.useState(0);

    const sendCheckCode = React.useCallback(async () => {
        if (state.validateNum) {
            try {
                await verifyWithAuth({
                    phone: state.phoneNum,
                });
                setCountNum(timeInterval);
                setState({ ...state, showCountDown: true });
            } catch (e) {
                console.log('e----', e);
                Toast.info({ content: e?.message || '验证码发送失败，请稍后重试', duration: 1500 });
            }
        }
    }, [state, timeInterval]);

    const receiveCoupon = React.useCallback(async () => {
        if (state.validateNum) {
            if (enableRecive) {
                try {
                    enableRecive = false;
                    const res = await receiveUsingPOST({
                        couponId: couponId ?? '',
                        receivePhone: state.phoneNum,
                        smsCode: state.checkCode,
                        /** 落地页信息 */
                        couponSource: {
                            /** 落地页id */
                            sourceId: getPageId(),
                            /** 落地页地址 */
                            sourceUrl: window.location.href,
                            /** callback */
                            callback: getCallbackInfo(),
                        },
                        fuWuHaoInfo: getFuWuHaoInfo(),
                        orientedCity: getUrlParam('cityName') || '',
                        reportRenderInfo: {
                            // 新增加的字段
                            renderType: 'COUPON_CARD', // renderType的值是字符串类型
                        },
                    });
                    if (res.data) {
                        enableRecive = true;
                        setState({
                            ...state,
                            showSuccessCard: true,
                            showCouponCard: false,
                            showRule: false,
                        });
                        dataTrack({
                            eventType: 'EVENT_ELEMENT_COUPONS_CLICK',
                            diyExtraInfo: JSON.stringify({
                                couponId,
                            }),
                        });
                    } else {
                        enableRecive = true;
                        Toast.info({ content: '领取失败，请稍后重试', duration: 1500 });
                    }
                } catch (e) {
                    enableRecive = true;
                    Toast.info({ content: e?.message || '领取失败，请稍后重试', duration: 1500 });
                }
            }
        } else {
            Toast.info({ content: '请输入正确的手机号码', duration: 1500 });
        }
    }, [state, setState, couponId]);

    React.useEffect(() => {
        let timer;
        if (countNum > 0 && timeInterval) {
            if (countNum <= timeInterval) {
                timer = window.setTimeout(() => {
                    setCountNum(countNum - 1);
                }, 1000);
            }
        } else {
            clearTimeout(timer);
            setState({ ...state, showCountDown: false, needResend: true });
        }
    }, [countNum]);

    const SuccessCard = React.useCallback(() => {
        return (
            <div
                className={styles.successCard}
                style={{
                    backgroundImage: `url(${modalImage.success})`,
                }}
            >
                <div className={styles.title}>领取成功</div>
                <div className={styles.desc}>可通过短信到指定门店进行使用</div>
                <div className={styles.couponName}>{couponName}</div>
                <div className={styles.validate}>{validity}</div>
                <div
                    onClick={() => {
                        setState({ ...state, showRule: true });
                    }}
                    className={styles.rule}
                >
                    卡券使用规则
                </div>
            </div>
        );
    }, [couponName, state, validity]);

    const modelHeight = document.getElementsByClassName(modelStyle.layer)[0]?.clientHeight;

    const RuleCard = React.useCallback(() => {
        return (
            // 锁定弹层高度是整个屏幕可视高度的0.819，避免不同机型高度不同导致弹层体感不同
            <div style={{ height: modelHeight * 0.819 }} className={styles.ruleCard}>
                <div
                    className={styles.close}
                    onClick={() => {
                        setState({ ...state, showRule: false });
                    }}
                />
                <div className={styles.title}>卡券使用规则</div>
                <div style={{ height: modelHeight * 0.568 }} className={styles.body}>
                    <div>
                        使用期限：
                        <span>{validity}</span>
                    </div>
                    <div>
                        适用门店：
                        {storeType === STORE_TYPE.ALL ? (
                            <span>全部门店</span>
                        ) : (
                            storeNames?.map((item, index) => {
                                return (
                                    <span key={index}>
                                        {item}
                                        {index !== storeNames.length - 1 && '、'}
                                    </span>
                                );
                            })
                        )}
                    </div>
                    <div>
                        使用规则：
                        {instructs?.map((item, index) => {
                            return (
                                <>
                                    <span key={index}>{item}</span>
                                    <br />
                                </>
                            );
                        })}
                    </div>
                </div>
                <div
                    className={styles.checkBtn}
                    onClick={() => {
                        console.log('---state', state);
                        setState({ ...state, showRule: false });
                    }}
                >
                    我知道了
                </div>
            </div>
        );
    }, [modelHeight, instructs, state, storeNames, validity, storeType]);

    const { edit } = useComContext();

    // const perviewLayerStyle = useEditModalStyle(false);

    return (
        <Modal
            style={{ zIndex: edit ? 10000 : 999 }}
            edit={edit}
            parent={edit ? document.getElementById('preview-app') ?? document.body : undefined}
        >
            {state.showCouponCard && (
                <div className={cs(styles.dialog, { [styles.extraDialog]: !countText })}>
                    <div
                        style={{
                            backgroundImage: `url(${
                                !countText ? modalImage.simpleModal : modalImage.modal
                            })`,
                        }}
                        className={cs(styles.couponModal, {
                            [styles.simpleModal]: !countText,
                        })}
                    >
                        <div className={styles.title}>
                            <div className={styles.couponName}>{couponName}</div>
                            {countText && (
                                <div className={styles.countText}>
                                    <span>{countText?.main}</span>
                                    <span>{countText?.sub}</span>
                                </div>
                            )}
                            <div
                                className={cs(styles.businessName, {
                                    [styles.simpleBusinessName]: !countText,
                                })}
                            >
                                {businessName}
                            </div>
                        </div>
                        {businessLogo ? (
                            <img className={styles.logo} src={businessLogo} alt="" />
                        ) : (
                            <div style={{ height: '52px', width: '52px' }} />
                        )}

                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
                            <input
                                style={{ paddingTop: '2px', marginLeft: '39px' }}
                                className={styles.input}
                                type="text"
                                placeholder="请输入您的手机号"
                                value={state.phoneNum}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const validate = /^[1]([3-9])[0-9]{9}$/.test(value);
                                    setState({ ...state, phoneNum: value, validateNum: validate });
                                }}
                                onFocus={() => {}}
                            />
                            <div className={styles.check}>
                                <input
                                    style={{ paddingTop: '14px', width: '110px' }}
                                    className={styles.input}
                                    type="text"
                                    placeholder="请输入验证码"
                                    value={state.checkCode}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setState({ ...state, checkCode: value });
                                    }}
                                    onFocus={() => {}}
                                />
                                {state.showCountDown ? (
                                    <span className={styles.spanText}>
                                        验证码已发送(
                                        {countNum}
                                        s)
                                    </span>
                                ) : state.needResend ? (
                                    <span className={styles.spanText} onClick={sendCheckCode}>
                                        重新发送
                                    </span>
                                ) : (
                                    <span
                                        className={styles.spanText}
                                        style={{
                                            color: state.validateNum
                                                ? '#fff'
                                                : 'rgba(255,255,255,0.66)',
                                        }}
                                        onClick={sendCheckCode}
                                    >
                                        发送验证码
                                    </span>
                                )}
                            </div>
                        </div>
                        <div
                            className={styles.modalText}
                            style={modalTextStyle}
                            onClick={receiveCoupon}
                        >
                            {modalText}
                        </div>
                        <div
                            onClick={() => {
                                setState({
                                    ...state,
                                    showRule: true,
                                });
                            }}
                            className={styles.rule}
                        >
                            卡券使用规则
                        </div>
                    </div>
                    <div
                        className={styles.close}
                        data-target="coupon-close"
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    />
                </div>
            )}
            {state.showSuccessCard && (
                <div className={styles.dialog}>
                    <SuccessCard />
                    <div
                        className={styles.close}
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    />
                </div>
            )}
            {state.showRule && <RuleCard />}
        </Modal>
    );
};

export default CouponModal;
