/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import styles from './style.module.less';
import { ShufflingImageProps } from './ShufflingImage';
import { useAnimation } from './Type1';
import { useLazyImg } from 'landingPage/common/utils/useLazyImg';
import { useComContext } from 'omega-render';

export const Type3: React.FunctionComponent<
    ShufflingImageProps & { height: number; width: number }
> = (props) => {
    const { edit } = useComContext();
    // card 的宽度
    const { width, playInterval, height, imgs } = props;
    const imgWidth = 0.733 * width;
    const opt = React.useMemo(() => {
        const len = imgWidth;
        const time = 400;
        const delay = playInterval || 4000;
        return {
            len,
            time,
            delay,
        };
    }, [imgWidth, playInterval]);
    const { lazyImgs, domRef } = useLazyImg(imgs, {
        edit,
    });

    const { moved, renderImgs, events } = useAnimation(props, opt);
    const offsetIndex = (renderImgs.length - 1) / 2;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={styles.type3} {...events} ref={domRef}>
            {renderImgs.map((conf, index) => {
                const { imgUrl, targetUrl } = conf;
                const { x, rotateY } = calcStyle(moved, index, offsetIndex, opt.len);
                return (
                    <div
                        key={index}
                        className={styles.img}
                        onClick={() => {
                            if (targetUrl) {
                                window.location.href = targetUrl;
                            }
                        }}
                        style={{
                            transformOrigin: rotateY < 0 ? 'left' : 'right',
                            transform: `perspective(500px) rotateY(${rotateY}deg) translate3d(${x}px, ${
                                0.078431373 * height
                            }px,${-Math.abs(rotateY * 8)}px)`,
                            backgroundImage: `url(${lazyImgs[imgUrl]})`,
                        }}
                    />
                );
            })}
        </div>
    );
};

function calcStyle(moved: number, index: number, offsetIndex: number, len: number) {
    let x = (index - offsetIndex) * len - moved + (len / 0.733 - len) / 2;
    if (index > 1) {
        if (len < 260) {
            x += Math.min(1 + Math.floor((280 - len) / 20) * 2, 7);
        } else if (len < 280) {
            x += 0;
        } else {
            x -= 1 + Math.floor((len - 280) / 10) * 2;
        }
    }
    return {
        x,
        rotateY: x * -0.07352941176470588 + 3.8234,
    };
}

// -220x+b=20
// 52x+b=0

// (272+52)x+b=-20;
//
