import * as React from 'react';
import cs from 'classnames';
import { WechatGameProps } from '../WechatGame';
import bgImg from '../img/bgImg.png';
import styles from '../style.module.less';
import { ClickPosition, openWechatGame } from '../utils';
import { useComContext } from 'omega-render';
import { useButtonTheme } from '@/landingPage/common/utils/useTheme';
import { getPicCDNSource } from '@/landingPage/common/utils/getUrlParam';

export interface ButtonStyleProps
    extends Pick<
        WechatGameProps,
        'gameInfo' | 'buttonText' | 'buttonStyle' | 'dynamicStatus' | 'picBtnImage' | 'position'
    > {}

const PicBtnStyle: React.FunctionComponent<ButtonStyleProps> = (props) => {
    const {
        gameInfo,
        buttonText,
        buttonStyle,
        dynamicStatus,
        picBtnImage = bgImg,
        position,
    } = props;
    const buttonTheme = useButtonTheme();

    const { preview } = useComContext();
    return (
        <div
            className={styles.picBtnWrapper}
            style={{
                backgroundImage: `url(${getPicCDNSource(picBtnImage)})`,
            }}
        >
            <div
                className={cs(styles.button, {
                    [styles.dynamic]: dynamicStatus === 1,
                })}
                style={{ ...buttonStyle, marginRight: 12, ...buttonTheme }}
                onClick={() => {
                    openWechatGame({
                        gameInfo,
                        preview,
                        renderClickArea: ClickPosition[position] || ClickPosition.base,
                    });
                }}
            >
                {buttonText}
            </div>
        </div>
    );
};

export default PicBtnStyle;
