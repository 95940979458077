import { rgbToHsv, rgbToHex, inputToRGB } from '@ctrl/tinycolor';

const hueStep = 2; // 色相阶梯
const saturationStep = 0.16; // 饱和度阶梯，浅色部分
const saturationStep2 = 0.05; // 饱和度阶梯，深色部分
const brightnessStep1 = 0.05; // 亮度阶梯，浅色部分
const brightnessStep2 = 0.15; // 亮度阶梯，深色部分
const lightColorCount = 5; // 浅色数量，主色上
const darkColorCount = 4; // 深色数量，主色下
// 暗色主题颜色映射关系表
const darkColorMap = [
    { index: 7, opacity: 0.15 },
    { index: 6, opacity: 0.25 },
    { index: 5, opacity: 0.3 },
    { index: 5, opacity: 0.45 },
    { index: 5, opacity: 0.65 },
    { index: 5, opacity: 0.85 },
    { index: 4, opacity: 0.9 },
    { index: 3, opacity: 0.95 },
    { index: 2, opacity: 0.97 },
    { index: 1, opacity: 0.98 },
];

interface HsvObject {
    h: number;
    s: number;
    v: number;
}

interface RgbObject {
    r: number;
    g: number;
    b: number;
}

// Wrapper function ported from TinyColor.prototype.toHsv
// Keep it here because of `hsv.h * 360`
function toHsv({ r, g, b }: RgbObject): HsvObject {
    const hsv = rgbToHsv(r, g, b);
    return { h: hsv.h * 360, s: hsv.s, v: hsv.v };
}

// Wrapper function ported from TinyColor.prototype.toHexString
// Keep it here because of the prefix `#`
function toHex({ r, g, b }: RgbObject): string {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `#${rgbToHex(r, g, b, false)}`;
}

// Wrapper function ported from TinyColor.prototype.mix, not treeshakable.
// Amount in range [0, 1]
// Assume color1 & color2 has no alpha, since the following src code did so.
function mix(rgb1: RgbObject, rgb2: RgbObject, amount: number): RgbObject {
    const p = amount / 100;
    const rgb = {
        r: (rgb2.r - rgb1.r) * p + rgb1.r,
        g: (rgb2.g - rgb1.g) * p + rgb1.g,
        b: (rgb2.b - rgb1.b) * p + rgb1.b,
    };
    return rgb;
}

function getHue(hsv: HsvObject, i: number, light?: boolean): number {
    let hue: number;
    // 根据色相不同，色相转向不同
    if (Math.round(hsv.h) >= 60 && Math.round(hsv.h) <= 240) {
        hue = light ? Math.round(hsv.h) - hueStep * i : Math.round(hsv.h) + hueStep * i;
    } else {
        hue = light ? Math.round(hsv.h) + hueStep * i : Math.round(hsv.h) - hueStep * i;
    }
    if (hue < 0) {
        hue += 360;
    } else if (hue >= 360) {
        hue -= 360;
    }
    return hue;
}

function getSaturation(hsv: HsvObject, i: number, light?: boolean): number {
    // grey color don't change saturation
    if (hsv.h === 0 && hsv.s === 0) {
        return hsv.s;
    }
    let saturation: number;
    if (light) {
        saturation = hsv.s - saturationStep * i;
    } else if (i === darkColorCount) {
        saturation = hsv.s + saturationStep;
    } else {
        saturation = hsv.s + saturationStep2 * i;
    }
    // 边界值修正
    if (saturation > 1) {
        saturation = 1;
    }
    // 第一格的 s 限制在 0.06-0.1 之间
    if (light && i === lightColorCount && saturation > 0.1) {
        saturation = 0.1;
    }
    if (saturation < 0.06) {
        saturation = 0.06;
    }
    return Number(saturation.toFixed(2));
}

function getValue(hsv: HsvObject, i: number, light?: boolean): number {
    let value: number;
    if (light) {
        value = hsv.v + brightnessStep1 * i;
    } else {
        value = hsv.v - brightnessStep2 * i;
    }
    if (value > 1) {
        value = 1;
    }
    return Number(value.toFixed(2));
}

interface Opts {
    theme?: 'dark' | 'default';
    backgroundColor?: string;
}

export default function generate(color: string, opts: Opts = {}): string[] {
    const patterns: string[] = [];
    const pColor = inputToRGB(color);
    for (let i = lightColorCount; i > 0; i -= 1) {
        const hsv = toHsv(pColor);
        const colorString: string = toHex(
            inputToRGB({
                h: getHue(hsv, i, true),
                s: getSaturation(hsv, i, true),
                v: getValue(hsv, i, true),
            }),
        );
        patterns.push(colorString);
    }
    patterns.push(toHex(pColor));
    for (let i = 1; i <= darkColorCount; i += 1) {
        const hsv = toHsv(pColor);
        const colorString: string = toHex(
            inputToRGB({
                h: getHue(hsv, i),
                s: getSaturation(hsv, i),
                v: getValue(hsv, i),
            }),
        );
        patterns.push(colorString);
    }

    // dark theme patterns
    if (opts.theme === 'dark') {
        return darkColorMap.map(({ index, opacity }) => {
            const darkColorString: string = toHex(
                mix(
                    inputToRGB(opts.backgroundColor ?? '#141414'),
                    inputToRGB(patterns[index]),
                    opacity * 100,
                ),
            );
            return darkColorString;
        });
    }
    return patterns;
}
type ColorInput = string | { r: number; g: number; b: number };

/**
 * Converts a color input to an RGBA string with the specified opacity.
 *
 * @param color - The color to convert. This can be a HEX string, an "rgb(x, x, x)" or "rgba(x, x, x, x)" string, or an RGB object.
 * @param opacity - The opacity to use in the resulting RGBA string. This should be a number between 0 (completely transparent) and 1 (completely opaque).
 * @returns An RGBA string representing the color with the specified opacity.
 * @throws Will throw an error if the color input is not a valid HEX string, RGB(A) string, or RGB object.
 */
export function convertToRGBA(color: ColorInput, opacity: number): string {
    let r: number, g: number, b: number;

    if (typeof color === 'string') {
        if (color.startsWith('#')) {
            // 处理Hex格式
            let hex = color.replace('#', '');
            if (hex.length !== 6) {
                throw new Error('Invalid HEX color format.');
            }
            r = parseInt(hex.substring(0, 2), 16);
            g = parseInt(hex.substring(2, 4), 16);
            b = parseInt(hex.substring(4, 6), 16);
        } else if (color.startsWith('rgb')) {
            // 处理"rgb(x, x, x)"或"rgba(x, x, x, x)"格式
            let components = color.match(/\d+(\.\d+)?/g);
            if (!components || components.length < 3 || components.length > 4) {
                throw new Error('Invalid RGB(A) format.');
            }
            r = parseInt(components[0], 10);
            g = parseInt(components[1], 10);
            b = parseInt(components[2], 10);
            if (components.length === 4) {
                // 如果已经有了透明度，可以选择是否使用它
                // 例如，可以选择忽略原来的透明度，使用新传入的透明度
                // opacity = parseFloat(components[3]);  // 可以取消注释以使用原始透明度
            }
        } else {
            throw new Error(
                'Invalid color input. Color must be a HEX string or an "rgb(x, x, x)" or "rgba(x, x, x, x)" string.',
            );
        }
    } else if (typeof color === 'object' && 'r' in color && 'g' in color && 'b' in color) {
        // 假设是RGB对象格式
        r = color.r;
        g = color.g;
        b = color.b;
        if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
            throw new Error('Invalid RGB value. Each color component must be between 0 and 255.');
        }
    } else {
        throw new Error(
            'Invalid color input. Color must be either a HEX string, an RGB string, an RGBA string, or an RGB object.',
        );
    }

    // 格式化输出为带有透明度的RGBA字符串
    return `rgba(${r}, ${g}, ${b}, ${opacity.toFixed(2)})`;
}
