import * as React from 'react';
import cs from 'classnames';
import { WechatGameProps } from '../WechatGame';
import styles from '../style.module.less';
import { ClickPosition, openWechatGame } from '../utils';
import { useComContext } from 'omega-render';
import { useButtonTheme } from '@/landingPage/common/utils/useTheme';

export interface ButtonStyleProps
    extends Pick<
        WechatGameProps,
        'gameInfo' | 'buttonText' | 'buttonStyle' | 'dynamicStatus' | 'position'
    > {}

const ButtonStyle: React.FunctionComponent<ButtonStyleProps> = (props) => {
    const { gameInfo, buttonText, buttonStyle, dynamicStatus, position } = props;
    const buttonTheme = useButtonTheme();
    const { preview } = useComContext();
    return (
        <div
            className={cs(styles.button, {
                [styles.dynamic]: dynamicStatus === 1,
            })}
            style={{
                ...buttonStyle,
                ...buttonTheme,
            }}
            onClick={() => {
                openWechatGame({
                    gameInfo,
                    preview,
                    renderClickArea: ClickPosition[position] || ClickPosition.base,
                });
            }}
        >
            {buttonText}
        </div>
    );
};

export default ButtonStyle;
