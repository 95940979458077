import * as React from 'react';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { useComContext } from 'omega-render';
import { invoke } from '@yoda/bridge';
import { trackerAd } from 'landingPage/common/utils/trackAd';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { checkVersion } from 'landingPage/common/utils/compareVersion';
import { isBeta, isInApp, isStaging } from 'landingPage/common/utils/env';
import { getIm, importBridge } from 'landingPage/common/bridge';
import {
    trackBridgeError,
    trackBridgeSuccess,
    trackIMCost,
} from 'landingPage/common/adTrack/monitor';
import Button, { ButtonProps } from '../Button/Button';
import { Div } from '../Blank/Blank';
import { trackState } from '../CustomerServiceDrag/leaveTrack';
import { getStoreState } from '../Store/utils/locStore';
import { ReactComponent as Icon } from './icon.svg';
import { ReactComponent as RightBottomIcon } from './right-icon.svg';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { getFuWuHaoInfoInCustom } from 'landingPage/common/utils/getFuWuHaoInfo';

// import loadable from 'landingPage/common/loadable';

// const getImUrl = loadable(() => import('@ks/kuaishou-fontend-ad-open-im'))

const IM_API = {
    test: 'https://ad-im.test.gifshow.com',
    production: 'https://adim.kuaishou.com',
    developer: '', // 走mock
};
let IM_API_URL = '';
if (process.env.NODE_ENV === 'production') {
    IM_API_URL = IM_API.production;
}
if (process.env.NODE_ENV === 'development') {
    IM_API_URL = IM_API.developer;
}
if (process.env.REACT_APP_STAGE) {
    IM_API_URL = IM_API.test;
}
if (isBeta) {
    IM_API_URL = 'https://adim-beta.corp.kuaishou.com';
}
if (isStaging) {
    IM_API_URL = 'https://adim.staging.kuaishou.com';
}

export enum IM_TYPE {
    KUAI = 1,
    OTHER = 2,
}

export interface CustomerServiceProps
    extends Pick<ButtonProps, 'buttonStyle' | 'backgroundColor' | 'text'> {
    position?: 'bottom' | 'base' | 'rightBottom';
    url?: string;
    funcId?: string;
    /** 右侧悬浮的样式 */
    rightBottomTextStyle: React.CSSProperties;
    /** 右侧按钮背景色 */
    rightBottomBackgroundColor: string;
    /** 右侧图片颜色 */
    rightBottomIconColor: string;
    /** 按钮和底部悬浮的icon颜色 */
    iconColor: string;
    /** 客服id，是美洽、53等 */
    appId?: number;
    /** 客服类型 * */
    csType?: IM_TYPE;
}
export const openServiceUrl = (
    comType: string,
    url?: string,
    funcId?: string,
    csType?: IM_TYPE,
) => {
    if (!url) {
        return;
    }
    trackState.clicked = true;
    const { dataTrack } = trackerAd;
    const storeId = getStoreState<'storeSelected'>('storeSelected')?.id || '';
    dataTrack({
        eventType: 'EVENT_CUSTOMER_SERVICE_CLICK',
        consultationPageId: funcId,
        diyExtraInfo: JSON.stringify({
            storeId,
        }),
    });
    const u = new URL(url);
    const [callback, pageId, multiConversion] = getUrlParam([
        'callback',
        'pageId',
        'multiConversion',
    ]);
    u.searchParams.append('lpPageId', pageId);
    u.searchParams.append('callback', callback);
    u.searchParams.append('multiConversion', multiConversion);
    u.searchParams.append('storeId', storeId);
    u.searchParams.append('areaCode', getUrlParam('cityName') || '');
    u.searchParams.append('viewComponentType', comType);
    checkVersion({ appVersionLimit: '8.2.40' }).then(async (checkRes) => {
        await importBridge();
        const getImUrl = getIm();
        const startTime = Date.now();
        const dataTrackInExtraInfo = await checkVersion({ appVersionLimit: '11.9.30' });

        getImUrl?.({
            h5Url: u.href,
            imURL: IM_API_URL as any,
            needH5Link: !(csType === IM_TYPE.KUAI && checkRes),
            dataTrackInExtraInfo,
            callbackExtraInfo: getFuWuHaoInfoInCustom(),
        })
            .then((realUrl) => {
                trackIMCost(Date.now() - startTime);
                // 走scheme
                if (realUrl.startsWith('kwai')) {
                    const a = window.document.createElement('a');
                    a.href = realUrl;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    openNewPageFromUrl(realUrl + '&hyId=commercial_im_h5');
                }
            })
            .catch((err) => {
                console.log(err);
                trackIMCost(Date.now() - startTime);
            });
    });
};
const CustomerService: React.FunctionComponent<CustomerServiceProps> = (props) => {
    const {
        url,
        rightBottomTextStyle,
        rightBottomBackgroundColor,
        rightBottomIconColor,
        buttonStyle,
        backgroundColor,
        text,
        children,
        iconColor,
        funcId,
        csType,
    } = props;

    // eslint-disable-next-line react/destructuring-assignment
    const position = props.position ?? 'base';
    const { edit } = useComContext();
    const onClick = React.useCallback(() => {
        if (process.env.RUNTIME !== 'server') {
            openServiceUrl('CUSTOMER_SERVICE', url, funcId, csType);
        }
    }, [funcId, csType, url]);
    console.log('process.env.RUNTIME----', process.env.RUNTIME);
    if (position === 'rightBottom') {
        return (
            <Div className={styles.rightBottom} onClick={onClick}>
                <div
                    className={styles.rightIcon}
                    style={{
                        backgroundColor: rightBottomBackgroundColor,
                    }}
                >
                    <RightBottomIcon fill={rightBottomIconColor} />
                </div>
                <p style={rightBottomTextStyle}>
                    {text.length > 4 ? `${text.slice(0, 4)}...` : text}
                </p>
            </Div>
        );
    }
    return (
        <Button
            bottom={position === 'bottom'}
            // icon={<Icon className={styles.icon} fill={iconColor} />}
            icon={<Icon className={styles.icon} fill={iconColor} />}
            text={text}
            buttonStyle={buttonStyle}
            onClick={onClick}
            backgroundColor={backgroundColor}
        >
            {children}
            {position === 'bottom' && (
                <>
                    <BlockPlaceholder pos="BOTTOM" preview={edit} />
                    <SafeAreaPlace />
                </>
            )}
        </Button>
    );
};

export default CustomerService;
export async function openNewPageFromUrl(realUrl: string) {
    if (isInApp) {
        try {
            await invoke('tool.loadUrlOnNewPage', {
                url: realUrl,
                type: 'back',
            });
            trackBridgeSuccess('loadUrlOnNewPage');
        } catch (e) {
            trackBridgeError('loadUrlOnNewPage', e);
        }
    } else {
        const a = window.document.createElement('a');
        a.href = realUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}
