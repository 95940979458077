import HeatmapCore from '@ad/heat-map-sdk';
import * as yoda from '@yoda/bridge';
import { fireflyIsProd, fireflySDK } from 'src/utils/firefly';

// import { isProduction, isBeta } from './env';

export function initHeatmapSdk() {
    fireflySDK
        .getData({
            path: fireflyIsProd
                ? '/kos/nlav11175/moliFE/heatmap_config.json'
                : '/kc/files/a/bird-test-cdn/moliFE/heatmap_config.json', // 注：测试环境与线上环境path不一样
        })
        .then((res) => {
            const data = res.data;
            // eslint-disable-next-line
            new HeatmapCore({
                appId: 'site-editor',
                mode: 'business',
                reportPath: {
                    click: '/rest/n/lp/heatmap/reportClick',
                    attention: '/rest/n/lp/heatmap/reportAttention',
                    scroll: '/rest/n/lp/heatmap/reportScroll',
                },
                kconf: data,
                yodaInstance: yoda,
            });
            // eslint-disable-next-line
            loadMobileRender({
                appId: 'site-editor',
                mode: 'business',
                kconf: data,
            });
        })
        .catch(() => {
            console.error('Fetch Heatmap config error');
        });
}

async function loadMobileRender(config: any) {
    if (!window.location.href.includes('enableHeatMapMobileRender')) {
        return;
    }

    try {
        await loadScript(
            `https://static.yximgs.com/udata/pkg/ks-ad-fe/heat-map-render/${
                (config.kconf.mobileRenderVersion as string) || 'latest'
            }/index.js`,
        );
        const hm = (window as any).HeatMapCore;
        if (hm?.Render) {
            return new hm.Render({
                ...config,
            });
        }
    } catch (e) {
        console.warn('[Heatmap Seed]Render SDK 初始化失败', e);
    }
    return null;
}

async function loadScript(scriptUrl: string) {
    return new Promise((res, rej) => {
        const headTag = document.getElementsByTagName('head')[0];
        const scriptEle = document.createElement('script');
        scriptEle.src = scriptUrl;
        headTag.appendChild(scriptEle);
        scriptEle.onload = () => {
            res(0);
        };
        scriptEle.onerror = () => {
            rej();
        };
    });
}
