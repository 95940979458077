/* eslint-disable react/style-prop-object */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
// 块级渲染
import styles from './style.module.less';

export const Element = ({ attributes, children, element }) => {
    const fontSize = children?.[0]?.props?.text?.fontSize;
    const fontStyle = {
        fontSize: fontSize ?? 'inherit',
        marginLeft: fontSize ? Math.floor((fontSize / 22) * 32) : 32,
        listStylePosition: 'inside',
    };
    const style = { textAlign: element.align };
    // console.log('[???]', attributes, children, element)
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            );
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case 'list-item':
            return (
                <li style={{ ...style, ...fontStyle }} {...attributes}>
                    {children}
                </li>
            );
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        default:
            return (
                <div style={style} {...attributes}>
                    {children}
                </div>
            );
    }
};
// 行内渲染
export const Leaf = ({ attributes, children, leaf }) => {
    return (
        <span className={styles.slateLeafWrapper}>
            <span
                {...attributes}
                style={{
                    fontSize: leaf.fontSize,
                    color: leaf.color,
                    letterSpacing: leaf.letterSpace,
                    lineHeight: leaf.lineSpace,
                    backgroundColor: leaf.bgcolor,
                    fontFamily: leaf.fontFamily,
                    fontWeight: leaf?.bold ? 'bold' : '',
                    fontStyle: leaf?.italic ? 'italic' : '',
                    textDecoration: leaf?.underline ? 'underline' : '',
                }}
            >
                {children}
            </span>
        </span>
    );
};
