const DistanceThreshold = 9;

export function isSuportPointerEvent() {
    return 'PointerEvent' in window;
}

export function isSuportTouchEvent() {
    return 'ontouchstart' in window;
}

export function calculateDistance(p1, p2) {
    return Math.sqrt((p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2);
}

export function isSamePointer(event1, event2) {
    return event1 && event2 && event1.pointerId === event2.pointerId;
}

export function isSameTouch(event1, event2) {
    if (event1 && event2 && event1.changedTouches && event2.changedTouches) {
        const {
            changedTouches: [{ identifier: id1 }],
        } = event1;
        const {
            changedTouches: [{ identifier: id2 }],
        } = event2;
        return id1 === id2;
    }
    return false;
}

const clickSimulation = {
    pointer(el, handler) {
        let lastStartEvent = null;
        let maxDistance = 0;

        el.addEventListener(
            'pointerdown',
            event => {
                lastStartEvent = lastStartEvent || event; // 如果发生多点触碰 只记录第一个
            },
            true
        );
        el.addEventListener(
            'pointermove',
            event => {
                if (isSamePointer(lastStartEvent, event)) {
                    const distance = calculateDistance(
                        [lastStartEvent.clientX, lastStartEvent.clientY],
                        [event.clientX, event.clientY]
                    );
                    maxDistance = Math.max(maxDistance, distance);
                }
            },
            true
        );
        el.addEventListener(
            'pointerup',
            event => {
                if (isSamePointer(lastStartEvent, event)) {
                    if (lastStartEvent.target === event.target && maxDistance <= DistanceThreshold) {
                        handler(event);
                    }
                    lastStartEvent = null;
                    maxDistance = 0;
                }
            },
            true
        );
        el.addEventListener(
            'pointercancel',
            event => {
                if (isSamePointer(lastStartEvent, event)) {
                    lastStartEvent = null;
                    maxDistance = 0;
                }
            },
            true
        );
    },

    touch(el, handler) {
        let lastStartEvent = null;
        let maxDistance = 0;

        el.addEventListener(
            'touchstart',
            event => {
                lastStartEvent = lastStartEvent || event; // 如果发生多点触碰 只记录第一个
            },
            true
        );
        el.addEventListener(
            'touchmove',
            event => {
                if (isSameTouch(lastStartEvent, event)) {
                    const {
                        changedTouches: [{ clientX: currentX, clientY: currentY }],
                    } = event;
                    const {
                        changedTouches: [{ clientX: startX, clientY: startY }],
                    } = lastStartEvent;

                    const distance = calculateDistance([startX, startY], [currentX, currentY]);
                    maxDistance = Math.max(maxDistance, distance);
                }
            },
            true
        );
        el.addEventListener(
            'touchend',
            event => {
                if (isSameTouch(lastStartEvent, event)) {
                    const {
                        changedTouches: [{ target: currentTarget }],
                    } = event;
                    const {
                        changedTouches: [{ target: startTarget }],
                    } = lastStartEvent;
                    if (currentTarget === startTarget && maxDistance <= DistanceThreshold) {
                        handler(event);
                    }
                    lastStartEvent = null;
                    maxDistance = 0;
                }
            },
            true
        );
        el.addEventListener(
            'touchcancel',
            event => {
                if (isSameTouch(lastStartEvent, event)) {
                    lastStartEvent = null;
                    maxDistance = 0;
                }
            },
            true
        );
    },

    click(el, handler) {
        el.addEventListener('click', handler, true);
    },
};

/**
 * 添加 click 监听
 * @param {Element} el
 * @param {function} handler
 */
export default function addClickEvent(el, handler) {
    clickSimulation.click(el, handler);
}
