import Modal from 'landingPage/common/components/Modal/Modal';
import styles from './style.module.less';
import cs from 'classnames';
import { useCallback, useState } from 'react';
import { AppInfo, getOrderId } from 'landingPage/common/api';
import { IOrderParams } from 'landingPage/common/api/interface';
import { useComContext, useRenderState } from 'omega-render';
import { getUrlParam } from 'omega-shared/utils';
import { bridgeGetDeviceInfo, getAdBridge } from 'landingPage/common/bridge';
import { IAppInfo } from '../../AppDownload';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { Toast } from '@ad/sharpui';
import { extraTrack } from '../../redux';

interface AppointmentModalProps {
    onClose: () => void;
    appInfo: IAppInfo | undefined;
    onSuccess: () => void;
}
export const AppointmentModal = (props: AppointmentModalProps) => {
    const { onClose, appInfo, onSuccess } = props;
    const [senMsg, setSendMsg] = useState(true);
    const { id } = useComContext();
    const changeSendMsg = useCallback(() => {
        setSendMsg(!senMsg);
    }, [senMsg]);
    const { state } = useRenderState((state) => {
        return {
            state: state.appDownload?.appInfo as AppInfo,
        };
    });

    const onAppointmentSure = useCallback(async () => {
        dataTrack({
            eventType: 'EVENT_APPOINT_BUTTON_CLICK',
            diyExtraInfo: JSON.stringify({
                renderType: 'APP_DOWNLOAD_DRAG',
                callback: getUrlParam('callback'),
                openSMS: senMsg,
            }),
        });
        let deviceId = '';
        const deviceInfo = await bridgeGetDeviceInfo();
        deviceId = deviceInfo?.data?.globalId;
        const param: IOrderParams = {
            deviceId,
            appId: (state.appId || appInfo?.appId) as string,
            appName: state.appName || appInfo?.appName || '',
            appointTime: new Date().getTime(),
            noticeType: senMsg ? 'MESSAGE' : 'NONE',
            callback: getUrlParam('callback'),
            packageId: (state.packageId || appInfo?.packageId) as string,
            componentType: 'APP_DOWNLOAD_DRAG',
            isH5: !!getUrlParam('isH5'),
            isMK: getUrlParam('downgradeSource') ? true : getUrlParam('isH5') ? false : '',
            isDowngrade: getUrlParam('downgradeSource'),
            appVersion: deviceInfo?.data?.appVersion,
            lpPageId: getUrlParam('pageId'),
        };
        try {
            const res = await getOrderId(param);
            extraTrack(appInfo, id, {
                renderType: 'APP_DOWNLOAD_DRAG',
                appVersion: deviceInfo?.data?.appVersion, //  MK相关
                callback: getUrlParam('callback'), //  MK相关
                isH5: !!getUrlParam('isH5'),
                isMK: getUrlParam('downgradeSource') ? true : getUrlParam('isH5') ? false : '',
                isDowngrade: getUrlParam('downgradeSource'), //  MK相关
                componentType: 'APP_DOWNLOAD_DRAG', //  MK相关
                openSMS: !!senMsg, //  开启短信；开启-true；未开启-false
                orderId: res.data, //  eapi返回的预约id；
            });
            // 该桥方法未登录可自动唤起登录
            (getAdBridge() as any)
                ?.adReserveApp({
                    state: 1, // 1-确认预约  2-取消预约
                    orderId: res.data,
                    // orderId: 'GRO_Ez1S+zaj7YO5cFxAIVMIgQ==',
                    // reserveTimeMs: '', // 可不传
                })
                .then((res) => {
                    console.log('adReserveApp then', res);
                    if (res.result === 1) {
                        Toast.success('预约成功');
                        onClose();
                        onSuccess();
                    } else {
                        Toast.error(`预约失败: ${res.message || res.msg}`);
                    }
                })
                .catch((e) => {
                    console.log('adReserveApp catch', e);
                    Toast.error('预约失败');
                });
        } catch (e) {
            console.log(e);
            Toast.error(e.message);
        }
    }, [senMsg, state]);
    return (
        <Modal style={{ zIndex: 1000 }}>
            <div className={styles.wrapper}>
                <div className={styles.title}>确认预约到期下载？</div>
                <div className={styles.text}>确认游戏上线后仅在WiFi环境下 自动进行下载？</div>
                <div className={styles.option}>
                    <span
                        className={senMsg ? styles.checked : styles.unCheck}
                        onClick={changeSendMsg}
                    ></span>
                    <span>使用快手绑定手机号接收短信通知</span>
                </div>
                <div className={styles.opWrapper}>
                    <div
                        className={cs(styles.cancelBtnWrapper, styles.commonBtn)}
                        onClick={() => {
                            onClose();
                        }}
                    >
                        取消
                    </div>
                    <div
                        className={cs(styles.sureBtnWrapper, styles.commonBtn)}
                        onClick={onAppointmentSure}
                    >
                        确认
                    </div>
                </div>
            </div>
        </Modal>
    );
};
