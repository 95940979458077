import { brandDetial } from '../services/apis';
import { getDynamicBrandData } from './utils/getDynamicBrandUtil';

interface IBrandInfo {
    name: string;
    description: string;
    imageUrl: string;

    // 不同商品独有信息
    fictionModel: {
        // 小说模块
        fictionTags: string[];
        fictionSections: [
            {
                title: string;
                content: string;
            },
        ];
    };
}

// 章节部分转换标识
const chapterFlag = {
    content: 'CHAPTOR_CONTENT',
    more: 'CHAPTOR_MORE',
    chaptorTitle: 'CHAPTOR_TITLE', // todo  替换模板
    chaptorContent: 'CHAPTOR_TEXT', // todo  替换模板
    chaptorTitleUrl: 'CHAPTOR_TITLE_URL',
};
// 主商品部分转换标识
const mainFlag = {
    banner: 'MAIN_BANNER', // banner图
    appIcon: 'MAIN_APPICON', // 应用图案
    appName: 'MAIN_APPNAME', // 应用名称
    tagText: 'MAIN_TAGTEXT', // 标签文案
    tagBorder: 'MAIN_TAGBORDER', // 标签边框
    appCard: 'MAIN_APPCARD', // app卡片部分
    desc: 'MAIN_DESC', // 简介部分
    descText: 'MAIN_DESCTEXT', // 简介文本部分
    descBorder: 'MAIN_DESCBORDER', // 简介边框部分
    descTextT4: 'MAIN_DESCTEXT_T4', // 在模板t4中的简介文本替换标识
    download: 'APPDOWNLOAD',
};

export const getSuggestCom = async (data, components) => {
    try {
        const resData = await brandDetial(data);
        const suggestData: IBrandInfo[] = resData?.data?.recProductList ?? [];
        const list = suggestData.map((item: IBrandInfo) => {
            return {
                imageUrl: item?.imageUrl,
                name: item?.name,
                tags: item?.fictionModel?.fictionTags,
                description: item?.description,
                btnColor: '#fff', // TODO 替换
            };
        });
        suggestionWrapper.props.list = list;

        // 背景色
        const appCardNode = findComponent(components, (com) => {
            return com?.props?.transFlag === mainFlag.appCard;
        });
        const bgColor = appCardNode?.props?.style?.backgroundColor;

        // 标签色
        const tagTextNode = findComponent(components, (com) => {
            return com?.props?.transFlag === mainFlag.tagText;
        });
        const primaryColor = tagTextNode?.props?.style?.color;

        // 描述色
        const descNode = findComponent(components, (com) => {
            return com?.props?.transFlag === mainFlag.descText;
        });
        const desc4Node = findComponent(components, (com) => {
            return com?.props?.transFlag === mainFlag.descTextT4;
        });
        const descColor = descNode?.props?.style?.color || desc4Node?.props?.style?.color;

        // 文本颜色
        const chaptorTitle = findComponent(components, (com) => {
            return com?.props?.transFlag === chapterFlag.chaptorTitle;
        });
        const nameColor = chaptorTitle?.props?.style?.color;

        // 下载配置
        const appdownloadConfig = findComponent(components, (com) => {
            return com?.props?.transFlag === mainFlag.download;
        });
        const config = appdownloadConfig?.props;

        bgColor && (suggestionWrapper.props.backgroundColor = bgColor);
        primaryColor && (suggestionWrapper.props.tagColor = primaryColor);
        descColor && (suggestionWrapper.props.descColor = descColor);
        nameColor && (suggestionWrapper.props.textColor = nameColor);
        config && (suggestionWrapper.props.downloadConfig = config);

        return [suggestionWrapper];
    } catch (e) {
        console.log(e);
        return [];
    }

    //    suggestionWrapper.props = resData.recProductList
};
export function findComponent(components: any[], fun: (com: any, index: number) => boolean) {
    function find(components?: any[]): any | null {
        if (!components) {
            return null;
        }
        for (let i = 0; i < components?.length; i++) {
            const com = components[i];
            if (fun(com, i)) {
                return com;
            }
        }
        for (let i = 0; i < components?.length; i++) {
            const res = find(components[i]?.components as any[]);
            if (res) {
                return res;
            }
        }
        return null;
    }
    return find(components);
}

const suggestionWrapper = {
    id: '352555094092385131',
    encryptedId: '',
    type: 'SuggestionForFiction',
    version: '1.0.0',
    props: {
        list: [
            {
                imageUrl:
                    'https://static.yximgs.com/udata/pkg/387963d066ad448c97f0488d536adfc0.png',
                name: '商品id1',
                tags: ['aaa', 'bbb'],
                description:
                    '商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述',
                btnColor: '#fff',
            },
            {
                imageUrl:
                    'https://static.yximgs.com/udata/pkg/387963d066ad448c97f0488d536adfc0.png',
                name: '商品id2',
                tags: ['aaa', 'bbb'],
                description:
                    '商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述',
                btnColor: '#fff',
            },
            {
                imageUrl:
                    'https://static.yximgs.com/udata/pkg/387963d066ad448c97f0488d536adfc0.png',
                name: '商品id3',
                tags: ['aaa', 'bbb'],
                description:
                    '商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述',
                btnColor: '#fff',
            },
        ],
        backgroundColor: '#fff',
        tagColor: '#fff',
        descColor: '#fff',
        textColor: '#fff',
        downloadConfig: null,
    },
    validate: {},
    order: 24,
    components: [],
};

export const fillSuggestContent = async (instance, pageJson, components) => {
    const { ksIsDynamic, recoProductIds, libraryId } = getDynamicBrandData();
    const pageId = pageJson?.id;
    if (ksIsDynamic === '1' && recoProductIds.length > 0 && libraryId && pageId) {
        try {
            const suggestionCom = await getSuggestCom(
                {
                    recoProductIds,
                    libraryId,
                    pageId,
                },
                components,
            );
            instance.updatePageJson([
                ...components,
                ...suggestionCom,
                {
                    id: '9999',
                    type: 'AGREEMENT',
                    version: '0.0.1',
                },
            ]);
        } catch (e) {
            console.log(e);
        }
    }
};
