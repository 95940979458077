import * as React from 'react';
import { useSetState } from 'react-use';

import { getWindow } from 'landingPage/common/utils/getWindow';
import { useEditHeight } from 'landingPage/common/hook/useEditHeight';
import { Div } from '../Blank/Blank';
import styles from './style.module.less';

export interface VideoProps {
    videoUrl: string;
    videoCover: string;
    loadend: () => void;
}

const useVideo = () => {
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const that = React.useRef({ timer: 0 });
    const [state, setState] = useSetState<{
        muted: boolean;
        status?: 'playing' | 'paused';
        showMask: boolean;
        showReplay: boolean;
        currentTime: string;
        duration: string;
        percent: string;
    }>({
        status: 'paused',
        showMask: false,
        showReplay: false,
        muted: true,
        currentTime: '00:00',
        duration: '00:01',
        percent: '0%',
    });
    const play = React.useCallback(() => {
        const videoDom = videoRef.current;

        const play = videoDom?.play();
        play?.then(() => {
            setState({
                status: 'playing',
            });
        }).catch(() => {
            setState({
                status: 'paused',
            });
        });
    }, [setState]);
    const pause = React.useCallback(() => {
        const videoDom = videoRef.current;

        videoDom?.pause();
        setState({
            status: 'paused',
        });
    }, [setState]);

    const onEnded = React.useCallback(() => {
        setState({
            showMask: false,
            showReplay: true,
        });
    }, [setState]);

    const replay = React.useCallback(() => {
        setState({ showReplay: false });
        play();
    }, [play, setState]);
    const onVideoClick = React.useCallback(() => {
        window.clearTimeout(that.current.timer);
        setState({
            showMask: true,
        });
    }, [setState]);

    const toggleMuted = React.useCallback(() => {
        setState((state) => {
            return {
                ...state,
                muted: !state.muted,
            };
        });
    }, [setState]);
    React.useEffect(() => {
        const { current } = that;
        return () => {
            window.clearTimeout(current.timer);
        };
    }, []);

    const fullScreen = React.useCallback(() => {
        const videoDom = videoRef.current as any;

        if (videoDom.requestFullscreen) {
            (videoDom as HTMLVideoElement).requestFullscreen();
        } else if (videoDom.mozRequestFullScreen) {
            /* Firefox */
            videoDom.mozRequestFullScreen();
        } else if (videoDom.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            videoDom.webkitRequestFullscreen();
        } else if (videoDom.msRequestFullscreen) {
            /* IE/Edge */
            videoDom.msRequestFullscreen();
        }
    }, []);
    const onTimeUpdate = React.useCallback(
        (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
            const { currentTime, duration } = e.currentTarget;
            const current = secondFormate(Math.floor(currentTime));
            const progress = (currentTime / duration).toFixed(2);
            const percent = `${Number(progress) * 100}%`;
            setState({
                currentTime: current,
                duration: secondFormate(Math.floor(duration)),
                percent,
            });
        },
        [setState],
    );
    const hideMask = React.useCallback(() => {
        window.clearTimeout(that.current.timer);
        if (state.status !== 'paused') {
            that.current.timer = window.setTimeout(() => {
                setState({
                    showMask: false,
                });
            }, 5000);
        }
    }, [setState, state.status]);
    React.useEffect(() => {
        hideMask();
    }, [hideMask, setState, state.showMask, state.status]);
    const onProgressMove = React.useCallback(
        (e: any) => {
            e.preventDefault();
            const videoDom = videoRef.current;

            const lineWrapper = getWindow().document.querySelector(
                `.${styles.lineWrapper}`,
            ) as HTMLDivElement;
            const width = lineWrapper.offsetWidth;
            const { left } = lineWrapper.getClientRects()[0];

            const touches = e.targetTouches[0];

            const offsetWidth = touches.clientX - left;
            let percent = parseInt(`${(offsetWidth / width) * 100}`, 10);

            if (percent <= 0) {
                percent = 0;
            }

            if (percent >= 100) {
                percent = 100;
            }
            const currentTime = (Number(videoDom?.duration) * percent) / 100;
            if (videoDom) {
                videoDom.currentTime = currentTime;
            }
            setState({
                percent: `${percent}%`,
            });
            hideMask();
        },
        [hideMask, setState],
    );
    const onLoadedMetadata = React.useCallback(() => {
        videoRef.current?.pause();
        setState({
            muted: false,
        });
    }, [setState]);
    return {
        videoRef,
        state,
        replay,
        onVideoClick,
        play,
        pause,
        onEnded,
        toggleMuted,
        fullScreen,
        onTimeUpdate,
        onProgressMove,
        onLoadedMetadata,
    };
};
const Video: React.FunctionComponent<VideoProps> = (props) => {
    const { videoUrl, children } = props;
    const {
        videoRef,
        state,
        replay,
        onVideoClick,
        pause,
        play,
        onEnded,
        toggleMuted,
        fullScreen,
        onTimeUpdate,
        onProgressMove,
        onLoadedMetadata,
    } = useVideo();
    const { editHeight } = useEditHeight();
    const {
        showMask,
        status,
        showReplay,
        muted,
        currentTime: beginTime,
        duration,
        percent,
    } = state;
    const onCanPlay: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void = () => {
        // loadend();
    };
    return (
        <Div className={styles.root} style={{ height: editHeight }}>
            <video
                src={videoUrl}
                ref={videoRef}
                playsInline
                muted={muted}
                onClick={onVideoClick}
                onEnded={onEnded}
                onTimeUpdate={onTimeUpdate}
                onCanPlay={onCanPlay}
                onLoadedMetadata={onLoadedMetadata}
                autoPlay
            />
            {showMask && (
                <div className={styles.mask}>
                    {status === 'playing' && <div className={styles.pause} onClick={pause} />}
                    {status === 'paused' && <div className={styles.play} onClick={play} />}
                    <div className={styles.bottom}>
                        <div className={styles.progress}>
                            <div className={styles.beginTime}>{beginTime}</div>
                            <div className={styles.lineWrapper}>
                                <div
                                    className={styles['video-progress-line']}
                                    style={{ width: percent }}
                                />
                                <div
                                    className={styles['video-progress-line-control']}
                                    onTouchMove={onProgressMove}
                                >
                                    <div className={styles.control} />
                                </div>
                            </div>
                            <div className={styles.totalTime}>{duration}</div>
                        </div>
                        <div
                            className={muted ? styles['voice-muted'] : styles.voice}
                            onClick={toggleMuted}
                        />
                        <div className={styles.fullscreen} onClick={fullScreen} />
                    </div>
                </div>
            )}
            {showReplay && (
                <div className={styles.replay}>
                    <div onClick={replay}>重播</div>
                </div>
            )}
            {children}
        </Div>
    );
};

export default Video;

/**
 * 时间由秒转化成 时 分 秒 格式
 */
const secondFormate = (seconds: number) => {
    const formateNumber = (number: number) => (number <= 9 ? `0${number}` : `${number}`);

    if (seconds > 60 * 60) {
        // 超过一个小时 xx:xx:xx
        const hour = Math.floor(seconds / 3600);
        const remain = seconds % 3600;
        const min = Math.floor(remain / 60);
        const s = remain % 60;
        return `${formateNumber(hour)}:${formateNumber(min)}:${formateNumber(s)}`;
    }
    // 小于一个小时 XX : xx
    const min = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${formateNumber(min)}:${formateNumber(s)}`;
};
