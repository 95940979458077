import { ListItem } from '../DragInteractionRecord';
import { generateItem } from './generateItem';

export function initList(limit: number, text: string) {
    const result: ListItem[] = [];
    for (let i = 0; i < limit; i++) {
        result.push(generateItem(result, text));
    }
    return result;
}
