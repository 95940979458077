import { getCallbackInfoCache } from '../adTrack/util/fetchCallbackByBridge';
import { getCallbackInfo } from '../adTrack/util/sendData';
import { FuWuHaoInfoProps } from '../api/interface';
import { getRadar } from '../weblog';
import { getUrlParam } from './getUrlParam';

/** 建站场景下的服务号归因参数透传 */
export const getFuWuHaoInfo = (): FuWuHaoInfoProps => {
    const [entityType, entityId, goodsId, sourceFrom, lpFromChannel, clueId] = getUrlParam([
        'sourceType',
        'sourceTypeId',
        'skuId',
        'sourceFrom',
        'lpFromChannel',
        'clueId',
    ]);

    const fuwuhaoInfo = {
        entityType,
        entityId,
        goodsId,
        sourceFrom,
        lpFromChannel,
        clueId,
    };

    const sendRadar = async () => {
        (await getRadar())?.event({
            name: 'fuwuhao_info', // 必填
            event_type: 'fuwuhao_info', // 【可选】
            category: 'traceLog', // 【可选】
            extra_info: JSON.stringify({
                callback: getCallbackInfo(),
                impressionId: getUrlParam('pageId'),
                url: window?.location?.href,
                fuwuhaoInfo,
            }),
        });
    };
    sendRadar();
    return fuwuhaoInfo;
};

export enum SourceType {
    PHOTO = 1,
    VIDEO = 2,
    P_PAGE = 3,
}

export interface CustomFuWuHaoInfo {
    imAccurateSource: string;
    sourceFrom: string;
    skuId: string;
    sourceType: string;
    fansTopAttributeParams?: string;
    photoId?: string;
    liveId?: string;
}

/** 咨询组件中获取服务号归因参数详情，参数key和表单、卡券、智能电话场景有些差异 */
export const getFuWuHaoInfoInCustom = () => {
    const [sourceType, sourceTypeId, skuId, sourceFrom] = getUrlParam([
        'sourceType',
        'sourceTypeId',
        'skuId',
        'sourceFrom',
    ]);
    const adInfoRes = getCallbackInfoCache();
    let result: CustomFuWuHaoInfo = {
        imAccurateSource: '1',
        sourceFrom,
        skuId,
        sourceType,
        fansTopAttributeParams: adInfoRes?.fansTopAttributeParams,
    };
    switch (Number(sourceType)) {
        case SourceType.PHOTO:
            result = {
                ...result,
                photoId: sourceTypeId,
            };
            break;
        case SourceType.VIDEO:
            result = {
                ...result,
                liveId: sourceTypeId,
            };
            break;
        default:
            break;
    }
    return result;
};
