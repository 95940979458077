import { fetchWechatServiceInfo, qywxFansCbMd5UsingPOST } from 'landingPage/common/api';
import { getPageId } from 'landingPage/common/utils/getPageId';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { wechatActionSence } from './components/commonFun';
import { DeeplinkAppType } from '../ButtonDrag/Button';
import { AnyObj, DIALOG_STYLE, WeChatType } from 'omega-render';
import { invoke } from '@yoda/bridge';
import { Toast } from '@ad/sharpui';
import { KEFU_JUMP_SUBTAGS, sendTraceLog, trackWXKFJump } from 'landingPage/common/adTrack/monitor';
import { PlanType, WechatActionSceneEnum, WeiXinOpt } from './WeiXin';
import { bridgeGetDeviceInfo } from '@/landingPage/common/bridge';
import { getUrlParam } from 'omega-shared/utils';
import { IResponseQywxCallback } from '@/landingPage/common/api/interface';
import { getMoliHost } from '@/landingPage/common/utils/getMoliDomain';
import { getComponentType } from '@/landingPage/common/adTrack/util/sendData';
import { SelectItem } from '../XiansuoFormMulti/components/IMComponent';
import { LaunchTypeEnum } from './components/JumpWxKefu';
import { enableSendEffectiveCustomerReport } from '@/landingPage/common/utils/firefly';
import { getTransCompList } from '@/landingPage/common/impression';

interface Md5Params {
    wechatType: WeChatType;
    id: string;
    renderType?: string;
    formId?: string;
    formInfo?: SelectItem[];
    launchType?: LaunchTypeEnum;
}

/**
 * 获取企业微信md5参数
 */
export const getQywxMd5Params = async (params: Md5Params) => {
    const { id, wechatType, renderType = 'WEI_XIN_DRAG', formId, formInfo, launchType } = params;
    let appVersion = '';
    try {
        const deviceInfo = await bridgeGetDeviceInfo();
        appVersion = deviceInfo?.data?.appVersion;
    } catch (e) {
        console.error(e);
    }
    try {
        const res = await qywxFansCbMd5UsingPOST({
            callback: getUrlParam('callback') || '',
            renderType,
            followPlanId: wechatType === WeChatType.KEFU ? id : undefined,
            appVersion,
            componentType: renderType,
            isMK: getUrlParam('downgradeSource') ? true : getUrlParam('isH5') ? false : '',
            customerAcqId:
                wechatType === WeChatType.ASSISTANT || wechatType === WeChatType.ASSISTANT_GROUP
                    ? id
                    : undefined,
            pageId: getPageId(),
            cityName: getUrlParam('cityName') || '',
            type:
                wechatType === WeChatType.ASSISTANT
                    ? 1
                    : wechatType === WeChatType.ASSISTANT_GROUP
                      ? 2
                      : 0,
            wechatType,
            formId,
            formInfo,
            qywxConversionChannelType: launchType,
        });
        return res.data;
    } catch (e) {
        console.log(e);
        trackWXKFJump(
            KEFU_JUMP_SUBTAGS.GET_MD5_FROM_API_ERROR,
            'WEI_XIN_DRAG',
            wechatType,
            e.msg || e.message || '获取失败',
        );
    }
};

interface JumpWxkfParams {
    /** json中的微信配置信息 */
    opt: WeiXinOpt;
    // md5Cb: string;
    dialogStyleType: DIALOG_STYLE;
    renderType?: string;
    /** 不上报EVENT_CLICK_WECHAT埋点 */
    noLog?: boolean;
    formInfo?: SelectItem[];
    formId?: string;
    launchType?: LaunchTypeEnum;
}

/**
 * 跳转微信客服
 * @param JumpWxkfParams
 * @returns
 * @description 跳转微信客服
 *
 */
export const jumpWxkf = async (params: JumpWxkfParams) => {
    const {
        opt,
        dialogStyleType,
        renderType = 'WEI_XIN_DRAG',
        noLog,
        formInfo,
        formId,
        launchType,
    } = params;
    let realMd5Cb: IResponseQywxCallback['data'] | undefined;
    try {
        realMd5Cb = await getQywxMd5Params({
            wechatType: WeChatType.KEFU,
            id: opt?.id as string,
            renderType,
            formInfo,
            formId,
            launchType,
        });
    } catch (e) {
        trackWXKFJump(
            KEFU_JUMP_SUBTAGS.GET_MD5_FROM_API_ERROR,
            renderType,
            WeChatType.KEFU as number,
            e.msg || e.message || '获取失败',
        );
        console.log(e);
    }
    console.log(`跳转地址：${opt.deepLink}&scene_param=${realMd5Cb?.md5Callback}`);
    if (!realMd5Cb?.md5Callback) {
        trackWXKFJump(KEFU_JUMP_SUBTAGS.JUMP_WITHOUT_MD5, renderType, WeChatType.KEFU);
    }
    let specialParam = '-1';
    let deepLink = opt.deepLink as string;
    /** 微信客服使用获客链接 */
    let useHKLink = false;
    try {
        const res = await fetchWechatServiceInfo({
            pageId: getPageId(),
            planId: opt?.id || '',
        });
        console.log('获取特殊参数', { res });
        res.specialParam && (specialParam = res.specialParam);
        res.deepLink && (deepLink = res.deepLink);
        if (res?.planType === PlanType.HK_LINK) {
            useHKLink = true;
        }
    } catch (e) {
        console.log('[获取特殊参数失败]', e);
        trackWXKFJump(
            KEFU_JUMP_SUBTAGS.GET_SPEC_PARAM_ERROR,
            renderType,
            WeChatType.KEFU,
            e.msg || e.message || '获取失败',
        );
    }
    // window.open(`${opt.deepLink}${opt.deepLink.indexOf('?') >= 0 ? '&' : '?'}scene_param=${md5Cb}`);
    openWx(
        `${deepLink}${deepLink.indexOf('?') >= 0 ? '&' : '?'}scene_param=${realMd5Cb?.md5Callback}`,
    );
    if (!noLog) {
        dataTrack({
            eventType: 'EVENT_CLICK_WECHAT',
            lp_page_type: 1,
            wechatActionScene: useHKLink
                ? WechatActionSceneEnum.KEFU_WITH_ASSISTANT
                : wechatActionSence(dialogStyleType),
            diyExtraInfo: JSON.stringify({
                renderType: renderType,
                followPlanId: dialogStyleType === DIALOG_STYLE.KEFU ? opt?.id : '',
                specialStatus: specialParam,
                state: realMd5Cb?.md5Callback,
            }),
        });
    }

    // try {
    //     wechatDataTrack({
    //         itemId: opt.itemId,
    //         invokeSuccess: false,
    //         sence: wechatActionSence(dialogStyleType),
    //         wechatId: opt.wechatId,
    //         appId: opt.appId,
    //         popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
    //     });
    // } catch (e) {
    //     console.log(e);
    // }

    try {
        const { installed } = await invoke('tool.checkAppInstalled', {
            android: 'com.tencent.mm',
            ios: 'weixin://',
        });
        console.log('[has install wetchat]', installed);
        if (installed) {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED',
                deeplink_app_type: DeeplinkAppType.WECHAT_SERVICE,
                wechatActionScene: useHKLink
                    ? WechatActionSceneEnum.KEFU_WITH_ASSISTANT
                    : wechatActionSence(dialogStyleType),
                diyExtraInfo: JSON.stringify({
                    renderType,
                    followPlanId: dialogStyleType === DIALOG_STYLE.KEFU ? opt?.id : '',
                    specialStatus: specialParam,
                    state: realMd5Cb?.md5Callback,
                    subLinkId: realMd5Cb?.subLinkId,
                    distributeType: realMd5Cb?.distributeType,
                    sellerId: realMd5Cb?.openUserId,
                }),
            });
            return true;
        } else {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                deeplink_app_type: DeeplinkAppType.WECHAT_SERVICE,
                wechatActionScene: useHKLink
                    ? WechatActionSceneEnum.KEFU_WITH_ASSISTANT
                    : wechatActionSence(dialogStyleType),
                diyExtraInfo: JSON.stringify({
                    renderType,
                    followPlanId: dialogStyleType === DIALOG_STYLE.KEFU ? opt?.id : '',
                    specialStatus: specialParam,
                    state: realMd5Cb?.md5Callback,
                    subLinkId: realMd5Cb?.subLinkId,
                    distributeType: realMd5Cb?.distributeType,
                    sellerId: realMd5Cb?.openUserId,
                }),
            });
            Toast.info('未安装微信，跳转失败');
            return false;
        }
    } catch (e) {
        console.log('[端外场景不打点]', e);
    }
    return true;
};

const transHKZSHttpsToSchema = (url: string) => {
    return `weixin://biz/ww/profile/${encodeURIComponent(url)}`;
};
/*
    获客助手
*/
export const jumpHkzs = async (data: {
    opt: AnyObj;
    dialogStyleType: DIALOG_STYLE;
    wechatType: WeChatType;
    renderType: string;
    useSchema: boolean;
    /** 不上报EVENT_CLICK_WECHAT埋点*/
    noLog?: boolean;
    md5Cb?: IResponseQywxCallback['data'];
    assertJump?: boolean;
    formId?: string;
    formInfo?: SelectItem[];
    launchType?: LaunchTypeEnum;
}) => {
    const {
        opt,
        dialogStyleType,
        renderType,
        useSchema,
        wechatType,
        noLog,
        md5Cb,
        assertJump = true,
        formId,
        formInfo,
        launchType,
    } = data;
    let realMd5Cb: IResponseQywxCallback['data'] | undefined = md5Cb;
    if (!realMd5Cb) {
        try {
            realMd5Cb = await getQywxMd5Params({
                wechatType,
                id: opt?.id as string,
                renderType,
                formId,
                formInfo,
                launchType,
            });
        } catch (e) {
            trackWXKFJump(
                KEFU_JUMP_SUBTAGS.GET_MD5_FROM_API_ERROR,
                renderType,
                wechatType as number,
                e.msg || e.message || '获取失败',
            );
            console.log(e);
        }
    }
    if (assertJump && realMd5Cb?.acqQrCodeJumpInfo?.posterUrl && renderType === 'WEI_XIN_DRAG') {
        if (!getUrlParam('pageId')) {
            Toast.info('长按企微类型无法预览，请发布后使用');
            return;
        }
        window.location.href = `${getMoliHost()}/qywx/page?pageId=${getUrlParam('pageId')}&callback=${getUrlParam('callback')}&pageConversionType=${getComponentType()}`;
        return;
    }

    const targetDeepLink = realMd5Cb?.deepLink || opt.deepLink;
    const targetLinkId = realMd5Cb?.linkId || opt.linkId;
    console.log(`跳转地址：${targetDeepLink}&customer_channel=${realMd5Cb?.md5Callback}`);
    // window.open(
    //     `${opt.deepLink}${opt.deepLink.indexOf('?') >= 0 ? '&' : '?'}customer_channel=${md5Cb}`,
    // );
    if (!realMd5Cb?.md5Callback) {
        trackWXKFJump(KEFU_JUMP_SUBTAGS.JUMP_WITHOUT_MD5, renderType, WeChatType.ASSISTANT);
    }
    const url = `${targetDeepLink}${
        targetDeepLink.indexOf('?') >= 0 ? '&' : '?'
    }customer_channel=${realMd5Cb?.md5Callback}`;
    console.log(1111, useSchema ? transHKZSHttpsToSchema(url) : url);
    openWx(useSchema ? transHKZSHttpsToSchema(url) : url);
    if (!noLog) {
        dataTrack({
            eventType: 'EVENT_CLICK_WECHAT',
            lp_page_type: 1,
            wechatActionScene: wechatActionSence(dialogStyleType),
            diyExtraInfo: JSON.stringify({
                renderType: renderType,
                link_id:
                    dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT ||
                    dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP
                        ? targetLinkId
                        : '',
                linkGroupId:
                    dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP ? opt?.id : '',
                state: realMd5Cb?.md5Callback,
            }),
        });
    }
    try {
        const { installed } = await invoke('tool.checkAppInstalled', {
            android: 'com.tencent.mm',
            ios: 'weixin://',
        });
        console.log('[has install wetchat]', installed);
        if (installed) {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED',
                deeplink_app_type:
                    dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT
                        ? DeeplinkAppType.ACQUISITION_ASSISTANT
                        : DeeplinkAppType.ACQUISITION_ASSISTANT_GROUP,
                wechatActionScene: wechatActionSence(dialogStyleType),
                diyExtraInfo: JSON.stringify({
                    renderType: renderType,
                    link_id:
                        dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT ||
                        dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP
                            ? targetLinkId
                            : '',
                    linkGroupId:
                        dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP ? opt?.id : '',
                    state: realMd5Cb?.md5Callback,
                    subLinkId: realMd5Cb?.subLinkId,
                    distributeType: realMd5Cb?.distributeType,
                    sellerId: realMd5Cb?.openUserId,
                }),
            });
            return true;
        } else {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                deeplink_app_type:
                    dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT
                        ? DeeplinkAppType.ACQUISITION_ASSISTANT
                        : DeeplinkAppType.ACQUISITION_ASSISTANT_GROUP,
                wechatActionScene: wechatActionSence(dialogStyleType),
                diyExtraInfo: JSON.stringify({
                    renderType: renderType,
                    link_id:
                        dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT ||
                        dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP
                            ? targetLinkId
                            : '',
                    linkGroupId:
                        dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP ? opt?.id : '',
                    state: realMd5Cb?.md5Callback,
                    subLinkId: realMd5Cb?.subLinkId,
                    distributeType: realMd5Cb?.distributeType,
                    sellerId: realMd5Cb?.openUserId,
                }),
            });
            Toast.info('未安装微信，跳转失败');
            return false;
        }
    } catch (e) {
        console.log('[端外场景不打点]', e);
        sendTraceLog({
            tags: {
                pageUrl: window.location.href,
                errs: e.toString(),
                ua: navigator?.userAgent,
                specTag: 'weixin_invoke_miss',
            },
        });
        return false;
    }
};

const openWx = (url: string) => {
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    window.setTimeout(() => {
        document.body.removeChild(iframe);
    }, 1000);
};

/** 上报表单提交事件&有效获客事件
 * @params wechatItemId 个微id
 * @params wechatActionStatus 个微的弹窗场景
 * @params renderType 组件渲染类型
 * @params enableSendEffectiveAction 是否需要上报有效获客事件
 */
export const sendFormSubmitAndEffectiveCustomerReport = async (params: {
    wechatItemId?: string;
    renderType?: string;
    enableSend?: boolean;
    wechatActionStatus: WechatActionSceneEnum;
}) => {
    const { renderType, enableSend, wechatItemId, wechatActionStatus } = params;
    dataTrack({
        eventType: 'EVENT_FORM_SUBMIT',
        wechatItemId: wechatItemId,
        lp_page_type: 1,
        WechatActionStatus: wechatActionStatus,
        eventProps: {
            actionName: 'EXTERNAL_ACTION904',
        },
        diyExtraInfo: JSON.stringify({
            renderType: renderType || 'WEI_XIN_DRAG',
            landing_page_infos: JSON.stringify({
                page_module_type: getTransCompList(),
            }),
        }),
    });
    if (enableSend) {
        try {
            //有效获客上报事件firefly开关（功能性）
            const res = await enableSendEffectiveCustomerReport();
            if (res) {
                dataTrack({
                    eventType: 'EVENT_EFFECTIVE_CUSTOMER_ACQUISITION',
                    lp_page_type: 1,
                    eventProps: {
                        actionName: 'EVENT_EFFECTIVE_CUSTOMER_ACQUISITION_CATEGORY17',
                    },
                    diyExtraInfo: JSON.stringify({
                        renderType: renderType || 'WEI_XIN_DRAG',
                        landing_page_infos: JSON.stringify({
                            page_module_type: getTransCompList(),
                        }),
                    }),
                });
            }
        } catch (e) {
            console.log('firefly 获取错误 or 接口上报错误，这两种场景都不上报有效获客', e);
        }
    }
};
