import { createModel } from 'rt-model';

/** 更新原信息，key为组件id */
interface UpdateData {
    [componentId: string]: any;
}
/** 落地页异步更新信息model */
export const updateJSONModel = createModel({
    namespace: 'updateJson',
    defaultState: {} as UpdateData,
    reducers: {},
});
