/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import styles from './style.module.less';

export interface ProgressProps {
    len: number;
    currentIndex: number;
    moved: number;
    transitionTime: string;
}

export const Progress: React.FunctionComponent<ProgressProps> = (props) => {
    const { len, transitionTime, currentIndex: currentIndexProps, moved } = props;
    const arr = React.useMemo(() => {
        return new Array(len).fill(1);
    }, [len]);
    const currentIndex = React.useMemo(() => {
        let index = currentIndexProps;
        if (len === 1) {
            return 0;
        }
        if (index < 0) {
            index = (index % len) + len;
        }
        // 这里currentIndex获取的是当前的index。
        if (moved === 0) {
            return index;
        }
        if (moved > 0) {
            index = index + 1;
        }
        if (moved < 0) {
            if (index > 0) {
                index = index - 1;
            } else {
                index = len - 1;
            }
        }

        return index % len;
    }, [len, currentIndexProps, moved]);

    return (
        <div className={styles.progress} style={{ transition: `${transitionTime} width` }}>
            {arr.map((_, index) => {
                return (
                    <Item
                        key={index}
                        index={index}
                        transitionTime={transitionTime}
                        currentIndex={currentIndex % len}
                    />
                );
            })}
        </div>
    );
};
const Item: React.FC<{ index: number; currentIndex: number; transitionTime: string }> = (props) => {
    const { index, currentIndex, transitionTime } = props;

    return (
        <div
            className={styles.item}
            style={{
                width: index === currentIndex ? 24 : 12,
                transition: `${transitionTime} width`,
            }}
        />
    );
};
