import React, { useEffect, useState } from 'react';

import ReactDOM from 'react-dom';
import cs from 'classnames';
import styles from './h5Map.module.less';
import { initMap, initMapPackage } from './util';

interface Iprops {
    show: boolean;
    position: {
        longitude: number;
        latitude: number;
    };
    title: string;
    onClose: () => void;
}

export default function H5Map(props: Iprops) {
    // React.useEffect(() => {
    //   if (props.show) {
    //     window.history.pushState(null, '魔力地图', '/h5Map');
    //   }
    // }, [props.show])
    const [el] = useState(document.createElement('div'));
    const container = React.useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (props.show) {
            document.body.appendChild(el);
            initMapPackage(() => {
                initMap(
                    container?.current as HTMLDivElement,
                    props?.position?.latitude,
                    props?.position?.longitude,
                );
            });
        }
        // eslint-disable-next-line
    }, [props.show]);

    return ReactDOM.createPortal(
        <div className={cs({ [styles.hidden]: !props.show })}>
            <div
                className={styles.header}
                onClick={(e) => {
                    e.stopPropagation();

                    props.onClose();
                }}
            >
                <div
                    className={styles.backIcon}
                    onClick={(e) => {
                        e.stopPropagation();

                        props.onClose();
                    }}
                />
                <div className={styles.title}>{props.title}</div>
            </div>
            <div ref={container} className={styles.container} />
        </div>,
        el,
    );
}
