/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback } from 'react';
import { ComponentJson, componentsForEach, ComponentStore, useRenderState } from 'omega-render';
import { updateJSONModel } from '../model/updateModel';

const targetComponent = ['XIANSUO_FORM_DRAG', 'XIANSUO_FORM_PAY'];

export enum TransFromType {
    ADD,
}

/** 版本升级逻辑（异步的）
 *【个人用户信息协议实验】,
 * PRD：https://docs.corp.kuaishou.com/d/home/fcAC0TS1o1uTCqMkdb9AfSgas
 * 当用户协议为实验样式的时候，因为form组件内部多了用户协议，导致form组件高度增加，需要同步增加父级blank的高度，防止遮挡
 * 2022-01-17 全量表单实验样式2
 */
export default function asyncUpdateFormComponent(
    components: ComponentJson[],
    store: ComponentStore,
) {
    componentsForEach(components, (component) => {
        if (targetComponent.includes(component.type)) {
            const parentBlank = components.find((c) => {
                return c.components?.some((child) => child.id === component.id);
            });
            if (!parentBlank) return;
            /** 新编辑的表单样式不需要兼容，flog */
            if (component?.props?.newUserAgreement) return;
            store.dispatch(
                updateJSONModel.actions.setModelState({
                    [parentBlank.id]: {
                        type: TransFromType.ADD,
                        value: 20,
                    },
                }),
            );
        }
    });
}

/** 提供给需要异步更新样式的组件使用
 * @param
 *  id: 组件id
 * @returns
 *  updateFn: 更新组件样式的方法
 */
export function useUpdateForm(id: string) {
    const updateJsonInfo = useRenderState((state) => {
        const updateJsonInfo = state.updateJSONInfo?.[id];
        return updateJsonInfo;
    });
    const updateFn = useCallback(
        (componentStyle) => {
            if (updateJsonInfo) {
                if (
                    updateJsonInfo?.type === TransFromType.ADD &&
                    updateJsonInfo.value &&
                    componentStyle?.height
                ) {
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    componentStyle.height = componentStyle.height + updateJsonInfo.value;
                }
            }
        },
        [updateJsonInfo],
    );
    return updateFn;
}
