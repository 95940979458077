import { getCallbackInfo } from '@/landingPage/common/adTrack/util/sendData';
import { DEVICE_TYPE, getDeviceType } from '@/landingPage/common/utils/getDeviceInfo';
import { getRadar } from '@/landingPage/common/weblog';
import { getConsultPageUrl } from '@/landingPage/services/apis';
import { useSafeAreaHeight } from 'landingPage/common/utils/getSafeArea';
import { getUrlParam, isBeta, isInApp, isPrt, isStaging } from 'omega-shared/utils';
import { useEffect, MutableRefObject } from 'react';

/**
 * 用于在页面底部添加安全区域的自定义Hook。临时hack的底部安全区逻辑，等通用方案之后删除
 * @param instance 一个可变的引用，指向要添加安全区域的元素。
 */
export function useSafeArea(instance: MutableRefObject<HTMLElement | null | undefined>) {
    const safeAreaHeight = useSafeAreaHeight();
    useEffect(() => {
        if (instance.current && safeAreaHeight) {
            // 找到最近的BLANK元素，并将其底部填充设置为安全区域高度的一半。
            const parent = instance.current.closest('[data-component-type="BLANK"]') as HTMLElement;
            if (parent?.style) {
                parent.style.paddingBottom = `${safeAreaHeight / 2}px`;
                parent.style.backgroundColor = 'white';
            }
            // 找到占位符元素，并将其高度增加安全区域高度的一半。
            const blockHolder = document.querySelector('.block-placeholder') as HTMLElement;
            if (blockHolder) {
                const currentHeight = parseInt(getComputedStyle(blockHolder).height, 10);
                blockHolder.style.height = `${currentHeight + safeAreaHeight / 2}px`;
            }
        }
    }, [instance, safeAreaHeight]);
}

/**
 * 获取三方客服咨询页链接
 * @param funcId 咨询页id
 * 当开关switchStatus=true时，根据当前端内/端外状态从deepLink/h5Url 获取咨询页链接
 * 当开关switchStatus=false时，从consultPageUrl获取咨询页链接
 * @returns {
 * switchStatus: 当前开关状态
 * consultPageUrl: 咨询页链接
 * isH5Url: 是否是h5链接
 * }
 */
export const fetchConsultPageUrl = async (funcId: string) => {
    const res = await getConsultPageUrl({
        consulPageId: funcId,
    });
    let consultPageUrl;
    if (res?.data?.switchStatus) {
        consultPageUrl = new URL((isInApp ? res?.data?.deepLink : res?.data?.h5Url) || '');
    } else if (res?.data?.consultPageUrl) {
        consultPageUrl = new URL(res.data.consultPageUrl);
    }
    if (!consultPageUrl.searchParams.get('cprId')) {
        consultPageUrl.searchParams.append('cprId', funcId);
    }
    return {
        switchStatus: res?.data?.switchStatus || false,
        consultPageUrl: consultPageUrl?.href,
        isH5Url: res?.data?.isH5Url || false,
    };
};

export const isIos = getDeviceType() === DEVICE_TYPE.IOS;

const IM_API = {
    test: 'https://ad-im.test.gifshow.com',
    production: 'https://adim.kuaishou.com',
    developer: '', // 走mock
};
export const getImApiUrl = () => {
    let IM_API_URL = '';
    if (process.env.NODE_ENV === 'production') {
        IM_API_URL = IM_API.production;
    }
    if (process.env.NODE_ENV === 'development') {
        IM_API_URL = IM_API.developer;
    }
    if (process.env.REACT_APP_STAGE) {
        IM_API_URL = IM_API.test;
    }
    if (isBeta || isPrt) {
        IM_API_URL = 'https://adim-beta.corp.kuaishou.com';
    }
    if (isStaging) {
        IM_API_URL = 'https://adim.staging.kuaishou.com';
    }

    if (isIos) {
        IM_API_URL = '';
    }
    return IM_API_URL;
};

export const sendConsultInfoTrack = async (url: string, csType: number | undefined) => {
    (await getRadar())?.event({
        name: 'customer_url', // 必填
        event_type: 'customer_url', // 【可选】
        category: 'traceLog', // 【可选】
        extra_info: JSON.stringify({
            callback: getCallbackInfo(),
            impressionId: getUrlParam('pageId'),
            customerUrl: url,
            csType,
        }),
    });
};
