import Modal from 'landingPage/common/components/Modal';
import { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './style.module.less';

interface IProps {
    isShowModal?: boolean;
    modalImg?: string;
    seconds?: number;
    edit?: boolean;
}
export const PageModal = (pageModal, edit) => {
    let divElememt = document.createElement('div');
    document.body.appendChild(divElememt);
    return ReactDOM.render(
        // eslint-disable-next-line react/jsx-props-no-spreading
        <PageImageModal {...pageModal} edit={edit} />,
        divElememt,
    );
};

export const PageImageModal: FC<IProps> = (props) => {
    const { modalImg, seconds, isShowModal, edit } = props;
    const [showModal, setShowModal] = useState(true);
    if (!edit && seconds && seconds > 0) {
        setTimeout(() => {
            setShowModal(false);
        }, seconds * 1000);
    }

    return (
        <>
            {showModal && isShowModal && (
                <Modal
                    style={{
                        zIndex: 999999,
                        // pointerEvents: edit ? 'none' : 'auto',
                    }}
                    parent={
                        edit ? document.getElementById('preview-app') ?? document.body : undefined
                    }
                    onClose={() => {
                        setShowModal(false);
                    }}
                >
                    <div className={styles.modelContainer}>
                        <div
                            className={styles.imageContainer}
                            style={{ height: edit ? '500px' : 'calc(100vw * 4 / 3)' }}
                        >
                            {modalImg && <img width="100%" height="100%" src={modalImg} />}
                        </div>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <div className={styles.closeContainer}>
                            <div
                                className={styles.closeButton}
                                onClick={() => {
                                    setShowModal(false);
                                }}
                            ></div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
