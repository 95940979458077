import * as React from 'react';
import { ClueForm, IClueForm } from '@ad/xiansuoSDK';
import { DIALOG_STYLE } from 'omega-render';
import { SubmitTurnValue, WeiXinModal } from '../XiansuoFormDrag/XiaoSuoModal';
import { SubmitTurnConfig } from '../XiansuoFormDrag/transformFormSetting';
import DragText from '../DragText';
import { AppointmentModal } from '../XiansuoFormDrag/AppointmentModal';
import { useTheme } from '@/landingPage/common/utils/useTheme';

interface FormPayProps {
    placeholderColor?: string;
    formClass: string;
    reRender: any;
    config: IClueForm | null;
    richText?: any;
    formStyle?: any;
}
export const BaseForm: React.FunctionComponent<FormPayProps> = (props) => {
    const { reRender, config, richText, formStyle = {} } = props;
    // 标题部分与表单部分背景割裂，此方式可以保持一致（图片纯色背景都兼容）
    // React.useEffect(() => {
    //     if (config?.domConfs?.formTheme?.formStyle) {
    //         config.domConfs.formTheme.formStyle = {
    //             ...config.domConfs.formTheme.formStyle,
    //             background: 'transparent',
    //             backgroundImage: '',
    //         };
    //     }
    // }, [config]);
    const { color: formBackgroundColor } = useTheme({
        path: 'formStyle.backgroundColor',
        options: { type: 'activeFill' },
    });
    return (
        <>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {!reRender && config && (
                <div style={{ ...formStyle, backgroundColor: formBackgroundColor }}>
                    {/* 富文本标题渲染 */}
                    {!!richText?.[0].children?.[0]?.text &&
                        config?.domConfs?.formTheme?.mainTitleStyle?.display === 'none' && (
                            <DragText
                                style={{
                                    margin: '0px 0 -22px',
                                    textAlign: 'center',
                                    zIndex: 1,
                                    paddingTop: '24px',
                                }}
                                type="rich"
                                singleText={config?.data?.formJson?.mainTitle || ''}
                                richText={richText}
                                optionalConfigTexts={[]}
                                activeIndex={0}
                            />
                        )}
                    <ClueForm {...config} />
                </div>
            )}
        </>
    );
};

export interface extraFormProps {
    children: React.ReactNode;
    edit?: boolean;
    preview?: boolean;
    show: boolean;
    modalSetting?: SubmitTurnConfig;
    onClose: () => void;
    showAppointment?: boolean;
    isAppointmentUser?: boolean;
    onCloseAppointment?: () => void;
}

export const ExtraForm: React.FunctionComponent<extraFormProps> = (props) => {
    const {
        children,
        edit,
        preview,
        show,
        modalSetting,
        onClose,
        showAppointment,
        isAppointmentUser,
        onCloseAppointment,
    } = props;
    return (
        <>
            {children}
            <WeiXinModal
                showAppointment={showAppointment}
                isEdit={!!edit || preview}
                show={show}
                onClose={onClose}
                afterSubmitText={modalSetting?.afterSubmitText}
                afterSuccessDisplayType={modalSetting?.afterSuccessDisplayType}
                wechatType={modalSetting?.weChatOpt?.wechatType}
                applets={modalSetting?.weChatOpt?.applets}
                appletSwitchState={
                    modalSetting?.weChatOpt?.dialogStyleType === DIALOG_STYLE.JUMPWX
                        ? {
                              checked: true,
                              disabled: true,
                          }
                        : modalSetting?.weChatOpt?.appletSwitchState
                }
                opt={modalSetting?.weChatOpt?.opt}
                info={modalSetting?.weChatOpt?.info}
                dialogStyle={modalSetting?.weChatOpt?.dialogStyle}
                dialogStyleType={modalSetting?.weChatOpt?.dialogStyleType}
                submitTurnValue={modalSetting?.submitTurnValue as SubmitTurnValue}
                switch={modalSetting?.switch}
                jumpSwitch={modalSetting?.jumpSwitch}
            />
            <AppointmentModal
                isEdit={!!edit || preview}
                show={showAppointment}
                modalSetting={modalSetting}
                isAppointmentUser={isAppointmentUser}
                onCloseAppointment={onCloseAppointment}
            />
        </>
    );
};
