import styles from './index.module.less';
import { CommentInfoListProps } from './interactionComment';
import { useState, useCallback, useEffect, useRef } from 'react';

interface RowStyleListProps {
    commentInfoList: CommentInfoListProps[];
    animationTimer: number;
}

const ANIMATION_STEP = 60;

let timer;

export const RowStyleList = (props: RowStyleListProps) => {
    const { commentInfoList, animationTimer = 1 } = props;

    const [listLines, setListLines] = useState<CommentInfoListProps[][]>([]);
    const lineRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
    const [leftPosList, setLeftPosList] = useState([375, 400]);

    const step = 375 / (animationTimer * ANIMATION_STEP);

    useEffect(() => {
        const sliceIndex = Math.floor(commentInfoList?.length / 2);
        let lineOne = [
            ...commentInfoList.slice(0, sliceIndex),
            ...commentInfoList.slice(0, sliceIndex),
        ];
        let lineTwo = [...commentInfoList.slice(sliceIndex), ...commentInfoList.slice(sliceIndex)];
        setListLines([[...lineOne], [...lineTwo]]);
    }, [commentInfoList]);

    const loopList = useCallback(
        (index) => {
            setLeftPosList((prev) => {
                const newLeftPosList = [...prev];
                const ref = lineRefs[index];
                const width = ref?.current?.getBoundingClientRect()?.width || 0;
                if (Math.abs(leftPosList[index]) >= width / 2) {
                    const splitIndex = listLines[index].length / 2;
                    let newList = listLines[index].slice(splitIndex);
                    newList = newList.concat([...listLines[index].slice(0, splitIndex)]);
                    const newListLines = [...listLines];
                    newListLines[index] = newList;
                    setListLines([...newListLines]);
                    newLeftPosList[index] = 0;
                } else {
                    newLeftPosList[index] = newLeftPosList[index] - step;
                }

                return newLeftPosList;
            });
        },
        [listLines, leftPosList, lineRefs],
    );

    useEffect(() => {
        timer = window.setInterval(() => {
            loopList(0);
            loopList(1);
        }, 1000 / ANIMATION_STEP);
        return () => {
            clearInterval(timer);
        };
    }, [loopList]);

    return (
        <div className={styles.rowStyle}>
            {listLines?.map((list, index) => {
                return (
                    <div
                        className={styles.rowChild}
                        ref={lineRefs[index]}
                        style={{ top: index * 30, left: leftPosList[index] }}
                        key={`line-${index}`}
                    >
                        {list?.map((item, index) => {
                            return <ListItem item={item} key={index} />;
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export const ListItem = ({ item }) => {
    return (
        <div
            className={styles.child}
            style={{
                backgroundColor: item?.bkgColor,
                marginRight: 8,
            }}
        >
            {item.icon && <img src={item.icon} className={styles.icon} />}
            <span>{item.text}</span>
        </div>
    );
};
