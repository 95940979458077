import "../chunk-5JCHA7OU.js";
import "../chunk-CLJEFBYP.js";

// middlePageTrans/index.ts
async function midPageTrans(pageJson, material, isDynamic, pageType) {
  await trans(pageJson, material, isDynamic, pageType);
  return pageJson;
}
var LINK_TYPE = /* @__PURE__ */ ((LINK_TYPE2) => {
  LINK_TYPE2[LINK_TYPE2["QUICK_LINK"] = 2] = "QUICK_LINK";
  LINK_TYPE2[LINK_TYPE2["ANDROID"] = 3] = "ANDROID";
  LINK_TYPE2[LINK_TYPE2["IOS"] = 4] = "IOS";
  LINK_TYPE2[LINK_TYPE2["HARMONY"] = 5] = "HARMONY";
  return LINK_TYPE2;
})(LINK_TYPE || {});
var keysNeedHandle = [
  "appHeadImgUrl",
  "appLabel",
  "appDescription",
  "appDetailImage",
  "appType",
  "appVideoUrl",
  "appBigImage"
];
var transDownload = (com, updatedRecommendElements) => {
  var _a, _b, _c, _d, _e;
  const appInfo = {
    accountId: 0,
    appVersion: updatedRecommendElements.appVersion,
    appName: updatedRecommendElements.appName,
    packageName: updatedRecommendElements.appPakageName,
    packageSize: revertAppSize((_a = updatedRecommendElements.packageSize) != null ? _a : ""),
    url: updatedRecommendElements.appUrl,
    appIconUrl: updatedRecommendElements.appIconUrl,
    h5App: false,
    platform: ((_b = com.props) == null ? void 0 : _b.linkType) === 4 /* IOS */ ? "ios" : "android",
    virusScore: 0,
    updateTime: new Date((_c = updatedRecommendElements.appUpdateTime) != null ? _c : "").getTime(),
    date: 16315488e5,
    appScore: 0,
    useSdk: false,
    downloadCount: 0,
    check: true,
    privacyUrl: updatedRecommendElements.appPrivacyUrl,
    scanStatus: 2,
    permissionList: updatedRecommendElements.appPermissionUrl,
    developerName: updatedRecommendElements.appDeveloper,
    functionIntroduction: updatedRecommendElements.functionIntroduction
  };
  if (com.props) {
    com.props.appInfo = appInfo;
    com.props.linkType = ((_d = com.props) == null ? void 0 : _d.linkType) || 3 /* ANDROID */;
    com.props.appName = updatedRecommendElements.appName;
    com.props.appDesc = updatedRecommendElements.appDescription;
    com.props.iOSLink = ((_e = com.props) == null ? void 0 : _e.linkType) === 4 /* IOS */ ? updatedRecommendElements.appUrl : "";
  }
};
var transPEDownload = (com, data, isDynamic, pageType) => {
  var _a;
  if (typeof data.appIconUrl === "string") {
    com.props.appIconUrl = data.appIconUrl;
  } else {
    com.props.appIconUrl = (_a = data.appIconUrl) == null ? void 0 : _a.imgUrl;
  }
  com.props = formatColorStyle({
    style: com.props,
    ColorMapList: data.mainColor,
    pageType,
    isDynamic
  });
  transDownload(com, data);
  if (isDynamic) {
    if (data.h5PageInspireCardText) {
      com.props.h5PageInspireCardText = data.h5PageInspireCardText;
    } else {
      delete com.props.h5PageInspireCardText;
    }
  }
};
var trans = async (components, recommendElements, isDynamic, pageType) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
  if (!components)
    return;
  const updatedRecommendElements = { ...recommendElements };
  const ColorMapList = pageType === 7 /* 大白中间页 */ ? updatedRecommendElements.phoneModeColor : updatedRecommendElements.mainColor;
  for (const com of components) {
    switch (com.type) {
      case "DRAG_TEXT" /* DRAG_TEXT */: {
        const selectKey = (_a = com.props) == null ? void 0 : _a.text;
        const linkKey = (_b = com.props) == null ? void 0 : _b.link;
        if (isDynamic || keysNeedHandle.includes(selectKey)) {
          const selectValue = updatedRecommendElements[selectKey];
          if (typeof selectValue === "string") {
            com.props && (com.props.text = selectValue);
          } else if (typeof selectValue === "object") {
            const replaceValue = selectValue[0];
            com.props && (com.props.text = replaceValue);
            updatedRecommendElements[selectKey] = selectValue.slice(1);
          }
          if (linkKey) {
            com.props && (com.props.link = updatedRecommendElements[linkKey]);
          }
        }
        break;
      }
      case "DRAG_IMAGE" /* DRAG_IMAGE */: {
        if (((_c = com.props) == null ? void 0 : _c.type) === "multi") {
          com.props && (com.props.mutiInfo = (_e = (_d = com.props) == null ? void 0 : _d.mutiInfo) == null ? void 0 : _e.map((item) => {
            const selectKey = item.imgUrl;
            const selectValue = updatedRecommendElements[selectKey];
            if (typeof selectValue === "string") {
              item.imgUrl = selectValue;
            } else if (typeof selectValue === "object") {
              const replaceValue = selectValue[0];
              item.imgUrl = replaceValue == null ? void 0 : replaceValue.imgUrl;
              item.pictureId = replaceValue == null ? void 0 : replaceValue.pictureId;
              updatedRecommendElements[selectKey] = selectValue == null ? void 0 : selectValue.slice(1);
            }
            return item;
          }));
        } else {
          const selectKey = (_f = com.props) == null ? void 0 : _f.imgUrl;
          if (isDynamic || keysNeedHandle.includes(selectKey)) {
            const selectValue = updatedRecommendElements[selectKey];
            if (typeof selectValue === "string") {
              com.props && (com.props.imgUrl = selectValue);
            } else if (typeof selectValue === "object") {
              let replaceValue = selectValue;
              if (Array.isArray(selectValue)) {
                replaceValue = selectValue[0];
                updatedRecommendElements[selectKey] = selectValue.slice(1);
              }
              com.props && (com.props.imgUrl = (replaceValue == null ? void 0 : replaceValue.imgUrl) || replaceValue);
              com.props && (com.props.pictureId = (replaceValue == null ? void 0 : replaceValue.pictureId) || replaceValue);
            }
          }
        }
        if (pageType === 6 /* 大图中间页 */ && !isDynamic) {
          com.magic && com.magic.dragFree && (com.magic.dragFree.height = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appBigImageHeight) || 0);
        }
        break;
      }
      case "VIDEO_DRAG" /* VIDEO_DRAG */: {
        const selectKey = (_g = com.props) == null ? void 0 : _g.videoUrl;
        const selectValue = updatedRecommendElements[selectKey];
        com.props && (com.props.videoUrl = selectValue == null ? void 0 : selectValue.videoUrl);
        com.props && (com.props.pictureId = selectValue == null ? void 0 : selectValue.pictureId);
        break;
      }
      case "PERMISSION_INFO" /* PermissionInfo */: {
        const selectValue = updatedRecommendElements.appPermissionUrl;
        com.props && (com.props.permissions = selectValue);
        break;
      }
      case "APP_DOWNLOAD_DRAG" /* APP_DOWNLOAD_DRAG */: {
        transDownload(com, updatedRecommendElements);
        break;
      }
      case "PEC_DOWNLOAD_FOR_MIDPAGE" /* PEC_DOWNLOAD_FOR_MIDPAGE */: {
        transPEDownload(com, updatedRecommendElements, isDynamic, pageType);
        break;
      }
      case "CORPORATE_INFO" /* CORPORATE_INFO */: {
        const corporationName = updatedRecommendElements.corporationName;
        const accountId = updatedRecommendElements.accountId;
        if (com.props && com.props.midPageAccountInfo) {
          com.props.midPageAccountInfo.corporationName = corporationName;
          com.props.midPageAccountInfo.accountId = accountId;
        }
        const selectValue = updatedRecommendElements.compName;
        com.props && (com.props.companyName = selectValue != null ? selectValue : "");
        break;
      }
      case "FUNCTION_INTRODUCTION" /* FUNCTION_INTRODUCTION */: {
        com.props && (com.props.desc = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.functionIntroduction) || "");
        break;
      }
      case "PRIVACY_FOR_MIDPAGE" /* PrivacyForMidpage */: {
        com.props && (com.props.appPrivacyUrl = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appPrivacyUrl) || "");
        com.props && (com.props.permissions = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appPermissionUrl) || []);
        com.props && (com.props.functionIntroduction = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.functionIntroduction) || "");
        break;
      }
      case "BLANK" /* BLANK */: {
        if (pageType === 6 /* 大图中间页 */ && ((_h = com == null ? void 0 : com.components) == null ? void 0 : _h.length) && ((_i = com.components[0]) == null ? void 0 : _i.type) === "DRAG_IMAGE" /* DRAG_IMAGE */ && !isDynamic) {
          com.magic && (com.magic.editHeight = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appBigImageHeight) || 0);
          com.props && com.props.style && (com.props.style.height = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appBigImageHeight) || 0);
        }
        break;
      }
      case "COLLAPSE_TEXT" /* COLLAPSE_TEXT */: {
        if (!isDynamic) {
          const selectKey = (_j = com.props) == null ? void 0 : _j.text;
          const selectValue = updatedRecommendElements[selectKey];
          com.props && (com.props.text = selectValue);
        }
        break;
      }
      default:
        break;
    }
    if ((_k = com.props) == null ? void 0 : _k.style) {
      const style = formatColorStyle({
        style: com.props.style,
        ColorMapList,
        pageType,
        isDynamic
      });
      com.props.style = style;
    }
    if ((_l = com.props) == null ? void 0 : _l.buttonStyle) {
      const style = formatColorStyle({
        style: com.props.buttonStyle,
        ColorMapList,
        pageType,
        isDynamic
      });
      com.props.buttonStyle = style;
    }
    if (((_m = com.props) == null ? void 0 : _m.color) || ((_n = com.props) == null ? void 0 : _n.textColor) || ((_o = com.props) == null ? void 0 : _o.btnTextColor) || ((_p = com.props) == null ? void 0 : _p.btnColor)) {
      const style = formatColorStyle({
        style: com.props,
        ColorMapList,
        pageType,
        isDynamic
      });
      com.props = style;
    }
    if (com.components && com.components.length > 0) {
      await trans(com.components, updatedRecommendElements, isDynamic, pageType);
    }
  }
};
var formatColorStyle = (params) => {
  var _a, _b, _c, _d;
  const { style, ColorMapList, pageType, isDynamic } = params;
  const formatStyle = { ...style };
  if (pageType === 7 /* 大白中间页 */ && isDynamic || pageType !== 7 /* 大白中间页 */) {
    for (const key in formatStyle) {
      if (String(formatStyle[key]).includes("mainColor")) {
        const number = (_b = (_a = formatStyle[key].match(/mainColor.[0-9]+./g)) == null ? void 0 : _a[0].match(/[0-9]+/g)) == null ? void 0 : _b[0];
        const color = (_c = ColorMapList == null ? void 0 : ColorMapList[Number(number)]) != null ? _c : "#ffffff";
        const realColor = color.startsWith("#") ? `${hexToRgb((_d = ColorMapList == null ? void 0 : ColorMapList[Number(number)]) != null ? _d : "#ffffff")}` : color;
        formatStyle[key] = formatStyle[key].replace(/mainColor.[0-9]+./g, realColor);
      }
    }
  }
  return formatStyle;
};
var hexToRgb = (hex) => {
  return `${parseInt(`0x${hex.slice(1, 3)}`)},${parseInt(`0x${hex.slice(3, 5)}`)},${parseInt(
    `0x${hex.slice(5, 7)}`
  )}`;
};
var revertAppSize = (size) => {
  if ((size == null ? void 0 : size.indexOf("MB")) >= 0) {
    return Number(size.replace("MB", "")) * 1024 * 1024;
  }
  return Number(size.replace("GB", "")) * 1024 * 1024 * 1024;
};
var getImgDetail = async (imgUrl) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const { width, height } = img;
      resolve({ width, height });
    };
    img.src = imgUrl;
  });
};
export {
  LINK_TYPE,
  getImgDetail,
  hexToRgb,
  midPageTrans
};
