function ReturnIcon(props) {
    const { fontSize, color } = props;
    return (
        <svg
            fill={color}
            height={fontSize}
            width={fontSize}
            viewBox="32 32 960 960"
            focusable="false"
        >
            <path d="M274.47 512a42.48 42.48 0 0112.5-30.17l321.5-321.49a42.62 42.62 0 1160.33 60.33L377.47 512 668.8 803.33a42.62 42.62 0 11-60.33 60.33l-321.5-321.5a42.5 42.5 0 01-12.5-30.16" />
        </svg>
    );
}
export default ReturnIcon;
