import * as React from 'react';
import DragInteractionRecordInstance from './DragInteractionRecordInstance';

export interface DragInteractionRecordProps {
    text: string;
    renderType: 1 | 2;
}

export interface ListItem {
    id: number;
    avatar: string;
    desc: string;
    fade?: boolean;
}

const DragInteractionRecord: React.FunctionComponent<DragInteractionRecordProps> = (props) => {
    const { renderType, text } = props;

    return (
        <>
            {renderType === 1 && <DragInteractionRecordInstance text={text} type={renderType} />}
            {renderType === 2 && <DragInteractionRecordInstance text={text} type={renderType} />}
        </>
    );
};

export default DragInteractionRecord;
