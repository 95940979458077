import React from 'react';

export default function NavIcon(props: { className?: string; fill: string }) {
    const { className, fill } = props;
    return (
        <svg
            className={className ?? ''}
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
        >
            <title>导航icon</title>
            <g id="门店" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="门店模块备份" transform="translate(-1319.000000, -1477.000000)">
                    <g id="门店组件备份-3" transform="translate(985.000000, 1448.000000)">
                        <g id="daohang-5" transform="translate(334.000000, 29.000000)">
                            <path
                                d="M12,2.27373675e-12 C18.62736,2.27373675e-12 24,5.37264 24,12 C24,18.62736 18.62736,24 12,24 C5.37264,24 0,18.62736 0,12 C0,5.37264 5.37264,2.27373675e-12 12,2.27373675e-12 Z M11.88064,4.75856 L8.15904,11.1036267 L10.7281067,11.1036267 L10.7281067,18.63056 L13.0625067,18.63056 C13.0625067,16.30896 14.9291733,14.4204267 17.2235733,14.4204267 L17.2235733,14.4204267 L17.2235733,12.0588267 C15.641379,12.0588267 14.1895765,12.6340912 13.0616887,13.5887072 L13.0625067,11.1036267 L15.60224,11.1036267 L11.88064,4.75856 Z"
                                id="形状结合"
                                fill={fill}
                                fillRule="nonzero"
                            />
                            <g id="编组" transform="translate(8.159040, 4.758560)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
