const SURNAME = [
    '王',
    '李',
    '张',
    '刘',
    '陈',
    '杨',
    '黄',
    '赵',
    '吴',
    '周',
    '徐',
    '孙',
    '马',
    '朱',
    '胡',
    '郭',
    '何',
    '高',
    '林',
    '郑',
    '谢',
    '罗',
    '梁',
    '宋',
    '唐',
    '许',
    '韩',
    '冯',
    '邓',
    '曹',
    '彭',
    '曾',
    '萧',
    '田',
    '董',
    '袁',
    '潘',
    '于',
    '蒋',
    '蔡',
    '余',
    '杜',
    '叶',
    '程',
    '苏',
    '魏',
    '吕',
    '丁',
    '任',
    '沈',
    '姚',
    '卢',
    '姜',
    '崔',
    '钟',
    '谭',
    '陆',
    '汪',
    '范',
    '金',
    '石',
    '廖',
    '贾',
    '夏',
    '韦',
    '付',
    '方',
    '白',
    '邹',
    '孟',
    '熊',
    '秦',
    '邱',
    '江',
    '尹',
    '薛',
    '闫',
    '段',
    '雷',
    '侯',
    '龙',
    '史',
    '陶',
    '黎',
    '贺',
    '顾',
    '毛',
    '郝',
    '龚',
    '邵',
    '万',
    '钱',
    '严',
    '覃',
    '武',
    '戴',
    '莫',
    '孔',
    '向',
    '汤',
];
let preMinute = 0;
export function getRandomInfo(text: string) {
    // + 1 为了生成的随机数永不为0
    let minute = Math.floor(Math.random() * 6 + 1 + preMinute);
    // 6 * 9 下一轮循环
    if (minute > 54) {
        preMinute = 0;
        minute = Math.floor(Math.random() * 6 + 1 + preMinute);
    }
    preMinute = minute;

    const surname = SURNAME[Math.floor(Math.random() * SURNAME.length)];
    return `${surname}**${minute}分钟前${text.substr(0, 15)}`;
}

export function getRandomAvatar() {
    const index = Math.floor(Math.random() * 34 + 1);
    return `https://ali2.a.kwimgs.com/udata/pkg/ks-ad-fe/lp_interactive/${index}.png`;
}
