import { bridge } from '@ad/ks-ad-alliance-bridge';

export function callLianmengBridge(param: { action: string; data: string; callback: string }) {
    const { action, data, callback } = param;

    console.log('window.kwaiAd', window, window.KwaiAd);
    if (!window.KwaiAd) {
        console.log('没有注入KwaiAd');
    }
    window?.KwaiAd?.callAdBridge(
        JSON.stringify({
            action, // 方法名
            data,
            callback,
        }),
    );
}

export function initLianmengBridgeSDK() {
    try {
        bridge.config({
            debug: true,
            handler: 'callAdBridge',
        });
    } catch (e) {
        console.log('bridge init fail', e);
    }
}
