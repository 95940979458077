import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useComContext } from 'omega-render';
import { ReactComponent as L1 } from './svg/Line.svg';
import { ReactComponent as L2 } from './svg/Line2.svg';
import { ReactComponent as El1 } from './svg/Rectangle.svg';
import { ReactComponent as El2 } from './svg/rect2.svg';
import { ReactComponent as El3 } from './svg/Vector.svg';
import { ReactComponent as El4 } from './svg/Vector2.svg';
import { ReactComponent as El5 } from './svg/Rectangle6.svg';
import { ReactComponent as El6 } from './svg/Vector3.svg';
import { ReactComponent as El7 } from './svg/Ellipse8.svg';
import { ReactComponent as El8 } from './svg/Star1.svg';
import { ReactComponent as El9 } from './svg/Union3.svg';
import { ReactComponent as El10 } from './svg/Rectangle4.svg';
import { ReactComponent as El11 } from './svg/Polygon1.svg';
import { ReactComponent as El12 } from './svg/Star2.svg';
import { ReactComponent as El13 } from './svg/Star3.svg';
import { ReactComponent as El14 } from './svg/Polygon2.svg';
import { ReactComponent as El15 } from './svg/Rectangle5.svg';
import { ReactComponent as El16 } from './svg/Polygon.svg';
import { ReactComponent as El17 } from './svg/Rectangle7.svg';
import { ReactComponent as El18 } from './svg/Union.svg';
import { ReactComponent as El19 } from './svg/Rectangle8.svg';
import { ReactComponent as El20 } from './svg/Subtract.svg';
import { ReactComponent as El21 } from './svg/Rectangle9.svg';
import { ReactComponent as El22 } from './svg/Rectangle3.svg';
import { ReactComponent as El23 } from './svg/Union2.svg';
import { ReactComponent as El24 } from './svg/Rectangle2.svg';
import styles from './style.module.less';
import { useTheme } from '@/landingPage/common/utils/useTheme';

export const ElList = {
    L1,
    L2,
    El1,
    El7,
    El10,
    El11,
    El8,
    El12,
    El14,
    El15,
    El16,
    El5,
    El17,
    El18,
    El19,
    El13,
    El20,
    El21,
    El22,
    El23,
    El2,
    El3,
    El9,
    El4,
    El6,
    El24,
};

export interface DragElementProps {
    type: string;
    color: string;
    backgroundImage?: string;
    stroke?: {
        width: number;
        color: string;
        borderRadius: number;
    };
}

const DragElement: React.FunctionComponent<DragElementProps> = (props) => {
    const { type, stroke, backgroundImage } = props;
    const { color } = useTheme({ path: 'color' });
    const { color: strokeColor } = useTheme({ path: 'stroke.color' });

    const Comp = ElList[type];

    const ref = React.useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const imgDefs = ref.current?.getElementsByTagName('image');
        const patternEl = ref.current?.getElementsByTagName('pattern');
        const rect = ref.current?.getElementsByTagName('rect');
        const circle = ref.current?.getElementsByTagName('circle');
        const path = ref.current?.getElementsByTagName('path');
        if (backgroundImage) {
            const id = uuidv4();
            patternEl?.[0]?.setAttribute('id', id);
            imgDefs?.[0]?.setAttribute('href', backgroundImage);
            rect?.[0]?.setAttribute('fill', `url(#${id})`);
            rect?.[1]?.setAttribute('fill', `url(#${id})`);
            circle?.[0]?.setAttribute('fill', `url(#${id})`);
            path?.[0]?.setAttribute('fill', `url(#${id})`);
        } else {
            patternEl?.[0]?.setAttribute('id', 'imgPattern');
            imgDefs?.[0]?.setAttribute('href', '');
            rect?.[0]?.setAttribute('fill', 'inherit');
            rect?.[1]?.setAttribute('fill', 'inherit');
            circle?.[0]?.setAttribute('fill', 'inherit');
            path?.[0]?.setAttribute('fill', 'inherit');
        }
    }, [backgroundImage]);

    const { magic } = useComContext();
    const { width = 0, height = 0 } = magic?.dragFree ?? {};
    useEffect(() => {
        const rect = ref.current?.getElementsByTagName('rect');
        if (rect?.length === 1) {
            ref.current?.setAttribute('viewBox', `0 0 ${width} ${height}`);
            rect[0].setAttribute('width', width);
            rect[0].setAttribute('height', height);
            if (stroke?.borderRadius) {
                const rad = (stroke?.borderRadius ?? 0).toString();
                rect[0].setAttribute('rx', rad);
                rect[0].setAttribute('ry', rad);
            } else if (rect[0].getAttribute('rx')) {
                const min = (Math.min(width, height) / 2).toString();
                rect[0].setAttribute('rx', min);
                rect[0].setAttribute('ry', min);
            }
        }
    }, [width, height, stroke?.borderRadius]);

    return (
        <div className={styles.dragElement}>
            <Comp
                ref={ref}
                style={{
                    fill: backgroundImage ? 'transparent' : color,
                    stroke: strokeColor ?? '#fff',
                    strokeWidth: `${stroke?.width ?? 0}px`,
                    borderRadius: stroke?.borderRadius ?? 0,
                    overflow: 'inherit',
                }}
            />
        </div>
    );
};

export default DragElement;
