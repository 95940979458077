import React from 'react';

export default function NavIcon(props: { fill: string }) {
    const { fill } = props;
    return (
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
            <title>编组 6</title>
            <defs>
                <rect id="path-1" x="0" y="0" width="14" height="14" />
            </defs>
            <g id="改2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="门店组件-丰富样式" transform="translate(-476.000000, -505.000000)">
                    <g id="编组-2" transform="translate(458.000000, 112.000000)">
                        <g id="编组-6" transform="translate(0.000000, 77.000000)">
                            <g id="编组-2" transform="translate(18.000000, 314.000000)">
                                <g id="Icon" transform="translate(0.000000, 2.000000)">
                                    {/* <mask id="mask-2" fill="white">
                                <use xlink:href="#path-1"></use>
                            </mask> */}
                                    <g id="Bounds" fillRule="nonzero" />
                                    <path
                                        d="M7,1.08333333 C8.39242797,1.08333333 9.65311688,1.64552339 10.565645,2.55471403 C11.4775089,3.46324281 12.0416667,4.71831986 12.0416667,6.10473182 C12.0416667,7.57036719 11.4111669,8.88919187 10.4056316,9.807367 L10.4056316,9.807367 L7.27183909,12.6742997 L3.76756603,9.95843081 C2.6619568,9.03741296 1.95833333,7.65300954 1.95833333,6.10473182 C1.95833333,4.71831986 2.52249114,3.46324281 3.43435499,2.55471403 C4.34688312,1.64552339 5.60757203,1.08333333 7,1.08333333 Z"
                                        id="Combined-Shape"
                                        stroke={fill}
                                        mask="url(#mask-2)"
                                    />
                                    <circle
                                        id="椭圆形"
                                        stroke={fill}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        cx="7"
                                        cy="5.96669685"
                                        r="1.75"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
