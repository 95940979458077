/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { useSetState } from 'react-use';

import { useComContext } from 'omega-render';
import { getWindow } from 'landingPage/common/utils/getWindow';
import { isWIFI } from 'landingPage/common/utils/getDeviceInfo';
import { Div } from '../Blank/Blank';
import styles from './style.module.less';

export interface VideoProps {
    videoUrl: string;
    videoCover?: string;
    autoPlay?: boolean;
    loadend: () => void;
}

const useVideo = () => {
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const that = React.useRef({ timer: 0 });
    const [state, setState] = useSetState<{
        muted: boolean;
        status?: 'playing' | 'paused';
        showMask: boolean;
        showPlayButton: boolean;
        showReplay: boolean;
        currentTime: string;
        duration: string;
        percent: string;
    }>({
        status: 'paused',
        showMask: false,
        showReplay: false,
        muted: true,
        currentTime: '00:00',
        duration: '00:01',
        percent: '0%',
        showPlayButton: true,
    });
    const play = React.useCallback(() => {
        const videoDom = videoRef.current;
        const play = videoDom?.play();
        play?.then(() => {
            setState({
                status: 'playing',
            });
        }).catch((err) => {
            console.error('video play error:', err);
            setState({
                status: 'paused',
            });
        });
    }, [setState]);
    const pause = React.useCallback(() => {
        const videoDom = videoRef.current;

        videoDom?.pause();
        setState({
            status: 'paused',
        });
    }, [setState]);

    const onEnded = React.useCallback(() => {
        setState({
            showMask: false,
            showReplay: true,
        });
    }, [setState]);

    const replay = React.useCallback(() => {
        setState({ showReplay: false });
        play();
    }, [play, setState]);
    const onVideoClick = React.useCallback(() => {
        window.clearTimeout(that.current.timer);
        setState({
            showMask: true,
        });
    }, [setState]);

    const onButtonClick = React.useCallback(() => {
        // window.clearTimeout(that.current.timer);
        play();
        setState({
            showMask: true,
            showPlayButton: false,
        });
    }, [play, setState]);

    const toggleMuted = React.useCallback(() => {
        setState((state) => {
            return {
                ...state,
                muted: !state.muted,
            };
        });
    }, [setState]);
    React.useEffect(() => {
        const { current } = that;
        return () => {
            window.clearTimeout(current.timer);
        };
    }, []);

    const onTimeUpdate = React.useCallback(
        (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
            const { currentTime, duration } = e.currentTarget;
            const current = secondFormate(Math.floor(currentTime));
            const progress = (currentTime / duration).toFixed(2);
            const percent = `${Number(progress) * 100}%`;
            setState({
                currentTime: current,
                duration: secondFormate(Math.floor(duration)),
                percent,
            });
        },
        [setState],
    );
    const hideMask = React.useCallback(() => {
        window.clearTimeout(that.current.timer);
        if (state.status !== 'paused') {
            that.current.timer = window.setTimeout(() => {
                setState({
                    showMask: false,
                });
            }, 5000);
        }
    }, [setState, state.status]);
    const fullScreen = React.useCallback(() => {
        const videoDom = videoRef.current as any;
        if (videoDom.requestFullscreen) {
            (videoDom as HTMLVideoElement).requestFullscreen();
        } else if (videoDom.mozRequestFullScreen) {
            /* Firefox */
            videoDom.mozRequestFullScreen();
        } else if (videoDom.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            videoDom.webkitRequestFullscreen();
            // } else if (videoDom.webkitSupportsFullscreen) {
            //     /* Chrome, Safari & Opera */
            //     videoDom.webkitEnterFullscreen();
        } else if (videoDom.msRequestFullscreen) {
            /* IE/Edge */
            videoDom.msRequestFullscreen();
        } else if (videoDom.webkitEnterFullscreen) {
            // iOS进入全屏
            videoDom.webkitEnterFullscreen();
        } else {
            console.error('[videoDrag]: not support fullScreen');
        }
    }, []);
    React.useEffect(() => {
        hideMask();
    }, [hideMask, setState, state.showMask, state.status]);
    React.useEffect(() => {
        const videoDom = videoRef.current as any;
        const pauseCb = () => {
            setState({
                status: 'paused',
            });
        };
        const playingCb = () => {
            setState({
                status: 'playing',
            });
        };
        videoDom.addEventListener('pause', pauseCb);
        videoDom.addEventListener('playing', playingCb);
        return () => {
            videoDom.removeEventListener('pause', pauseCb);
            videoDom.removeEventListener('playing', playingCb);
        };
    }, [setState]);
    const onProgressMove = React.useCallback(
        (e: any) => {
            e.preventDefault();
            const videoDom = videoRef.current;

            const lineWrapper = getWindow().document.querySelector(
                `.${styles.lineWrapper}`,
            ) as HTMLDivElement;
            const width = lineWrapper.offsetWidth;
            const { left } = lineWrapper.getClientRects()[0];

            const touches = e.targetTouches[0];

            const offsetWidth = touches.clientX - left;
            let percent = parseInt(`${(offsetWidth / width) * 100}`, 10);

            if (percent <= 0) {
                percent = 0;
            }

            if (percent >= 100) {
                percent = 100;
            }
            const currentTime = (Number(videoDom?.duration) * percent) / 100;
            if (videoDom) {
                videoDom.currentTime = currentTime;
            }
            setState({
                percent: `${percent}%`,
            });
            hideMask();
        },
        [hideMask, setState],
    );
    const onLoadedMetadata = React.useCallback(() => {
        videoRef.current?.pause();
        // setState({
        //     muted: false,
        // });
    }, [setState]);
    return {
        videoRef,
        state,
        replay,
        onVideoClick,
        play,
        pause,
        onEnded,
        toggleMuted,
        fullScreen,
        onTimeUpdate,
        onProgressMove,
        onLoadedMetadata,
        onButtonClick,
    };
};
const Video: React.FunctionComponent<VideoProps> = (props) => {
    const { preview, id } = useComContext();
    const { videoUrl, videoCover, autoPlay = false, children } = props;
    const {
        videoRef,
        state,
        replay,
        onVideoClick,
        pause,
        play,
        onEnded,
        toggleMuted,
        fullScreen,
        onTimeUpdate,
        onProgressMove,
        onLoadedMetadata,
        onButtonClick,
    } = useVideo();
    const [autoPlayed, setAutoPlayed] = React.useState(false);
    const [canPlay, setCanPlay] = React.useState(false);
    const [wifi, setWifi] = React.useState(true);
    React.useEffect(() => {
        console.log('video init');
        const init = async () => {
            const wifi = await isWIFI();
            setWifi(!!wifi);
            console.log('setWifi', wifi);
        };
        init();
    }, []);
    const inView = useObserve(`div[data-id='${id}']`, [0, 0.9]);
    React.useEffect(() => {
        console.log(`%c inView Change:${inView}`, 'color: pink');
    }, [inView]);
    React.useEffect(() => {
        const pauseVideo = function () {
            if (document.visibilityState === 'hidden') {
                pause();
            }
        };
        document.addEventListener('visibilitychange', pauseVideo);
        return () => document.removeEventListener('visibilitychange', pauseVideo);
    }, [pause]);
    // const onFullscreenChange = React.useCallback(() => {
    //     const videoDom = videoRef.current!;
    //     if(!videoDom.classList.contains('is-fullScreen')) {
    //         videoDom.classList.add('is-fullScreen')
    //     } else {
    //         if (document.exitFullscreen) {
    //             document.exitFullscreen()
    //             // @ts-ignore
    //           } else if (document.webkitExitFullscreen) {
    //             // @ts-ignore
    //             document.webkitExitFullscreen()
    //             // @ts-ignore
    //           } else if (document.mozCancelFullScreen) {
    //             // @ts-ignore
    //             document.mozCancelFullScreen()
    //             // @ts-ignore
    //           } else if (document.msExitFullscreen) {
    //             // @ts-ignore
    //             document.msExitFullscreen()
    //           }
    //           window.setTimeout(() => {
    //             videoDom.classList.remove('is-fullScreen')

    //           }, 2000)
    //     }
    // }, [videoRef])
    // React.useEffect(() => {
    //     ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange'].forEach(item => {
    //         document.addEventListener(item, onFullscreenChange)
    //       })
    // })

    const {
        showMask,
        status,
        showReplay,
        muted,
        currentTime: beginTime,
        duration,
        percent,
        showPlayButton,
    } = state;
    React.useEffect(() => {
        if (
            !preview &&
            autoPlay &&
            inView &&
            wifi &&
            canPlay &&
            status === 'paused' &&
            !autoPlayed
        ) {
            // window.setTimeout(() => {
            onButtonClick();
            setAutoPlayed(true);
            // }, 1000)
        }
        if (!inView && status === 'playing') {
            pause();
            onVideoClick();
        }
    }, [
        autoPlay,
        play,
        inView,
        autoPlayed,
        status,
        onButtonClick,
        pause,
        onVideoClick,
        preview,
        canPlay,
    ]);
    const onCanPlay: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void = () => {
        // window.setTimeout(() => {
        setCanPlay(true);
        // }, 1000)
        // loadend();
        // if (autoPlay && inView && status === 'paused') {
        //     play();
        // }
    };
    React.useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, []);
    return (
        <Div className={styles.root}>
            <video
                autoPlay={autoPlay && wifi}
                src={videoUrl}
                ref={videoRef}
                playsInline
                muted={muted}
                onClick={onVideoClick}
                onEnded={onEnded}
                onTimeUpdate={onTimeUpdate}
                onCanPlay={onCanPlay}
                onLoadedMetadata={onLoadedMetadata}
                style={{ display: 'block' }}
                // webkit-playsinline="true"
                // x5-video-player-type="h5"
                // x5-video-orientation="portraint"
            />
            {showPlayButton && (
                <div
                    className={styles.cover}
                    style={
                        videoCover
                            ? {
                                  backgroundImage: `url(${videoCover})`,
                              }
                            : {}
                    }
                />
            )}
            {showPlayButton && <div className={styles.playButton} onClick={onButtonClick} />}
            {showMask && (
                <div className={styles.mask}>
                    {status === 'playing' && <div className={styles.pause} onClick={pause} />}
                    {status === 'paused' && <div className={styles.play} onClick={play} />}
                    <div className={styles.bottom}>
                        <div className={styles.progress}>
                            <div className={styles.beginTime}>{beginTime}</div>
                            <div className={styles.lineWrapper}>
                                <div
                                    className={styles['video-progress-line']}
                                    style={{ width: percent }}
                                />
                                <div
                                    className={styles['video-progress-line-control']}
                                    onTouchMove={onProgressMove}
                                >
                                    <div className={styles.control} />
                                </div>
                            </div>
                            <div className={styles.totalTime}>{duration}</div>
                        </div>
                        <div
                            className={muted ? styles['voice-muted'] : styles.voice}
                            onClick={toggleMuted}
                        />
                        <div className={styles.fullscreen} onClick={fullScreen} />
                    </div>
                </div>
            )}
            {showReplay && (
                <div className={styles.replay}>
                    <div onClick={replay}>重播</div>
                </div>
            )}
            {children}
        </Div>
    );
};

export default Video;

/**
 * 时间由秒转化成 时 分 秒 格式
 */
const secondFormate = (seconds: number) => {
    const formateNumber = (number: number) => (number <= 9 ? `0${number}` : `${number}`);

    if (seconds > 60 * 60) {
        // 超过一个小时 xx:xx:xx
        const hour = Math.floor(seconds / 3600);
        const remain = seconds % 3600;
        const min = Math.floor(remain / 60);
        const s = remain % 60;
        return `${formateNumber(hour)}:${formateNumber(min)}:${formateNumber(s)}`;
    }
    // 小于一个小时 XX : xx
    const min = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${formateNumber(min)}:${formateNumber(s)}`;
};

const useObserve = (querySelector: string, threshold: number[]) => {
    const [inView, setInView] = React.useState(false);
    const callback: IntersectionObserverCallback = React.useCallback(
        (val) => {
            if (val[0].intersectionRatio >= threshold[1]) {
                setInView(true);
            }
            if (val[0].intersectionRatio <= threshold[0]) {
                setInView(false);
            }
        },
        [threshold],
    );
    React.useEffect(() => {
        const io = new IntersectionObserver(callback, {
            threshold,
        });
        if (document.querySelector(querySelector)) {
            io.observe(document.querySelector(querySelector) as Element);
        }
    }, [callback, querySelector, threshold]);
    return inView;
};
