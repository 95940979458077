const formIcon =
    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-06-05/1685896175319.08c2b2bba7eac739.gif';

export const showAnchorFormIcon = (params: {
    targetNode?: Element;
    // inputList?: HTMLCollectionOf<HTMLElement>;
    // textareaList?: HTMLCollectionOf<HTMLElement>;
}) => {
    // const { inputList, textareaList } = params;
    const { targetNode } = params;
    let inputList: HTMLCollectionOf<HTMLInputElement> = [] as any;
    let originInputList = targetNode?.getElementsByTagName('input');
    const textareaList = targetNode?.getElementsByTagName('textarea');
    const showIconHover = (top: number, right: number, child: HTMLElement) => {
        const icon = document.createElement('img');
        icon.src = formIcon;
        icon.style.position = 'fixed';
        icon.style.width = '52px';
        icon.style.height = '52px';
        icon.style.zIndex = '9999';

        icon.style.top = `${top}px`;
        icon.style.right = `${375 - right}px`;
        document.body.appendChild(icon);
        let hasCleared = false;
        child.addEventListener('click', () => {
            if (!hasCleared) {
                hasCleared = true;
                document.body.removeChild(icon);
            }
        });
        /** 300ms后再监听页面滚动行为，避免表单锚点到页面的滚动过程中图标就被清除了 */
        setTimeout(() => {
            window.addEventListener('scroll', () => {
                if (!hasCleared) {
                    hasCleared = true;
                    document.body.removeChild(icon);
                }
            });
        }, 300);

        let timer;
        timer = setTimeout(() => {
            if (!hasCleared) {
                hasCleared = true;
                document.body.removeChild(icon);
            }
            clearTimeout(timer);
        }, 3000);
    };
    /** 剔除掉只能看不能填写的input组件 */
    if (originInputList?.length) {
        let j = 0;
        for (let i = 0; i < originInputList?.length; i++) {
            if (originInputList[i]?.getAttribute('readonly') === null) {
                inputList[j] = originInputList[i];
                j++;
            }
        }
    }

    if (textareaList?.length && inputList?.length) {
        const textPosition = textareaList[0]?.getBoundingClientRect();
        const { top: textTop, right: textRight } = textPosition;
        const inputPosition = inputList[0]?.getBoundingClientRect();
        const { top: inputTop, right: inputRight } = inputPosition;

        if (inputTop > textTop) {
            showIconHover(textTop, textRight, textareaList[0]);
        } else {
            showIconHover(inputTop, inputRight, inputList[0]);
        }
    } else if (textareaList?.length) {
        const textPosition = textareaList[0]?.getBoundingClientRect();
        const { top, right } = textPosition;
        showIconHover(top, right, textareaList[0]);
    } else if (inputList?.length) {
        console.log(inputList, originInputList);
        const position = inputList[0]?.getBoundingClientRect();
        const { top, right } = position;
        console.log('---top', top, right);
        showIconHover(top, right, inputList[0]);
    }
};
