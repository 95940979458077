import React from 'react';

export default function StoreIcon(props: { fill: string }) {
    const { fill } = props;
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>导航icon备份</title>
            <g id="改2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="门店组件-丰富样式" transform="translate(-797.000000, -507.000000)">
                    <g id="编组-2" transform="translate(458.000000, 112.000000)">
                        <g id="编组-6" transform="translate(0.000000, 77.000000)">
                            <g id="daohang-5" transform="translate(339.000000, 318.000000)">
                                <path
                                    d="M10,0.5 C12.62333,0.5 14.9983293,1.56333612 16.7174966,3.28250339 C18.4366639,5.00167066 19.5,7.37667 19.5,10 C19.5,12.62333 18.4366639,14.9983293 16.7174966,16.7174966 C14.9983293,18.4366639 12.62333,19.5 10,19.5 C7.37667,19.5 5.00167066,18.4366639 3.28250339,16.7174966 C1.56333612,14.9983293 0.5,12.62333 0.5,10 C0.5,7.37667 1.56333612,5.00167066 3.28250339,3.28250339 C5.00167066,1.56333612 7.37667,0.5 10,0.5 Z"
                                    id="形状结合"
                                    stroke={fill || '#3089FF'}
                                />
                                <path
                                    d="M7.07031808,4.97556091 C7.50280203,4.94368735 7.8407858,5.08126217 8.09063131,5.39083195 L8.11373389,5.42148149 C8.14647946,5.46719591 8.36426242,6.06870873 8.76708278,7.22601997 L8.84819575,7.52198831 C8.94514101,7.88100775 8.84435424,8.26547227 8.58310086,8.53140769 L7.79663755,9.323718 L7.89697776,9.52246523 C8.47776403,10.628205 9.42398531,11.5908009 10.370887,12.0173487 L10.5966533,12.1121223 L11.2767742,11.4389936 C11.5209959,11.1957992 11.868761,11.0878291 12.2076132,11.1499237 C13.5978668,11.475364 14.3033759,11.6423054 14.3241406,11.6507481 L14.4126921,11.6899544 C14.8597489,11.9059646 15.0621825,12.3806809 14.9833129,13.0487432 C14.8644494,14.0752098 13.9543379,15.0359418 12.6902333,15.0289336 C11.2223931,15.0206284 9.11846744,13.9491169 7.62823592,12.3804129 C5.74417878,10.3941244 5.03778458,8.54480581 5.00069024,7.04138919 C4.97060014,5.82185174 5.9284212,5.06081044 7.07031808,4.97556091 Z"
                                    id="路径"
                                    fill={fill || '#3089FF'}
                                    fillRule="nonzero"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
