import { getAdBridge } from '@/landingPage/common/bridge';
import { IAppInfo } from '../AppDownload';
import { HandleAdUrlParams } from '@ad/ks-ad-bridge';
import { mapAppInfo } from '../utils';
import { bridge as allianceBridge } from '@ad/ks-ad-alliance-bridge';
import { isInApp } from 'omega-shared/utils';

export const harmonyDownload = (params?: IAppInfo) => {
    //触发鸿蒙下载
    console.log('----start download harmony', params);
    const newParams = {
        type: 1, //下载类广告统一标识
        ...mapAppInfo(params),
    };

    if (!isInApp) {
        allianceBridge?.handleAdUrl({ ...params } as unknown as HandleAdUrlParams);
        // callLianmengBridge({
        //     action: 'handleAdUrl',
        //     data: JSON.stringify(params),
        //     callback: '',
        // });
    } else {
        // 该方法默认支持下载后自动安装
        getAdBridge()
            ?.handleAdUrl(newParams as unknown as HandleAdUrlParams)
            .catch((e) => {
                throw e;
            });
    }
};
