/* eslint-disable @typescript-eslint/restrict-template-expressions */
import dayjs from 'dayjs';

const STORAGE_KEY = 'MOLI_INTERACTIVE_POPUP_CLOSE_TIME';

const isInCooling = (pageId?: string) => {
    let coolingTime;
    try {
        coolingTime = window.localStorage.getItem(`${STORAGE_KEY}_${pageId}`);
    } catch (e) {
        console.error(e);
    }
    console.log('[getCoolingTime]:', coolingTime);
    if (!coolingTime) return false;
    const today = dayjs().startOf('day');
    const isToday = dayjs(+coolingTime).isSame(today); // dayjs() return current date
    return isToday;
};
const updateCoolingTime = (pageId?: string) => {
    try {
        const coolingTime = String(+dayjs().startOf('day'));
        window.localStorage.setItem(`${STORAGE_KEY}_${pageId}`, coolingTime);
        console.log('[setCoolingTime]:', coolingTime);
    } catch (e) {
        console.error(e);
    }
};

export { isInCooling, updateCoolingTime };
