/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
// import { SelectDesc } from 'landingPage/page/pageEdit/componentEditor/XiansuoFormEditor/XiansuoFormEditor';
import cs from 'classnames';
import { ComponentZIndex } from 'omega-shared/utils';
import FormUserAgreement from 'landingPage/components/XiansuoFormDrag/components/FormUserAgreement';
import {
    ChatStyleTypeEnum,
    SelectDesc,
    SubstepFormFollowActionType,
    XiansuoFormProps,
} from '../../XiansuoForm';
import styles from './index.module.less';
import { IFormInfoView } from 'landingPage/services/models';
import { ClueForm } from '@ad/xiansuoSDK';
import { sendData } from 'landingPage/common/adTrack/util/sendData';
import onlineStatusPng from './images/onlineStatus.png';
import { getABResult } from 'landingPage/common/api';

interface ButtonProps {
    style?: React.CSSProperties;
    className?: string;
    onClick?: () => void;
    children?: React.ReactNode;
}

function Button(props: ButtonProps) {
    const { children, className } = props;
    return (
        // eslint-disable-next-line
        <button {...props} className={cs(styles.baseButton, className)}>
            {children}
        </button>
    );
}

export interface IImComponentProps
    extends Pick<XiansuoFormProps, 'welcome' | 'end' | 'messageExpand' | 'chatStyleType'> {
    formSetting: any;
    options?: SelectDesc[];
    preview?: boolean;
    serviceAvatarUrl?: string;
    serviceNickname?: string;
    buttonStyle?: React.CSSProperties;
    onEnd: () => void;
    children?: React.ReactNode;
    startButtonInfo?: string;
    needStartButtonInfoAnimate?: boolean; // 是否需要开始咨询按钮动效
    endButtonInfo?: string;
    formOpt?: IFormInfoView;
    config?: any;
    onClickEndButton?: (formInfo?: SelectItem[]) => void;
    showCustomPrivacyAgreement?: boolean;
    agreementColor?: string;
}

export enum ImType {
    ME,
    SERVICE,
}

// 对话表单新老样式
export enum FormStyle {
    OLD, // 旧样式
    NEW, // 新样式
}

export interface IImItemProps {
    avatarUrl?: string;
    message?: Message;
    type?: ImType;
    readed?: boolean; // 标志是否是最后一条消息，是的话添加“已读提示”
}

export interface IServiceInfo {
    avatarUrl?: string;
    sendState?: number;
    serviceNickname?: string;
}
// 消息类型
enum MessageType {
    // 预览时候提示添加欢迎语
    PlaceHolder,
    Message,
}
interface Message {
    type: MessageType;
    sender: ImType;
    value: string;
}
export interface SelectItem {
    componentId: string;
    value: string;
}
export function ImItem(props: IImItemProps) {
    const { avatarUrl, message, type, readed } = props;
    return (
        <div
            className={cs(
                styles.messageItem,
                { [styles.placeHolder]: message?.type === MessageType.PlaceHolder },
                { [styles.me]: type === ImType.ME },
            )}
        >
            {type === ImType.SERVICE ? (
                <div className={styles.messageItemService}>
                    <div
                        className={styles.avatar}
                        style={{ backgroundImage: avatarUrl && `url(${avatarUrl})` }}
                    />
                    <div className={styles.message}>{message?.value}</div>
                </div>
            ) : (
                <div className={styles.messageItemMe}>
                    {readed && <span className={styles.readed}>已读</span>}
                    <div className={styles.message}>{message?.value}</div>
                </div>
            )}
        </div>
    );
}

// 发送消息状态
enum SendState {
    // 发送中
    Sending,
    // 已经发送完，可以发送下一个选项
    Done,
}

const replyStatusMap = {
    [SendState.Sending]: '对方输入中...',
    [SendState.Done]: '当前在线',
};

export function ServiceInfo(props: IServiceInfo) {
    const { avatarUrl, sendState, serviceNickname } = props;
    return (
        <div className={styles.serviceContainer}>
            {/* 头像、在线状态等 */}
            <div
                className={styles.onlineAvatar}
                style={{ backgroundImage: avatarUrl && `url(${avatarUrl})` }}
            >
                <img className={styles.onlineIcon} src={onlineStatusPng} alt="" />
            </div>
            {/* 昵称、对话状态等 */}
            <div>
                <div className={styles.nickName}>{serviceNickname || '客服1'}</div>
                <div className={styles.sayStatus}>{replyStatusMap[sendState as number]}</div>
            </div>
        </div>
    );
}

enum RunType {
    // 欢迎语阶段
    Start,
    // 分布问话阶段
    Step,
    // 结束语，表单提交阶段
    End,
}

enum componentSubTypeEnum {
    '空白表单' = 0,
    '普通表单' = 1,
    '分步表单' = 2,
}
let time = Date.now();

export function ImComponent(props: IImComponentProps) {
    const {
        welcome,
        options,
        end,
        preview,
        serviceAvatarUrl,
        serviceNickname,
        buttonStyle,
        onEnd,
        messageExpand,
        startButtonInfo,
        needStartButtonInfoAnimate,
        endButtonInfo,
        agreementColor,
        formOpt,
        config,
        chatStyleType,
    } = props;
    const [messageStack, setMessageStack] = React.useState<Message[]>([]);
    const [selectItemStack, setSelectItemStack] = React.useState<SelectItem[]>([]);
    const [chatFormOffsetTop, setChatFormOffsetTop] = React.useState(0);
    const [chatFormScrolledHeight, setChatFormScrolledHeight] = React.useState(0);
    const [hasNewMessageBoxHeight, setHasNewMessageBoxHeight] = React.useState(false);
    // const sendMessageRef = React.useRef<(value: string, sender: ImType) => void>();

    // 当前是第几步数
    const [step, setStep] = React.useState(0);
    const messageBoxRef = React.useRef<HTMLDivElement>(null);
    const chatFormBoxRef = React.useRef<HTMLDivElement>(null);
    const footerRef = React.useRef<HTMLDivElement>(null);
    // 执行状态
    const [state, setState] = React.useState(RunType.Start);
    // 非正常结束,触发了广告主配置的结束选项
    const [expState, setExpState] = React.useState(RunType.Start);

    const [currentSelectIndex, setCurrentSelectIndex] = React.useState(-1);
    const [messageBoxHeight, setMessageBoxHeight] = React.useState(0);
    const wasSendOptionIndexRef = React.useRef(-1);
    const [isFir, setIsFir] = React.useState(false);
    const [ABResult, setABResult] = React.useState<{
        dialog_form_Imitate_type?: number; // 对话表单新旧样式类型
        dialog_form_reply_time?: number; // 对话表单回复时间
        dialog_form_read_time?: number;
        dialog_form_sending_time?: number;
    }>({});
    const [lastMessageIndex, setLastMessageIndex] = React.useState(-1); // 用户最后一条回复，用来添加“已读”状态
    // 出现已读延迟时间
    const [readDelayed, setReadDelayed] = React.useState(0);
    // 出现正在输入中延迟时间
    const [sendingDelayed, setSendingDelayed] = React.useState(0);
    // 发送消息延迟时间，默认历史延迟为800毫秒
    const [replyDelayed, setReplyDelayed] = React.useState(800);
    // 总的延迟时间=已读+正在输入中+发送消息延迟时间
    // 发送辅助信息，或者需等待已读、正在输入中状态结束后发送消息时使用
    const [delayed, setDelayed] = React.useState(800);
    const reportFill = React.useCallback(() => {
        if (isFir) return;
        const params = {
            eventType: 'EVENT_FORM_SUBMIT_FILL',
            formType: 3, // 对话表单
            diyExtraInfo: JSON.stringify({
                componentSubType: componentSubTypeEnum.分步表单,
                formType: 3, // 对话表单
                renderType: 'XIANSUO_FORM_DRAG',
            }),
        };
        setIsFir(true);
        sendData(params);
    }, [isFir]);
    // 发送状态
    const [sendState, setSendState] = React.useState(SendState.Done);
    // 用户发送状态
    const [userSendState, setUserSendState] = React.useState(SendState.Sending);

    // 发送第一条消息的timer
    const sendFirsetMessageTimer = React.useRef(-1);

    // 发送消息
    const sendMessage = React.useCallback(
        (value: string, sender: ImType) => {
            // setMessageStack([...messageStack, { type: MessageType.Message, sender, value }]);
            setMessageStack((prev) => {
                const newStack = [...prev];
                newStack.push({ type: MessageType.Message, sender, value });
                return newStack;
            });
        },
        [messageStack],
    );
    // React.useEffect(() => {
    //     sendMessageRef.current = sendMessage;
    // }, [sendMessage]);

    React.useEffect(() => {
        let delayTime = 0;
        if (ABResult?.dialog_form_read_time) {
            delayTime += ABResult?.dialog_form_read_time;
            setReadDelayed(ABResult?.dialog_form_read_time * 1000);
        }
        if (ABResult?.dialog_form_sending_time) {
            // AB实验取实际值的两倍
            delayTime += ABResult?.dialog_form_sending_time / 2;
            setSendingDelayed(ABResult?.dialog_form_sending_time * 500);
        }
        if (ABResult?.dialog_form_reply_time) {
            delayTime += ABResult?.dialog_form_reply_time;
            setReplyDelayed(ABResult?.dialog_form_reply_time * 1000);
        }
        setDelayed(delayTime * 1000); // 转换为毫秒
    }, [ABResult]);

    React.useEffect(() => {
        (async () => {
            const res = await getABResult({
                adTypes: [
                    'dialog_form_Imitate_type',
                    'dialog_form_reply_time',
                    'dialog_form_read_time',
                    'dialog_form_sending_time',
                ],
            });
            setABResult(res?.data);
        })();
    }, []);

    React.useEffect(() => {
        //当chatFormOffsetTop为0时，才设置当前对话表单距离顶部的高度
        //避免多次设置引发滚动距离不准确的问题
        if (!Boolean(chatFormOffsetTop)) {
            setChatFormOffsetTop(chatFormBoxRef?.current?.getBoundingClientRect()?.top || 0);
        }
    }, [chatFormOffsetTop, chatFormBoxRef]);

    // 欢迎语
    React.useEffect(() => {
        const welcomeMessage = welcome?.map((item) => ({
            type: item.value ? MessageType.Message : MessageType.PlaceHolder,
            value: item.value ?? '请在右侧输入欢迎语',
            sender: ImType.SERVICE,
        }));
        if (preview) {
            if (!welcomeMessage) {
                setMessageStack([
                    {
                        type: MessageType.PlaceHolder,
                        value: '请在右侧输入欢迎语',
                        sender: ImType.SERVICE,
                    },
                ]);
            } else {
                setMessageStack(welcomeMessage);
            }
        }
        return () => {
            setMessageStack([]);
        };
    }, [preview, welcome]);

    // 运行过程欢迎语和结束语
    React.useEffect(() => {
        let endTimer: number;
        if (!preview) {
            if (state === RunType.Start) {
                if (welcome) {
                    const welcomeMessage = welcome
                        ?.filter((item) => item != null)
                        ?.map((message) => {
                            return {
                                type: MessageType.Message,
                                sender: ImType.SERVICE,
                                value: message.value ?? '',
                            };
                        });
                    setMessageStack([...welcomeMessage]);
                }
            }
            if (state === RunType.End) {
                if (end) {
                    // end 目前只有一个所以应该不用直接清除上一个endTimer
                    //结束语的展示：每隔一个replyDelayed 时间后，展示一条结束语
                    end?.forEach((message) => {
                        // endTimer = window.setTimeout(() => {
                        if (message.value) {
                            sendMessage(message.value, ImType.SERVICE);
                        }
                        setSendState(SendState.Done);
                        setUserSendState(SendState.Sending);
                        // }, replyDelayed + 100);
                    });
                }
            }
        }
        return () => {
            clearTimeout(endTimer);
        };
    }, [state, welcome, end, preview]);

    // 自动恢复辅助信息
    React.useEffect(() => {
        const lastMessage = messageStack[messageStack.length - 1];
        let timer: number;
        if (lastMessage?.sender === ImType.ME) {
            const expandOption = messageExpand?.[step]?.options?.[currentSelectIndex];
            // 选择的选项会结束对话
            if (expandOption?.exitQuestion) {
                setTimeout(() => {
                    // setState(RunType.End);
                    setExpState(RunType.End);
                }, delayed);
            }
            const expand = expandOption?.message;
            if (expand) {
                // 存在辅助信息，等待已读+正在输入中+发送消息延迟时间后发送
                timer = window.setTimeout(() => {
                    console.log('辅助信息', (Date.now() - time) / 1000);
                    time = Date.now();
                    sendMessage(expand, ImType.SERVICE);
                    if (expState !== RunType.End) {
                        setStep(step + 1);
                    }
                }, delayed);
            } else {
                // 不存在辅助信息，等待已读+正在输入中延迟时间后修改step，触发下一个问题
                if (expState !== RunType.End) {
                    setTimeout(() => {
                        setStep(step + 1);
                    }, readDelayed + sendingDelayed);
                }
            }
        }

        return () => {
            clearTimeout(timer);
        };
    }, [messageStack, sendMessage]);

    // 根据当前step 和 options 长度设置结束
    React.useEffect(() => {
        if ((step === options?.length && state === RunType.Step) || expState === RunType.End) {
            setTimeout(() => {
                setState(RunType.End);
                onEnd();
                //messageBoxHeight不为0 且 有了新的messageBoxHeight时，才执行页面的滚动操作
                //防止页面抖动
                if (Boolean(messageBoxHeight) && hasNewMessageBoxHeight) {
                    chatFormScrollInPage();
                    setHasNewMessageBoxHeight(false);
                }
            }, replyDelayed);
        }
    }, [
        step,
        expState,
        state,
        options?.length,
        onEnd,
        chatFormScrolledHeight,
        messageBoxHeight,
        hasNewMessageBoxHeight,
    ]);

    // 发送下一个问题
    React.useEffect(() => {
        let timer: number;

        if (step > 0 && expState !== RunType.End) {
            const label = options?.[step]?.label;
            // 修改step时已经等待已读+正在输入中延迟时间，这里只等待发送消息延迟时间
            timer = window.setTimeout(() => {
                if (label) {
                    console.log('发送信息', (Date.now() - time) / 1000);
                    sendMessage(label, ImType.SERVICE);
                }
                setSendState(SendState.Done);
                setUserSendState(SendState.Sending);
            }, replyDelayed);
        }
        return () => {
            if (timer) {
                console.log('取消发送');
                clearTimeout(timer);
            }
        };
    }, [step]);

    React.useEffect(() => {
        if (footerRef.current && state !== RunType.Start) {
            const { height } = footerRef.current.getBoundingClientRect();
            // messageBoxHeight 从 a -> 0 -> b 的新状态时，判定当前有了新的messageBoxHeight
            if (messageBoxHeight !== height && messageBoxHeight === 0) {
                setHasNewMessageBoxHeight(true);
            }
            setMessageBoxHeight(height);
        }
    }, [state, step, sendState, messageBoxHeight, userSendState]);

    //对于选择完成后提交表单场景，如果存在结束语，则需要等结束语都展示完成后再滚动表单，所以执行了一个setTimeout
    React.useEffect(() => {
        if (
            state === RunType.End &&
            formOpt?.substepFormFollowActionType === SubstepFormFollowActionType.FORM
        ) {
            setTimeout(
                () => {
                    chatFormScrollInPage();
                },
                (end?.length || 0) * replyDelayed + 200,
            );
        }
    }, [state, formOpt, end]);

    //嵌入式对话样式时，页面缓慢滚动到对话底部
    const chatFormScrollInPage = () => {
        let timer: number;
        if (chatFormBoxRef.current) {
            const objDiv = chatFormBoxRef.current;
            const { scrollHeight } = objDiv;
            const screenHeight = window.innerHeight;
            objDiv.scrollTop = objDiv.scrollHeight;
            if (chatFormOffsetTop + scrollHeight > screenHeight) {
                const duration = 500;
                let start = 0;
                const frame = duration / 60;
                const step =
                    ((chatFormOffsetTop + scrollHeight - screenHeight - chatFormScrolledHeight) /
                        duration) *
                    frame;
                let currentScrollTop = chatFormScrolledHeight;

                timer = window.setInterval(() => {
                    currentScrollTop += step;
                    window.scrollTo(0, currentScrollTop);
                    start += frame;
                    if (start >= duration) {
                        clearInterval(timer);
                        setChatFormScrolledHeight(chatFormOffsetTop + scrollHeight - screenHeight);
                    }
                }, frame);
            }
        }
        return () => {
            clearInterval(timer);
        };
    };

    // 沉浸式表单样式时，对话缓动滚动到底部
    React.useEffect(() => {
        let timer: number;
        if (chatStyleType !== ChatStyleTypeEnum.InsertScreen) {
            if (messageBoxRef.current) {
                const objDiv = messageBoxRef.current;
                // 不要缓动就把其他的注了
                // objDiv.scrollTop = objDiv.scrollHeight;
                const duration = 1000;
                let start = 0;
                const { scrollTop } = objDiv;
                const { scrollHeight } = objDiv;
                const frame = 1000 / 60;
                const step = ((scrollHeight - scrollTop) / duration) * frame;
                timer = window.setInterval(() => {
                    objDiv.scrollTop += step;
                    start += frame;
                    if (start >= duration) {
                        clearInterval(timer);
                    }
                }, frame);
            }
            return () => {
                clearInterval(timer);
            };
        } else {
            //嵌入式对话表单样式中
            //在对话进行过程中，监听到messageStack改变时，只有messageBoxHeight不为0 且有新的messageBoxHeight时才进行滚动
            if (Boolean(messageBoxHeight) && hasNewMessageBoxHeight) {
                setHasNewMessageBoxHeight(false);
                chatFormScrollInPage();
            }
        }
    }, [messageStack, messageBoxHeight, chatFormOffsetTop, chatFormScrolledHeight, userSendState]);

    // 这里之所以需要转换数据，是因为线索通配置的是分步表单会跟对话表单的选项冲突，这里做一次转换把分步表单数据转成普通表单数据
    config.data.formJson.components = formOpt?.substepFormFollowFormComponents;
    config.data.formJson.tag = '';
    config.domConfs.formTheme.formStyle = {
        ...config.domConfs.formTheme.formStyle,
        borderRadius: '12px',
    };
    config.data.messageStack = selectItemStack;
    console.log('messageStack=====', selectItemStack);

    //监听页面全屏状态
    //在沉浸式样式中会生效
    //嵌入式样式因为window.history.state 不会改变，所以自动不会执行
    React.useEffect(() => {
        function handleFullScreen() {
            console.log('state改变', window.history.state);
            if (!window.history.state?.fullScreen) {
                clearTimeout(sendFirsetMessageTimer.current);
                if (welcome) {
                    const welcomeMessage = welcome
                        ?.filter((item) => item != null)
                        ?.map((message) => {
                            return {
                                type: MessageType.Message,
                                sender: ImType.SERVICE,
                                value: message.value ?? '',
                            };
                        });
                    setMessageStack([...welcomeMessage]);
                    setExpState(RunType.Start);
                    setStep(0);
                    setSendState(SendState.Done);
                    setUserSendState(SendState.Sending);
                    setState(RunType.Start);
                    setCurrentSelectIndex(-1);
                }
            }
        }
        window.addEventListener('popstate', handleFullScreen);
        return () => {
            window.removeEventListener('popstate', handleFullScreen);
        };
    }, [welcome]);

    //准备执行对话过程
    const startChat = () => {
        const firstMessage = options?.[step]?.label;
        if (firstMessage) {
            sendFirsetMessageTimer.current = window.setTimeout(() => {
                sendMessage(firstMessage, ImType.SERVICE);
                setSendState(SendState.Done);
                setUserSendState(SendState.Sending);
            }, replyDelayed);
            setSendState(SendState.Sending);
            setState(RunType.Step);
        }
        if (chatStyleType !== ChatStyleTypeEnum.InsertScreen) {
            window.history.pushState({ fullScreen: true }, '');
        }
    };

    //当对话表单样式为嵌入式，且不是预览状态时，执行自动准备对话过程
    React.useEffect(() => {
        if (chatStyleType === ChatStyleTypeEnum.InsertScreen && !preview) {
            startChat();
        }
    }, [chatStyleType, options]);

    React.useEffect(() => {
        // 获取用户最后一条回复，用来添加“已读”状态
        setTimeout(() => {
            setLastMessageIndex(
                (messageStack as unknown as any)?.findLastIndex(
                    (message) => message.sender === ImType.ME,
                ),
            );
        }, readDelayed);
    }, [messageStack]);

    return (
        <div
            className={cs(
                styles.im,
                {
                    [styles.insertChatImStyle]: chatStyleType === ChatStyleTypeEnum.InsertScreen,
                },
                {
                    [styles.defaultChatImStyle]:
                        chatStyleType !== ChatStyleTypeEnum.InsertScreen && state === RunType.Start,
                },
                {
                    [styles.fullScreenChatImStyle]:
                        chatStyleType !== ChatStyleTypeEnum.InsertScreen && state !== RunType.Start,
                },
            )}
            style={{
                zIndex:
                    chatStyleType !== ChatStyleTypeEnum.InsertScreen && state !== RunType.Start
                        ? ComponentZIndex.IMComponentZIndex
                        : 1,
            }}
            // style={imStyle as React.CSSProperties}
            ref={chatFormBoxRef}
        >
            {/* 根据AB实验结果判断是否展示新样式 */}
            {ABResult?.dialog_form_Imitate_type === FormStyle.NEW && (
                <ServiceInfo
                    avatarUrl={serviceAvatarUrl}
                    sendState={sendState}
                    serviceNickname={serviceNickname}
                />
            )}
            <div
                style={{
                    overflow: 'scroll',
                    height: `calc(${ABResult?.dialog_form_Imitate_type === FormStyle.NEW ? 91 : 100}% - ${messageBoxHeight}px)`,
                }}
            >
                <div
                    ref={messageBoxRef}
                    className={styles.messageBox}
                    style={{
                        height: '100%',
                    }}
                >
                    {messageStack?.map((item, i) => {
                        return (
                            <ImItem
                                avatarUrl={serviceAvatarUrl}
                                key={i}
                                message={item}
                                type={item.sender}
                                readed={
                                    lastMessageIndex === i &&
                                    ABResult?.dialog_form_Imitate_type === FormStyle.NEW
                                } // 根据实验结果及是否最后一条回复判断是否展示”已读“
                            />
                        );
                    })}
                    {/* 表单弹窗要作为消息的一部分存在 */}
                    {state === RunType.End &&
                        expState !== RunType.End &&
                        formOpt?.substepFormFollowActionType ===
                            SubstepFormFollowActionType.FORM && (
                            <div style={{ borderRadius: 8, marginTop: 16 }}>
                                <ClueForm {...config} />
                            </div>
                        )}
                </div>
            </div>

            <div
                className={styles.footer}
                style={{
                    position:
                        chatStyleType === ChatStyleTypeEnum.InsertScreen ? 'relative' : 'absolute',
                }}
            >
                <div className={styles.buttonBox} ref={footerRef}>
                    {state === RunType.Start && (
                        //开始咨询的按钮
                        <Button
                            className={cs(styles.startButton, {
                                [styles.startButtonAnimation]: needStartButtonInfoAnimate,
                            })}
                            style={buttonStyle}
                            onClick={() => {
                                startChat();
                            }}
                        >
                            <span style={{ color: buttonStyle?.color }}>{startButtonInfo}</span>
                            {/* {item.name} */}
                        </Button>
                    )}
                    {state === RunType.Step &&
                        sendState === SendState.Done &&
                        userSendState === SendState.Sending &&
                        options?.[step]?.options?.map((item, i) => {
                            return (
                                <Button
                                    key={item.id}
                                    className={styles.button}
                                    style={{
                                        backgroundColor: buttonStyle?.backgroundColor,
                                    }}
                                    onClick={() => {
                                        reportFill();
                                        time = Date.now();
                                        sendMessage(item.name, ImType.ME);

                                        setSelectItemStack([
                                            ...selectItemStack,
                                            {
                                                value: item.id,
                                                componentId: options?.[step]?.componentId,
                                            },
                                        ]);
                                        setUserSendState(SendState.Done);
                                        setTimeout(() => {
                                            setSendState(SendState.Sending);
                                        }, readDelayed + sendingDelayed);
                                        setCurrentSelectIndex(i);

                                        wasSendOptionIndexRef.current = step;
                                    }}
                                >
                                    <p
                                        style={{
                                            color: buttonStyle?.color,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            width: '100%',
                                            margin: 0,
                                            padding: 0,
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {' '}
                                        {item.name}
                                    </p>
                                </Button>
                            );
                        })}
                    {state === RunType.End &&
                        expState !== RunType.End &&
                        formOpt?.substepFormFollowActionType ===
                            SubstepFormFollowActionType.WECHAT && (
                            <>
                                <Button
                                    className={styles.startButton}
                                    style={{
                                        ...buttonStyle,
                                        marginBottom: 16,
                                    }}
                                    onClick={() => {
                                        reportFill();
                                        // eslint-disable-next-line react/destructuring-assignment
                                        props.onClickEndButton?.(selectItemStack);
                                    }}
                                >
                                    <span style={{ color: buttonStyle?.color }}>
                                        {`同意并${endButtonInfo}`}
                                    </span>
                                    {/* {item.name} */}
                                </Button>
                                <FormUserAgreement
                                    showCustom={props.showCustomPrivacyAgreement}
                                    hasCunstomContent={props?.formSetting?.privacy?.switch}
                                    color={agreementColor || '#666'}
                                    buttonColor={buttonStyle?.color || buttonStyle?.backgroundColor}
                                    {...props?.formSetting?.privacy?.itemConfig}
                                />
                            </>
                        )}
                </div>
            </div>
        </div>
    );
}
