/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import cs from 'classnames';
import { callApp } from 'landingPage/common/utils/callApp';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { PopupType, WechatActionSceneEnum } from '../WeiXinDrag/WeiXin';
import { wechatDataTrack } from '../WeiXinDrag/components/commonFun';
import { copy } from './copy';
import styles from './style.module.less';
import { getTransCompList } from 'landingPage/common/impression';

// 多转化事件 优化目标
const multiConversion = getUrlParam('multiConversion') === 'true';

export interface CopyDialogProps {
    info?: string;
    wechatId?: string;
    onClose?: () => void;
    itemId?: string;
    packId?: string;
    appId?: string;
}

export function useLongPress(time: number, cb: () => void) {
    // let timer: number;
    const [timer, setTimer] = React.useState<number>();
    const touchStart = React.useCallback(() => {
        const timer = window.setTimeout(() => {
            cb();
        }, time);
        setTimer(timer);
    }, [cb, time]);
    const touchEnd = React.useCallback(() => {
        clearTimeout(timer);
    }, [timer]);
    return [touchStart, touchEnd];
}

export const LongPressDialog: React.FunctionComponent<CopyDialogProps> = (props) => {
    const { info, wechatId, onClose, itemId, packId, appId } = props;
    const [selected, setSelected] = React.useState(false);
    const longPressCb = React.useCallback(() => {
        // nodePitch(document.querySelector('.wechat-id'))
        setSelected(true);
        wechatId && copy(wechatId, itemId, packId);
        console.log('%clongPressed', 'color: pink');
    }, [itemId, packId, wechatId]);
    React.useEffect(() => {
        wechatId &&
            dataTrack({
                eventType: 'EVENT_VIEW_WECHAT',
                wechatItemId: itemId,
                wechatActionScene: WechatActionSceneEnum.COPY_WECHAT_ID,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN',
                    popupType: PopupType.COMPONENT,
                }),
            });
    }, [itemId, wechatId]);
    const [onLongPressStart, onLongPressEnd] = useLongPress(500, longPressCb);
    // React.useEffect(() => {
    //   wechatId && copy(wechatId, itemId, packId);
    // }, [info, itemId, packId, wechatId]);
    const callWX = React.useCallback(() => {
        // dataTrack({ eventType: 'EVENT_ADD_WECHAT', wechatItemId: itemId });
        // 多转化事件优化目标，不打除表单转化的EVENT_FORM_SUBMIT点
        if (!multiConversion) {
            dataTrack({
                eventType: 'EVENT_FORM_SUBMIT',
                diyExtraInfo: JSON.stringify({
                    landing_page_infos: JSON.stringify({
                        page_module_type: getTransCompList(),
                    }),
                }),
            });
        }
        callApp(
            'weixin:// ',
            'https://weixin.qq.com/cgi-bin/readtemplate?t=download/ver_voice',
            () => {
                wechatDataTrack({
                    itemId,
                    invokeSuccess: true,
                    sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                    wechatId,
                    appId,
                    popupType: PopupType.COMPONENT,
                });
            },
            () => {
                wechatDataTrack({
                    itemId,
                    invokeSuccess: false,
                    sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                    wechatId,
                    appId,
                    popupType: PopupType.COMPONENT,
                });
            },
        );
        onClose?.();
    }, [itemId, wechatId, appId, onClose]);
    const onClick = React.useCallback(() => {
        setSelected(false);
        callWX();
    }, [callWX]);
    return (
        <div className={cs([styles.pressDialog, styles.dialog])}>
            <div className={styles.exit} onClick={onClose} />
            {/* <Checked className={styles.icon}></Checked> */}
            <div className={styles.text}>{info}</div>
            <div
                className={styles.account}
                onTouchStart={onLongPressStart}
                onTouchEnd={onLongPressEnd}
            >
                <span className={styles.label}>微信号：</span>
                <span className={cs({ 'wechat-id': true, [styles.selected]: selected })}>
                    {wechatId}
                    {selected && (
                        <span className={styles.toolTip}>
                            <span className={styles.toolTipContent}>
                                <span className={styles.toolTipArrow}>
                                    <span className={styles.toolTipArrowContent} />
                                </span>
                                <span className={styles.toolTipText} onClick={onClick}>
                                    <span>复制并前往微信</span>{' '}
                                </span>
                            </span>
                        </span>
                    )}
                </span>
            </div>
            <div className={styles.info}>长按复制微信号，去微信搜索添加</div>
            {/* <div className={styles.close} onClick={callWX}>
                去添加
            </div> */}
        </div>
    );
};
