import { LOC_ERROR } from '../Store';

export const getDefaultCopyWriting = (status: LOC_ERROR) => {
    switch (status) {
        case LOC_ERROR.NO_CITY:
            return {
                title: '请选择城市和门店',
                content: '暂未获取到位置信息',
            };
        case LOC_ERROR.NO_DEFAULT_STORE:
            return {
                title: '请选择门店',
                content: '暂未获取到附近门店',
            };
        default:
            return {
                title: '门店名称',
                content: '门店地址',
            };
    }
};
