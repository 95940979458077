import _ from 'lodash';
import { ComponentJson, useComContext, useRenderState } from 'omega-render';
import React, { useEffect, useMemo, useRef } from 'react';
import { getEnhanceImageUrl, getPicCDNSource } from './getUrlParam';

// 懒加载的默认占位图
const DEFAULT_PLACEHOLDER =
    'https://p1-yx.adkwai.com/kos/nlav10749/defaultSingleImage.483f6c93b5158d0c.png';

/**
 * 计算当前组件距离页面最顶部的高度
 * @returns {number} 当前组件页面最顶部的高度
 */
export const useBlankHeight = () => {
    const { id } = useComContext();
    const components = useRenderState((state) => state.rolex.components);
    let blankHeight = 0;
    let comJSON: ComponentJson | undefined;
    for (let i = 0; i < components.length; i++) {
        // 查找当前组件的 JSON
        let currentComJson = components[i]?.components?.find((child) => child.id === id);
        if (components[i].id === id) {
            currentComJson = components[i];
        }

        if (currentComJson) {
            comJSON = currentComJson;
            break;
        }
        // 如果组件类型为 'BLANK'，则累加其高度
        if (components[i].type === 'BLANK') {
            blankHeight =
                blankHeight +
                (components[i].props?.style?.height ?? components[i].magic?.editHeight ?? 0);
        }
    }
    // 获取当前子组件自由拖拽的 Y 值
    const dragFreeY = comJSON?.magic?.dragFree?.y ?? 0;
    return blankHeight + dragFreeY;
};

export type ImgItem = { imgUrl: string };

/**
 * 将图片数组格式化为对象，对象的键为图片URL，值为处理过的URL或原始URL。
 * @param imgList - 需要格式化的图片项数组。
 * @param fn - 可选的函数，用于处理每个图片URL。如果未提供，将使用原始URL。
 * @returns 一个对象，其键为图片URL，值为处理过的URL或原始URL。
 */
export const formatImgWith = (
    imgList: ImgItem[],
    fn?: (img: string) => string,
): { [key: string]: string } => {
    // 使用 reduce 方法将数组转换为对象
    return imgList.reduce((cur, last) => {
        // 展开当前对象
        // 添加一个新的属性，键为图片URL，值为处理过的URL或原始URL
        return {
            ...cur,
            [last.imgUrl]: fn ? fn(last.imgUrl) : last.imgUrl,
        };
    }, {});
};

/**
 * 使用懒加载图片的 Hook（支持懒加载&大图渐进式加载）
 *  默认情况下，距离顶部小于 600 时，直接加载图片，否则加载placeholder
 * @param {Object[]} rawImgs - 原始图片数组，每个对象包含一个 imgUrl 属性
 * @returns {Object} 包含懒加载图片和 DOM 引用的对象
 */
export function useLazyImg(rawImgs: { imgUrl?: string }[], options?: { edit?: boolean }) {
    const inputImgs: ImgItem[] = rawImgs.filter((img): img is ImgItem => img.imgUrl !== undefined);
    const prevImgs = useRef(inputImgs);

    const { isSupportWebp } = useComContext();
    const distanceToTop = useBlankHeight();
    // 图片加载状态MAP
    const [loadStatus, setLoadStatus] = React.useState({} as { [key: string]: boolean });
    // 初始化显示状态和当前图片
    const [show, setShow] = React.useState(distanceToTop < 600 ? true : false);
    const [currentImg, setCurrentImg] = React.useState<{
        [key: string]: string;
    }>(
        distanceToTop < 600
            ? // 如果距离顶部小于 600，直接加载原图
              formatImgWith(inputImgs, (img) => getEnhanceImageUrl(img, isSupportWebp, true))
            : // 否则加载占位符图片
              formatImgWith(inputImgs, () => getPicCDNSource(DEFAULT_PLACEHOLDER, isSupportWebp)),
    );
    const lazyImgs = useMemo(() => {
        if (options?.edit) {
            return formatImgWith(inputImgs, (img) => getPicCDNSource(img, isSupportWebp));
        }
        return currentImg;
    }, [currentImg, rawImgs]);
    useEffect(() => {
        if (inputImgs && prevImgs && !_.isEqual(inputImgs, prevImgs.current)) {
            setCurrentImg(
                formatImgWith(inputImgs, (img) => getEnhanceImageUrl(img, isSupportWebp, false)),
            );
        }
        prevImgs.current = inputImgs;
    }, [inputImgs]);
    // 使用 useEffect 进行副作用操作
    useEffect(() => {
        Object.keys(currentImg).forEach((imgKey) => {
            const img = currentImg[imgKey];
            if (!loadStatus[imgKey] && !img.includes('defaultSingleImage')) {
                // 如果图片未加载且不是占位符图片，则设置加载状态并加载实际的原图
                setLoadStatus((state) => {
                    return {
                        ...state,
                        [imgKey]: true,
                    };
                });
                loadSingle(imgKey).then((res) => {
                    setCurrentImg((state) => {
                        return {
                            ...state,
                            [imgKey]: res as string,
                        };
                    });
                });
            }
        });
    }, [currentImg]);
    // 定义加载单个图片的函数
    const loadSingle = async (imgUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = getPicCDNSource(imgUrl, isSupportWebp);
            img.onload = function () {
                resolve(getPicCDNSource(imgUrl, isSupportWebp));
            };
        });
    };
    const domRef = React.useRef<HTMLDivElement>(null);
    React.useLayoutEffect(() => {
        if (options?.edit) return;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting || entry.intersectionRatio > 0) {
                        if (!show) {
                            setShow(true);
                            setCurrentImg(
                                formatImgWith(inputImgs, (img) =>
                                    getEnhanceImageUrl(img, isSupportWebp, true),
                                ),
                            );
                        }
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null, // 相对于视口进行观察
                rootMargin: '30px', // 在视口边缘50px时开始加载图片
                threshold: 0.01, // 当1%的元素可见时触发回调
            },
        );
        if (domRef.current) {
            observer.observe(domRef.current);
        }
        return () => {
            observer?.disconnect();
        };
    }, []); // 确保只在组件加载时运行
    return {
        lazyImgs,
        domRef,
    };
}
