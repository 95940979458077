import { DataSDK } from '@firefly/firefly-sdk';
import { isStaging, isDevelopment } from './env';

export const fireflyIsProd = !isStaging && !isDevelopment;
// 初始化sdk
export const fireflySDK = new DataSDK({
    projectName: 'moli', // 必填（注：只有这一项是必填，仅用于错误埋点上报）
    env: fireflyIsProd ? 'PROD' : 'TEST', // 非必填 PROD | TEST
    requestTimes: 4, // 重试次数
});
