import { getChangeAggregateCorporInfoPoi } from './firefly';
import { getUrlParam } from './getUrlParam';

const isXiansuoFormComp = (comp) => {
    return ['XIANSUO_FORM', 'XIANSUO_FORM_DRAG'].includes(comp.type);
};

export const assembleComps = async (components: any[]) => {
    let comps = [...components];
    let res;
    try {
        res = await getChangeAggregateCorporInfoPoi();
    } catch (e) {
        console.log('----fetch firefly error', e);
    }
    //如果是聚合竞价落地页，且修改商户信息组件的开关是true，则把商户信息组件的位置从页面最底部调整到表单组件所处的blank下方
    if (getUrlParam('isAggregatePage') && res) {
        const corporIndex = comps.findIndex((item) => {
            return item.type === 'CORPORATE_INFO';
        });
        const corporComp = comps[corporIndex];
        comps.splice(corporIndex, 1);
        const formIndex = comps.findIndex((item) => {
            let isTargetIndex = false;
            if (isXiansuoFormComp(item)) {
                isTargetIndex = true;
            }
            if (
                item.type === 'BLANK' &&
                item.components?.length === 1 &&
                isXiansuoFormComp(item.components[0])
            ) {
                isTargetIndex = true;
            }
            return isTargetIndex;
        });
        comps.splice(formIndex + 1, 0, corporComp);
    }
    return [
        {
            id: '8888',
            type: 'SCREEN_TOP_BAR',
            version: '0.0.1',
        },
        ...comps,
        {
            id: '9999',
            type: 'AGREEMENT',
            version: '0.0.1',
        },
        {
            id: '10004',
            type: 'INTERACTION_COMMENT',
            version: '0.0.1',
            props: {
                position: 'rightBottom',
            },
        },
    ];
};
