import { SystemArrowLargeLeftLine, WarningOutlined } from '@m-ui/icons';
import { openNewPageFromUrl } from 'landingPage/components/CustomerServiceDrag/CustomerService';
import { exitWebview } from 'landingPage/common/bridge';
import { getContainerType } from 'landingPage/common/utils/getContainerType';
import { CONTAINER_TYPE } from '../Blank/utils/useContainerStatus';

export default function NavBackForMidPage() {
    const feedbackUrl =
        'https://activity.e.kuaishou.com/pages/report?layoutType=4&negativeSource=6';
    const gotoFeedback = () => {
        openNewPageFromUrl(feedbackUrl);
    };
    const goBack = () => {
        exitWebview();
    };
    return (
        <>
            {getContainerType() !== CONTAINER_TYPE.halfScreen && (
                <div
                    style={{
                        padding: '0 15px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <SystemArrowLargeLeftLine
                        style={{ color: '#fff', fontSize: '21.74px' }}
                        onClick={goBack}
                    />
                    <WarningOutlined
                        style={{ color: '#fff', fontSize: '21.74px' }}
                        onClick={gotoFeedback}
                    />
                </div>
            )}
        </>
    );
}
