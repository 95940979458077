import * as React from 'react';
import cs from 'classnames';
import { useComContext } from 'omega-render';
import DownloadIcon from '../DownloadIcon';
import styles from './style.module.less';
import { useTheme } from '@/landingPage/common/utils/useTheme';
import { getPicCDNSource } from '@/landingPage/common/utils/getUrlParam';
import { Div } from '@/landingPage/components/Blank/Blank';

interface ICircleBtn extends React.CSSProperties {
    text: string;
    btnType: number;
    dynamic: boolean;
    iconColor?: string;
}
enum btnTypeMap {
    'cardBtn' = 5,
    'circleIconBtn' = 6,
    'circleBtn' = 7,
}
export const CircleBtn: React.FC<ICircleBtn> = (props: ICircleBtn) => {
    const { text, btnType, dynamic, iconColor, ...rest } = props;
    const { color: buttonColor } = useTheme({
        path: 'buttonStyle.color',
    });
    const { color: buttonBackgroundColor } = useTheme({
        path: 'buttonStyle.backgroundColor',
    });
    const ref = React.useRef<HTMLDivElement>(null);
    const { magic } = useComContext();

    const [width, setWidth] = React.useState(
        ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree.width,
    );
    React.useEffect(() => {
        setWidth(ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree.width);
    }, [magic?.globalDragFree.width]);

    return (
        <Div
            className={cs({
                [styles.wrap]: true,
            })}
        >
            {btnType === btnTypeMap.circleBtn && (
                <div
                    className={cs({
                        [styles.circle]: true,
                        [styles.ani]: dynamic,
                    })}
                    style={{
                        background: rest.background ?? '#3089ff',
                        backgroundColor: buttonBackgroundColor ?? 'inherit',
                        backgroundImage: getPicCDNSource(rest.backgroundImage) ?? '#3089ff',
                        color: rest.color ?? '#fff',
                        fontSize: rest.fontSize ? `${rest.fontSize}px` : '12px',
                        fontFamily: rest.fontFamily ?? 'unset',
                        width: width - 16,
                        height: width - 16,
                        fontWeight: 400,
                        lineHeight: '18px',
                    }}
                >
                    {text?.substring(0, 2)}
                    <br />
                    {text?.substring(2)}
                </div>
            )}
            {btnType === btnTypeMap.circleIconBtn && (
                <>
                    <div
                        className={cs({
                            [styles.circleIcon]: true,
                            [styles.ani]: dynamic,
                        })}
                        style={{
                            background: rest.background ?? '#3089ff',
                            backgroundImage: getPicCDNSource(rest.backgroundImage) ?? '#3089ff',
                            backgroundColor: buttonBackgroundColor ?? 'inherit',
                            width: width - 16,
                            height: width - 16,
                        }}
                    >
                        <DownloadIcon
                            fill={iconColor ?? '#fff'}
                            style={{
                                width: 0.5 * (parseInt(width) - 16) - 4,
                                height: 0.5 * (parseInt(width) - 16) - 4,
                            }}
                        />
                    </div>
                    <div
                        className={styles.text}
                        style={{
                            color: `${buttonColor ?? '#3089ff'}`,
                            fontSize: rest.fontSize ? `${rest.fontSize}px` : '12px',
                            fontFamily: `${rest.fontFamily ?? 'unset'}`,
                        }}
                    >
                        {text}
                    </div>
                </>
            )}
        </Div>
    );
};
