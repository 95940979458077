/* eslint-disable */
import { sendPerfLogLog, sendTraceLog } from './adTrack/monitor';
import { isBeta, isStaging, isDevelopment } from './utils/env';
import { isAudit, isPC } from './utils/getDeviceInfo';
import { getUrlParam } from './utils/getUrlParam';

function log() {
    sendPerfLogLog({
        subtag: 'MOLI_PC_PREVIEW',
        extra1: window.location.host,
    });
    sendTraceLog({
        tags: {
            url: window.location.href,
            ua: navigator && navigator.userAgent,
        },
    });
}

export const getAllParams = () => {
    const currentUrl = new URL(window.location.href);

    // 获取所有参数
    const params = new URLSearchParams(currentUrl.search);

    let paramsString = '';
    params.forEach((value, key) => {
        if (key !== 'target') {
            paramsString += `${key}=${value}&`;
        }
    });

    paramsString = paramsString.slice(0, -1);
    return paramsString;
};

export function handlePCPreview(edit?: boolean, preview?: boolean) {
    /** 在PC打开的时候，跳转PC预览兼容页 */
    if (isPC() && !isAudit() && !edit && !preview) {
        let host = 'https://moli.kuaishou.com';
        if (isStaging || isDevelopment || isBeta) {
            host = window.location.origin;
        }
        // const currentURL = encodeURIComponent(window.location.href);
        // const pageId = getUrlParam('pageId');

        const paramsString = getAllParams();
        window.location.href = `${host}/pc-preview?${paramsString}`;

        // window.location.href = `${host}/pc-preview?pageId=${pageId}&target=${currentURL}`;
    }
    if (getUrlParam('from') === 'pc-preview') {
        console.log('pc-prevew');
        log();
    }
}
