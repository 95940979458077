import React from 'react';

export default function NavIcon(props: { className?: string; fill: string }) {
    const { className, fill } = props;
    return (
        <svg className={className ?? ''} width="6px" height="12px" viewBox="0 0 6 12" version="1.1">
            <title>universal_icon_listarrow_grey_s_normal</title>
            <g id="改2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="门店落地页c端一屏备份-3" transform="translate(-352.000000, -295.000000)">
                    <g id="编组-6" transform="translate(0.000000, 64.000000)">
                        <g id="编组-7" transform="translate(17.000000, 226.000000)">
                            <g id="编组-11" transform="translate(283.000000, 3.000000)">
                                <g
                                    id="universal_icon_listarrow_grey_s_normal"
                                    transform="translate(52.000000, 2.000000)"
                                >
                                    <g
                                        id="Icon"
                                        transform="translate(1.125000, 1.500000)"
                                        stroke={fill}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <polyline
                                            id="Stroke-1"
                                            transform="translate(1.875000, 4.595896) scale(-1, 1) translate(-1.875000, -4.595896) "
                                            points="3.75 0 0 4.86148049 3.75 9.19179299"
                                        />
                                    </g>
                                    <polygon
                                        id="Bounds"
                                        opacity="0"
                                        points="3.04518315e-16 0 6 0 6 12 3.04518315e-16 12"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
