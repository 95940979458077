import { createModel } from 'rt-model';
import { useRenderActions } from 'omega-render';

/**
 * 咨询页浮层弹窗的状态。
 */
export enum ModalStatus {
    HIDDEN, // 隐藏
    EIGHTY, // 80%屏幕
    HALF, // 50%屏幕
}

/**
 * 咨询页浮层弹窗数据状态。
 */
export interface CustomerState {
    currentUrl?: string; // 当前URL
    status: ModalStatus; // 模态框状态
}

/**
 *  咨询页浮层弹窗的默认值。
 */
export const defaultState: CustomerState = {
    currentUrl: '',
    status: ModalStatus.HIDDEN,
};

/**
 * 咨询页浮层弹窗的model。
 * 为什么需要这个model?
 * => 页面存在多个咨询组件的时候，需要复用同一个浮层弹窗，因此需要一个model来可以跨组件管理这个浮层弹窗的状态。
 */
export const customerServiceModal = createModel({
    namespace: 'customerService',
    defaultState,
    reducers: {},
    effects: {
        /**
         * 设置当前咨询的URL。
         * @param url 当前咨询的URL。
         */
        setCurrentConsultUrl(url: string) {
            return async (dispatch, getState) => {
                const state = getState().customerService;
                if (state.currentUrl !== url) {
                    dispatch(customerServiceModal.actions.setModelState({ currentUrl: url }));
                }
            };
        },
        /**
         * 关闭客服模态框。
         */
        closeCustomerServiceModal() {
            return async (dispatch) => {
                dispatch(
                    customerServiceModal.actions.setModelState({ status: ModalStatus.HIDDEN }),
                );
            };
        },
        /**
         * 打开客服浮层弹窗。
         */
        openCustomerServiceModal() {
            return async (dispatch) => {
                dispatch(
                    customerServiceModal.actions.setModelState({ status: ModalStatus.EIGHTY }),
                );
            };
        },
        /**
         * 切换客服模态框状态。
         */
        toggleCustomerServiceModal() {
            return async (dispatch, getState) => {
                const state = getState().customerService;
                if (state.status === ModalStatus.EIGHTY) {
                    dispatch(
                        customerServiceModal.actions.setModelState({ status: ModalStatus.HALF }),
                    );
                } else {
                    dispatch(
                        customerServiceModal.actions.setModelState({ status: ModalStatus.EIGHTY }),
                    );
                }
            };
        },
    },
});

/**
 * reducer映射。
 */
export const reducerMap = {
    customerService: customerServiceModal?.reducer,
};

/**
 * 获取客服弹窗的actions。
 */
export const useCustomerServiceActions = () => useRenderActions(customerServiceModal.actions);
