export const hexToRgba = (hex: string, opacity: number) => {
    return `rgba(${parseInt(`0x${hex.slice(1, 3)}`)},${parseInt(`0x${hex.slice(3, 5)}`)},${parseInt(
        `0x${hex.slice(5, 7)}`,
    )},${opacity})`;
};

export const hexToRgb = (hex: string) => {
    return `${parseInt(`0x${hex.slice(1, 3)}`)},${parseInt(`0x${hex.slice(3, 5)}`)},${parseInt(
        `0x${hex.slice(5, 7)}`,
    )}`;
};

export const getAlphaValue = (color: string) => {
    // 正则表达式匹配RGBA中的alpha值
    const regex = /rgba?\(([^)]+)\)/;
    const match = color.match(regex);

    if (match) {
        // 提取括号内的值
        const values = match[1].split(',');
        // 返回alpha值，注意在RGBA中alpha值是最后一个参数
        return parseFloat(values[values.length - 1]);
    } else {
        // 如果不是RGBA格式，则返回1（不透明）
        return 1;
    }
};
