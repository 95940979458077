import React from 'react';

export default function Location(props: { fill: string }) {
    const { fill } = props;
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.99992 0.083252C7.21879 0.083252 9.16658 1.88389 9.16658 4.04241C9.16658 6.41221 7.86271 8.37032 5.25496 9.91672C5.10754 10.0041 4.92641 10.0096 4.77478 9.93309L4.74486 9.9167L4.65647 9.86381C2.10766 8.32538 0.833252 6.38055 0.833252 4.02933C0.833252 1.87081 2.78105 0.083252 4.99992 0.083252ZM4.99992 1.08325C3.29872 1.08325 1.83325 2.45745 1.83325 4.02933C1.83325 5.9284 2.82679 7.51581 4.92021 8.85079L4.99992 8.90108L5.0762 8.85299C7.14615 7.53411 8.14093 5.97328 8.1661 4.11496L8.16659 4.04241C8.16659 2.46707 6.69756 1.08325 4.99992 1.08325ZM4.99991 3.08325C5.64425 3.08325 6.16658 3.60559 6.16658 4.24992C6.16658 4.89425 5.64425 5.41659 4.99991 5.41659C4.35558 5.41659 3.83325 4.89425 3.83325 4.24992C3.83325 3.60559 4.35558 3.08325 4.99991 3.08325Z"
                fill={fill}
            />
        </svg>
    );
}
