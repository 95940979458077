import { IStoreView } from 'landingPage/common/api/interface';
import { STORE_LOC_STATUS } from '../Store';

/* 通过共享store，来跨页面传递定位数据 */
export interface ILoc {
    lat?: string;
    lon?: string;
    cityId: string;
    cityName: string;
}

export enum STORE_COMPONENT_TYPE {
    STORE,
    STORE_PAGE,
}
interface IState {
    componentType?: STORE_COMPONENT_TYPE;
    /** 门店包id */
    packageId?: string;
    /** 地理位置 */
    loc?: ILoc;
    /** 选择门店 */
    storeSelected?: IStoreView;
    /** 选择城市 */
    citySelected?: {
        id: string;
        name: string;
    };
    /** 定位状态 */
    locStatus?: STORE_LOC_STATUS;
    /** 列表页曝光是否已上报打点，用来防止重复上报 */
    listTracked?: boolean;
    /** 城市页曝光是否已上报打点，用来防止重复上报 */
    cityTracked?: boolean;
}

type stateKey = keyof IState;

let state: IState = {};

export function setStoreState(data: IState) {
    state = {
        ...state,
        ...data,
    };
}

export function getStoreState<K extends stateKey>(key: stateKey): IState[K] {
    return state[key] as IState[K];
}
