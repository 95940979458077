import React from 'react';

export default function StoreIcon(props: { fill: string }) {
    const { fill } = props;
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
            <title>daohang-5</title>
            <g id="改2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="门店组件-丰富样式" transform="translate(-757.000000, -507.000000)">
                    <g id="编组-2" transform="translate(458.000000, 112.000000)">
                        <g id="编组-6" transform="translate(0.000000, 77.000000)">
                            <g id="daohang-5" transform="translate(299.000000, 318.000000)">
                                <path
                                    d="M10,0.5 C12.62333,0.5 14.9983293,1.56333612 16.7174966,3.28250339 C18.4366639,5.00167066 19.5,7.37667 19.5,10 C19.5,12.62333 18.4366639,14.9983293 16.7174966,16.7174966 C14.9983293,18.4366639 12.62333,19.5 10,19.5 C7.37667,19.5 5.00167066,18.4366639 3.28250339,16.7174966 C1.56333612,14.9983293 0.5,12.62333 0.5,10 C0.5,7.37667 1.56333612,5.00167066 3.28250339,3.28250339 C5.00167066,1.56333612 7.37667,0.5 10,0.5 Z"
                                    id="形状结合"
                                    stroke={fill || '#3089FF'}
                                />
                                <g
                                    id="编组-3"
                                    transform="translate(6.532868, 4.090909)"
                                    fill={fill || '#3089FF'}
                                >
                                    <polygon
                                        id="路径"
                                        points="4.08622222 5.28755556 4.08622222 11.56 2.14088889 11.56 2.14088889 5.28755556 -1.90554643e-13 5.28755556 3.10133333 -4.68312258e-14 6.20266667 5.28755556"
                                    />
                                    <path
                                        d="M4.08622222,11.56 L2.14088889,11.56 C2.14088889,8.54044444 4.56888889,6.08355556 7.55377778,6.08355556 L7.55377778,8.05155556 C5.64177778,8.05155556 4.08622222,9.62533333 4.08622222,11.56 L4.08622222,11.56 Z"
                                        id="路径"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
