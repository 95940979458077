import { getRadar } from '../weblog';
import { getCallbackInfo } from './util/sendData';

//上报聚合竞价相关的数据
//默认携带callback和完整原始X链接
export const aggregateInfoReport = async (params: { [key in string]: string | boolean }) => {
    (await getRadar())?.event({
        name: 'aggregate_info', // 必填
        event_type: 'aggregate_info', // 【可选】
        category: 'traceLog', // 【可选】
        extra_info: JSON.stringify({
            callback: getCallbackInfo(),
            url: location.href,
            ...params,
        }),
    });
};
