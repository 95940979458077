import * as React from 'react';
import { useCallback } from 'react';
import { useMount } from 'react-use';
import { useComContext, useRenderState } from 'omega-render';
import cs from 'classnames';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { useSelector } from 'react-redux';
import { BizId } from 'landingPage/common/enums';
import styles from '../AppDownloadDrag/style.module.less';
import Button, { ButtonProps } from '../Button/Button';
import { getText, useAppDownloadActions } from '../AppDownloadDrag/redux';
import { useSecureNote } from '../AppDownloadDrag/securityAlter';
import { ReactComponent as Img } from '../AppDownloadDrag/icons/placeholder.svg';
import { AppNameOptionList } from '../AppDownloadDrag/AppDownload';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';

export interface AppDownloadProps extends Omit<ButtonProps, 'jumpLink'> {
    linkType: 2 | 3 | 4;
    appInfo?: {
        appId: number;
        accountId: number;
        appVersion: string;
        appName: string;
        packageName: string;
        sysPackageName: null;
        packageSize: number;
        url: string;
        appIconUrl: string;
        h5App: boolean;
        platform: string;
        virusScore: number;
        updateTime: number;
        date: number;
        appScore: number;
        iosAppId: null;
        useSdk: boolean;
        downloadCount: number;
    };
    quickAppLink?: string;
    iOSLink?: string;
    lbpBizId?: BizId;
    index?: number;
    buttonInfo?: string;
    imgLink?: string;
    appName?: string;
    appNameStyle?: {
        fontSize?: number;
        color?: string;
    };
    appDesc?: string;
    appDescStyle?: {
        fontSize?: number;
        color?: string;
    };
    deepLink?: string;
}

/**
 * @param text 默认展示的文本
 * @param id
 */
export const useProgress = (text: string, id?: string, compType?: string) => {
    const { downLoadProgress, clickedId } = useRenderState((state) => {
        return {
            downLoadProgress: state.appDownload?.downLoadProgress,
            clickedId: state.appDownload?.clickedId,
        };
    });
    const status = downLoadProgress?.status;
    const content = getText(downLoadProgress);
    let buttonText = text;
    if (compType === 'appdownload') {
        buttonText = AppNameOptionList.includes(text) ? text : AppNameOptionList[0];
    }
    if (typeof content === 'string') {
        buttonText = content;
    }
    if (status === 2) {
        // 多个下载按钮，只有被点击的展示进度。图片不传id即可
        if (id !== clickedId) {
            buttonText = '下载中';
        } else {
            buttonText = `${Number(Number(content) * 100).toFixed(0)}%`;
        }
    }
    return {
        buttonText,
        status,
        clickedId,
    };
};

const AppDownload: React.FunctionComponent<AppDownloadProps> = (props) => {
    const appState = useSelector((s) => s);
    const {
        appInfo,
        linkType,
        quickAppLink,
        text,
        children,
        iOSLink,
        index,
        imgLink = '',
        appName,
        buttonInfo,
        appDesc,
        appNameStyle,
        appDescStyle,
        bottom,
        deepLink,
        ...rest
    } = props;
    const { id, preview, edit } = useComContext();
    const actions = useAppDownloadActions();

    useSecureNote(styles, preview, appState);

    useMount(() => {
        actions.setModelState({ hasAppDownload: true });
        if (!preview) {
            actions.init({ appInfo });
        }
    });
    const onClick = useCallback(() => {
        actions.openOrDownload({
            type: 1,
            appInfo,
            linkType,
            quickAppLink,
            id,
            iOSLink,
            deepLink,
        });
    }, [actions, appInfo, iOSLink, id, linkType, quickAppLink, deepLink]);

    const { buttonText, status } = useProgress(
        index === 5 ? buttonInfo ?? '' : text,
        id,
        linkType !== 4 ? 'appdownload' : '',
    );
    let shadow: any = null;

    if (status === 2) {
        shadow = <div className={styles.shadow} style={{ width: buttonText }} />;
    }
    const commonRest = index === 5 ? { ...rest } : { ...rest, bottom };
    return (
        <>
            {index === 5 ? (
                <div
                    className={cs(styles.appDownloadInfo, { [styles.bottom]: bottom })}
                    style={{ backgroundColor: rest.backgroundColor }}
                    onClick={() => {
                        onClick();
                    }}
                >
                    {imgLink ? (
                        <div
                            className={styles.appIconInfo}
                            style={{
                                backgroundImage: `url(${imgLink})`,
                            }}
                        />
                    ) : (
                        // <div className={cs(styles.appIconInfo)} style={{ backgroundImage: `url(${imgLink})` }} />
                        // <img src={imgLink} alt="" className={cs(styles.appIconInfo)} />
                        <Img style={{ height: '48px', width: '48px' }} />
                    )}

                    <div className={cs(styles.appInfo)}>
                        <p style={appNameStyle} className={cs(styles.appName)}>
                            {appName}
                        </p>
                        <p style={appDescStyle} className={cs(styles.appDesc)}>
                            {appDesc}
                        </p>
                    </div>

                    <Button
                        className={cs({ [styles.progress]: status === 2 })}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...commonRest}
                        text={buttonText}
                        // 避免button组件打form-submit打点
                        onClick={() => {}}
                    >
                        {shadow}
                        {children}
                    </Button>
                    {/* placeholder本应收口到Button中  */}
                    {bottom && (
                        <>
                            <BlockPlaceholder pos="BOTTOM" preview={edit} />
                            <SafeAreaPlace />{' '}
                        </>
                    )}
                </div>
            ) : (
                <Button
                    className={cs({ [styles.progress]: status === 2 })}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonRest}
                    // {...backgroundColor}
                    onClick={onClick}
                    text={buttonText}
                >
                    {shadow}
                    {children}
                </Button>
            )}
        </>
    );
};

export default AppDownload;
