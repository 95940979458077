import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './style.module.less';

interface Iprops {
    show?: boolean;
    children: any;
    // title: string;
    // onClose: () => void;
}

export default function Modal(props: Iprops) {
    const [el] = useState(document.createElement('div'));

    useEffect(() => {
        document.body.classList.add('modal-no-scroll');
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
            document.body.classList.remove('modal-no-scroll');
        };
    }, [el]);

    return ReactDOM.createPortal(
        <div onClick={(e) => e.stopPropagation()}>
            <div id="container" className={styles.modalContainer}>
                {props.children}
            </div>
        </div>,
        el,
    );
}
