/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import AppDownload from '../AppDownloadDrag';
import Modal from '../../common/components/Modal';
import { LINK_TYPE } from '../AppDownloadDrag/redux';
import styles from './style.module.less';
import close from './close.png';
import { AnyObj } from 'omega-render';

interface PermissionInfoProps {
    permissions: {
        permissionName: string;
        permissionDetail: string;
    }[];
    color: string;
    buttonStyle?: React.CSSProperties;
    appInfo?: any;
    linkType?: LINK_TYPE;
    showDownloadBtn?: boolean;
    text?: string;
    style?: AnyObj;
    fontSize?: number;
}

export default function PermissionInfoModal(props: PermissionInfoProps) {
    const [show, setShow] = useState(false);
    const {
        permissions,
        buttonStyle,
        appInfo,
        linkType,
        color,
        showDownloadBtn = true,
        text = '权限详情',
        style,
        fontSize = 16,
    } = props;
    const newStyle: React.CSSProperties = Object.assign(
        {},
        {
            color,
            textAlign: 'left',
            fontSize: fontSize,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        style,
    ) as React.CSSProperties;
    return (
        <>
            <div style={newStyle} onClick={() => setShow(true)}>
                {text}
            </div>
            {show && (
                <Modal
                    style={{
                        zIndex: 999999,
                    }}
                >
                    <div className={styles.modelContainer}>
                        <div className={styles.titleWrapper}>
                            <img
                                src={close}
                                onClick={() => setShow(false)}
                                alt=""
                                data-target="formClose"
                            />
                            权限详情
                        </div>
                        <div className={styles.contentWrapper}>
                            {permissions?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <span>{index + 1}:</span>

                                        <span>{item.permissionDetail}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.opWrapper}>
                            {showDownloadBtn && (
                                <AppDownload
                                    linkType={linkType as LINK_TYPE}
                                    appInfo={appInfo}
                                    buttonStyle={{
                                        ...buttonStyle,
                                        // color: style.tagColor,
                                        // border: `solid 1px ${style.tagColor}`,
                                        width: 327,
                                        height: 44,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#FE3666',
                                        color: 'white',
                                        borderRadius: 24,
                                        fontSize: 16,
                                    }}
                                    text="立即下载"
                                />
                            )}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}
