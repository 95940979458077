/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import compareVersions from 'compare-versions';
// import { useLocation } from 'wouter';
import {
    IMessageRegionView,
    IStoreDetailView,
    IStoreNewView,
    IStoreView,
} from 'landingPage/common/api/interface';
import { useComContext, useRenderState } from 'omega-render';
import loadable from '@loadable/component';
import { invoke } from '@yoda/bridge';
import {
    getStoreUsingPOST,
    storeDetailUsingPOST,
    storeGroupInfoUsingPOST,
    storeInfoUsingPOST,
    storeListUsingPOST,
} from 'landingPage/common/api';
import { getLocationCity, ILocation } from '../../common/utils/getLocation';
import { getUrlParam, paramsToUrl } from '../../common/utils/getUrlParam';
import { getPageId } from '../../common/adTrack/util/sendData';
import { getClientOSType } from '../Map/Map';
import {
    STORE_LOAD_STAG,
    trackStoreLoad,
    trackStoreLocError,
    trackStoreLocGPS,
    trackStoreLocIP,
    trackUpdateLocation,
} from '../../common/adTrack/monitor';
import { getStoreState, setStoreState, STORE_COMPONENT_TYPE } from './utils/locStore';
import Modal from './Modal';
import { STORE_TYPE } from './hooks/useNewStoreList';
import StoreRichStyle, { RichStoreDetail } from './StoreRichStyle';
import StoreLightStyle from './StoreLightStyle';
import { NewGetStoreFunc, OldGetStoreFunc, useAdaptStoreList } from './hooks/useAdaptStoreList';
// import { IStoreNewView } from 'landingPage/services/models';

const trackStateTime = {
    /** 首次加载时间 */
    initStart: 0,
    /** 获取到门店详情时间 */
    getDetailEnd: 0,
    first: true,
};
const StoreList = loadable(async () => import('./StoreList'));
const CityList = loadable(async () => import('./CityList/index'));

export enum IPosition {
    BASE = 'base',
    TOP = 'topBlank',
    BOTTOM = 'bottomBlank',
    EX_TOP = 'TOP',
    EX_BOTTOM = 'BOTTOM',
}

const IPath = {
    STORE: 'STORE',
    STORE_LIST: 'STORE_LIST',
    CITY_LIST: 'CITY_LIST',
};

export interface RichStore extends IStoreDetailView, IStoreView {}

const pathName = {
    [IPath.STORE_LIST]: '/storeList',
    [IPath.CITY_LIST]: '/cityList',
    [IPath.STORE]: '/',
};

export enum DisplayType {
    /** 简约样式 */
    LIGHT,
    /** 丰富样式 */
    RICH,
}
export interface newStoreProps {
    position: IPosition;
    funcId?: string;
    version: string;
    storeType: STORE_TYPE;
    clueAccountId: number;
    /** 标识是否是门店页中的门店组件 */
    page?: boolean;
    /**
     * 门店组件样式，老组件不存在，默认值为0
     * 0: 简约样式
     * 1: 丰富样式
     * */
    displayType: DisplayType;
    /** 新门店包id，仅当storeType === 1时，有值 */
    packageId: string;
    /** 门店id，仅当storeType === 2时，有值 */
    storeId: string;
    style?: {
        /** 门店名称字号 */
        nameSize?: number;
        /** 门店名称字色 */
        nameColor?: string;
        /** 门店地址字号 */
        addressSize?: number;
        /** 门店地址字色 */
        addressColor?: string;
        /** 导航icon颜色 */
        iconColor?: string;
        /** 组件高度 */
        height?: number;
    };
    /** 组件样式，仅当门店类型为丰富样式的时候存在 */
    richStyle?: {
        /** 门店名称字号 */
        nameSize?: number;
        /** 门店名称字色 */
        nameColor?: string;
        /** 标题名称字号 */
        titleSize?: number;
        /** 标题名称字色 */
        titleColor?: string;
        /** 门店地址字号 */
        addressSize?: number;
        /** 门店地址字色 */
        addressColor?: string;
        /** 导航icon颜色 */
        iconColor?: string;
        /** 背景颜色 */
        bgColor?: string;
        /** 组件高度 */
        height?: number;
    };
    /** 提供给门店页组件使用，传递门店详情 */
    onStoreDetailChange?: (detail: RichStoreDetail) => void;
}

export enum LOC_ERROR {
    NO_CITY,
    NO_DEFAULT_STORE,
    INIT,
}

export enum LIST_STATUS {
    /** 1:定位失败，2:同城市推荐，3:同省份推荐，4:无推荐 */
    LOCATE_ERROR = 1,
    SAME_CITY = 2,
    SAME_PROVINCE = 3,
    NO_RECO = 4,
}

export enum STORE_LOC_STATUS {
    UNKNOWN = '0',
    GPS = '1',
    IP = '2',
    ERROR = '3',
}

function transformSampleStore(store: IStoreNewView): RichStoreDetail {
    return {
        id: store.storeId,
        name: store.storeName,
        address: store.storeAddress,
        pics: store.merchantPics,
        iccId: '',
        poiInfo: {
            lat: '',
            lon: '',
            city: 0,
            address: store.storeAddress,
            area: 0,
            storeName: '',
            provinceCode: 0,
        },
        lat: '',
        lon: '',
        ...store,
        members: store.members.map((item) => {
            return {
                head: item.headImg,
                name: item.nickName,
                tag: item.workExperience,
                title: item.position,
                order: 0,
            };
        }),
        goodsList: store.goods.map((item) => {
            return {
                goodsDesc: item.desc,
                goodsName: item.goodName,
                goodsPic: item.coverImg,
                order: 0,
            };
        }),
    };
}

const Store: React.FunctionComponent<newStoreProps> = (props) => {
    const {
        funcId,
        position,
        version,
        displayType = DisplayType.LIGHT,
        style,
        storeType,
        storeId,
        richStyle,
        clueAccountId,
        onStoreDetailChange,
        page,
    } = props;
    let { packageId } = props;
    const isNewVersion = compareVersions(version || '1.0.0', '2.0.0') === 1;
    if (!isNewVersion) {
        packageId = funcId ?? '';
    }

    const iconColor = style?.iconColor ?? richStyle?.iconColor ?? '';

    /** 获取定位和门店列表的状态 */
    const [locStatus, setLocStatus] = useState(STORE_LOC_STATUS.UNKNOWN);
    const [locError, setLocError] = useState(LOC_ERROR.INIT);
    const [coords, setCoords] = useState<ILocation>();
    const [, setCity] = useState<IMessageRegionView>();
    /** 是否展示授权弹窗 */
    const [, setShowRequestModal] = useState(false);
    const [currentPath, setCurrentPath] = useState(IPath.STORE);
    /* 获取定位是否完成 */
    const [located, setLocated] = useState(false);
    /* 组件是否在编辑器中 */
    const { edit, preview, type: compType } = useComContext();
    /* [推荐门店，其他门店，拉取门店方法] */
    const [recoStoreList, otherStoreList, getStoreList, , , defaultStore] =
        useAdaptStoreList(isNewVersion);

    const pageTitle = useRenderState((state) => {
        const pageTitle = state.pageInfo?.pageTitle as string;
        return pageTitle;
    });
    useEffect(() => {
        // 设置webview title
        invoke('webview.setPageTitle', {
            title: pageTitle || '',
            titleBackgroundColor: '#f8f8f8',
        });
    }, [pageTitle, window.location.hash]);
    const getLocationAndCity = useCallback(async function getLocationAndCity(
        force?: boolean,
        disableRequest?: boolean,
    ) {
        // await importBridge();
        const res = await getLocationCity(force, disableRequest);
        return res;
    },
    []);
    /** 用户已选择的门店, 简单数据，只包含id，名称等，之前给light样式私用 */
    const [storeUserSelected, setStoreUserSelected] = useState<IStoreView | undefined>(
        getStoreState<'storeSelected'>('storeSelected'),
    );
    useEffect(() => {
        setStoreUserSelected(getStoreState<'storeSelected'>('storeSelected'));
    }, [currentPath]);
    // const storeUserSelectDetail = useMemo(() => {}, []);

    const storeAutoSelected = useMemo<IStoreView | undefined>(() => {
        if (page) {
            // 门店页自动选择逻辑
            if (recoStoreList?.length) {
                return recoStoreList[0];
            }
            if (otherStoreList?.length) {
                return otherStoreList[0];
            }
            return defaultStore;
        }
        /** 门店自动选择逻辑
         *  自动选择门店：推荐门店或者其他门店列表第一位,
         *  v2: 不再自动选择'其他门店'第一位,
         *  不在自动选择没有经纬度的门店
         * */
        if (recoStoreList?.length && coords?.latitude) {
            return recoStoreList[0];
        }
    }, [coords?.latitude, defaultStore, otherStoreList, page, recoStoreList]);
    useEffect(() => {
        /** 判断不自动选择门店的情况
         * 1. 没有list门店列表
         * 2. 有list门店列表，但是没有经纬度
         */
        const noDefaultStore =
            !recoStoreList?.length || (recoStoreList?.length && coords?.latitude);
        if (noDefaultStore && locError !== LOC_ERROR.NO_CITY) {
            setLocError(LOC_ERROR.NO_DEFAULT_STORE);
        }
    }, [recoStoreList?.length, locError, coords?.latitude]);

    /** 优先选择，用户选择门店，如无，选择自动选择门店 */
    const storeSelected = useMemo(
        () => storeUserSelected || storeAutoSelected,
        [storeAutoSelected, storeUserSelected],
    );
    useEffect(() => {
        /* 同步选择门店到store中存储 */
        const store = getStoreState<'storeSelected'>('storeSelected');
        // 判断是选择门店否已存，或者存的信息不完整，防止重复存
        if (store?.id !== storeSelected?.id || !store?.name) {
            console.log('[StoreStore] 同步选择门店到store中存储', storeSelected);
            setStoreState({
                storeSelected,
            });
        }
    }, [storeSelected]);
    const locate = useCallback(
        async (force?: boolean, disableRequest?: boolean) => {
            setStoreState({
                packageId,
            });
            let locStatus = STORE_LOC_STATUS.UNKNOWN;
            const locationStart = Date.now();
            const [city, location] = await getLocationAndCity(force, disableRequest);
            const locationEnd = Date.now();
            trackStoreLoad(STORE_LOAD_STAG.getLocationAndCity, locationEnd - locationStart);
            console.log(
                `[duration]:[getLocationAndCity]:${
                    locationEnd - locationStart
                }，start${locationStart}`,
            );
            // 当重定位失败的时候，不更新
            if (!location && coords?.latitude) {
                return;
            }
            if (location) {
                // 获取经纬度，GPS成功
                setCoords(location);
                locStatus = STORE_LOC_STATUS.GPS;
                trackStoreLocGPS();
            }
            if (city) {
                setCity(city);
                if (!location) {
                    // 通过IP定位成功
                    locStatus = STORE_LOC_STATUS.IP;
                    trackStoreLocIP();
                }
            }
            // 如果获取不到城市信息，弹窗请求定位权限
            if (!city && !preview) {
                // 定位失败
                setLocError(LOC_ERROR.NO_CITY);
                setShowRequestModal(true);
                locStatus = STORE_LOC_STATUS.ERROR;
                trackStoreLocError();
            }
            setLocStatus(locStatus);
            /* 保存地理信息 */
            setStoreState({
                loc: {
                    cityId: city?.id,
                    cityName: city?.name,
                    lat: `${location?.latitude}`,
                    lon: `${location?.longitude}`,
                },
                packageId,
                locStatus,
            });
            setLocated(true);
            const newLocation = {
                latitude: `${location?.latitude}`,
                longitude: `${location?.longitude}`,
            };
            if (isNewVersion) {
                await (getStoreList as NewGetStoreFunc)({
                    type: storeType,
                    coords: newLocation,
                    city: city?.id,
                    packageId,
                    clueAccountId,
                });
            } else {
                await (getStoreList as OldGetStoreFunc)({
                    coords: newLocation,
                    city: city?.id,
                    packageId,
                });
            }
        },
        [
            clueAccountId,
            coords?.latitude,
            getLocationAndCity,
            getStoreList,
            isNewVersion,
            packageId,
            preview,
            storeType,
        ],
    );
    /** 重新定位 */
    const updateLocation = useCallback(
        (e) => {
            e?.stopPropagation();
            locate(true);
            trackUpdateLocation(locStatus);
        },
        [locStatus, locate],
    );
    useEffect(() => {
        // 监控可见性，当用户去修改系统定位权限回来的时候重新定位
        function handler() {
            // 编辑态下，不需要定位
            if (edit) return;
            // 单门店不需要定位
            if (storeType === STORE_TYPE.SINGLE) return;
            // 用户打开或回到页面
            if (document.visibilityState === 'visible') {
                locate(false, true);
            }
        }
        document.addEventListener('visibilitychange', handler);
        return () => {
            document.removeEventListener('visibilitychange', handler);
        };
    }, []);
    const [storeSelectedDetail, setStoreSelectedDetail] = useState<RichStoreDetail>();
    /** 编辑态下获取展示门店 */
    const getSampleStore = useCallback(async () => {
        let sampleStoreRes;
        if (storeType === STORE_TYPE.PACKAGE) {
            if (!packageId) return;
            const res = await storeGroupInfoUsingPOST({
                groupId: packageId,
            });
            sampleStoreRes = res.sampleStore;
        } else if (storeType === STORE_TYPE.SINGLE) {
            if (!storeId) return;
            const res = await storeInfoUsingPOST({
                storeId,
            });
            sampleStoreRes = res;
        } else if (storeType === STORE_TYPE.ALL) {
            // if (storeSelectedDetail?.id) return;
            const res = await storeListUsingPOST({
                req: {
                    keyWord: '',
                    pageNum: 1,
                    pageSize: 1,
                    totalNum: 1,
                    type: 1,
                },
            });
            console.log('storeListUsingPOST', res);
            // eslint-disable-next-line prefer-destructuring
            sampleStoreRes = res.storeInfo.list[0];
        }
        return sampleStoreRes;
    }, [packageId, storeId, storeType]);
    useEffect(() => {
        // 老版门店组件编辑器获取sampleStore
        if (!edit) return;
        async function oldGetSample() {
            if (!isNewVersion) {
                if (!packageId) return;
                const res = await getStoreUsingPOST({
                    packageId,
                });
                setStoreUserSelected({
                    name: res?.storeDetail?.storeName,
                    address: res?.storeDetail?.storeAddress,
                    id: '',
                    lat: '',
                    lon: '',
                });
            }
        }
        oldGetSample();
    }, [edit, isNewVersion, packageId]);
    useEffect(() => {
        /** 编辑态下，根据storeId获取门店详情 */
        async function updateSampleDetail() {
            if (!edit) return;
            if (!isNewVersion) return;
            const sampleStore = await getSampleStore();
            if (!sampleStore) return;
            const transformedSampleStore = transformSampleStore(sampleStore);
            setStoreUserSelected(transformedSampleStore);
            setStoreSelectedDetail(transformedSampleStore);
            onStoreDetailChange?.(transformedSampleStore);
        }
        updateSampleDetail();
    }, [edit, getSampleStore, isNewVersion]);
    useEffect(() => {
        /** C端，根据storeId获取门店详情 */
        async function getStoreDetail() {
            if (!storeSelected?.id) return;
            if (storeSelected.id === storeSelectedDetail?.id) return;
            if (
                isNewVersion &&
                (displayType === DisplayType.RICH ||
                    (displayType === DisplayType.LIGHT && !storeSelected.name)) &&
                !edit
            ) {
                const storeDetail = await storeDetailUsingPOST({
                    param: { storeId: storeSelected.id },
                });
                if (trackStateTime.first) {
                    trackStateTime.first = false;
                    trackStateTime.getDetailEnd = Date.now();
                    trackStoreLoad(
                        STORE_LOAD_STAG.totalGetDetail,
                        trackStateTime.getDetailEnd - trackStateTime.initStart,
                    );
                }
                if (storeDetail.storeId) {
                    const detail = {
                        ...storeSelected,
                        ...storeDetail,
                        id: storeDetail.storeId,
                        name: storeDetail.storeName,
                        address: storeDetail.poiInfo.address,
                        lon: storeDetail.poiInfo.lon,
                        lat: storeDetail.poiInfo.lat,
                    };
                    setStoreSelectedDetail(detail);
                    setStoreUserSelected(detail);
                    onStoreDetailChange?.(detail);
                }
            }
        }
        getStoreDetail();
    }, [
        displayType,
        edit,
        isNewVersion,
        onStoreDetailChange,
        storeSelected,
        storeSelectedDetail?.id,
    ]);
    useEffect(() => {
        /* bridge获取经纬度 => 接口通过经纬度获取所在城市 => 接口获取门店列表 */
        const init = async () => {
            trackStateTime.initStart = Date.now();
            if (page) {
                setStoreState({ componentType: STORE_COMPONENT_TYPE.STORE_PAGE });
            } else {
                setStoreState({ componentType: STORE_COMPONENT_TYPE.STORE });
            }
            if (!edit) {
                // 单门店下直接选择，无需定位
                if (storeType === STORE_TYPE.SINGLE) {
                    setStoreUserSelected({
                        id: storeId,
                        name: '',
                        address: '',
                        lat: '',
                        lon: '',
                    });
                } else {
                    locate();
                }
            } else {
                /* 落地页编辑态，无需定位，使用建站接口直接获取 */
                /* 落地页编辑态，无需定位，使用建站接口直接获取 */
                // if (!isNewVersion) {
                //     console.log('packageId hhh', packageId)
                //     if (!packageId) return;
                //     const res = await getStoreUsingPOST({
                //         packageId,
                //     });
                //     setStoreUserSelected({
                //         name: res?.storeDetail?.storeName,
                //         address: res?.storeDetail?.storeAddress,
                //         id: '',
                //         lat: '',
                //         lon: '',
                //     });
                // }
            }
        };
        init();
        return () => {
            if (edit) {
                /* 落地页编辑态，删除门店组件时，清除门店选择 */
                setStoreState({
                    storeSelected: undefined,
                });
            }
        };
    }, []);
    /** 跳转native地图 */
    const goToMap = useCallback((store: IStoreView) => {
        if (!store) return;
        const urlBase = 'kwai://map/nav';
        // 这里必须传callback
        const callback = getUrlParam('callback') || 'preview';
        const params = {
            title: store.name,
            address: store.address,
            longitude: store.lon,
            latitude: store.lat,
            lpPageId: getPageId(),
            callback,
        };

        const openFrom = JSON.stringify({
            url: window.location.href,
            from: getClientOSType().toLowerCase(),
        });
        const param = { ...params, openFrom };
        const url = `${urlBase}?${paramsToUrl(param)}`;

        window.location.href = url;
    }, []);
    const goToStoreLIst = useCallback((e?: React.MouseEvent) => {
        e?.stopPropagation();
        window.location.hash = '/storeList';
    }, []);
    useEffect(() => {
        const handler = () => {
            const { hash } = window.location;
            switch (true) {
                case hash.includes(pathName[IPath.STORE_LIST]):
                    setCurrentPath(IPath.STORE_LIST);
                    return;
                case hash.includes(pathName[IPath.CITY_LIST]):
                    setCurrentPath(IPath.CITY_LIST);
                    return;
                default:
                    setCurrentPath(IPath.STORE);
            }
        };
        window.addEventListener('hashchange', handler);
        return () => window.removeEventListener('hashchange', handler);
    }, []);
    return (
        <div data-component-type={compType}>
            {displayType === DisplayType.LIGHT && (
                <StoreLightStyle
                    position={position}
                    style={style ?? {}}
                    edit={edit}
                    storeSelected={storeSelected}
                    // storeSelectedPoi={poi}
                    locError={locError}
                    goToStoreLIst={goToStoreLIst}
                    located={located}
                    storeType={storeType}
                    goToMap={goToMap}
                    updateLocation={updateLocation}
                />
            )}
            {displayType === DisplayType.RICH && (
                <StoreRichStyle
                    storeType={storeType}
                    position={position}
                    style={richStyle ?? {}}
                    edit={edit}
                    storeSelected={storeSelectedDetail}
                    // storeSelectedPoi={poi}
                    locError={locError}
                    goToStoreLIst={goToStoreLIst}
                    located={located}
                    goToMap={goToMap}
                    updateLocation={updateLocation}
                />
            )}
            {currentPath === IPath.STORE_LIST && (
                <Modal>
                    <StoreList
                        iconColor={iconColor}
                        newVersion={isNewVersion}
                        clueAccountId={clueAccountId}
                        storeType={storeType}
                    />
                </Modal>
            )}
            {currentPath === IPath.CITY_LIST && (
                <Modal>
                    <CityList />
                </Modal>
            )}
        </div>
    );
};

export default Store;
