/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { ClueForm, IAllFormJson, IClueForm } from '@ad/xiansuoSDK';
import { Toast } from '@ad/sharpui';
import { DIALOG_STYLE, useComContext, useRenderState, WeChatType } from 'omega-render';
import { getPageId } from 'landingPage/common/utils/getPageId';
import { getXianSuoDomain } from 'landingPage/common/utils/getXianSuoDomain';
import { trackClick, trackFormAutoPhoneStatus } from 'landingPage/common/adTrack/util/zt';
import { isBeta, isProduction, isSSR, isSSRHydrate, isStaging } from 'landingPage/common/utils/env';
import { trackEvent, trackSkipStepFormApi } from 'landingPage/common/adTrack/monitor';
import { IFormInfoView } from '../../services/models';
import '@ad/xiansuoSDK/lib/index.css';
import { Div } from '../Blank/Blank';
import styles from '../XiansuoFormDrag/style.module.less';
import { SubmitTurnValue, WeiXinModal, DialogTypeEnum } from '../XiansuoFormDrag/XiaoSuoModal';
import {
    transformDialogStyleType,
    transformFormSetting,
} from '../XiansuoFormDrag/transformFormSetting';
import {
    assertRequiredStore,
    doTrackStoreSubmit,
    getOtherSubmitData,
} from '../XiansuoFormDrag/XiansuoForm';
import FormUserAgreement from '../XiansuoFormDrag/components/FormUserAgreement';
import { ImComponent, SelectItem } from './components/IMComponent';
import { jumpHkzs, jumpWxkf } from '../WeiXinDrag/util';
import { signApi } from 'landingPage/common/utils/encrypt';
import { getCallbackInfo, getComponentType } from 'landingPage/common/adTrack/util/sendData';
import { wechatActionSence, wechatDataTrack } from '../WeiXinDrag/components/commonFun';
import { PopupType, WechatActionSceneEnum, WeiXinOpt } from '../WeiXinDrag/WeiXin';
import { useStepQYWXSwitch } from 'landingPage/common/utils/firefly';
import { useAbHooks } from '../WeiXinDrag/hooks/useAbHooks';
import { getEnterPageTimeStamp } from '@/landingPage/common/weblog';
import { getPageJson } from '@/landingPage/common/utils/fetchCompPropsInPageJson';
import { getFormComponentType } from 'omega-shared/utils';
import { useTheme } from '@/landingPage/common/utils/useTheme';
import { invoke } from '@yoda/bridge';
import { LaunchTypeEnum } from '../WeiXinDrag/components/JumpWxKefu';
import { needSendEffectiveAction } from './utils';

export interface MessageExpand {
    label?: string;
    options?: { id: string; name: string; message?: string; exitQuestion?: boolean }[];
}
export interface Message {
    id: string;
    value?: string;
}
export function getOptions(formOpt?: FormOpt) {
    if (isFenBu(formOpt)) {
        return formOpt?.components
            ?.filter((component) => component.componentType === 'NORMAL_SINGLE_SELECT')
            ?.map((component) => {
                return {
                    ...component.desc,
                    componentId: (component as any).id,
                } as DialogueSettingData;
            });
    }
    return [] as DialogueSettingData[];
}

export function isFenBu(formOpt?: FormOpt) {
    return formOpt?.tag === 'STEP_FORM';
}

export interface AccountSelectProps {
    // 微信类型
    packType?: 1 | 2;
    // 1 是单个微信 2是号码包
    wechatType?: WeChatType | undefined;
    onChange?: (
        param: Pick<
            AccountSelectProps,
            | 'wechatType'
            | 'opt'
            | 'funcId'
            | 'dialogStyle'
            | 'dialogStyleType'
            | 'applets'
            | 'appletSwitchState'
            | 'info'
        >,
    ) => void;
    dialogStyle?: DialogTypeEnum | undefined;
    opt?: {
        id?: string;
        wechatId?: string;
        wechatNickname?: string;
        wechatAppId?: string;
        wechatType?: WeChatType;
        packId?: string;
        isDel?: boolean;
        deepLink?: string;
    };
    funcId?: string;
    type?: 1;
    // 分布表单
    isFenbuBiaodan?: boolean;
    error?: string;
    // 是否需要弹框样式设置
    needModalStyle?: boolean;
    // 点击弹框样式设置回调
    onClickModalStyle?: () => void;
    tooltip?: string;
    dialogStyleType?: DIALOG_STYLE;
    applets?: { appletsId: string; callback: string }; // 微信小程序的信息
    appletSwitchState?: {
        checked: boolean;
        disabled: boolean;
    }; // 小程序开关的状态
    info?: string;
}

export interface FenBuComponent {
    componentType: 'NORMAL_SINGLE_SELECT';
    desc?: SelectDesc;
}

export interface FormOpt {
    components?: FenBuComponent[];
    tag?: string;
}

export interface SelectDesc {
    label?: string;
    options?: { id: string; name: string; exitQuestion?: boolean; ifValid?: boolean }[];
    componentId: string;
}

export type DialogueSettingData = SelectDesc;

export enum SubstepFormFollowActionType {
    WECHAT = 'wechat',
    FORM = 'form',
}

export enum ChatStyleTypeEnum {
    FullScreen = 0,
    InsertScreen = 1,
}

export interface XiansuoFormProps {
    showLabel: 'show' | 'hidden';
    theme?: string;
    funcId?: string;
    formOpt: IFormInfoView;
    isPreview?: boolean;
    formSetting?: any;
    // 分布表单自定义样式类型
    customStyle?: 0 | 1;
    mainTitleStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    formStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    optionStyle?: React.CSSProperties;
    optionActiveStyle?: React.CSSProperties;
    // 提交按钮样式
    submitStyle?: React.CSSProperties;
    placeholderColor?: string;
    /** 微信信息 开始 */
    wechatFuncId?: AccountSelectProps['funcId'];
    wechatType?: AccountSelectProps['wechatType'];
    wechatOpt?: AccountSelectProps['opt'];
    wechatInfo?: string;
    applets?: AccountSelectProps['applets'];
    appletSwitchState?: AccountSelectProps['appletSwitchState'];
    dialogStyle?: AccountSelectProps['dialogStyle'];
    dialogStyleType?: AccountSelectProps['dialogStyleType'];
    /** 微信信息 结束 */
    // 欢迎语
    welcome?: Message[];
    // 结束语
    end?: Message[];
    // 客服头像
    serviceAvatarUrl?: string;
    // 客服昵称
    serviceNickname?: string;
    // 辅助消息列表
    messageExpand?: MessageExpand[];
    // 开始阶段，分布表单的按钮文案
    startButtonInfo?: string;
    /** 分布表单自定义样式 类型定义 结束 */
    showCustomPrivacyAgreement?: boolean;
    // 分布表单单行选项个数
    optionLineNum?: number;
    privacyTextColor?: string; //隐私协议文本颜色
    previewSubstepFollowForm?: boolean; //分步表单附加表单预览
    chatStyleType?: ChatStyleTypeEnum; //嵌入式样式能力
    /** 是否有按钮动效 */
    buttonMotion?: boolean;
    /** 按钮形状 */
    optionShape?: 'circle' | 'square';
    formVersion?: number;
}
export const formClass = 'com-form';
const XiansuoForm: React.FunctionComponent<XiansuoFormProps> = (props) => {
    const enableStep = useStepQYWXSwitch();
    const {
        formOpt,
        showLabel,
        // theme,
        customStyle,
        children,
        funcId,
        formSetting,
        welcome,
        end,
        serviceAvatarUrl,
        serviceNickname,
        messageExpand,
        wechatInfo,
        wechatOpt,
        wechatType,
        startButtonInfo,
        dialogStyle,
        dialogStyleType,
        applets,
        appletSwitchState,
        mainTitleStyle,
        titleStyle,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        submitStyle,
        showCustomPrivacyAgreement,
        optionLineNum,
        optionShape,
        buttonMotion,
        privacyTextColor,
        previewSubstepFollowForm,
        placeholderColor,
        chatStyleType,
        formVersion,
    } = props;

    const { color: theme } = useTheme({
        path: 'theme',
    });

    const formCommitInfoAutoFill = useRenderState(
        (state) => state.rolex.opt?.originalPageJson?.pageMeta?.formCommitInfoAutoFill,
    );
    const [selectFormItemList, setSelectFormItemList] = React.useState<SelectItem[]>([]);

    const pageId = getPageId();
    // const pageName = document.querySelector('title')?.innerHTML;
    const { edit, preview } = useComContext();
    const [show, setShow] = React.useState(false);
    const [fenbuOptions, setFenbuOptions] = React.useState<SelectDesc[]>([]);
    const isFenbuBiaodan = isFenBu(formOpt as unknown as FormOpt);
    const abData = useAbHooks();
    React.useEffect(() => {
        if (formOpt) {
            const options = getOptions(formOpt as unknown as FormOpt);
            if (options) {
                setFenbuOptions(options);
            }
        }
    }, [formOpt]);
    // const extraInfo = {
    //     pageId,
    //     getOtherSubmitData,
    //     sourceDesc: {
    //         // pageName: pageName ? pageName : '无标题页面',
    //     },
    // };
    const { color: activeBackground } = useTheme({
        path: 'optionActiveStyle.backgroundColor',
        options: {
            type: 'activeFill',
        },
    });
    const { color: activeBorderColor } = useTheme({
        path: 'optionActiveStyle.borderColor',
        options: {
            type: 'border',
        },
    });
    const { color: activeColor } = useTheme({
        path: 'optionActiveStyle.color',
    });

    const { config, modalSetting, submitText } = React.useMemo(() => {
        if (!formOpt) {
            return {
                config: null,
            };
        }
        // if (isSSR) {
        //     return {
        //         ClueFormHoc: null,
        //     };
        // }
        if (!isProduction) {
            // console.log('formOpt:', formOpt);
        }
        const { realFormOpt, modalSetting, submitText } = transformFormSetting(
            formOpt,
            formSetting,
        );
        const cunstomAgreementSetting = formSetting?.privacy?.itemConfig;

        const config: IClueForm = {
            domConfs: {
                theme,
                isPreview: !!(!!edit || preview),
                hideProtocol: true,
                showLabel: showLabel === 'show',
                previewSubstepFollowForm,
                formTheme: {
                    mainTitleStyle,
                    titleStyle,
                    formStyle,
                    inputStyle,
                    optionStyle,
                    optionActiveStyle: {
                        ...optionActiveStyle,
                        backgroundColor: activeBackground,
                        borderColor: activeBorderColor,
                        color: activeColor,
                    },
                    submitStyle,
                    placeholderColor,
                },
                /** 自定义用户协议，插入到提交按钮之前 */
                customProtocolBefore: () => {
                    return (
                        <FormUserAgreement
                            showCustom={showCustomPrivacyAgreement}
                            hasCunstomContent={formSetting?.privacy?.switch}
                            {...cunstomAgreementSetting}
                            color={privacyTextColor || '#666'}
                            buttonColor={theme}
                        />
                    );
                },
                /** 自定义提交按钮文案 */
                customSubmitText: (origin) => `同意并${origin as string}`,
                customSuccessPage:
                    formOpt.substepFormFollowActionType !== SubstepFormFollowActionType.FORM,
                optionLineNum: optionLineNum ?? 2,
                optionShape: optionShape || 'square',
                buttonMotion: buttonMotion || false,
            },
            data: {
                type: 'sync',
                pageId,
                getOtherSubmitData,
                pubBizId: 1003,
                formJson: realFormOpt as IAllFormJson,
                callback: getCallbackInfo(),
                renderType: 'XIANSUO_FORM_DRAG',
                enterPageTime: getEnterPageTimeStamp(),
                formType:
                    getPageJson()?.pageMeta?.formComponentType ||
                    getFormComponentType(getPageJson())?.formComponentType,
                formVersion: (realFormOpt?.version || formVersion) as string,
                formCommitInfoAutoFill,
            },
            callBackHooks: {
                onAutoFillStatusChange(status) {
                    trackFormAutoPhoneStatus(status);
                },
                beforeSubmit: async (params) => {
                    let signRes = '';
                    try {
                        signRes = await signApi({
                            query: { sigCatVer: 1 },
                            body: JSON.stringify({ ...params.formSubmitParam }),
                            // cookie: document.cookie,
                        });
                    } catch (e) {}

                    return {
                        ...params,
                        __NS_sig3: signRes,
                    };
                },
                submitBtnClick: () => {
                    // console.log('提交成功');
                    // setShow(true);
                    trackClick('XIANSUO_FORM_BUTTON_CLICK');
                    trackEvent('XIANSUO_FORM_BUTTON_CLICK');
                    if (!assertRequiredStore()) {
                        Toast.info({
                            content: '提交失败！请选择门店',
                            className: styles.assertRequiredToast,
                        });
                        return false;
                    }
                    return true;
                },
                skipApiRequest: async () => {
                    if (!enableStep) {
                        return false;
                    }
                    const isQYWX = [
                        WeChatType.KEFU,
                        WeChatType.ASSISTANT,
                        WeChatType.ASSISTANT_GROUP,
                    ].includes(wechatType as WeChatType);
                    // 分布表单+企微，不请求上报表单接口
                    if (isFenbuBiaodan && isQYWX) {
                        trackSkipStepFormApi();
                        return true;
                    }
                    return false;
                },
                onSubmitSuccess: (value) => {
                    // CPL2场景，表单提交成功后向外层同步该状态
                    invoke('event.dispatchGlobalEvent', {
                        type: 'ad_consult_sku_clue_status',
                        data: JSON.stringify({ siteId: pageId }) as any,
                    });
                    // 不弹企微跳转弹窗
                    const directJumpQywx =
                        (wechatType === WeChatType.KEFU ||
                            wechatType === WeChatType.ASSISTANT ||
                            wechatType === WeChatType.ASSISTANT_GROUP) &&
                        abData.formPopupQywxStyle;
                    if (directJumpQywx) {
                        jumpQywx();
                    } else {
                        const formItemList = value?.results?.map((item) => {
                            return {
                                componentId: item.componentId,
                                value: item.value,
                            };
                        });
                        setSelectFormItemList(formItemList || []);
                        formOpt.substepFormFollowActionType !== SubstepFormFollowActionType.FORM &&
                            setShow(true);
                    }
                    doTrackStoreSubmit();
                    trackEvent('XIANSUO_FORM_SUBMIT_SUCCESS');
                    trackClick('XIANSUO_FORM_SUBMIT_SUCCESS');
                },
                onSubmitError: (err) => {
                    Toast.error(err.join(''));
                },
            },
            ssr: isSSR,
            env: isBeta ? 'beta' : isStaging ? 'test' : 'production',
            url: getXianSuoDomain().replace('https:', ''),
            componentType: getComponentType(),
        };

        return {
            config,
            modalSetting,
            submitText,
        };
    }, [
        formOpt,
        formSetting,
        theme,
        edit,
        preview,
        showLabel,
        mainTitleStyle,
        titleStyle,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        submitStyle,
        pageId,
        isFenbuBiaodan,
        optionLineNum,
        previewSubstepFollowForm,
        abData,
        enableStep,
    ]);
    const [reRender, setReRender] = React.useState<any>(false);
    React.useEffect(() => {
        // 强制reRender，解决hydrate表单初次渲染不触发styled-components render的问题
        if (isSSRHydrate) {
            setReRender(true);
            window.setTimeout(() => {
                setReRender(false);
            });
        }
    }, []);

    const isFenBuBiaodanMessage = isFenbuBiaodan && customStyle === 1;

    const { type } = useComContext();

    const jumpQywx = React.useCallback(
        async (formInfo?: SelectItem[]) => {
            const transformedDialogStyleType = transformDialogStyleType(
                dialogStyle,
                dialogStyleType,
                wechatType,
            );
            const launchType = LaunchTypeEnum[type] || LaunchTypeEnum.WECHAT_STAY;
            if (wechatType === WeChatType.KEFU) {
                jumpWxkf({
                    opt: wechatOpt as WeiXinOpt,
                    dialogStyleType: transformedDialogStyleType,
                    renderType: 'XIANSUO_FORM_DRAG',
                    formInfo,
                    formId: formOpt?.id,
                    launchType,
                });
            } else if (wechatType === WeChatType.ASSISTANT) {
                jumpHkzs({
                    opt: wechatOpt as WeiXinOpt,
                    dialogStyleType: transformedDialogStyleType,
                    renderType: 'XIANSUO_FORM_DRAG',
                    useSchema: Boolean(abData.qywxCustomerAcquisitionSchemeType),
                    wechatType,
                    formInfo,
                    formId: formOpt?.id,
                    launchType,
                });
            } else if (wechatType === WeChatType.ASSISTANT_GROUP) {
                jumpHkzs({
                    opt: wechatOpt as WeiXinOpt,
                    dialogStyleType: transformedDialogStyleType,
                    renderType: 'XIANSUO_FORM_DRAG',
                    useSchema: Boolean(abData.qywxCustomerAcquisitionSchemeType),
                    wechatType,
                    formInfo,
                    formId: formOpt?.id,
                    launchType,
                });
            }
            // 上报Add埋点
            try {
                wechatDataTrack({
                    itemId: wechatOpt?.id,
                    invokeSuccess: false,
                    sence:
                        (dialogStyleType && wechatActionSence(dialogStyleType)) ||
                        WechatActionSceneEnum.UNKNOWN_WECHAT_ACTION_SCENE,
                    wechatId: wechatOpt?.wechatId,
                    appId: '',
                    popupType: PopupType.COMPONENT,
                });
            } catch (e) {
                console.log(e);
            }
        },
        [wechatOpt, dialogStyleType, dialogStyle, abData],
    );

    return (
        <Div className={formClass} data-form-id={funcId}>
            {/* 是消息类型的分布表单不用渲染 */}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {!reRender && !isFenBuBiaodanMessage && config && <ClueForm {...config} />}
            {children}

            {isFenBuBiaodanMessage && (
                <ImComponent
                    serviceAvatarUrl={serviceAvatarUrl}
                    serviceNickname={serviceNickname}
                    welcome={welcome}
                    end={end}
                    messageExpand={messageExpand}
                    options={fenbuOptions}
                    preview={edit}
                    startButtonInfo={startButtonInfo}
                    needStartButtonInfoAnimate={buttonMotion}
                    buttonStyle={{
                        backgroundColor: theme,
                        ...submitStyle,
                    }}
                    agreementColor={privacyTextColor}
                    onEnd={() => {}}
                    endButtonInfo={submitText}
                    config={config}
                    formOpt={formOpt}
                    onClickEndButton={(formInfo?: SelectItem[]) => {
                        if (
                            wechatType === WeChatType.KEFU ||
                            wechatType === WeChatType.ASSISTANT ||
                            wechatType === WeChatType.ASSISTANT_GROUP
                        ) {
                            if (abData.formPopupQywxStyle) {
                                return jumpQywx(formInfo);
                            }
                        }
                        setSelectFormItemList(formInfo ?? []);
                        setShow(true);
                    }}
                    showCustomPrivacyAgreement={showCustomPrivacyAgreement}
                    formSetting={formSetting}
                    chatStyleType={chatStyleType}
                />
            )}
            {/* 如果是分布表单，并且存在微信包 */}
            {isFenbuBiaodan && (!!wechatOpt || dialogStyleType === DIALOG_STYLE.JUMPWX) ? (
                // 分布表单 对话类型的弹框
                <WeiXinModal
                    isFenbuMessageType
                    show={show}
                    onClose={() => {
                        setShow(false);
                    }}
                    afterSubmitText={modalSetting?.afterSubmitText}
                    afterSuccessDisplayType={modalSetting?.afterSuccessDisplayType}
                    wechatType={wechatType}
                    info={wechatInfo}
                    opt={wechatOpt}
                    applets={applets}
                    appletSwitchState={appletSwitchState}
                    dialogStyle={dialogStyle}
                    dialogStyleType={transformDialogStyleType(
                        dialogStyle,
                        dialogStyleType,
                        wechatType,
                    )}
                    //
                    needSendEffectiveAction={needSendEffectiveAction({
                        isChatForm: isFenbuBiaodan,
                        formInfo: selectFormItemList,
                        formComps: fenbuOptions,
                    })}
                    submitTurnValue={modalSetting?.submitTurnValue as SubmitTurnValue}
                    switch={modalSetting?.switch}
                    selectFormItemList={selectFormItemList}
                    chatFormId={formOpt?.id}
                />
            ) : (
                // 高级设置的弹框
                <WeiXinModal
                    show={show}
                    onClose={() => {
                        setShow(false);
                    }}
                    afterSubmitText={modalSetting?.afterSubmitText}
                    afterSuccessDisplayType={modalSetting?.afterSuccessDisplayType}
                    wechatType={modalSetting?.weChatOpt?.wechatType}
                    applets={applets}
                    appletSwitchState={appletSwitchState}
                    opt={modalSetting?.weChatOpt?.opt}
                    dialogStyle={dialogStyle}
                    info={wechatInfo}
                    dialogStyleType={transformDialogStyleType(dialogStyle, dialogStyleType)}
                    submitTurnValue={modalSetting?.submitTurnValue as SubmitTurnValue}
                    switch={modalSetting?.switch}
                />
            )}
            <div
                className="xiansuoModalBtn"
                style={{ display: 'none' }}
                onClick={() => setShow(true)}
            />
            <div
                className="xiansuoModalClose"
                style={{ display: 'none' }}
                onClick={() => setShow(false)}
            />
        </Div>
    );
};

export default XiansuoForm;
