import * as React from 'react';
import { callApp } from 'landingPage/common/utils/callApp';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { PopupType, WechatActionSceneEnum } from '../WeiXinDrag/WeiXin';
import { wechatDataTrack } from '../WeiXinDrag/components/commonFun';
import { ReactComponent as Checked } from './icon-yi-fu-zhi.svg';
import { copy } from './copy';
import styles from './style.module.less';

// 多转化事件 优化目标
const multiConversion = getUrlParam('multiConversion') === 'true';

export interface CopyDialogProps {
    info?: string;
    wechatId?: string;
    onClose?: () => void;
    itemId?: string;
    packId?: string;
    appId?: string;
}

export const CopyDialog: React.FunctionComponent<CopyDialogProps> = (props) => {
    const { info, wechatId, onClose, itemId, packId, appId } = props;
    React.useEffect(() => {
        wechatId &&
            dataTrack({
                eventType: 'EVENT_VIEW_WECHAT',
                wechatItemId: itemId,
                wechatActionScene: WechatActionSceneEnum.COPY_WECHAT_ID,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN',
                    popupType: PopupType.COMPONENT,
                }),
            });
        wechatId && copy(wechatId, itemId, packId);
    }, [info, itemId, packId, wechatId]);
    const callWX = React.useCallback(() => {
        // dataTrack({ eventType: 'EVENT_ADD_WECHAT', wechatItemId: itemId });
        // 多转化事件优化目标，不打除表单转化的EVENT_landingPage/common_SUBMIT点
        if (!multiConversion) {
            dataTrack({ eventType: 'EVENT_landingPage/common_SUBMIT' });
        }
        callApp(
            'weixin:// ',
            'https://weixin.qq.com/cgi-bin/readtemplate?t=download/ver_voice',
            () => {
                wechatDataTrack({
                    itemId,
                    invokeSuccess: true,
                    sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                    wechatId,
                    appId,
                    popupType: PopupType.COMPONENT,
                });
            },
            () => {
                wechatDataTrack({
                    itemId,
                    invokeSuccess: false,
                    sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                    wechatId,
                    appId,
                    popupType: PopupType.COMPONENT,
                });
            },
        );
        onClose?.();
    }, [itemId, wechatId, appId, onClose]);
    return (
        <div className={styles.dialog}>
            <div className={styles.exit} onClick={onClose} />
            <Checked className={styles.icon} />
            <div className={styles.text}>微信号已复制</div>
            <div className={styles.account}>{wechatId}</div>
            <div className={styles.info}>{info}</div>
            <div className={styles.close} onClick={callWX}>
                去添加
            </div>
        </div>
    );
};
