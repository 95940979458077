import { ActivityCodeProps } from '@/landingPage/components/ActivityCode/ActivityCode';
import { AppDownloadProps } from '@/landingPage/components/AppDownload/AppDownload';
import { ButtonProps } from '@/landingPage/components/ButtonDrag/Button';
import { CustomerServiceProps } from '@/landingPage/components/CustomerServiceDrag/CustomerService';
import {
    MultiConsultProps,
    StyleType,
} from '@/landingPage/components/MultiConsultDrag/MultiConsult';
import { TelProps } from '@/landingPage/components/TelDrag/Tel';
import { TextProps } from '@/landingPage/components/Text/Text';
import { WechatGameProps } from '@/landingPage/components/WechatGame/WechatGame';
import { WeiXinProps } from '@/landingPage/components/WeiXinDrag/WeiXin';

export const THEME_CONFIG = {
    ACTIVITY_CODE: (props: ActivityCodeProps) => {
        return processButtonTheme(props, {
            'buttonStyle.backgroundColor': {
                status: true,
            },
        });
    },
    APP_DOWNLOAD_DRAG: (props: AppDownloadProps) => {
        if (props.index === 6) {
            return {
                'buttonStyle.backgroundColor': {
                    status: true,
                },
                'buttonStyle.color': {
                    status: true,
                },
            };
        }
        // if (props.index === 7) {
        return processButtonTheme(props, {
            'buttonStyle.backgroundColor': {
                status: true,
            },
        });
        // }
    },
    // APP_DOWNLOAD: AppDownloadConfig,
    BLANK: () => {},
    BUTTON_DRAG: (props: ButtonProps) => {
        return processButtonTheme(props, {
            'buttonStyle.backgroundColor': {
                status: true,
            },
        });
    },
    // BUTTON: ButtonConfig,
    COUPON_CARD: () => {},
    COUNTDOWN_DRAG: () => {
        return {
            backgroundColor: {
                status: true,
            },
        };
    },
    CUSTOMER_SERVICE_DRAG: (props: CustomerServiceProps) => {
        if (props?.position === 'rightBottom' && props?.index === 0) {
            return {
                'buttonStyle.backgroundColor': {
                    status: true,
                },
                'buttonStyle.color': {
                    status: true,
                },
            };
        }
        return processButtonTheme(props, {
            'buttonStyle.backgroundColor': {
                status: true,
            },
        });
    },
    // CUSTOMER_SERVICE: CustomerServiceConfig,
    DRAG_ELEMENT: () => {
        return {
            color: {
                status: true,
            },
            'stroke.color': {
                status: true,
            },
        };
    },
    DRAG_IMAGE: () => {},
    DRAG_INTERACTION_RECORD: () => {},
    DRAG_TEXT: (props: TextProps) => {
        if (!props?.themeConfig || props?.themeConfig['style.color']?.status !== true) {
            return {
                'style.color': {
                    status: false,
                },
            };
        }
    },
    // FORM: FormConfig,
    // IMAGE: ImageConfig,
    MAP_DRAG: () => {},
    // MAP: MapConfig,
    MULTI_CONSULT_DRAG: (props: MultiConsultProps) => {
        if (props?.styleType === StyleType.SQUARE || props?.styleType === StyleType.ROUND) {
            return {
                iconColor: {
                    status: false,
                },
                backgroundColor: {
                    status: true,
                },
            };
        }
        return {
            iconColor: {
                status: true,
            },
            backgroundColor: {
                status: false,
            },
        };
    },
    // MULTI_CONSULT: MultiConsultConfig,
    SHUFFLING_IMAGE_DRAG: () => {},
    // SHUFFLING_IMAGE: ShufflingImageConfig,
    STORE: () => {},
    // STORE_PAGE: StorePageConfig,
    TEL_DRAG: (props: TelProps) => {
        if (props.position === 'rightBottom' && props.index === 0) {
            return {
                'buttonStyle.backgroundColor': {
                    status: true,
                },
                'textStyle.color': {
                    status: true,
                },
            };
        }
        return processButtonTheme(props, {
            'buttonStyle.backgroundColor': {
                status: true,
            },
            'textStyle.color': {
                status: false,
            },
        });
    },
    // TEL: TelConfig,
    // TEXT: TextConfig,
    VIDEO_DRAG: () => {},
    // VIDEO: VideoConfig,
    WEI_XIN_DRAG: (props: WeiXinProps) => {
        if (props?.position === 'rightBottom' && props?.index === 0) {
            return {
                'buttonStyle.backgroundColor': {
                    status: true,
                },
                'buttonStyle.color': {
                    status: true,
                },
            };
        }
        return processButtonTheme(props, {
            'buttonStyle.backgroundColor': {
                status: true,
            },
            'rightBottomTextStyle.color': {
                status: false,
            },
        });
    },
    // WEI_XIN: WeiXinConfig,
    XIANSUO_FORM_DRAG: () => {},
    XIANSUO_FORM: () => {},
    XIANSUO_FORM_PAY: () => {},
    WECHAT_GAME: (props: WechatGameProps) => {
        return processButtonTheme(props, {
            'buttonStyle.backgroundColor': {
                status: true,
            },
        });
    },
    CORPORATE_INFO: () => {},
};

/**
 * 按钮类的主题色配置
 */
function processButtonTheme(props: any, config: any) {
    /** 背景图 */
    if (props?.backgroundFillMode === true) {
        config['buttonStyle.backgroundColor'] = {
            status: false,
        };
        config['buttonStyle.color'] = {
            status: false,
        };
        config['textStyle.color'] = {
            status: false,
        };
        /** 线框 */
    } else if (props?.buttonStyle?.borderColor) {
        config['buttonStyle.borderColor'] = {
            status: true,
        };
        config['buttonStyle.color'] = {
            status: true,
        };
        config['buttonStyle.backgroundColor'] = {
            status: false,
        };

        config['textStyle.color'] = {
            status: true,
        };
        /** 填充 */
    } else {
        config['buttonStyle.backgroundColor'] = {
            status: true,
        };
        config['buttonStyle.borderColor'] = {
            status: false,
        };
        config['buttonStyle.color'] = {
            status: props?.themeConfig?.['buttonStyle.color']?.status ?? false,
        };

        config['textStyle.color'] = {
            status: false,
        };
    }
    return config;
}
