import * as React from 'react';
import { useSetState } from 'react-use';
import cs from 'classnames';
import { isSSR } from 'landingPage/common/utils/env';
import { ShufflingImageProps } from './ShufflingImage';
import styles from './style.module.less';
import { Progress } from './Progress';
import { useComContext } from 'omega-render';
import { useLazyImg } from 'landingPage/common/utils/useLazyImg';
import { DEVICE_TYPE, getDeviceType } from '@/landingPage/common/utils/getDeviceInfo';
import { invoke } from '@yoda/bridge';
// import { useEffect } from 'react';
// import Placeholder from '../ImageSingle/icon-image.svg';

export interface Type1Props
    extends Omit<
        ShufflingImageProps,
        'groupImageConfig' | 'labelStyles' | 'labelActiveStyles' | 'groupStyles' | 'shufflingType'
    > {
    progressClass?: string;
}

export const Type1: React.FunctionComponent<Type1Props> = (props) => {
    const { edit } = useComContext();
    const { renderType = 1, imgs, width, playInterval, progressClass } = props;

    const len = renderType === 1 ? width ?? 375 : ((width ?? 375) * 312) / 375;
    const opt = React.useMemo(() => {
        const time = 400;
        const delay = playInterval || 4000;
        return {
            len,
            time,
            delay,
        };
    }, [len, playInterval]);
    const { lazyImgs, domRef } = useLazyImg(imgs, {
        edit,
    });
    const { moved, renderImgs, events, index } = useAnimation(
        {
            ...props,
            imgs: imgs.map((img) => {
                return {
                    ...img,
                    imgUrl: lazyImgs[img.imgUrl] || img.imgUrl,
                };
            }),
        },
        opt,
    );
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={styles.type1} {...events} ref={domRef}>
            {renderImgs.map((conf, index) => {
                const { imgUrl, targetUrl } = conf;
                return (
                    <div
                        key={index}
                        className={cs(styles.img, renderType === 2 && styles.img2)}
                        onClick={() => {
                            if (targetUrl) {
                                window.location.href = targetUrl;
                            }
                        }}
                        style={{
                            transform: `translate(${
                                (index - (renderImgs.length - 1) / 2) * opt.len - moved
                            }px,${0}px)`,
                            backgroundImage: `url(${imgUrl})`,
                        }}
                    />
                );
            })}
            {renderType === 1 && (
                <Progress
                    len={imgs.length}
                    progressClass={progressClass}
                    transitionTime={`${opt.time / 1000}s`}
                    currentIndex={index}
                    moved={moved}
                />
            )}
        </div>
    );
};
let hasError = false;
function getEle<T>(arr: T[], index: number) {
    if (arr.length === 0) {
        console.error('数组长度不能为0');
        // 果然没有一行代码是多余的
        if (!hasError) {
            !isSSR && alert('空数据无法预览');
            hasError = true;
        }
    }
    if (arr.length === 1) {
        return arr[0];
    }
    if (index >= arr.length) {
        // 这里有可能算出-1，所以需要后面小于0的逻辑
        index = ((index + 1) % arr.length) - 1;
    }
    if (index < 0) {
        // 这里有可能算出arr.length，所以需要后面小于0的逻辑
        index = (index % arr.length) + arr.length;
    }
    if (index >= arr.length) {
        index = ((index + 1) % arr.length) - 1;
    }
    return arr[index];
}
interface AnimationOpt {
    len: number;
    time: number;
    delay: number;
}
// export function useLasyImg(imgs: { imgUrl: string }[]) {
//     const { isSupportWebp } = useComContext();
//     const [lazyImgs, setLazyImgs] = React.useState(
//         imgs.map((img) => {
//             return {
//                 ...img,
//                 imgUrl: getPicCDNSource(
//                     'https://p1-yx.adkwai.com/kos/nlav10749/defaultSingleImage.483f6c93b5158d0c.png',
//                     isSupportWebp,
//                 ),
//             };
//         }),
//     );
//     const domRef = React.useRef<HTMLDivElement>(null);
//     React.useLayoutEffect(() => {
//         const observer = new IntersectionObserver(
//             (entries) => {
//                 entries.forEach((entry) => {
//                     if (entry.isIntersecting || entry.intersectionRatio > 0) {
//                         console.log('show', entry);
//                         setLazyImgs(imgs);
//                         observer.unobserve(entry.target);
//                     }
//                 });
//             },
//             {
//                 root: null, // 相对于视口进行观察
//                 rootMargin: '50px', // 在视口边缘100px时开始加载图片
//                 threshold: 0.01, // 当1%的元素可见时触发回调
//             },
//         );
//         if (domRef.current) {
//             observer.observe(domRef.current);
//         }
//         return () => {
//             observer.disconnect();
//         };
//     }, []); // 确保只在组件加载时运行
//     return {
//         lazyImgs,
//         domRef,
//     };
// }

export function useAnimation(props: Type1Props, opt: AnimationOpt) {
    const { imgs } = props;
    const { len, time, delay } = opt;
    const step = len / (time / 16);
    const [state, setState] = useSetState({ moved: 0, index: 0 });
    const that = React.useRef<{
        timmer?: number;
        delayTimer?: number;
        moved: number;
        index: number;
        autoplay?: boolean;
        touchState: {
            startXY: number[];
            moved?: number;
            moveOffset: number;
            touchingOffsetX?: number;
        };
    }>({
        index: 0,
        touchState: {
            startXY: [],
            moveOffset: 0,
        },
        moved: 0,
    });
    that.current.autoplay = props.autoplay;
    that.current.moved = state.moved;
    that.current.index = state.index;
    const isIos = getDeviceType() === DEVICE_TYPE.IOS;
    const stopAnimation = React.useCallback(() => {
        if (that.current.timmer != null) {
            cancelAnimationFrame(that.current.timmer);
        }
        if (that.current.delayTimer) {
            clearTimeout(that.current.delayTimer);
        }
    }, []);
    /**
     * reverse 是否倒着来
     */
    const startAnimation = React.useCallback(
        (reverse?: boolean) => {
            let { moved } = that.current;
            if (reverse) {
                moved -= step;
            } else {
                moved += step;
            }
            if (Math.abs(moved) >= len) {
                // 到头了
                const { index } = that.current;
                setState({
                    moved: 0,
                    index: reverse ? index - 1 : index + 1,
                });
                if (!that.current.autoplay) {
                    return;
                }
                that.current.delayTimer = window.setTimeout(() => {
                    startAnimation(false);
                }, delay);
                return;
            }
            setState({
                moved,
            });
            that.current.timmer = requestAnimationFrame(() => startAnimation(reverse));
        },
        [delay, len, setState, step],
    );

    React.useEffect(() => {
        let timmer: number;
        if (props.autoplay) {
            timmer = window.setTimeout(() => {
                startAnimation();
            }, delay);
        }

        return () => {
            stopAnimation();
            window.clearTimeout(timmer);
        };
    }, [delay, props.autoplay, startAnimation, stopAnimation]);

    const renderImgs = React.useMemo(() => {
        const { index } = state;
        if (props.renderType === 2) {
            return [
                getEle(imgs, index - 2),
                getEle(imgs, index - 1),
                getEle(imgs, index),
                getEle(imgs, index + 1),
                getEle(imgs, index + 2),
            ];
        }
        return [getEle(imgs, index - 1), getEle(imgs, index), getEle(imgs, index + 1)];
    }, [imgs, props.renderType, state.index]);
    // 触摸控制
    const onTouchStart = React.useCallback(
        async (e: React.TouchEvent) => {
            const { pageX, pageY } = e.touches[0];
            if (isIos) {
                await invoke('webview.setSlideBack', { enabled: false });
            }
            that.current.touchState.startXY = [pageX, pageY];
            const { moved } = that.current;
            that.current.touchState = { startXY: [pageX, pageY], moved, moveOffset: 0 };
            stopAnimation();
        },
        [stopAnimation],
    );
    const onTouchMove = React.useCallback(
        (e: React.TouchEvent) => {
            const { pageX, pageY } = e.touches[0];
            const { startXY, moved, moveOffset } = that.current.touchState;
            if (startXY.length === 0) {
                return;
            }
            const [offsetX] = [pageX - startXY[0], pageY - startXY[1]];
            that.current.touchState.touchingOffsetX = offsetX;
            if (moved)
                setState({
                    moved: moved - offsetX + moveOffset,
                });
        },
        [setState],
    );
    const onTouchEnd = React.useCallback(async () => {
        if (isIos) {
            await invoke('webview.setSlideBack', { enabled: true });
        }
        if (that.current.touchState.startXY.length) {
            const { touchingOffsetX } = that.current.touchState;
            that.current.touchState = {
                startXY: [],
                moveOffset: 0,
            };
            startAnimation((touchingOffsetX ?? 0) > 0);
        }
    }, [startAnimation]);
    // 触摸的时候，修正状态
    React.useEffect(() => {
        if (that.current.touchState.startXY.length) {
            const { index } = that.current;
            if (state.moved >= len) {
                that.current.touchState.moveOffset -= len;
                setState({
                    index: index + 1,
                    moved: state.moved - len,
                });
            } else if (state.moved <= -len) {
                that.current.touchState.moveOffset += len;

                setState({
                    index: index - 1,
                    moved: state.moved + len,
                });
            }
        }
    }, [len, setState, state.moved]);
    return {
        moved: state.moved,
        index: state.index,
        renderImgs,
        stopAnimation,
        events: {
            onTouchStart,
            onTouchMove,
            onTouchEnd,
        },
    };
}
