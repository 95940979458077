import React, { useEffect, useState } from 'react';
import styles from './style.module.less';
import Tel from './img/tel';
import Location from './img/location';
import { fetchAggregateMerchantInfo, fetchMerchantInfo } from '@/landingPage/common/api';
import { getUrlParam } from 'omega-shared/utils';
import {
    CampaignType,
    getAggregationInfoList,
    isAggergateCreativeX,
} from '@/landingPage/common/utils/getAggregatePageInfoList';
import { aggregateInfoReport } from '@/landingPage/common/adTrack/radarReport';
import { useShowCorporateInfo } from '@/landingPage/common/utils/firefly';

export interface CorporateInfoProps {
    companyName: string;
    //选填，填写则按照电话校验规则去校验
    companyTel?: string;
    //选填，无校验
    companyAddress?: string;
    style: React.CSSProperties;
}

const CorporateInfo: React.FunctionComponent<CorporateInfoProps> = (props) => {
    const { companyName, companyTel, companyAddress, style } = props;
    const [companyNameList, setCompanyNameList] = useState<string[]>([]);

    const isLightCreative = getUrlParam('ksCampaignType') === CampaignType.Light;
    const [ksSiteAccountId, isAggregatePage, aggregateBiddingPageId] = getUrlParam([
        'ksSiteAccountId',
        'isAggregatePage',
        'aggregateBiddingPageId',
    ]);

    const showCorporInfo = useShowCorporateInfo();

    useEffect(() => {
        //聚合竞价、极简创编场景，用pageId、accountId 获取商户信息并展示出来
        //优先使用ksSiteAccountId（账户id）获取商户信息
        //如果没有账户id，则用pageId（落地页id）获取商户信息
        const init = async () => {
            try {
                //不是极简创编 or 聚合竞价时，不请求接口获取商户信息
                if (!(isAggregatePage || isLightCreative)) {
                    return;
                }
                //获取聚合竞价BCD信息并按照账户id，pageId向accountList，pageIdList中赋值
                const urlResList = await getAggregationInfoList();
                const accountList: string[] = [];
                const pageIdList: string[] = [];
                //是聚合竞价的情况
                if (urlResList?.length) {
                    urlResList.forEach((item) => {
                        if (item?.ksSiteAccountId) {
                            accountList.push(item.ksSiteAccountId);
                        } else {
                            pageIdList.push(item.siteId);
                        }
                    });
                }
                //增加A的账户id/pageId
                //如果有账户id，则使用账户id（极简创编为新增类型，一定会携带ksSiteAccountId）
                //如果没有账户id，则使用aggregateBiddingPageId（此场景为标准创编x聚合竞价的兜底case）
                if (!isAggergateCreativeX()) {
                    if (ksSiteAccountId) {
                        accountList.push(ksSiteAccountId);
                    } else {
                        pageIdList.push(aggregateBiddingPageId);
                    }
                }

                let companyInfoRes: { corporationName: string }[] = [];
                let companyNameRes: string[] = [];
                if (pageIdList?.length) {
                    companyInfoRes = companyInfoRes.concat(
                        (await fetchAggregateMerchantInfo(pageIdList)) || [],
                    );
                }
                if (accountList?.length) {
                    companyInfoRes = companyInfoRes.concat(
                        (await fetchMerchantInfo(accountList)) || [],
                    );
                }
                companyNameRes = companyInfoRes?.map((item) => {
                    return item.corporationName;
                });
                setCompanyNameList(companyNameRes);
            } catch (e) {
                aggregateInfoReport({
                    handleCorInfo: 'false',
                    error: String(e),
                });
            }
        };
        init();
    }, []);
    //如果是聚合竞价，且打开商户信息的开关是关闭的，则不渲染商户信息组件
    if (!showCorporInfo && isAggregatePage?.length) {
        return null;
    }
    return (
        <div style={{ ...style }} className={styles.corporateInfo} id="corporateInfo">
            {/* 聚合竞价 or 极简创编 广告主信息 */}
            {companyNameList?.length ? (
                companyNameList.map((item, index) => {
                    return (
                        <div
                            style={{ fontFamily: style?.fontFamily, margin: '0 19px' }}
                            key={index}
                        >
                            {item}
                        </div>
                    );
                })
            ) : (
                <div style={{ fontFamily: style?.fontFamily, margin: '0 19px' }}>{companyName}</div>
            )}

            {companyTel && (
                <div className={styles.company}>
                    <Tel fill={style?.color || '#8C8E97'} />
                    <span style={{ marginLeft: 4, fontFamily: style?.fontFamily }}>
                        {companyTel}
                    </span>
                </div>
            )}
            {companyAddress && (
                <div className={styles.company}>
                    <Location fill={style?.color || '#8C8E97'} />
                    <span style={{ marginLeft: 4, fontFamily: style?.fontFamily }}>
                        {companyAddress}
                    </span>
                </div>
            )}
        </div>
    );
};

export default CorporateInfo;
