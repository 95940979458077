/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useRef } from 'react';
import Modal from 'landingPage/common/components/Modal/Modal';
import { invoke } from '@yoda/bridge';
import { isInApp, isSSR } from 'landingPage/common/utils/env';
import { Toast } from '@ad/sharpui';
import {
    comWrapperSelector,
    useComContext,
    useRenderState,
    AppletsModal,
    DIALOG_STYLE,
    WeChatType,
} from 'omega-render';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import cs from 'classnames';
import { ComponentZIndex } from 'omega-shared/utils';
import { trackBridgeError, trackBridgeSuccess } from 'landingPage/common/adTrack/monitor';
import { getClipboard, exitWebview } from 'landingPage/common/bridge';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import Button, { ButtonProps } from '../ButtonDrag/Button';
import { Div } from '../Blank/Blank';
import { ExplicitButton } from './components/ExplicitButton';
import { ReactComponent as CommonIcon } from './image/common-icon.svg';
import { ReactComponent as WxIcon } from './image/icon-wechat-24px.svg';
import {
    fetchWechatId,
    useRandomItem,
    DialogStyleModal,
    jumpToMiniApp,
    wechatActionSence,
} from './components/commonFun';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { BtnType } from '../WechatGame/WechatGame';
import { jumpHkzs, jumpWxkf } from './util';
import { useAbHooks } from './hooks/useAbHooks';
import { useTheme } from '@/landingPage/common/utils/useTheme';
import { ASSISTANT_SOURCE } from '../XiansuoFormDrag/XiansuoForm';
import { LaunchTypeEnum } from './components/JumpWxKefu';

export enum dialogTypeEnum {
    QrCode = 2,
    WechatId = 1,
}

export enum WechatActionSceneEnum {
    UNKNOWN_WECHAT_ACTION_SCENE = 0,
    COPY_WECHAT_ID = 1, // 复制微信号加微信场景
    SCAN_WECHAT_QR_CODE = 2, // 扫微信二维码加微信场景：先保存图库，调起微信，从图库扫码
    JUMP_WECHAT_APPLET = 3, // 跳转到微信小程序加微信
    DIRECT_SUBSCRIBE = 5, // 直达关注
    JUMP_WECHAT_APPLET_FROM_POPUP = 4, //小程序二次弹窗
    JUMP_KEFU = 6, //跳转客服
    ACQUISITION_ASSISTANT = 7, //获客助手
    ACQUISITION_ASSISTANT_GROUP = 8, //获客助手
    KEFU_WITH_ASSISTANT = 9, //微信客服带获客链接
}

export enum PopupType {
    COMPONENT = 1,
    WANLIU = 2,
}

export enum WechatActionStatusEnum {
    UNKNOWN_WECHAT_ACTION_STATUS = 0,
    WECHAT_SAVE_QR_CODE_SUCCESS = 1, // 保存微信二维码到图库成功
    WECHAT_SAVE_QR_CODE_FAILED = 2, // 保存微信二维码到图库失败；原因：图片访问权限等
    WECHAT_INVOKED_SUCCESS = 3, // 微信调起成功
    WECHAT_INVOKED_FAILED = 4, // 微信调起失败
}

export enum WechatButtonType {
    EXPLICIT = 1,
    NORMAL = 2,
}

export interface DialogInfo {
    wechatId: string;
    packId: string;
    itemId: string;
    qrcode: string;
}

/** 微信加粉方式 */
export enum PlanType {
    /** 号码包 */
    KEFU = 1,
    /** 获客链接 */
    HK_LINK = 2,
}
export interface WeiXinOpt {
    id?: string;
    hasQrCode?: boolean;
    isDel?: boolean;
    packId?: string;
    wechatAppId?: string;
    wechatId?: string;
    wechatNickname?: string;
    wechatType?: string;
    useCount?: number;
    createTime?: number;
    updateTime?: number;
    itemViews?: any;
    packageName?: string;
    againstFlowPrefixSwitch?: boolean; // 是否分流
    againstFlowPrefix?: string; // 分流前缀（公众号名称）
    directSubscribeSwitch?: boolean; // 是否直达关注
    directSubscribeLink?: string; // 公众号链接
    deepLink?: string; // 企微客服
    planType?: PlanType; // 微信加粉方式
    linkId?: string;
}

export interface SwitchState {
    checked: boolean;
    disabled: boolean;
}

export interface Applets {
    appletsId: string;
    appletsName?: string;
}

export interface WeiXinProps
    extends Pick<ButtonProps, 'buttonStyle' | 'backgroundColor' | 'text' | 'dynamicStatus'> {
    position?: 'bottom' | 'base' | 'rightBottom' | 'bottomBlank';
    backgroundFillMode?: boolean;
    info?: string;
    /** 右侧悬浮的样式 */
    rightBottomTextStyle: React.CSSProperties;
    /** 右侧按钮背景色 */
    rightBottomBackgroundColor: string;
    rightBottomButtonStyle?: React.CSSProperties;
    /** 右侧图片颜色 */
    rightBottomIconColor: string;
    /** 微信号码包id */
    funcId?: string;
    /** 微信号码包内容 */
    opt?: WeiXinOpt;
    index?: number;
    wechatType?: WeChatType;
    /* 微信弹窗样式 */
    dialogStyleType: DIALOG_STYLE;
    dialogType: dialogTypeEnum;
    /* 是否是页面离开前的挽留弹窗 */
    stayBeforeClose?: boolean;
    iconStyle?: React.CSSProperties;
    applets?: Applets; // 微信小程序的信息
    standbyMode: number; // 备选方式

    /** 样式信息 */
    wechatButtonType?: WechatButtonType;
    /** 微信外显描述样式 */
    explicitDescStyles?: React.CSSProperties;
    /** 微信外显微信号样式 */
    explicitWechatStyles?: React.CSSProperties;
    /** 微信外显整体样式 */
    explicitButtonStyles?: React.CSSProperties;
    explicitPrexStyles?: React.CSSProperties;
    iconColor?: string;
    appletSwitchState: SwitchState; // 小程序开关的状态
    btnType?: BtnType; // 新样式新增属性
    radiusStyle?: string; // 圆角类型
    btnSetting?: {
        fontMax?: number;
        fontMin?: number;
    };
    sizeType?: 'normal' | 'large';
    showAppletsModal?: boolean; //小程序弹窗的状态
    appletsModal?: AppletsModal; // 小程序弹窗内容
    showModalInEditor?: boolean; // 在编辑器中操作弹窗
    isShare?: ASSISTANT_SOURCE;
    themeConfig: { [key in string]: any };
    imageHideWeiXinIcon?: boolean; // 图片填充样式是否隐藏微信图标
}

const WeiXin: React.FunctionComponent<WeiXinProps> = (props) => {
    const {
        position,
        text,
        buttonStyle,
        backgroundColor,
        info,
        children,
        rightBottomTextStyle,
        index,
        opt,
        wechatType,
        stayBeforeClose,
        rightBottomButtonStyle,
        dynamicStatus,
        iconStyle,
        dialogStyleType = DIALOG_STYLE.DEFAULT,
        applets,
        wechatButtonType,
        appletSwitchState,
        showModalInEditor,
        imageHideWeiXinIcon = false,
    } = props;
    const { color: buttonColor } = useTheme({
        path: 'buttonStyle.color',
    });
    const { color: buttonBackgroundColor } = useTheme({
        path: 'buttonStyle.backgroundColor',
    });
    const { color: rightBottomTextColor } = useTheme({
        path: 'rightBottomTextStyle.color',
    });
    const [state, setState] = React.useState({ show: false });
    const [dialogInfo, setDialogInfo] = React.useState(useRandomItem(props) as DialogInfo);
    const ref = useRef<HTMLDivElement>(null);
    const pageMeta = useRenderState((state) => state.rolex.opt?.originalPageJson?.pageMeta);
    const abData = useAbHooks();
    const { id, edit, preview, magic, type } = useComContext();
    React.useEffect(() => {
        if (!isInApp) {
            const node = document.querySelector(comWrapperSelector(id));
            if (!node) {
                return;
            }
            const Clipboard = getClipboard();
            const clipboard = Clipboard && new Clipboard(node);
            return () => {
                clipboard?.destroy();
            };
        }
    }, [id]);

    const editDialogInfo = useRandomItem(props) as DialogInfo;

    React.useEffect(() => {
        if (edit) {
            if (showModalInEditor) {
                setState({ show: true });
            } else {
                setState({ show: false });
            }
        }
    }, [showModalInEditor]);

    const onClick = React.useCallback(async () => {
        if (wechatType === WeChatType.KEFU && opt?.deepLink) {
            await jumpWxkf({
                opt,
                dialogStyleType,
                renderType: 'WEI_XIN_DRAG',
                launchType: LaunchTypeEnum[type],
            });
            return;
        }
        if (wechatType === WeChatType.ASSISTANT && opt?.deepLink) {
            await jumpHkzs({
                opt,
                dialogStyleType: DIALOG_STYLE.ACQUISITION_ASSISTANT,
                renderType: 'WEI_XIN_DRAG',
                useSchema: Boolean(abData.qywxCustomerAcquisitionSchemeType),
                wechatType,
                launchType: LaunchTypeEnum[type],
            });
            return;
        }
        if (wechatType === WeChatType.ASSISTANT_GROUP) {
            await jumpHkzs({
                opt: opt as WeiXinOpt,
                dialogStyleType: DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP,
                renderType: 'WEI_XIN_DRAG',
                useSchema: Boolean(abData.qywxCustomerAcquisitionSchemeType),
                wechatType,
                launchType: LaunchTypeEnum[type],
            });
            return;
        }
        if (wechatType === WeChatType.MINIAPP || appletSwitchState?.checked) {
            if (applets?.appletsId) {
                if (pageMeta?.showAppletsModal) {
                    dataTrack({
                        eventType: 'EVENT_CLICK_WECHAT',
                        wechatItemId: '0',
                        lp_page_type: 1,
                        wechatActionScene: WechatActionSceneEnum.JUMP_WECHAT_APPLET_FROM_POPUP,
                        diyExtraInfo: JSON.stringify({
                            renderType: 'WEI_XIN_DRAG',
                        }),
                    });
                    setState({ show: true });
                } else {
                    dataTrack({
                        eventType: 'EVENT_CLICK_WECHAT',
                        wechatItemId: '0',
                        lp_page_type: 1,
                        wechatActionScene: WechatActionSceneEnum.JUMP_WECHAT_APPLET,
                        diyExtraInfo: JSON.stringify({
                            renderType: 'WEI_XIN_DRAG',
                        }),
                    });
                    jumpToMiniApp({ preview, applets });
                }
            }
            return;
        }
        const res = await fetchWechatId({
            opt,
            wechatType,
            edit,
            dialogInfo: edit ? editDialogInfo : dialogInfo,
            dialogStyleType,
            showModal: () => {
                setState({ show: true });
            },
            onError: () => {
                if (stayBeforeClose) {
                    exitWebview();
                } else {
                    Toast.error('暂无可复制的微信号');
                }
            },
        });
        res && setDialogInfo(res);
        /** 如果不是微信外显才会打点 */
        if (wechatButtonType !== WechatButtonType.EXPLICIT) {
            dataTrack({
                eventType: 'EVENT_CLICK_WECHAT',
                wechatItemId: res?.itemId,
                lp_page_type: 1,
                wechatActionScene: wechatActionSence(dialogStyleType),
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    followPlanId: dialogStyleType === DIALOG_STYLE.KEFU ? opt?.id : '',
                }),
            });
        }
    }, [opt, wechatType, stayBeforeClose, dialogStyleType, abData]);

    /** 历史产物，等运行一段时间后，微信挽留全量迁移到pageMeta后可整段删掉 */
    useEffect(() => {
        console.log('start set topleft >>>>> stayBeforeClose', stayBeforeClose);
        if (stayBeforeClose && !isSSR && !edit) {
            (async () => {
                try {
                    await invoke('webview.setTopLeftBtn', {
                        show: true,
                        icon: 'back',
                        onClick: () => {
                            if (!window.history?.state?.fullScreen) {
                                const curUrl =
                                    window.location.href.includes('https://weixin.qq.com/');

                                console.log('stayBeforeClose', stayBeforeClose, curUrl);

                                if (stayBeforeClose && !curUrl) {
                                    onClick();
                                } else {
                                    exitWebview();
                                }
                            } else {
                                window.history.back();
                            }
                        },
                    });
                    trackBridgeSuccess('setTopLeftBtn');
                } catch (e) {
                    trackBridgeError('setTopLeftBtn', e);
                }
            })();
        }
    }, []);

    useEffect(() => {
        /** 如果不是右下角,不是预览环境下，如果是微信外显进入页面就得获取微信相关信息 */

        const fetchWechatStatus = async () => {
            if (
                position !== 'rightBottom' &&
                wechatButtonType === WechatButtonType.EXPLICIT &&
                !preview
            ) {
                // onClick();

                let errorStatus = false;
                const res = await fetchWechatId({
                    opt,
                    wechatType,
                    dialogInfo,
                    dialogStyleType,
                    // showModal: () => {},
                    onError: () => {
                        errorStatus = true;
                    },
                });

                if (errorStatus) {
                    setDialogInfo({
                        itemId: '',
                        wechatId: '',
                        packId: '',
                        qrcode: '',
                    });
                } else {
                    res && setDialogInfo(res);
                }
            }
        };
        fetchWechatStatus();
    }, []);

    let content: any = null;

    const [width, setWidth] = React.useState(
        ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree?.width,
    );
    React.useEffect(() => {
        setWidth(ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree?.width);
    }, [magic?.globalDragFree?.width]);

    if (position === 'rightBottom') {
        content = (
            <Div
                data-clipboard-text={dialogInfo.wechatId}
                className={styles.rightBottom}
                onClick={onClick}
            >
                {/* index=1 表示展示的是动态样式 */}
                {index === 1 ? (
                    <>
                        {dynamicStatus ? (
                            <div className={styles.wechatGif} />
                        ) : (
                            <div className={styles.wechatStatic} />
                        )}
                    </>
                ) : (
                    <div
                        className={cs(styles.iconWrapper, {
                            [styles.dynamic]: dynamicStatus === 1,
                        })}
                        style={{
                            ...rightBottomButtonStyle,
                            backgroundColor: buttonBackgroundColor,
                            backgroundImage: buttonStyle?.backgroundImage,
                            height: width - 16,
                            width: width - 16,
                        }}
                    >
                        {!imageHideWeiXinIcon && (
                            <WxIcon
                                // className={styles.rightIcon}
                                onClick={onClick}
                                style={{
                                    ...iconStyle,
                                    width: 0.58 * (parseInt(width) - 16 || 73),
                                    height: 0.58 * (parseInt(width) - 16 || 73),
                                }}
                            />
                        )}
                    </div>
                )}
                <p
                    style={{
                        marginTop: index === 1 ? '-4px' : '4px',
                        fontWeight: 'bold',
                        ...rightBottomTextStyle,
                        color: rightBottomTextColor,
                    }}
                >
                    {text.length > 4 ? `${text.slice(0, 4)}...` : text}
                </p>
            </Div>
        );
    } else {
        content = (
            <Button
                data-clipboard-text={dialogInfo.wechatId}
                bottom={position === 'bottom'}
                icon={
                    !imageHideWeiXinIcon && (
                        <CommonIcon
                            className={styles.buttonIcon}
                            style={{ ...iconStyle, color: buttonColor, fill: buttonColor }}
                        />
                    )
                }
                text={text}
                buttonStyle={buttonStyle}
                onClick={onClick}
                backgroundColor={backgroundColor}
                className={cs({ [styles.dynamic]: dynamicStatus === 1 })}
            >
                {children}
                {position === 'bottomBlank' && (
                    <>
                        <BlockPlaceholder pos="BOTTOM" preview={edit} />
                        <SafeAreaPlace />
                    </>
                )}
            </Button>
        );
    }

    return (
        <>
            {wechatButtonType === WechatButtonType.EXPLICIT && position !== 'rightBottom' ? (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ExplicitButton {...props} {...dialogInfo} preview={preview} />
            ) : (
                <>
                    {!stayBeforeClose && content}
                    {state.show &&
                        (wechatType !== WeChatType.MINIAPP ||
                            (wechatType === WeChatType.MINIAPP && pageMeta?.showAppletsModal)) &&
                        !appletSwitchState?.checked && (
                            <Modal
                                style={{ zIndex: ComponentZIndex.WechatZIndex }}
                                parent={
                                    edit
                                        ? (document.getElementById('preview-app') ?? document.body)
                                        : undefined
                                }
                            >
                                <DialogStyleModal
                                    dialogStyleType={dialogStyleType}
                                    dialogInfo={dialogInfo}
                                    showAppletsModal={pageMeta?.showAppletsModal}
                                    appletsModal={pageMeta?.appletsModal}
                                    wechatType={wechatType}
                                    applets={applets}
                                    info={info}
                                    renderType="WEI_XIN_DRAG"
                                    opt={opt as WeiXinOpt}
                                    onClose={() => {
                                        const time =
                                            dialogStyleType === DIALOG_STYLE.QRCODE ? 4000 : 1000;
                                        setState({ show: false });
                                        if (stayBeforeClose) {
                                            window.setTimeout(() => {
                                                exitWebview();
                                            }, time);
                                        }
                                    }}
                                />
                            </Modal>
                        )}
                </>
            )}
        </>
    );
};

export default WeiXin;
