import { useLayoutEffect, useState } from 'react';

/**
 * 获取安全区域的高度。
 * @returns 安全区域的高度，以像素为单位。
 */
export function getSafeAreaHeight(): number {
    const safeAreaInsetBottom = getComputedStyle(document.body).getPropertyValue(
        '--safe-area-inset-bottom',
    );
    console.log('[???safeAreaHeight]', safeAreaInsetBottom);

    return Number(safeAreaInsetBottom?.replace('px', ''));
}

/**
 * 用于获取安全区域高度的自定义钩子。
 * @returns 安全区域的高度，以像素为单位。
 */
export const useSafeAreaHeight = (): number => {
    const [safeAreaHeight, setSafeAreaHeight] = useState(0);

    useLayoutEffect(() => {
        setSafeAreaHeight(getSafeAreaHeight() || 0);

        setTimeout(() => {
            setSafeAreaHeight(getSafeAreaHeight() || 0);
        }, 1000);
    }, []);

    return safeAreaHeight;
};
