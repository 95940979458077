/* eslint-disable */
import { BasePlugin } from '@ks/weblogger';
import * as yoda from '@yoda/bridge';
import {
    trackAsyncModuleError,
    trackBridgeError,
    trackBridgeSuccess,
    sendTraceLog,
} from './adTrack/monitor';
// import { isProduction, isTest, isInApp, ua } from './utils/env';
import { isDevelopment, isInApp, ua } from 'src/landingPage/common/utils/env';
import getImUrl from '@ad/kuaishou-fontend-ad-open-im';
import { isPC } from './utils/getDeviceInfo';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { compareVersion } from './utils/compareVersion';

let adBridge: (typeof import('@ad/ks-ad-bridge'))['bridge'] | undefined;
// let getImUrl: typeof import('@ad/kuaishou-fontend-ad-open-im').default | undefined;
let clipboard: typeof import('clipboard') | undefined;
let webLogger: any;
let imported = false;

export async function importBridge() {
    return new Promise(async (resolve, reject) => {
        if (imported) {
            console.log('[importBridge]: 已加载完成，跳过');
            resolve(null);
        }
        // import('@ad/kuaishou-fontend-ad-open-im')
        //     .then(({ default: get }) => {
        //         getImUrl = get;
        //     })
        //     .catch((e) => {
        //         console.log('open-im fetch error', e);
        //     });
        import('clipboard')
            .then(({ default: def }) => {
                clipboard = def;
            })
            .catch((e) => {
                console.log('clipboard  fetch error', e);
            });

        try {
            if (isDevelopment) {
                resolve(null);
                return;
            }
            console.log('加载ks ad bridge');
            const { bridge } = await import('@ad/ks-ad-bridge');
            console.log('加载ks ad bridge 完成');
            adBridge = bridge;
            adBridge?.config?.({ handler: 'callAdBridge' });
            imported = true;
            resolve(null);
        } catch (e) {
            console.log('ad-bridge fetch error', e);
        }
    });
}

// 包装exitWebview的失败成功打点，其他方法以后再统一处理
export function exitWebview() {
    try {
        if (!isPC()) {
            if (isInApp) {
                yoda.invoke('webview.exitWebView');
            } else {
                bridgeGetDeviceInfo().then((res) => {
                    if (compareVersion(res?.data?.SDKVersion, '3.3.65') >= 0) {
                        bridge?.exitWebView();
                    }
                });
            }
        }
        trackBridgeSuccess('exitWebview');
    } catch (e) {
        trackBridgeError('exitWebview');
    }
}

interface deviceInfoProps {
    result?: number;
    data?: {
        [key: string]: any;
    };
}

// 包装bridge getDeviceInfo
export async function bridgeGetDeviceInfo(): Promise<deviceInfoProps> {
    if (isInApp) {
        try {
            const res = await yoda.invoke('webview.getDeviceInfo');
            trackBridgeSuccess('getDeviceInfo');
            return res;
        } catch (e) {
            trackBridgeError('getDeviceInfo', JSON.stringify(e));
            return {} as deviceInfoProps;
        }
    } else {
        try {
            const res = await bridge?.getDeviceInfo();
            console.log('------ lianmeng getDeviceInfo result', res);
            trackBridgeSuccess('getDeviceInfo');

            let result = res;
            if (typeof res === 'string') {
                result = JSON.parse(res);
            }

            return result;
        } catch (e) {
            trackBridgeError('getDeviceInfo', JSON.stringify(e));
            return {} as deviceInfoProps;
        }

        // return new Promise<deviceInfoProps>((resolve) => {
        //     if (!window.KwaiAd) {
        //         const res = {
        //             data: {
        //                 uuid: `${encodeURIComponent(getPageId())}${ua}`,
        //             },
        //         };
        //         resolve(res);
        //     } else {
        //         window.callback_getDeviceInfo = (res) => {
        //             let jsonRes = res;
        //             if (typeof res === 'string') {
        //                 jsonRes = JSON.parse(res);
        //             }
        //             if (jsonRes.result !== 1) {
        //                 sendTraceLog({
        //                     tags: {
        //                         url: window.location.href,
        //                         ua: ua,
        //                         pageId: getPageId(),
        //                         response: JSON.stringify(res),
        //                         specTag: 'tel_uuid_error_extend',
        //                     },
        //                 });
        //                 console.error('failed: ', JSON.stringify(res));
        //                 trackBridgeError('getDeviceInfo', JSON.stringify(res));
        //                 resolve({} as deviceInfoProps);
        //             } else {
        //                 sendTraceLog({
        //                     tags: {
        //                         url: window.location.href,
        //                         ua: ua,
        //                         pageId: getPageId(),
        //                         response: JSON.stringify(res),
        //                         specTag: 'tel_uuid_success',
        //                     },
        //                 });
        //                 console.log('success: ', JSON.stringify(res));
        //                 trackBridgeSuccess('getDeviceInfo');
        //                 res = jsonRes;
        //                 resolve(res);
        //             }
        //         };
        //         callLianmengBridge({
        //             action: 'getDeviceInfo',
        //             data: JSON.stringify({}),
        //             callback: 'callback_getDeviceInfo',
        //         });
        //     }
        // });
    }
}

export function getAdBridge() {
    if (!adBridge) {
        trackAsyncModuleError('adBridge');
    }
    return adBridge;
}

export function getIm() {
    if (!getImUrl) {
        trackAsyncModuleError('getImUrl');
    }
    return getImUrl;
}

export function getClipboard() {
    if (!getImUrl) {
        trackAsyncModuleError('clipboard');
    }
    return clipboard;
}
