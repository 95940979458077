import { createModel } from 'rt-model';

/** 用户协议样式 */
export enum UserAgreementStyle {
    DEFAULT,
    STYLE_1,
    STYLE_2,
    STYLE_3,
    STYLE_4,
}

/** 落地页配置信息model */
export const configInfoModel = createModel({
    namespace: 'config',
    defaultState: {} as { userAgreementStyle: UserAgreementStyle },
    reducers: {},
});
