import React from 'react';
import { IGoodsInfo } from 'landingPage/common/api/interface';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import styles from './style.module.less';

export function Goods(props: { goods: IGoodsInfo[] }) {
    const { goods } = props;
    const goodsItem = goods.sort((first, second) => first.order - second.order);
    return (
        <div className={styles.storeGoods}>
            <div className={styles.title}>服务商品</div>
            <div className={styles.detail}>
                {goodsItem.map((good) => {
                    return (
                        <div className={styles.goodItem} key={good.goodsName}>
                            <div className={styles.avatar}>
                                <img
                                    src={transformCDN2Escape(good.goodsPic)}
                                    alt={good.goodsName}
                                />
                            </div>
                            <div className={styles.goodDetail}>
                                <div className={styles.goodName}>{good.goodsName}</div>
                                <div className={styles.goodDesc}>{good.goodsDesc}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
