import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useComContext, useRenderState } from 'omega-render';
import cs from 'classnames';
import MutationObserver from 'mutation-observer';
import { useUpdateForm } from 'landingPage/common/jsonTransform/updateFormUserAgreement';
import { ComWrapper } from 'landingPage/common/ComWrapper';
import styles from './style.module.less';
import { getContainerType } from 'landingPage/common/utils/getContainerType';
import {
    CONTAINER_TYPE,
    extractUrlFromBackgroundImage,
    formatBackgroundImageFromUrl,
    useFixedBarStatus,
} from './utils/useContainerStatus';
import { useLazyImg } from 'landingPage/common/utils/useLazyImg';
import { plcContainerState } from 'landingPage/components/Blank/utils/registerPlcContainerState';
import { isIOS } from 'omega-shared/utils';
import { useTheme } from '@/landingPage/common/utils/useTheme';

/** 现在 */
export interface BlankProps {
    style?: React.CSSProperties & {
        tempHeight?: number;
    };
    activeOption?: string;
    /** 当没有height的时候的占位高度，优化没有初始height的加载闪动 */
    tempHeight?: string;
    /** 部分中间页模板强制要求底部悬浮按钮需要带底色，配置这个属性来兼容 */
    alwaysShowBackground?: boolean;
}

export type domHeight = {
    [key in string]: { width: number; height: number };
};
export const Div = ComWrapper.div;
export const Ol = ComWrapper.ol;
export const whiteColor = [
    'rgba(255, 255, 255, 1)',
    'rgb(255, 255, 255)',
    'white',
    '#ffffff',
    '#fff',
];

/** 动态计算组件高度，将子组件高度塞进domHeightTree，并返回子组件node list */
export function calculateHeight(id: string, domHeightTree: domHeight, first?: boolean) {
    const node = window.document.querySelector<HTMLElement>(
        `.${'rolex_com_wrapper'}[data-id='${id}']`,
    );
    let children: Element[] = node?.children ? Array.from(node.children) : [];
    if (first) {
        if (node?.children[0]) {
            children = [node?.children[0]];
        } else {
            return;
        }
    }
    for (let i = 0; i < Number(children?.length); i++) {
        const child = children[i];
        const dataId = (children[i]?.children[0] as HTMLElement)?.dataset.id;
        calculateChildHeight({ node: child, id: String(dataId), domHeightTree });
    }
    return { node: children };
}

/** 获取子组件高度，将宽高塞进domHeightTree，key为data-id，value为宽高 */
export function calculateChildHeight(param: {
    node: Element;
    id: string;
    domHeightTree: domHeight;
}) {
    const { node, id, domHeightTree } = param;
    let child = node;
    if ((node?.children[0] as HTMLElement)?.dataset.componentType === 'DRAG_IMAGE') {
        child = node?.children[0];
    }
    const extraHeight = calculateExtraHeight(child);
    const { width, height } = child.getBoundingClientRect();
    domHeightTree[`${id}`] = {
        width,
        height: height + extraHeight,
    };
}

/** 遍历domHeightTree，获取子组件中最高的高度并将其返回 */
export function calculateMaxHeight(domHeightTree: domHeight) {
    let maxHeight = 0;
    for (const key in domHeightTree) {
        if (domHeightTree[key].height > maxHeight) {
            maxHeight = domHeightTree[key].height;
        }
    }
    return maxHeight;
}

/** 计算组件的偏移和一些特殊组件的高度偏移 */
function calculateExtraHeight(node: Element) {
    let extraHeight = 0;
    if ((node?.children[0] as HTMLElement)?.dataset?.componentType === 'DRAG_TEXT') {
        extraHeight = 10;
    }
    const { offsetTop } = node as HTMLElement;
    return (offsetTop ?? 0) + extraHeight;
}
let observerInstance: MutationObserver;

const Blank: React.FunctionComponent<BlankProps> = (props) => {
    const { magic, edit, id } = useComContext();
    const components = useRenderState((state) => state.rolex.componentsMap[id]?.json?.components);
    const { style, activeOption, children, alwaysShowBackground } = props;
    const divRef = useRef<HTMLDivElement | null>(null);

    // 获取pageBackground的临时方案，后续要从useRenderState中统一获取
    const pageStyleList = document.body.getAttribute('style')?.split(';');
    const pageBackground = pageStyleList
        ?.filter((item) => {
            return item.includes('background-color');
        })[0]
        ?.split(':')?.[1];

    // const [isFullScreen, setIsFullScreen] = useState(false);
    const { tempHeight } = style as React.CSSProperties & {
        tempHeight?: number;
    };
    const [backupHeight, setBackupHeight] = useState<number>();
    const hasHeight =
        ((magic?.bottomBlank || magic?.topBlank) && magic?.editHeight) || style?.height;
    /** 子组件高度树 */
    const domHeightTree = React.useRef<domHeight>({});

    /** 对每个子组件进行监听，高度变化时执行子组件高度计算逻辑，并重新获取最大高度 */
    const observe = useCallback(
        (node: Element) => {
            observerInstance = new MutationObserver(() => {
                const dataId = (node?.children[0] as HTMLElement)?.dataset.id;
                calculateChildHeight({
                    node,
                    id: String(dataId),
                    domHeightTree: domHeightTree.current,
                });
                const maxHeight = calculateMaxHeight(domHeightTree.current);
                if (maxHeight) {
                    setBackupHeight(maxHeight);
                }
            });
            observerInstance.observe(node, {
                attributes: true,
                childList: true,
                subtree: true,
            });
        },
        [domHeightTree],
    );

    /** 注册监听plc容器bridge事件 */
    useFixedBarStatus({
        isIOS: isIOS,
        containerType: getContainerType(),
        fixedBarElement: divRef,
    });
    useEffect(() => {
        /** 未明确设置高度，开始动态计算子组件的高度来撑高blank */
        if (!hasHeight && !backupHeight) {
            /** TODO：优化判断，如果不是智能中间页，只根据首个子元素的高度撑开 */
            const first = !components?.every((com) => com.type === 'DRAG_TEXT');
            /** 初始化子组件高度树，获取子组件node list */
            const { node } = calculateHeight(id, domHeightTree.current, first) ?? {};
            if (node?.length) {
                for (let i = 0; i < node.length; i++) {
                    observe(node[i]);
                }
            }
            const maxHeight = calculateMaxHeight(domHeightTree.current);
            if (maxHeight) {
                setBackupHeight(maxHeight);
            }
        }
    }, [backupHeight, hasHeight, id, observe, domHeightTree]);
    // useEffect(() => {
    // if (components?.find((com) => com.magic?.dragFree?.isFullScreen)) {
    // setIsFullScreen(true);
    // }
    // }, [components]);
    const updateFormParentHeight = useUpdateForm(id);

    /** 是否展示75分屏相关样式 */
    const show75SceenStyle = getContainerType() === CONTAINER_TYPE.halfScreen && magic?.bottomBlank;

    const { plcContainerState } = useRenderState((state) => {
        return { plcContainerState: state.plcContainer as plcContainerState };
    });
    // blankStyle不能放在useEffect里面进行计算，ssr不会执行useEffect里面的代码
    let blankStyle = { ...style, height: style?.height ?? backupHeight ?? tempHeight };
    if (activeOption === 'color') {
        delete blankStyle.backgroundImage;
    }
    // 智能中间页Blank预先撑起高度
    // const pageJsonType = useRenderState((state) => state.rolex.opt?.originalPageJson?.type);
    // if (pageJsonType === EnumPageType.智能中间页 && blankStyle.height === undefined) {
    if (blankStyle.height === undefined) {
        blankStyle.height = 500;
    }
    /** 顶底部悬浮位置 & 样式计算 */
    if ((magic?.topBlank || magic?.bottomBlank) && style && !edit) {
        blankStyle = {
            ...style,
            position: 'fixed',
            bottom: show75SceenStyle ? plcContainerState.fixedBarBottomPixel : 0,
            top: magic?.topBlank && 0,
            zIndex: 999,
            height: style.height ?? magic.editHeight ?? backupHeight ?? tempHeight,
        };
    }
    const { color: backgroundColor } = useTheme({
        path: 'style.backgroundColor',
        options: {
            type: 'activeFill',
        },
    });
    blankStyle.backgroundColor = backgroundColor;
    // 兼容pageScale 导致的gap问题
    /** 为兼容pageScale导致的gap，
     * 需要将顶底部悬浮 & 背景色是白色的部分删除背景色
     * 部分中间页模板需要强制保留背景色，所以配置alwaysShowBackground 来控制
     * 如果alwaysShowBackground为true，则直接不删除backgroundColor */
    if (
        !alwaysShowBackground &&
        (magic?.bottomBlank ||
            magic?.topBlank ||
            (whiteColor.includes(blankStyle.backgroundColor ?? '') &&
                whiteColor.includes(pageBackground ?? '')))
    ) {
        delete blankStyle.backgroundColor;
    }
    const extractedBackgroundUrl = extractUrlFromBackgroundImage(blankStyle.backgroundImage);
    // const [lazyImgs, setLazyImgs] = useState<{ [key: string]: string }>({});
    const { lazyImgs: lazyBackgroundImage, domRef: lazyRef } = useLazyImg(
        [
            {
                imgUrl: extractedBackgroundUrl,
            },
        ],
        {
            edit,
        },
    );
    // const [curbackgroundImage, setCurbackgroundImage] = useState(
    //     blankStyle.backgroundImage
    //         ? formatBackgroundImageFromUrl(
    //               getEnhanceImageUrl(
    //                   extractUrlFromBackgroundImage(blankStyle.backgroundImage),
    //                   isSupportWebp,
    //                   true,
    //               ),
    //           )
    //         : '',
    // );
    // useEffect(() => {
    //     if (style?.backgroundImage) {
    //         const url = extractUrlFromBackgroundImage(style?.backgroundImage);
    //         const img = new Image();
    //         img.src = getPicCDNSource(url, isSupportWebp);
    //         img.onload = function () {
    //             setCurbackgroundImage(
    //                 formatBackgroundImageFromUrl(getPicCDNSource(url, isSupportWebp)),
    //             );
    //         };
    //     }
    // }, [style]);

    // const fullScreenHright = edit ? '603px' : '100vh';
    /** 异步更新逻辑：撑起form的parent blank高度 */
    updateFormParentHeight(blankStyle);
    return (
        <Div
            // ref={domRef}
            className={cs([styles.root, magic?.bottomBlank ? styles.bottomBlank : ''], {
                [styles['fixed-bootom']]: show75SceenStyle,
                [styles.hide]: !plcContainerState.fixedBarVisible && show75SceenStyle,
                [styles.pausedAnimation]: !plcContainerState.fixedBarVisible && show75SceenStyle,
            })}
            ref={(node) => {
                divRef.current = node;
                (lazyRef as unknown as React.MutableRefObject<HTMLDivElement | null>).current =
                    node;
            }}
            style={{
                ...blankStyle,
                backgroundImage: formatBackgroundImageFromUrl(
                    extractedBackgroundUrl ? lazyBackgroundImage[extractedBackgroundUrl] : '',
                ),
                // height: isFullScreen ? fullScreenHright : blankStyle?.height,
            }}
        >
            {children}
        </Div>
    );
};

export default Blank;
