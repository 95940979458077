import * as React from 'react';

import { useComContext } from 'omega-render';
import cs from 'classnames';
import { Type1 } from './Type1';
import { Type3 } from './Type3';
import styles from './style.module.less';
import { ShufflingImageProps } from './ShufflingImage';

const textClassName = 'shuffling-image-label-text';
export const ShufflingImageGroup: React.FunctionComponent<ShufflingImageProps> = (props) => {
    const {
        groupImageConfig,
        renderType = 1,
        groupStyles,
        activeLabelStyles,
        labelStyles,
        activeBorderStyles,
    } = props;
    const { magic } = useComContext();
    const [activeIndex, setActiveIndex] = React.useState(0);

    return (
        <div className={cs(styles.imageGroup)}>
            <div className={cs(styles.group, styles.groupFlex)} style={groupStyles}>
                {groupImageConfig.map((itemImageConfig, index) => {
                    const isActive = index === activeIndex;
                    const labelStylesTemp = isActive ? activeLabelStyles : labelStyles;
                    return (
                        <div
                            key={itemImageConfig.id}
                            className={styles.label}
                            style={{
                                ...labelStylesTemp,
                                width: `${Math.floor((1 / groupImageConfig.length) * 100)}%`,
                            }}
                            onClick={() => {
                                setActiveIndex(index);
                            }}
                        >
                            <span
                                className={cs(styles.labelWrapper, {
                                    [styles.labelActive]: activeIndex === index,
                                })}
                            >
                                <span className={cs(styles.labelText, textClassName)}>
                                    {itemImageConfig.name || `组${index + 1}`}
                                </span>

                                {isActive && (
                                    <span
                                        className={styles.labelAfter}
                                        style={{
                                            backgroundColor: activeBorderStyles?.backgroundColor,
                                        }}
                                    />
                                )}
                            </span>
                        </div>
                    );
                })}
            </div>

            {renderType === 3 ? (
                <Type3
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    imgs={groupImageConfig[activeIndex].imgs}
                    height={magic?.dragFree.height - 48}
                    width={magic?.dragFree.width}
                />
            ) : (
                <Type1
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    imgs={groupImageConfig[activeIndex].imgs}
                    width={magic?.dragFree.width}
                />
            )}
        </div>
    );
};
