import { isDevelopment } from './env';
/**
 * 域名白名单限制，防止页面被第三方抓取后执行逻辑
 */
// 域名白名单
const WhiteDomain = ['kuaishou.com', 'chenzhongkj.com'];

export const isInWhiteDomain = () => {
    if (isDevelopment) return true;
    const { host } = window.location;
    return (
        WhiteDomain.filter((domain) => {
            return host.includes(domain);
        }).length > 0
    );
};
