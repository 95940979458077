import { getPicCDNSource } from '@/landingPage/common/utils/getUrlParam';
import { useComContext } from 'omega-render';
import { AppDownloadProps } from '../../AppDownload';
import defaultSingleImage from './defaultSingleImage.png';
import styles from './style.module.less';
import { Div } from '@/landingPage/components/Blank/Blank';

interface HotAreaProps extends Pick<AppDownloadProps, 'hotAreaInfo'> {
    onClick: () => void;
}

export default function HotArea(props: HotAreaProps) {
    const { hotAreaInfo, onClick } = props;
    const { edit, magic } = useComContext();
    return (
        <Div
            className={styles.wrapper}
            style={{
                backgroundImage: hotAreaInfo?.picUrl
                    ? `url(${getPicCDNSource(hotAreaInfo?.picUrl)})`
                    : `url(${defaultSingleImage})`,
                backgroundSize: '100% 100%',
            }}
        >
            {hotAreaInfo?.hotAreas?.map((hotArea) => (
                // eslint-disable-next-line react/jsx-key
                <div
                    className={styles.hotAreaWrapper}
                    onClick={onClick}
                    style={{
                        top: magic?.dragFree.height * hotArea.relativeY,
                        left: magic?.dragFree.width * hotArea.relativeX,
                        width: magic?.dragFree.width * hotArea.width,
                        height: magic?.dragFree.height * hotArea.height,
                        background: edit
                            ? (hotAreaInfo?.defaultBgColor ?? 'rgba(0, 117, 255, 0.1)')
                            : '',
                        border: edit
                            ? `1px solid ${hotAreaInfo?.defaultBorderColor ?? '#0075FF'}`
                            : '',
                    }}
                />
            ))}
        </Div>
    );
}
