import { trackPVEnter } from './adTrack/util/zt';

// import * as Sentry from '@sentry/browser';
export const initRadar = async () => {
    console.log('this is ====== yoda2.0 ---222');
    console.log('initRadar>>>>>>>>');
    // initWeblog();
    trackPVEnter();
    // initSentry();
};
// let Sentry: typeof import('@sentry/browser') | undefined;

// export async function initSentry() {
//     console.log('initSentry>>>>>>>>');
//     Sentry = await import('@sentry/browser');
//     console.log('>>>>>>>', { Sentry });
//     Sentry.init({
//         dsn: 'https://44c1901d1712436a9062fa942ca85013@sentry-web.corp.kuaishou.com/2227',
//         release: process.env.GIAO_SENTRY_RELEASE,
//         sampleRate: 1,
//         environment: process.env.NODE_ENV,
//         // allowUrls: ['chenzhongkj.com', 'moli.kuaishou.com'],
//         ignoreErrors: ['Refused to evaluate a string as JavaScript because'],
//     });
//     window.sentry_Init = true;
//     console.log('>>>>>>>', window.sentry_Init);
//     console.log(window.sentry_errors);
//     window?.sentry_errors?.forEach((err) => {
//         Sentry?.captureException(err);
//     });
// }

// export function getSentry() {
//     return Sentry;
// }
