import { useNewStoreList } from './useNewStoreList';
import { useStoreList } from './useStoreList';

export function useAdaptStoreList(newStoreUser: boolean) {
    const oldValue = useStoreList();
    const newValue = useNewStoreList();
    let res: typeof oldValue | typeof newValue;
    if (newStoreUser) {
        res = newValue;
    } else {
        res = oldValue;
    }
    return res;
}

export type OldGetStoreFunc = ReturnType<typeof useStoreList>[2];
export type NewGetStoreFunc = ReturnType<typeof useNewStoreList>[2];
