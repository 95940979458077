/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// import { message } from 'm-ui';
import { Toast } from '@ad/sharpui';
import ax from 'axios';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { trackApi, trackApiError } from 'landingPage/common/adTrack/monitor';
import * as model from './models';
import { getMoliHost } from 'landingPage/common/utils/env';
import { getImApiUrl } from '../components/CustomerServiceDrag/utils';
// import { trackApiError, trackApi } from '../util/adMonitor';

// 单独使用的axios
const axios = ax.create({
    headers: {
        'Moli-Front-Trace-Id': window.SSR_TRACE_ID,
    },
});

axios.interceptors.response.use(
    (res) => {
        const { data } = res;
        // 获取请求url（不包含后面携带的参数）
        const getPathUrl = res?.config?.url?.split('?')?.[0] ?? '';
        if (data && +data.result === 1) {
            trackApi(getPathUrl);
            return res;
        }
        trackApiError(getPathUrl, `${res?.data?.result}`);

        if (data && +data.result === 109) {
            data.error_msg = '网络连接失败，请刷新当前页面。';
        }
        if (data && data.result === 206) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            Toast.error(data.msg);
        }

        return Promise.reject(data);
    },
    async (err) => Promise.reject(err),
);

/** Agent Controller  代理商登录 */
export async function loginUsingGET(params: {
    agentUserId?: number;
    accountUcId?: number;
    accountUserId?: number;
    adminUserId?: number;
}) {
    const paramArr: string[] = [];
    for (const key in params) {
        paramArr.push(
            `${key}=${
                typeof (params as any)[key] === 'string'
                    ? (params as any)[key]
                    : JSON.stringify((params as any)[key])
            }`,
        );
    }
    const result = await axios.get<any>(`/rest/lp/agent/login?${paramArr.join('&')}`);
    return result.data.data;
}

/** Agent Controller  代理商登出 */
export async function logoutUsingGET() {
    const result = await axios.get<any>(`/rest/lp/agent/logout`);
    return result.data.data;
}

/** 应用中心相关接口  根据应用pack获取应用名 */
export async function getAppNameUsingPOST(params: { packageName?: string }) {
    const result = await axios.post<model.IMessageAppNameResult>(`/rest/lp/app/getAppName`, params);
    return result.data.data;
}

/** 应用中心相关接口  建站-获取应用列表 */
export async function listAppUsingPOST() {
    const result = await axios.post<model.IAdDspApp[]>(`/rest/lp/app/list`);
    return result.data;
}

/** 应用中心相关接口  应用上传或更新接口 */
export async function uploadAppUsingPOST(params: { appFile?: File; app: model.ICreateAppParam }) {
    const formData = new FormData();
    for (const key in params) {
        formData.append(key, (params as any)[key]);
    }
    const result = await axios.post<number[]>(`/rest/lp/app/update`, formData);
    return result.data;
}

/** Config Controller  announcement */
export async function announcementUsingGET() {
    const result = await axios.get<model.IMessageobject>(`/rest/lp/config/announcement`);
    return result.data.data;
}

/** Config Controller  outlinks */
export async function outlinksUsingGET() {
    const result = await axios.get<model.IMessageListstring>(`/rest/lp/config/out-link`);
    return result.data.data;
}

/** 小说接口  批量创建小说模板 */
export async function batchCreateFictionPageUsingPOST(params: any) {
    const result = await axios.post<model.IMessageListAdLpPageView>(
        `/rest/lp/fiction/create/batch`,
        { ...params },
    );
    return result.data.data;
}

/** 小说接口  小说列表 */
export async function fictionListUsingPOST(params: any) {
    const result = await axios.post<any>(`/rest/lp/fiction/list`, { ...params });
    return result.data;
}

/** 小说接口  批量保存小说模板 */
export async function batchSaveFictionPageUsingPOST(params: { fictionPageIds?: string[] }) {
    const result = await axios.post<model.IMessageSaveBatch>(`/rest/lp/fiction/save/batch`, params);
    return result.data;
}

/** 营销组件接口  创建表单 */
export async function createFormUsingPOST(params: { req: model.IFormCreateParam }) {
    const result = await axios.post<model.IMessageform>(`/rest/lp/form/create`, { ...params.req });
    return result.data.data;
}

/** 营销组件接口  根据新表单Id获取表单 */
export async function getFormByIdUsingPOST(params: { id?: string }) {
    const result = await axios.post<model.IMessageformInfoView>(`/rest/lp/form/getFormV2`, params);
    return result.data.data;
}

/** 营销组件接口  获取用户表单列表 */
export async function listFormsUsingPOST(params: { param: model.IFormListParam }) {
    const result = await axios.post<model.IMessagecommonListResultform>(`/rest/lp/form/list`, {
        ...params.param,
    });
    return result.data.data;
}

/** 营销组件接口  获取用户默认表单组件列表 */
export async function listDefaultFormComponentsUsingPOST() {
    const result = await axios.post<model.IMessagecommonListResultAdLpFormComponentView>(
        `/rest/lp/form/listDefaultFormComponents`,
    );
    return result.data.data;
}

/** 营销组件接口  获取用户新表单列表 */
export async function listFormsNUsingPOST(params: { param: model.IFormListParam }) {
    const result = await axios.post<model.IMessagecommonListResultformInfoView>(
        `/rest/lp/form/listN`,
        {
            ...params.param,
        },
    );
    return result.data.data;
}

/** 营销组件接口  获取用户售卖活动类别 */
export async function listSaleActivityNUsingPOST(params: { param: model.ISaleListParam }) {
    const result = await axios.post<model.IMessagecommonListResultSaleActivityView>(
        `/rest/lp/activity/saleActivity/list`,
        {
            ...params.param,
        },
    );
    return result.data.data;
}

/** 主页接口  获取首页Banner图列表 */
export async function bannerUsingGET() {
    const result = await axios.get<model.IMessageListHomeBannerView>(`/rest/lp/home/banner/list`);
    return result.data.data;
}

/** 主页接口  获取首页消息中心数据 */
export async function messageCenterUsingPOST(params: { request: model.IAdLpMessageQueryReq }) {
    const result = await axios.post<model.IMessagecommonListResultHomeMessageCenterView>(
        `/rest/lp/home/message/list`,
        {
            ...params.request,
        },
    );
    return result.data.data;
}

/** 主页接口  设置消息为已读状态 */
export async function setMessageHaveReadUsingPOST(params: {
    request: model.ISetMessageHaveReadReq;
}) {
    const result = await axios.post<model.IMessageobject>(`/rest/lp/home/message/read`, {
        ...params.request,
    });
    return result.data;
}

/** 主页接口  获取首页数据概览数据 */
export async function summaryUsingPOST(params: { reportParam: model.IHomeReportQueryReq }) {
    const result = await axios.post<model.IMessageHomeReportView>(`/rest/lp/home/report/summary`, {
        ...params.reportParam,
    });
    return result.data.data;
}

/** 主页接口  获取首页模板列表 */
export async function templateListUsingPOST(params: {
    templateListRequest: model.IHomeTemplateListRequest;
}) {
    const result = await axios.post<model.IMessagecommonListResultAdLpPageView>(
        `/rest/lp/home/template/list`,
        {
            ...params.templateListRequest,
        },
    );
    return result.data.data;
}

/** Chat Controller  去授权 */
export async function gotoGrantUsingGET(params: { cb: string; appId: number }) {
    const paramArr: string[] = [];
    for (const key in params) {
        paramArr.push(
            `${key}=${
                typeof (params as any)[key] === 'string'
                    ? (params as any)[key]
                    : JSON.stringify((params as any)[key])
            }`,
        );
    }
    const result = await axios.get<undefined>(`/rest/lp/im/app/grant?${paramArr.join('&')}`);
    return result.data;
}

/** Chat Controller  all客服应用列表 */
export async function appListUsingPOST() {
    const result = await axios.post<model.IMessageListAdLpIMAppView>(`/rest/lp/im/app/list`);
    return result.data.data;
}

/** Chat Controller  解除授权 */
export async function goUnGrantUsingPOST(params: { grantId?: number }) {
    const result = await axios.post<model.IMessageVoid>(`/rest/lp/im/app/un-grant`, params);
    return result.data;
}

/** Chat Controller  创建咨询页 */
export async function createChatPageUsingPOST(params: { param: model.IChatPageCreateParam }) {
    const result = await axios.post<model.IMessagelong>(`/rest/lp/im/chat-page/create`, {
        ...params.param,
    });
    return result.data.data;
}

/** Chat Controller  咨询页列表 */
export async function listChatPageUsingPOST(params: { req: model.IAdLpChatPageReq }) {
    const result = await axios.post<model.IMessagecommonListResultChatPageView>(
        `/rest/lp/im/chat-page/list`,
        {
            ...params.req,
        },
    );
    return result.data.data;
}

/** Chat Controller  已授权客服列表 */
export async function grantedListUsingPOST() {
    const result = await axios.post<model.IMessageListIMGrantView>(`/rest/lp/im/granted/list`);
    return result.data.data;
}

/** Metadata Controller  getClueMetaData */
export async function clueStatUsingGET() {
    const result =
        await axios.get<model.IMessageMapstringListMapstringobject>(`/rest/lp/metadata/clue`);
    return result.data.data;
}

/** 落地页接口  view组件列表 */
export async function queryCompsUsingGET(params: { bizId?: number }) {
    const paramArr: string[] = [];
    for (const key in params) {
        paramArr.push(
            `${key}=${
                typeof (params as any)[key] === 'string'
                    ? (params as any)[key]
                    : JSON.stringify((params as any)[key])
            }`,
        );
    }
    const result = await axios.get<model.IMessageListViewCompView>(
        `/rest/lp/page/component?${paramArr.join('&')}`,
    );
    return result.data.data;
}

/** 落地页接口  创建落地页 */
export async function createPageUsingPOST(params: { req: model.IPageCreateReq }) {
    const result = await axios.post<model.IMessageAdLpPageView>(`/rest/lp/page/create`, {
        ...params.req,
    });
    return result.data.data;
}

export async function createAndPublishPageUsingPOST(params: { req: model.IPageCreateReq }) {
    const result = await axios.post<model.IMessageAdLpPageView>(`/rest/lp/page/createAndPublish`, {
        ...params.req,
    });
    return result.data.data;
}

/** 落地页接口  创建预览 */
export async function createPreviewUsingPOST(params: { previewParam: model.IPagePreviewParam }) {
    const result = await axios.post<model.IMessagestring>(`/rest/lp/page/createPreview`, {
        ...params.previewParam,
    });
    return result.data.data;
}

/** 落地页接口  删除页面 */
export async function deleteUsingPOST(params: { id?: string }) {
    const result = await axios.post<model.IMessageVoid>(`/rest/lp/page/delete`, params);
    return result.data;
}

/** 落地页接口  获取已删除落地页摘要列表 */
export async function listDeletedSummaryUsingPOST(params: { req: model.IPagePreviewParam }) {
    const result = await axios.post<model.IMessagecommonListResultAdLpPageView>(
        `/rest/lp/page/deleted/list`,
        {
            ...params.req,
        },
    );
    return result.data.data;
}

/** 落地页接口  获取落地页详情 （目前一直没用过） */
export async function getPageUsingGET(params: { id?: string }) {
    const paramArr: string[] = [];
    for (const key in params) {
        paramArr.push(
            `${key}=${
                typeof (params as any)[key] === 'string'
                    ? (params as any)[key]
                    : JSON.stringify((params as any)[key])
            }`,
        );
    }
    const result = await axios.get<model.IMessageAdLpPageView>(
        `/rest/lp/page/get?${paramArr.join('&')}`,
    );
    return result.data.data;
}

/** 落地页接口  查看预览页面 */
export async function getPreviewUsingGET(params: { key: string }) {
    const paramArr: string[] = [];
    for (const key in params) {
        paramArr.push(
            `${key}=${
                typeof (params as any)[key] === 'string'
                    ? (params as any)[key]
                    : JSON.stringify((params as any)[key])
            }`,
        );
    }
    const result = await axios.get<string>(`/rest/lp/page/getPreview?${paramArr.join('&')}`);
    return result.data;
}

/** 落地页接口  白名单用户,复制落地页时,获得去重后的标题和名称 */
export async function uniqueTitleUsingPOST(params: { req: model.IAdLpPageUniqueTitleReq }) {
    const result = await axios.post<model.IMessageadLpPageUniqueTitleView>(
        `/rest/lp/page/getUniqueName`,
        {
            ...params.req,
        },
    );
    return result.data.data;
}

/** 落地页接口  获取落地页列表 */
export async function listPageUsingPOST(params: { req: model.IPagePreviewParam }) {
    const result = await axios.post<model.IMessagecommonListResultAdLpPageView>(
        `/rest/lp/page/list`,
        {
            ...params.req,
        },
    );
    return result.data.data;
}

/** 落地页接口  发布页面 */
export async function publishPageUsingPOST(params: { id?: string; coverImgUrl?: string }) {
    const result = await axios.post<model.IMessageVoid>(`/rest/lp/page/publish`, params);
    return result.data;
}

/** 落地页接口  批量发布落地页 */
export async function batchPublishUsingPOST(params: { pageIds?: string[] }) {
    const result = await axios.post<model.IMessageVoid>(`/rest/lp/page/publish/batch`, params);
    return result.data;
}

/** 落地页接口  删除页面 */
export async function restorePageUsingPOST(params: { id?: string }) {
    const result = await axios.post<model.IMessageVoid>(`/rest/lp/page/restore`, params);
    return result.data;
}

/** 落地页接口  保存页面 */
export async function savePageUsingPOST(params: { req: model.IPageEditReq }) {
    const result = await axios.post<model.IMessageAdLpPageView>(`/rest/lp/page/save`, {
        ...params.req,
    });
    return result.data.data;
}

/** 落地页接口  获取落地页摘要列表 */
export async function listSummaryUsingPOST(params: { req: model.IPagePreviewParam }) {
    const result = await axios.post<model.IMessagecommonListResultAdLpPageView>(
        `/rest/lp/page/summary/list`,
        {
            ...params.req,
        },
    );
    return result.data.data;
}

/** 落地页接口  修改页面名 */
export async function updateNameUsingPOST(params: { name?: string; id?: string }) {
    const result = await axios.post<model.IMessageAdLpPageView>(
        `/rest/lp/page/update-name`,
        params,
    );
    return result.data.data;
}

/** 落地页接口  验证落地页标题是否唯一 */
export async function verifyPageNameUsingPOST(params: { req: model.IAdLpVerifyUniqueNameReq }) {
    const result = await axios.post<model.IMessageVoid>(`/rest/lp/page/verifyName`, {
        ...params.req,
    });
    return result.data;
}

/** Phone Controller  查询智能电话 */
export async function listIccUsingPOST() {
    const result = await axios.post<model.IMessagecommonListResulticc>(`/rest/lp/phone/listIcc`);
    return result.data.data;
}

/** Upload Controller  photoUpload */
export async function photoUploadUsingPOST(params: { file: File }) {
    const formData = new FormData();
    for (const key in params) {
        formData.append(key, (params as any)[key]);
    }
    const result = await axios.post<model.IMessageUploadView>(`/rest/lp/photo/upload`, formData);
    return result.data.data;
}

/** 报表接口  chart */
export async function chartUsingPOST(params: { reportParam: model.IReportQueryReq }) {
    const result = await axios.post<model.IMessageReportChart>(`/rest/lp/report/chart`, {
        ...params.reportParam,
    });
    return result.data.data;
}

/** 报表接口  summary */
export async function reportSummaryUsingPOST(params: { reportParam: model.IReportQueryReq }) {
    const result = await axios.post<model.IMessageReportView>(`/rest/lp/report/summary`, {
        ...params.reportParam,
    });
    return result.data.data;
}

/** 落地页模板接口  获取模板列表 */
export async function listTemplatesUsingPOST(params: { req: model.ITemplateListRequest }) {
    const result = await axios.post<model.IMessagecommonListResultAdLpPageView>(
        `/rest/lp/template/list`,
        {
            ...params.req,
        },
    );
    return result.data.data;
}

/** 落地页模板接口  上传模板 */
export async function pushTemplateUsingPOST(params: any) {
    const result = await axios.post<model.IMessagestring>(`/rest/lp/template/pushTemplate`, params);
    return result.data.data;
}

/** 落地页模板接口  模板列表分类 */
export async function showCategoriesUsingGET() {
    const result = await axios.get<model.IMessageListcasCategory>(
        `/rest/lp/template/showCategories`,
    );
    return result.data.data;
}

/** 用户信息接口  短信提醒查询 */
export async function getSmsRemindUsingGET() {
    const result = await axios.get<model.IMessage>(`/rest/lp/user/getSmsRemind`);
    return result.data.data;
}

/** 用户信息接口  获取用户基本信息 */
export async function infoUsingPOST() {
    const result = await axios.post<model.IMessageobject>(`/rest/lp/user/info`);
    // result.data.data.lpUser.optimizationPageUser = true;
    return result.data.data;
}

/** 用户信息接口  修改短信提醒设置 */
export async function modSmsRemindUsingPOST(params: { phone?: string; status?: number }) {
    const result = await axios.post<model.IMessage>(`/rest/lp/user/modSmsRemind`, params);
    return result.data.data;
}

/** 用户信息接口  用户协议 */
export async function enableUserProtoUsingPOST() {
    const result = await axios.post<model.IMessageVoid>(`/rest/lp/user/proto/enable`);
    return result.data;
}

/** Upload Controller  videoUpload */
export async function videoUploadUsingPOST(params: { file: File }) {
    const formData = new FormData();
    for (const key in params) {
        formData.append(key, (params as any)[key]);
    }
    const result = await axios.post<model.IMessageUploadView>(`/rest/lp/video/upload`, formData);
    return result.data.data;
}

/** 微信接口  查询微信号码包 */
export async function listPackUsingPOST(params: { wechatListReq: model.IWechatListReq }) {
    const result = await axios.post<model.IMessagecommonListResultwechatPackView>(
        `/rest/lp/wechat/list`,
        {
            ...params.wechatListReq,
        },
    );
    return result.data.data;
}

/** 微信接口  查询单个微信号 */
export async function itemUsingGET() {
    const result =
        await axios.get<model.IMessagecommonListResultwechatItemView>(`/rest/lp/wechat/item`);
    return result.data.data;
}

/** 微信接口  查询微信包 */
export async function pageckageUsingGET() {
    const result =
        await axios.get<model.IMessagecommonListResultwechatPackageView>(`/rest/lp/wechat/package`);
    return result.data.data;
}

/** 微信接口  查询微信公众号 */
export async function listPublicItemUsingPOST() {
    const result = await axios.post<model.IMessagecommonListResultwechatItemView>(
        `/rest/lp/wechat/listPublicItem`,
    );
    return result.data.data;
}

// export async function verifyJumpUrlUsingPOST(param: string) {
//     const result = await axios.post<model.verifyJumpUrlView>(`/rest/lp/validate/verifyJumpUrl`, {
//         url: param,
//     });
//     return result.data.data;
// }

/** 校验接口  url合法性校验 */
export async function verifyJumpUrlUsingPOST(param: string) {
    const result = await axios.post<model.IMessageVerifyJumpUrlView>(
        `/rest/lp/validate/verifyJumpUrl`,
        {
            url: param,
        },
    );

    return result.data.data;
}

// let IMBaseUrl = 'https://ad-im-pc.test.gifshow.com';
// if (window.location.host.includes('localhost')) {
//     IMBaseUrl = '/rest/im';
// }

// if (isProduction) {
//     IMBaseUrl = 'https://adim.kuaishou.com';
// }
// if (isBeta) {
//     IMBaseUrl = 'https://adim-beta.corp.kuaishou.com';
// }
// if (isStaging) {
//     IMBaseUrl = 'https://adim.staging.kuaishou.com';
// }

export async function imLogin({ authToken, sid }: { authToken: string; sid: string }) {
    const res = await axios.post<any, any>(
        `${getImApiUrl()}/rest/infra/sts?authToken=${authToken}&sid=${sid}`,
        null,
        {
            withCredentials: true,
        },
    );
    return res;
}

/** 快聊客服用户信息 */
export async function imUserInfo(params: model.IImLoginRequest) {
    const result = await axios.post<model.IImUserInfo>(
        `${getImApiUrl()}/rest/web/user/info?ac=${params.accountId}&bi=103&ks=${params.ksUserId}`,
        null,
        {
            withCredentials: true,
        },
    );
    return result.data.data;
}

/** 开通快聊客服 */
export async function createImAccount(params: model.IImLoginRequest) {
    const result = await axios.post<model.IMessageAdImLoginVo>(
        `${getImApiUrl()}/rest/web/user/login?ac=${params.accountId}&bi=103&ks=${params.ksUserId}`,
        params,
        {
            withCredentials: true,
        },
    );
    return result.data.data;
}

/** 转增 */
export async function giveAwayUsingPOST(params: {
    req: {
        accountId: number;
        page: model.IGivePageView;
    };
}) {
    const result = await axios.post<model.IMessageAdLpPageView>(`/rest/lp/page/give`, {
        ...params.req,
    });
    return result.data;
}

/** 批量转增 */
export async function batchGiveUsingPOST(params: {
    accountIds: string[];
    pages?: model.IGivePageView[];
}) {
    const result = await axios.post<model.IBatchGiveView>(`/rest/lp/page/batchGive`, { ...params });
    return result.data;
}

/** 批量删除 */
export async function batchDeleteUsingPOST(pageIds: string[]) {
    const result = await axios.post<model.IBatchGiveView>(`/rest/lp/page/batchDelete`, { pageIds });
    return result.data;
}

/** 获取广告主id */
export async function SameCorpAccountIdsUsingPost() {
    const result = await axios.post<model.ISameCorpAccountIdsView>(
        `/rest/lp/page/getSameCorpAccountIds`,
    );
    return result.data;
}

/** 白名单 * */
export async function configSwitchUsingGet(params: {
    req: {
        id: number;
        caseName: string;
    };
}) {
    const result = await axios.get<model.IMessage<boolean>>(`/rest/lp/config/switch`, {
        params: { ...params.req },
    });
    return result.data;
}

/** 门店  获取门店包下任意一个门店 */
export async function getStoreUsingPOST(payload: { packageId?: string }) {
    const data = {
        packageId: payload.packageId,
    };

    const result = await axios.request<model.IMessageStoreDetailView>({
        url: `/rest/lp/store/getStore`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 门店  查询门店包 */
export async function packageListUsingGET() {
    const result = await axios.request<model.IMessagecommonListResultStorePackageView>({
        url: `/rest/lp/store/package/list`,
        method: 'get',
    });
    return result.data.data;
}

/** 门店  查询门店组信息 */
export async function storeGroupInfoUsingPOST(payload: { groupId?: string }) {
    const data = {
        groupId: payload.groupId,
    };

    const result = await axios.request<model.IResponseStoreGroupView>({
        url: `/rest/lp/store/group/info`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

export async function createStorePageUsingPOST(params: { req: model.IPageCreateReq }) {
    const result = await axios.post<model.IMessageAdLpPageView>(`/rest/lp/store/page/create`, {
        ...params.req,
    });
    return result.data;
}

/** 门店  查询门店组列表 */
export async function storeGroupListUsingPOST(payload: { req: model.IStoreListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapStoreGroupView>({
        url: `/rest/lp/store/group/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 门店  查询门店信息 */
export async function storeInfoUsingPOST(payload: { storeId?: string }) {
    const data = {
        storeId: payload.storeId,
    };

    const result = await axios.request<model.IResponseStoreView>({
        url: `/rest/lp/store/info`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 门店  查询门店列表 */
export async function storeListUsingPOST(payload: { req: model.IStoreListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseStoreMultiView>({
        url: `/rest/lp/store/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 卡券 查询卡券列表 */
export async function couponListUsingPost(params: {
    couponName?: string;
    pageSize: number;
    pageNum: number;
}) {
    const result = await axios.post<model.ICouponListView>(`/rest/lp/coupon/list`, {
        ...params,
    });
    return result.data.data;
}

/** 卡券 查询卡券详情 */
export async function couponDetailUsingPost(params: { couponId: string }) {
    const result = await axios.post<model.ICouponDetailView>(`/rest/lp/coupon/detail`, {
        ...params,
    });
    return result.data.data;
}

// 获取微信小程序信息，B端
export async function getAppletsUsingGet() {
    const result = await axios.get<model.IMessage<{ appletsName: string; appletsId: number }[]>>(
        `/rest/lp/wechat/getApplets`,
    );
    return result.data.data;
}

/** 获取微信包中微信号最长的微信号 */
export async function getWechatFromPackage(data: { itemId: number }) {
    const result = await axios.post<model.IMessage<model.IMessageWechatInfo>>(
        '/rest/lp/wechat/getWechatFromPackage',
        {
            ...data,
        },
    );
    return result.data.data;
}

/** 获取程序化落地页list */
export async function postProgrammaticList(params: any) {
    const result = await axios.post<model.IProgrammaticList>('/rest/lp/group/list', {
        ...params,
    });
    return result.data.data;
}

/** 获取程序化落地页组数据 */
export async function postProgrammaticReport(params: any) {
    const result = await axios.post<model.IProgrammaticReport>('/rest/lp/group/report', {
        ...params,
    });
    return {
        groupId: params.groupId,
        data: result.data.data,
    };
}

/** 程序化落地页投放开关 */
export async function postProgrammaticSwitch(params: any) {
    const result = await axios.post<model.IProgrammaticSwitch>('/rest/lp/group/page/switch', {
        ...params,
    });
    return result.data.data;
}

/** 删除落地页组 */
export async function postProgrammaticGroupDelete(params: { groupId: string }) {
    const result = await axios.post<model.IProgrammaticGroupDelete>('/rest/lp/group/delete', {
        ...params,
    });
    return result.data;
}

/** 程序化落地页组回收站 */
export async function postProgrammaticDeleteList(params: any) {
    const result = await axios.post<model.IProgrammaticList>('/rest/lp/group/deleted/list', {
        ...params,
    });
    return result.data.data;
}

/** 恢复落地页组 */
export async function postProgrammaticListRestore(params: { groupId: string }) {
    const result = await axios.post<model.IProgrammaticGroupDelete>('/rest/lp/group/restore', {
        ...params,
    });
    return result.data;
}

/** 获取建站落地页详情数据 */
export async function postPageEditDetail(params: { outPageId: string }) {
    const result = await axios.post<model.IMessageAdLpPageView>('/rest/lp/group/page/get', {
        ...params,
    });
    return result.data.data;
}

export async function postDeletePageGroupItem(params: { outPageId: string; groupId: string }) {
    const result = await axios.post('/rest/lp/group/page/delete', params);
    return result.data.data;
}

export interface ThirdPageList {
    url: string;
    pageName: string;
}

export interface RootObject {
    groupId: string;
    groupName: string;
    groupSource: string;
    outPageIdList?: string[];
    thirdPageList?: ThirdPageList[];
}

export async function updatePageGroup(params: RootObject) {
    const result = await axios.post('/rest/lp/group/update', params);
    return result.data.data;
}

/** Agent Controller  代理商token传递 */
export async function passTokenUsingGET(params: { agentTk: string }) {
    const paramArr: string[] = [];
    for (const key in params) {
        paramArr.push(
            `${key}=${
                typeof (params as any)[key] === 'string'
                    ? (params as any)[key]
                    : JSON.stringify((params as any)[key])
            }`,
        );
    }
    const result = await axios.get<object>(
        `${getImApiUrl()}/rest/web/agent/passToken?${paramArr.join('&')}`,
        {
            withCredentials: true,
        },
    );
    return result.data;
}

export async function createHalfPage(params: any) {
    const result = await axios.post(`/rest/lp/half/create`, params);
    return result.data.data;
}

export interface IPageGroupListQuery {
    pageNum: number;
    pageSize: number;
    select: string;
}
export interface IPageGroupListData {
    outPageId: string;
    coverImgUrl: string;
    name: string;
    platform: string;
    componentsType: string[];
}
export interface IPageGroupListResp {
    data: {
        list: IPageGroupListData[];
        splitInfo: { pageNum: number; pageSize: number; totalNum: number };
    };
}

export async function getPageGroupList(params: IPageGroupListQuery) {
    return (await axios.post<IPageGroupListResp>('/rest/lp/group/page/list', params)).data;
}

export const getPageGroupListDebounced = AwesomeDebouncePromise(getPageGroupList, 500);

export interface IPageGroupSubmit {
    groupId?: string;
    groupName: string;
    groupSource: number;
    outPageIdList: string[];
    thirdPageList: { pageName: string; url: string }[];
}
export const GroupSource = {
    LandingPage: 0,
    ThirdParty: 1,
};

export async function submitOrUpdateGroup(params: IPageGroupSubmit, groupId?: string) {
    const url = `/rest/lp/group/${groupId ? 'update' : 'create'}`;
    if (groupId) {
        params.groupId = groupId;
    }
    return (await axios.post(url, params)).data;
}

export async function pageGroupVerify(params: { name: string; groupId?: string }) {
    return (await axios.post('/rest/lp/group/verifyName', params)).data;
}

export async function dynamicReport(outPageId: string) {
    try {
        return (await axios.post('/rest/lp/report/pageReport', { outPageId })).data.data;
    } catch (e) {
        Toast.error(e.message || '获取数据失败');
    }
}

export interface IGetCityParam {
    lat: number;
    lon: number;
    pageId: string;
    callback?: string;
}

export async function getCity(params: IGetCityParam) {
    try {
        return (await axios.post('/rest/n/lp/page/getCurrentCity', params)).data.data;
    } catch (e) {
        Toast.error(e?.message || '获取城市数据失败');
    }
}

export interface DynamicElementReportEntity {
    compTypeId: string;
    compType: 'DRAG_TEXT' | 'DRAG_IMAGE' | 'XIANSUO_FORM_DRAG';
    dynamicElementType: 'TEXT_CONTENT' | 'IMAGE_CONTENT' | 'FORM_TITLE' | 'FORM_BUTTON';
    elementValueMap: ElementValueMapEntity[];
}

export interface ElementValueMapEntity {
    elementValue: string;
    exposureRate: number;
}

/** ***互动弹窗start */

export async function popupCreate(popInfo: model.IPopupInfo) {
    try {
        return (await axios.post('/rest/lp/popup/create', popInfo)).data;
    } catch (e) {
        Toast.error(e.msg || '创建失败');
    }
}

export async function popupSave(popInfo: model.ISavePopInfo) {
    try {
        return (await axios.post('/rest/lp/popup/save', popInfo)).data;
    } catch (e) {
        Toast.error(e.msg || '保存失败');
    }
}
export async function popupSubmit(popInfo: model.ISavePopInfo) {
    try {
        return (await axios.post('/rest/lp/popup/submit', popInfo)).data;
    } catch (e) {
        Toast.error(e.msg || '提交失败');
    }
}

export async function popupList(params: model.IPopupQueryParam) {
    try {
        return (await axios.post('/rest/lp/popup/list', params)).data.data;
    } catch (e) {
        Toast.error(e.msg || '获取弹窗数据失败');
    }
}

export async function popupDetail(id: string) {
    try {
        return (await axios.post('/rest/lp/popup/detail/info', { id })).data.data;
    } catch (e) {
        Toast.error(e.msg || '获取弹窗详情数据失败');
    }
}

export async function popupDetele(id: string) {
    try {
        return (await axios.post('/rest/lp/popup/delete', { id })).data;
    } catch (e) {
        Toast.error(e.msg || '删除弹窗失败');
    }
}
export async function popupTerminate(id: string) {
    try {
        return (await axios.post('/rest/lp/popup/termination', { id })).data;
    } catch (e) {
        Toast.error(e.msg || '终止弹窗失败');
    }
}
/** ***互动弹窗end */

export async function brandDetial(data: model.IBrandDetialParam) {
    try {
        return (await axios.post('/rest/n/lp/page/getRecoProductList', data)).data;
    } catch (e) {
        Toast.error(e.msg || '获取商品详情失败');
    }
}

export async function getConsultPageUrl(data: { consulPageId: string }) {
    try {
        return (
            await axios.post<{
                data: {
                    isH5Url?: boolean;
                    consultPageUrl?: string;
                    switchStatus?: boolean;
                    h5Url?: string;
                    deepLink?: string;
                };
            }>(`/rest/c/laotie/im/page/url`, data)
        ).data;
    } catch (e) {
        Toast.error(e.msg || '获取咨询页url失败');
    }
}

/** 私信B端相关接口  私信预览接口 */
export async function privateLetterPreviewUsingPOST(payload: {
    req: model.IPrivateLetterPreviewReq;
}) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePrivateLetterPreviewView>({
        url: `${getMoliHost()}/rest/lp/customer/private/letter/preview`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 私信C端相关接口  私信预览接口 */
export async function privateLetterPreviewUsingPOSTForC(payload: {
    req: model.IPrivateLetterPreviewReq;
}) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponsePrivateLetterPreviewView>({
        url: `/rest/c/lp/customer/private/letter/preview`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 获取私信链接deepLink */
export async function getPrivateLetterInfo(data: { userId: number }) {
    try {
        return (
            await axios.post<model.IResponsePrivateLetter>(
                `/rest/c/lp/customer/private/letter/info`,
                data,
            )
        ).data;
    } catch (e) {
        console.error(e.msg || '获取咨询页deeplink失败');
    }
}

/** 获取私信详情 */
export async function getPrivateLetterInfoInPC(data: { uid: number }) {
    try {
        return (
            await axios.post<model.IResponsePrivateLetterUserView>(
                `${getMoliHost()}/rest/lp/customer/private/letter/info`,
                data,
            )
        ).data;
    } catch (e) {
        console.error(e.msg || '获取咨询页deeplink失败');
    }
}
/** 获取隐私协议 */
export async function getPrivacyAgreementUsingPOST(payload: { key: string }) {
    const data = {
        ...payload,
    };
    const result = await axios.request<model.IResponseGetAgreementContent>({
        url: `/rest/n/lp/common/getFormPrivacyAgreement`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 获取快应用品牌信息 */
export async function getQuickAppBrandConfig() {
    const result = await axios.get<model.IResponseQuickAppBrand>(`/rest/lp/page/quickAppBrand`);
    return result.data;
}

/** 获取落地页pageJson */
export async function getPageJson(payload: { pageId: string }) {
    const result = await axios.get<model.IResponseUnknown>(`/rest/tk/lp/page/getPage`, {
        params: {
            pageId: payload.pageId,
        },
    });
    return result.data.data;
}

// export async function qywxFansCbMd5UsingPOST(payload: {
//     callback: string;
//     isMK?: boolean | string;
//     followPlanId?: string;
//     appVersion?: string;
//     renderType?: string;
//     componentType?: string;
//     customerAcqId?: string;
//     pageId?: string;
//     cityName?: string;
//     type?: number;
// }) {
//     const result = await axios.request<model.IResponseQywxCallback>({
//         url: `/rest/c/gemini/wechat/report-qywx-lp-page-callback`,
//         method: 'post',
//         data: payload,
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });

//     return result.data;
// }
