import * as React from 'react';
import { ReactComponent as IconKefu } from '../image/wechatKefu.svg';
import styles from '../style.module.less';
import { jumpWxkf, jumpHkzs } from '../util';
import { PlanType, PopupType, WechatActionSceneEnum, WeiXinOpt } from '../WeiXin';
import { DIALOG_STYLE, useComContext, WeChatType } from 'omega-render';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { wechatActionSence, wechatDataTrack } from './commonFun';
import { useAbHooks } from '../hooks/useAbHooks';
import { SelectItem } from '../../XiansuoFormMulti/components/IMComponent';

export interface CopyDialogProps {
    info?: string;
    onClose?: () => void;
    deepLink?: string;
    opt: WeiXinOpt;
    dialogStyleType: DIALOG_STYLE;
    wechatType: WeChatType;
    renderType?: string;
    wechatStay?: boolean;
    formId?: string;
    formInfo?: SelectItem[];
}

export enum LaunchTypeEnum {
    WEI_XIN_DRAG = 2,
    WEI_XIN = 2,
    XIANSUO_FORM_DRAG = 3,
    XIANSUO_FORM = 3,
    WECHAT_STAY = 4,
}
export const JumpWxKefu: React.FunctionComponent<CopyDialogProps> = (props) => {
    const {
        info,
        onClose,
        dialogStyleType,
        opt,
        wechatType,
        renderType,
        wechatStay,
        formInfo,
        formId,
    } = props;
    const abData = useAbHooks();

    const { type } = useComContext();
    const launchType = LaunchTypeEnum[type] || LaunchTypeEnum.WECHAT_STAY;

    const callWX = React.useCallback(async () => {
        if (dialogStyleType === DIALOG_STYLE.KEFU) {
            jumpWxkf({
                opt,
                dialogStyleType,
                renderType,
                noLog: true,
                formInfo,
                formId,
                launchType,
            });
        } else if (dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT) {
            jumpHkzs({
                opt,
                dialogStyleType,
                renderType: renderType as string,
                useSchema: Boolean(abData.qywxCustomerAcquisitionSchemeType),
                wechatType: WeChatType.ASSISTANT,
                formInfo,
                formId,
                launchType,
            });
        } else if (dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP) {
            jumpHkzs({
                opt,
                dialogStyleType,
                renderType: renderType as string,
                useSchema: Boolean(abData.qywxCustomerAcquisitionSchemeType),
                wechatType: WeChatType.ASSISTANT_GROUP,
                formInfo,
                formId,
                launchType,
            });
        }
        // 上报Add埋点
        try {
            wechatDataTrack({
                itemId: opt.id,
                invokeSuccess: false,
                sence:
                    opt.planType === PlanType.HK_LINK
                        ? WechatActionSceneEnum.KEFU_WITH_ASSISTANT
                        : wechatActionSence(dialogStyleType),
                wechatId: opt.wechatId,
                appId: '',
                popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
            });
        } catch (e) {
            console.log(e);
        }
        setTimeout(() => {
            onClose && onClose();
        }, 60000);
    }, [opt, dialogStyleType, abData]);
    React.useEffect(() => {
        dataTrack({
            eventType: 'EVENT_VIEW_WECHAT',
            wechatItemId: '0',
            wechatActionScene:
                opt.planType === PlanType.HK_LINK
                    ? WechatActionSceneEnum.KEFU_WITH_ASSISTANT
                    : wechatActionSence(dialogStyleType),
            lp_page_type: 1,
            diyExtraInfo: JSON.stringify({
                renderType: 'WEI_XIN_DRAG',
                popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
            }),
        });
    }, []);
    return (
        <div className={styles.dialog}>
            <div className={styles.exitNew} data-target="wechat-close" onClick={onClose} />
            <IconKefu className={styles.icon} style={{ fill: 'none' }} />
            <div className={styles.text} style={{ marginTop: 16 }}>
                {wechatType === WeChatType.KEFU ? '点击前往微信客服' : '点击添加企微客服'}
            </div>
            <div className={styles.info} style={{ marginTop: 12, color: '#666' }}>
                {info}
            </div>
            <div className={styles.close} style={{ marginTop: 16 }} onClick={callWX}>
                {wechatType === WeChatType.KEFU ? '去微信客服' : '去企微客服'}
            </div>
        </div>
    );
};
