/* eslint-disable */
import { ComponentJson, isCanUseWebp } from 'omega-render';
// import { trackUpgradeCDNError } from './adTrack/monitor';
const upgradeHostList = [
    'static.yximgs.com',
    'h2.static.yximgs.com',
    'ali2.a.kwimgs.com',
    's2-10889.kwimgs.com',
    'p1-yxjz.adbkwai.com',
    'p2-yxjz.adbkwai.com',
    'p3-yxjz.adbkwai.com',
    'p1-yx.adkwai.com',
    'p2-yx.adkwai.com',
    'p3-yx.adkwai.com',
    'p1-ad.adukwai.com',
    'p2-ad.adukwai.com',
    'p3-ad.adukwai.com',
    'p4-ad.adukwai.com',
    'p5-ad.adukwai.com',
];
/** SSR用这个变量设置合读取逃生域名， 这个域名带有协议头 */
let globalCDNPublicPath = '';
/**  是否支持webp，SSR渲染的时候使用这个变量 */
let globalSupportWebp = false;

/**
 * Processes the CDN host by adding the 'https://' protocol if it is missing.
 *
 * @param cdnHost - The CDN host to process.
 * @returns The processed CDN host with the 'https://' protocol.
 */
export function processCDNHostWithProtocol(cdnHost?: string): string {
    if (!cdnHost) {
        return '';
    }
    const regex = /^https:\/\//i;
    if (!regex.test(cdnHost)) {
        cdnHost = 'https://' + cdnHost;
    }
    return cdnHost;
}

/** CDN逃生域名 */
export function getTargetHost() {
    return processCDNHostWithProtocol(
        globalCDNPublicPath || window?.cdn_public_path || 'https://ali2.a.kwimgs.com',
    );
}

/** 设置是否支持webp */
export function setGlobalSupportWebp(supportWebp: boolean) {
    globalSupportWebp = supportWebp;
}
/** 设置获取是否支持webp */
export function getGlobalSupportWebp() {
    if (process.env.RUNTIME === 'server') {
        return globalSupportWebp;
    }
    return isCanUseWebp;
}
/** 设置域名逃生CDN域名，不使用window，是因为有SSR渲染场景 */
export function setGlobalCDNPublicPath(cdnHost: string) {
    globalCDNPublicPath = cdnHost;
}

export function getGlobalCDNPublicPath() {
    return globalCDNPublicPath;
}

export default function upgradeCDN(components: ComponentJson[]) {
    try {
        let stringJson = JSON.stringify(components);
        const hostReg = upgradeHostList.map((host) => `https://${host}`).join('|');
        const regex = RegExp(`(${hostReg})`, 'ig');
        stringJson = stringJson.replace(regex, `${getTargetHost()}`);
        const res = JSON.parse(stringJson);
        return res;
    } catch (err) {
        // trackUpgradeCDNError(String(err));
        console.error(err);
        return components;
    }
}

/** 转换固定CDN域名为逃生域名 */
export function transformCDN2Escape(cdnUrl?: string) {
    if (!cdnUrl) return '';
    let res = cdnUrl;
    try {
        const hostReg = upgradeHostList.map((domain) => `https://${domain}`).join('|');
        const regex = RegExp(`(${hostReg})`, 'ig');
        res = cdnUrl.replace(regex, `${getTargetHost()}`);
    } catch (e) {
        console.error(e);
    }
    return res;
}
