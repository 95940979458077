/* eslint-disable */
import * as React from 'react';
import { useComContext } from 'omega-render';
import { Div } from '../Blank/Blank';

import { Type1 } from './Type1';
import { Type3 } from './Type3';
import { ShufflingImageGroup } from './ShufflingImageGroup';

export enum ShufflingType {
    /** 单个轮播 */
    SINGLE = 1,
    /** 组轮播 */
    GROUP = 2,
}

export enum RenderType {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

export interface ShufflingImageProps {
    loadend?: () => void;
    /** 图片比例 */
    ratio: number;
    /** 单个轮播: 1 / 组轮播: 2 */
    shufflingType: ShufflingType;
    /** 轮播样式（三种） */
    renderType?: RenderType;
    /** 轮播间隔 */
    playInterval: number;
    /** 宽度 */
    width?: number;
    /** 自动轮播 */
    autoplay: boolean;
    /** 单个轮播 - 轮播图片 */
    imgs: { text?: string; imgUrl: string; targetUrl?: string }[];
    /** 组轮播 - 轮播图片 */
    groupImageConfig: {
        id: string;
        name: string;
        imgs: ShufflingImageProps['imgs'];
    }[];
    /** 组文字样式 */
    labelStyles?: React.CSSProperties;
    /** 选中条样式 */
    activeBorderStyles?: React.CSSProperties;
    /** 激活标签样式 */
    activeLabelStyles?: React.CSSProperties;

    /** 组样式 */
    groupStyles?: React.CSSProperties;

    randomHeight?: boolean;
}

const ShufflingImage: React.FunctionComponent<ShufflingImageProps> = (props) => {
    const { imgs, loadend, children, ratio, randomHeight = false } = props;
    const { magic } = useComContext();
    const renderType = props.renderType || 1;

    // React.useEffect(() => {
    //     const img = new window.Image();
    //     img.src = imgs?.[0]?.imgUrl || ''; // 暂时取第一个图片加载时间为标准
    //     img.onload = () => {
    //         if (loadend) {
    //             loadend();
    //         }
    //     };
    //     img.onerror = () => {
    //         if (loadend) {
    //             loadend();
    //         }
    //     };
    // }, [imgs, loadend]);
    return (
        <Div
            style={
                randomHeight
                    ? {}
                    : {
                          height: '100%',
                          minWidth: 80,
                          maxWidth: 375,
                          minHeight: 80 / ratio,
                          maxHeight:
                              375 / ratio + (props.shufflingType === ShufflingType.GROUP ? 50 : 0),
                      }
            }
        >
            {props.shufflingType === ShufflingType.GROUP ? (
                <ShufflingImageGroup {...props} />
            ) : (
                <>
                    {renderType === 3 ? (
                        <Type3
                            {...props}
                            height={magic?.dragFree.height}
                            width={magic?.dragFree.width}
                        />
                    ) : (
                        <Type1 {...props} width={magic?.dragFree.width} />
                    )}
                </>
            )}

            {children}
        </Div>
    );
};

export default ShufflingImage;
