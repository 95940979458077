export const DownloadIcon = (props) => {
    const { style } = props;
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...style }}
        >
            <g clip-path="url(#clip0_74_27813)">
                <g mask="url(#mask0_74_27813)">
                    <path
                        // fill-rule="evenodd"
                        // clip-rule="evenodd"
                        d="M11.5 16.6665V2.6665H13.5V16.6665H11.5ZM3.8335 20H21.167V22H3.8335V20Z"
                    />
                    <path
                        // fill-rule="evenodd"
                        // clip-rule="evenodd"
                        d="M12.4899 15.5189L6.59732 9.62631L5.18311 11.0405L12.4899 18.3473L19.7966 11.0405L18.3824 9.62631L12.4899 15.5189Z"
                    />
                </g>
            </g>
            {/* <defs>
                <clipPath id="clip0_74_27813">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs> */}
        </svg>
    );
};
