/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';

import ReactDOM from 'react-dom';
import cs from 'classnames';
import styles from './h5Map.module.less';

const MAP_KEY = 'YNRBZ-QPPLP-SAYDA-LP6UC-OP3GT-LLF5N';
let script: HTMLScriptElement | null = null;
interface Iprops {
    show: boolean;
    position: {
        longitude: number;
        latitude: number;
    };
    title: string;
    onClose: () => void;
}

declare let TMap: any;

export default function H5Map(props: Iprops) {
    const [el] = useState(document.createElement('div'));
    const [inited, setInited] = useState(false);
    // React.useEffect(() => {
    //   if (props.show) {
    //     window.history.pushState(null, '魔力地图', '/h5Map');
    //   }
    // }, [props.show])

    const startDraw = () => {
        setInited(true);
        // 地图初始化函数，本例取名为init，开发者可根据实际情况定义
        function initMap() {
            // 定义地图中心点坐标
            const center = new TMap.LatLng(props?.position?.latitude, props?.position?.longitude);
            // 定义map变量，调用 TMap.Map() 构造函数创建地图
            const map = new TMap.Map(document.getElementById('container'), {
                center, // 设置地图中心点坐标
                zoom: 16, // 设置地图缩放级别
                // pitch: 43.5,  //设置俯仰角
                rotation: 0, // 设置地图旋转角度
                viewMode: '2D',
                showControl: false,
            });

            // 创建并初始化MultiMarker
            new TMap.MultiMarker({
                map, // 指定地图容器
                // 样式定义
                // styles: {
                //   //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                //   "myStyle": new TMap.MarkerStyle({
                //     "width": 25,  // 点标记样式宽度（像素）
                //     "height": 35, // 点标记样式高度（像素）
                //     "src": '../img/marker.png',  //图片路径
                //     //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                //     "anchor": { x: 16, y: 32 }
                //   })
                // },
                // 点标记数据数组
                geometries: [
                    {
                        id: '1', // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                        styleId: 'myStyle', // 指定样式id
                        position: new TMap.LatLng(
                            props?.position?.latitude,
                            props?.position?.longitude,
                        ), // 点标记坐标位置
                        properties: {
                            // 自定义属性
                            title: 'marker1',
                        },
                    },
                ],
            });
        }
        initMap();
    };
    const init = useCallback(() => {
        document.body.appendChild(el);
        if (typeof TMap === 'undefined') {
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${MAP_KEY}`;
            document.body.appendChild(script);
            script.onload = startDraw;
        } else {
            startDraw();
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (!inited && props.show) {
            init();
        }
        // return () => {
        //   document.body.removeChild(el)
        // }
        // eslint-disable-next-line
    }, [inited, props.show]);

    return ReactDOM.createPortal(
        <div className={cs({ [styles.hidden]: !props.show })}>
            <div
                className={styles.header}
                onClick={(e) => {
                    e.stopPropagation();

                    props.onClose();
                }}
            >
                <div
                    className={styles.backIcon}
                    onClick={(e) => {
                        e.stopPropagation();

                        props.onClose();
                    }}
                />
                <div className={styles.title}>{props.title}</div>
            </div>
            <div id="container" className={styles.container} />
        </div>,
        el,
    );
}
