import { createModel } from 'rt-model';
import { useRenderActions } from 'omega-render';

/**
 * plc容器监听状态
 */
export interface plcContainerState {
    fixedBarBottomPixel: number;
    fixedBarVisible: boolean;
    showTopBar: boolean;
}

/**
 *  plc容器状态默认值
 */
export const defaultState: plcContainerState = {
    fixedBarBottomPixel: 0,
    fixedBarVisible: false,
    showTopBar: false,
};

/**
 * plc容器滚动状态、距离底部距离等状态的监听store
 * 需要在多个组件中共享该状态
 */
export const plcContainerModal = createModel({
    namespace: 'plcContainer',
    defaultState,
    reducers: {},
    effects: {
        /**
         * 底部悬浮按钮距离页面底部距离设置
         */
        setFixedBarBottomPixel(bottomPixel: number) {
            return async (dispatch) => {
                dispatch(
                    plcContainerModal.actions.setModelState({ fixedBarBottomPixel: bottomPixel }),
                );
            };
        },
        /**
         * 底部悬浮按钮是否可见
         */
        setFixedBarVisible(barVisible: boolean) {
            return async (dispatch) => {
                dispatch(plcContainerModal.actions.setModelState({ fixedBarVisible: barVisible }));
            };
        },
        /**
         * 顶部拖拽bar是否可见
         */
        setShowTopBar(showTopBar: boolean) {
            return async (dispatch) => {
                dispatch(plcContainerModal.actions.setModelState({ showTopBar: showTopBar }));
            };
        },
    },
});

/**
 * reducer映射。
 */
export const reducerMap = {
    plcContainer: plcContainerModal?.reducer,
};

/**
 * 获取plc容器监听状态的actions。
 */
export const usePlcContainerActions = () => useRenderActions(plcContainerModal.actions);
