import React, { useState } from 'react';
import cs from 'classnames';
import { IStoreDetailView, IBusinessTime, IStoreView } from '../../common/api/interface';
import { callTel } from '../Tel/Tel';
import { dataTrack } from '../../common/adTrack/util/track';
import { transformCDN2Escape } from '../../common/upgradeCDN';
import RichNavIcon from './icon/richNav';
import TelIcon from './icon/tel';
import AddressIcon from './icon/AddressIcon';
import TimeIcon from './icon/Time';
import RightArrow from './icon/RightArrow';
import styles from './style.module.less';
import { IPosition, LOC_ERROR } from './Store';
import { Slides } from './components/Slides';
import { STORE_TYPE } from './hooks/useNewStoreList';
import { useRenderState } from 'omega-render';

const emptyImageUrl = transformCDN2Escape(
    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-09-26/1695714570010.54efce6d32d783ce.png',
);
export enum BusinessTimeType {
    UNKNOWN = 0,
    EVERYDAY = 1,
    WORKDAY = 2,
    WEEKEND = 3,
}

export interface RichStoreDetail
    extends Pick<
            IStoreDetailView,
            | 'pics'
            | 'businessTimes'
            | 'poiInfo'
            | 'iccId'
            | 'members'
            | 'goodsList'
            | 'normalPhoneNumber'
        >,
        IStoreView {}

export interface StoreRichProps {
    position: IPosition;
    // funcId: string;
    // version: string;
    /** 是否是编辑模式 */
    edit?: boolean;
    storeType: STORE_TYPE;
    storeSelected?: RichStoreDetail;
    // storeSelectedPoi?: Poi;
    located: boolean;
    style: {
        /** 门店名称字号 */
        nameSize?: number;
        /** 门店名称字色 */
        nameColor?: string;
        /** 标题名称字号 */
        titleSize?: number;
        /** 标题名称字色 */
        titleColor?: string;
        /** 门店地址字号 */
        addressSize?: number;
        /** 门店地址字色 */
        addressColor?: string;
        /** 导航icon颜色 */
        iconColor?: string;
        /** 背景颜色 */
        bgColor?: string;
        /** 组件高度 */
        height?: number;
    };
    locError: LOC_ERROR;
    goToStoreLIst: (e: React.MouseEvent) => void;
    goToMap: (item: IStoreView) => void;
    updateLocation: (e: any) => void;
}

function getBusinessTime(businessTime: IBusinessTime[]) {
    if (!businessTime) return '';
    const businessTimesString = businessTime.map((time) => {
        switch (time.type) {
            case BusinessTimeType.EVERYDAY:
                return `周一至周日${time.startTime}-${time.endTime}`;
            case BusinessTimeType.WORKDAY:
                return `周一至周五${time.startTime}-${time.endTime}`;
            case BusinessTimeType.WEEKEND:
                return `周六至周日${time.startTime}-${time.endTime}`;
            default:
                return `${time.startTime}-${time.endTime}`;
        }
    });
    return businessTimesString.join(',');
}

export default function StoreRichStyle(props: StoreRichProps) {
    const { style, storeSelected, storeType, edit } = props;
    const { goToStoreLIst, goToMap, located } = props;
    const {
        nameSize,
        nameColor,
        titleSize,
        titleColor,
        addressSize,
        addressColor,
        iconColor,
        bgColor,
    } = style;
    /** 智能电话防连点 */
    const [loadingTel, setLoadingTel] = useState(false);
    const pageJson = useRenderState((state) => state.rolex.opt?.originalPageJson);
    return (
        <div className={styles.rich}>
            <div>
                <Slides
                    imgs={storeSelected?.pics?.length ? storeSelected?.pics : [emptyImageUrl]}
                />
            </div>
            <div
                className={styles.detailWrapper}
                style={{
                    backgroundColor: bgColor,
                }}
            >
                <div className={styles.storeNameWrapper}>
                    <div
                        className={cs([
                            styles.storeName,
                            storeType === STORE_TYPE.SINGLE ? styles.singleStoreName : '',
                        ])}
                        style={{
                            fontSize: nameSize,
                            color: nameColor,
                        }}
                    >
                        {storeSelected?.name ?? '门店名称'}
                    </div>
                    {storeType !== STORE_TYPE.SINGLE && (located || edit) && (
                        <div
                            className={styles.moreButton}
                            style={{
                                color: addressColor,
                            }}
                            onClick={(e) => {
                                located && goToStoreLIst(e);
                            }}
                        >
                            更多门店
                            <RightArrow
                                fill={addressColor as string}
                                className={styles.rightArrow}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.businessTime}>
                    <div className={styles.title}>
                        <TimeIcon fill={titleColor as string} />
                        <div
                            className={styles.titleContent}
                            style={{
                                fontSize: titleSize,
                                color: titleColor,
                            }}
                        >
                            营业时间
                        </div>
                    </div>
                    <div
                        className={styles.businessTimeDetail}
                        style={{
                            fontSize: addressSize,
                            color: addressColor,
                        }}
                    >
                        {/* eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain */}
                        {getBusinessTime(storeSelected?.businessTimes as IBusinessTime[]) ||
                            '暂无营业时间'}
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.storeAddress}>
                        <div className={styles.title}>
                            <AddressIcon fill={titleColor as string} />
                            <div
                                className={styles.titleContent}
                                style={{
                                    fontSize: titleSize,
                                    color: titleColor,
                                }}
                            >
                                门店地址
                            </div>
                        </div>
                        <div
                            className={styles.storeAddressDetail}
                            style={{
                                fontSize: addressSize,
                                color: addressColor,
                            }}
                        >
                            {storeSelected?.poiInfo?.address ?? '详细地址'}
                        </div>
                    </div>
                    {(!!edit || storeSelected?.id) && (
                        <div className={styles.buttonGroup} style={{ color: iconColor }}>
                            <div
                                className={styles.buttonItem}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    goToMap(storeSelected as IStoreView);
                                    dataTrack({
                                        eventType: 'EVENT_ELEMENT_NAVIGATE_CLICK',
                                        diyExtraInfo: JSON.stringify({
                                            storeId: storeSelected?.id,
                                        }),
                                    });
                                }}
                            >
                                <RichNavIcon fill={iconColor as string} />
                                <div className={styles.buttonTitle}>导航</div>
                            </div>
                            <div
                                className={styles.buttonItem}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dataTrack({
                                        eventType: 'EVENT_ELEMENT_PHONE_CLICK',
                                        diyExtraInfo: JSON.stringify({
                                            storeId: storeSelected?.id,
                                        }),
                                    });
                                    // const
                                    if (storeSelected?.iccId) {
                                        callTel({
                                            telType: 'AImobile',
                                            iccId: storeSelected?.iccId,
                                            loading: loadingTel,
                                            setLoading: setLoadingTel,
                                            comType: 'STORE',
                                            tel: undefined,
                                            pageId: pageJson?.outPageId,
                                            customData: {
                                                storeId: storeSelected?.id,
                                                storeName: storeSelected?.name,
                                            },
                                        });
                                    } else if (storeSelected?.normalPhoneNumber) {
                                        callTel({
                                            telType: 'phone',
                                            tel: storeSelected?.normalPhoneNumber,
                                            comType: 'STORE',
                                        });
                                    }
                                }}
                            >
                                <TelIcon fill={iconColor as string} />
                                <div className={styles.buttonTitle}>拨打</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
