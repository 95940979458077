import { useComContext, useRenderState } from 'omega-render';
import { get } from 'lodash';
import { THEME_CONFIG } from './themeConfig';
import generate, { convertToRGBA } from '@/landingPage/common/utils/genColor';
import { getAlphaValue } from './color';

interface IProps {
    path: string;
    componentProps?: any;
    options?: {
        type?: 'primary' | 'border' | 'activeFill';
    };
}
/**
 * 使用主题颜色。这个函数会根据给定的属性和主题配置，返回一个包含颜色的对象。
 *
 * @param props - 包含以下属性的对象：
 *   - `path`: 要获取的颜色的路径
 * @returns 返回一个对象，包含一个 `color` 属性。如果路径对应的值存在，并且主题配置允许使用主题颜色，`color` 为格式化后的主题颜色或路径对应的值；否则，`color` 为路径对应的值；如果路径对应的值不存在，`color` 为 `undefined`。
 *
 * @example
 * const { color } = useThemeColor({ path: 'button.color'});
 * console.log(color); // 输出 "red" 或主题颜色
 */
export const useTheme = (props: IProps) => {
    const { props: cProps, type } = useComContext();
    const { path, componentProps = cProps } = props;
    const pageMeta = useRenderState((state) => state.rolex.opt?.originalPageJson?.pageMeta);
    const value = get(componentProps, path);
    const themeConfig = THEME_CONFIG?.[type]?.(componentProps);
    /** 组件默认的主题色配置开关 */
    const isThemeConfigDefault = get(themeConfig, [props.path, 'status']);
    /** 组件后期设置的主题色配置开关，如果用户在边编辑器中修改过该颜色值，则后续不再应用主题色，这个开关会被设置为false */
    const isThemeConfigInProps = get(componentProps, ['themeConfig', props.path, 'status']);
    const isTheme = !(isThemeConfigDefault === false || isThemeConfigInProps === false);
    if (isTheme) {
        const colorPlateIndex = get(componentProps, ['themeConfig', props.path, 'colorPlateIndex']);
        if (colorPlateIndex !== undefined && pageMeta?.theme?.primaryColor) {
            const themes = generate(pageMeta?.theme?.primaryColor);
            return {
                color: value
                    ? convertToRGBA(themes[colorPlateIndex], getAlphaValue(value))
                    : themes[colorPlateIndex],
            };
        } else {
            return {
                color: value
                    ? formatWithOptions(pageMeta?.theme?.primaryColor, props.options) || value
                    : undefined,
            };
        }
    }
    return {
        color: value ? value : undefined,
    };
};

/**
 * 获取按钮类的主题色
 */
export const useButtonTheme = () => {
    const { color: buttonBackgroundColor } = useTheme({
        path: 'buttonStyle.backgroundColor',
    });
    const { color: buttonBorderColor } = useTheme({
        path: 'buttonStyle.borderColor',
    });
    const { color: buttonColor } = useTheme({
        path: 'buttonStyle.color',
    });
    return {
        backgroundColor: buttonBackgroundColor,
        borderColor: buttonBorderColor,
        color: buttonColor,
    };
};

/**
 * 特殊类的色值处理
 * activeFill 已选填充
 * border 已选边框
 */
function formatWithOptions(color?: string, options?: IProps['options']) {
    if (!color) {
        return;
    }
    if (options?.type === 'activeFill') {
        const themes = generate(color);
        return themes[0];
    }
    if (options?.type === 'border') {
        return convertToRGBA(color, 0.3);
    }
    return color;
}
