/* eslint-disable */
// 门店中心新获取门店列表方法
import { useCallback, useState } from 'react';
import { listStoreUsingPOST } from 'landingPage/common/api';
import { IStoreViewNew } from 'landingPage/common/api/interface';
// import { STORE_TYPE } from '../Store';

export enum STORE_TYPE_GEMINI {
    ALL = 1,
    PACKAGE = 2,
}

/** 包含的门店类型 */
export enum STORE_TYPE {
    /** 全部门店 */
    ALL,
    /** 门店包 */
    PACKAGE,
    /** 单门店 */
    SINGLE,
}

export enum BACKUP_TYPE {
    /** 1:定位失败，2:同城市推荐，3:同省份推荐，4:无推荐，有list直接按list展示，该枚举针对backup */
    ERROR = 1,
    SAME_CITY = 2,
    SAME_PROVINCE = 3,
    NO_RECO = 4,
}

/**  转换磁力建站门店包类型到线索通接口的门店包类型 */
const StoreTypePropsToGeminiMap = {
    [STORE_TYPE.ALL]: STORE_TYPE_GEMINI.ALL,
    [STORE_TYPE.PACKAGE]: STORE_TYPE_GEMINI.PACKAGE,
};

// type PickKey<T, K extends keyof T> = Extract<keyof T, K>;

type Picked_STORE_TYPE = STORE_TYPE.ALL | STORE_TYPE.PACKAGE;

const useNewStoreList = () => {
    /* 推荐门店 */
    const [recoStoreList, setRecoStoreList] = useState<IStoreViewNew[]>([]);
    /* 其他门店 */
    const [otherStoreList, setOtherStoreList] = useState<IStoreViewNew[]>([]);
    const [defaultStore, setDefaultStore] = useState<IStoreViewNew>();
    const [backupType, setBackupType] = useState<BACKUP_TYPE>();
    const [totalNum, setTotalNum] = useState(0);
    /* 获得门店列表 */
    const getStoreList = useCallback(
        async (opt: {
            type: STORE_TYPE;
            coords?: {
                latitude: string;
                longitude: string;
            };
            city: string;
            pageNum?: number;
            packageId: string;
            clueAccountId: number;
        }) => {
            const storeList = await listStoreUsingPOST({
                param: {
                    cityCode: opt.city,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    lat: opt?.coords?.latitude!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    lon: opt?.coords?.longitude!,
                    packageId: opt.packageId,
                    type: StoreTypePropsToGeminiMap[opt.type as Picked_STORE_TYPE],
                    pagingInfo: {
                        pageNum: opt?.pageNum || 1,
                        pageSize: 10,
                        totalNum: 10,
                    },
                    clueAccountId: opt.clueAccountId,
                },
            });
            if (Number(opt?.pageNum) > 1) {
                setRecoStoreList((val) => {
                    return storeList?.list ? [...val, ...storeList.list] : val;
                });
                setOtherStoreList((val) => {
                    return storeList?.backup ? [...val, ...storeList.backup] : val;
                });
            } else {
                setRecoStoreList(storeList?.list || []);
                setOtherStoreList(storeList?.backup || []);
            }
            setDefaultStore(storeList.defaultStore);
            setBackupType(storeList.backupType);
            setTotalNum(storeList.pagingInfo.totalNum);
            return storeList;
        },
        [],
    );
    type IGetStoreList = typeof getStoreList;
    return [
        recoStoreList,
        otherStoreList,
        getStoreList,
        totalNum,
        backupType,
        defaultStore,
    ] as unknown as [
        IStoreViewNew[],
        IStoreViewNew[],
        IGetStoreList,
        number,
        BACKUP_TYPE,
        IStoreViewNew,
    ];
};

export { useNewStoreList };
