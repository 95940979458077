import * as React from 'react';
import styles from '../style.module.less';

export interface BottomStyleProps {
    couponTitle: string;
    couponTitleStyle?: React.CSSProperties;
    buttonText: string;
    buttonTextStyle?: React.CSSProperties;
    setModalVisible: (param: boolean) => void;
}

export enum Position {
    base = 'base',
    bottomBlank = 'bottomBlank',
    rightBottom = 'rightBottom',
}

const BottomStyle: React.FunctionComponent<BottomStyleProps> = (props) => {
    const { couponTitle, couponTitleStyle, buttonText, buttonTextStyle, setModalVisible } = props;
    return (
        <div
            className={styles.bottomStyle}
            onClick={() => {
                setModalVisible(true);
            }}
        >
            <div className={styles.couponTitle} style={couponTitleStyle}>
                {couponTitle}
            </div>
            <div className={styles.buttonText} style={buttonTextStyle}>
                {buttonText}
            </div>
        </div>
    );
};

export default BottomStyle;
