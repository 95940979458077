import { startIdentityVerification } from '@ks/identity-verification';
import { sendVerifyUsingPOST } from '../api';

interface IParams {
    requestToken?: string;
    verifyType?: number;
    phone: string;
}

// 带滑动验证的发送验证码
export async function verifyWithAuth(params: IParams) {
    const { phone } = params;
    try {
        // 请求验证码
        await sendVerifyUsingPOST({
            ...params,
        });
        return true;
    } catch (e) {
        console.log('[verifyWithAuth]', e);
        if (e?.result === 162023) {
            const verifyUrl = e.data?.url;
            if (verifyUrl) {
                console.log('[verify SDK]', verifyUrl);
                let verifyRes;
                try {
                    verifyRes = await startIdentityVerification({
                        url: verifyUrl,
                    });
                } catch (error) {
                    console.error('[Error verify SDK]', error);
                }
                console.log('[verify SDK]', verifyRes);
                if (verifyRes.result === 1) {
                    // 验证成功，返回token和type, 需要拿着这俩参数回放请求
                    console.log('[identity-verification]', verifyRes.type, verifyRes.token);
                    return verifyWithAuth({
                        requestToken: verifyRes.token,
                        verifyType: verifyRes.type,
                        phone,
                    });
                }
                if (verifyRes.result !== 0) {
                    // 验证异常，需要重新验证
                    throw Error('滑块验证失败');
                }
            }
        }
        throw e;
    }
}
