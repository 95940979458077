import React from 'react';

export default function NavIcon(props: { fill: string }) {
    const { fill } = props;
    return (
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
            <title>shijian</title>
            <g id="改2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="门店组件-丰富样式" transform="translate(-476.000000, -453.000000)">
                    <g id="编组-2" transform="translate(458.000000, 112.000000)">
                        <g id="编组-6" transform="translate(0.000000, 77.000000)">
                            <g id="编组" transform="translate(18.000000, 262.000000)">
                                <g id="shijian" transform="translate(0.000000, 2.000000)">
                                    <rect id="矩形" x="0" y="0" width="14" height="14" />
                                    <path
                                        d="M6.99998779,1.24719149 C8.52572967,1.24719149 9.98898237,1.85328466 11.0678459,2.93214612 C12.1467092,4.01100731 12.7528085,5.47425837 12.7528085,7 C12.7528085,8.58859421 12.1089035,10.0267963 11.0678499,11.0678499 C10.0267963,12.1089035 8.58859421,12.7528085 7,12.7528085 C5.41140579,12.7528085 3.97320367,12.1089035 2.93215009,11.0678499 C1.89109651,10.0267963 1.24719149,8.58859421 1.24719149,7 C1.24719149,5.41140579 1.89109651,3.97320367 2.93215009,2.93215009 C3.97320367,1.89109651 5.41140579,1.24719149 6.99998779,1.24719149 Z"
                                        id="路径"
                                        stroke={fill}
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="M6.5,4.5 C6.77614237,4.5 7,4.72385763 7,5 L7,7.5 C7,7.77614237 6.77614237,8 6.5,8 C6.22385763,8 6,7.77614237 6,7.5 L6,5 C6,4.72385763 6.22385763,4.5 6.5,4.5 Z M9.5,7.5 C9.5,7.77614237 9.27614237,8 9,8 L6.5,8 C6.22385763,8 6,7.77614237 6,7.5 C6,7.22385763 6.22385763,7 6.5,7 L9,7 C9.27614237,7 9.5,7.22385763 9.5,7.5 Z"
                                        id="形状"
                                        fill={fill}
                                        fillRule="nonzero"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
