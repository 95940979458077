import { SystemCorrectCircleFill, SystemInfoCircleLine } from '@m-ui/icons';
import { useMemo, useRef, useState, useEffect } from 'react';
import AppDownload from '../AppDownloadDrag';
import { AppDownloadProps } from '../AppDownloadDrag/AppDownload';
import Modal from 'landingPage/common/components/Modal/Modal';
import { ReactComponent as CloseIcon } from './close.svg';
import styles from './style.module.less';

interface CardConfig {
    cardDisplayWord: string; // 浅度Bar文案
    nextCardDisplayWord: string; // 深度Bar文案
    deepDisplayWord: string; // 卡片标题
    deepTitle1: string; // 卡片浅度文案
    deepTitle2: string; // 卡片深度文案
    deepConvert: boolean; // 深度浅度
}
interface PecDownloadForMidpageProps extends AppDownloadProps {
    h5PageInspireCardText: CardConfig;
    btnColor: string;
    textStyle: React.CSSProperties;
    appIconUrl:
        | string
        | {
              imgUrl: string;
          };
    textColor: string;
    cardBgColor: string;
    bgColor: string;
}

export default function PecDownloadForMidpage(props: PecDownloadForMidpageProps) {
    const [showCard, setShowCard] = useState(false);
    // const [showCard, setShowCard] = useState(true);
    const ref = useRef<HTMLDivElement | null>(null);
    const [showModel, setShowModel] = useState(false);
    const { h5PageInspireCardText, appIconUrl, btnColor, cardBgColor, bgColor } = props;
    const cardRef = useRef<HTMLDivElement | null>(null);
    const btnText = useMemo(() => {
        if (!h5PageInspireCardText) return '立即下载';
        return h5PageInspireCardText.deepConvert
            ? h5PageInspireCardText.nextCardDisplayWord
            : h5PageInspireCardText.cardDisplayWord;
    }, [h5PageInspireCardText]);
    const downloadProps = useMemo(() => {
        const temProps: any = { ...props };
        delete temProps.appIconUrl;
        delete temProps.btnColor;
        delete temProps.textColor;
        delete temProps.cardBgColor;
        delete temProps.h5PageInspireCardText;
        return temProps;
    }, [props]);
    useEffect(() => {
        const parentBlankEle = (ref.current as any)?.parentNode?.parentNode;
        if (!parentBlankEle) return;
        parentBlankEle.style.background = 'transparent';
        if (showCard) {
            parentBlankEle.style.background = '';
        }
    }, [ref, showCard]);
    const iconUrl: string = useMemo(() => {
        return (
            (
                appIconUrl as {
                    imgUrl: string;
                }
            )?.imgUrl || (appIconUrl as string)
        );
    }, [appIconUrl]);

    return (
        <div className={styles.wrapper} ref={ref}>
            <div
                ref={cardRef}
                className={styles.cardWrapper}
                style={{
                    display: showCard ? 'block' : 'none',
                    background: showCard ? ` #fff ${cardBgColor}` : '',
                }}
            >
                <div
                    className={styles.cardTitle}
                    style={{ color: bgColor, display: showCard ? 'flex' : 'none' }}
                >
                    <span>{h5PageInspireCardText?.deepDisplayWord}</span>
                    <SystemInfoCircleLine
                        style={{ marginLeft: 5, color: bgColor, opacity: 0.3 }}
                        onClick={() => {
                            setShowModel(true);
                        }}
                    />
                </div>
                <div
                    className={styles.contentWrapper}
                    style={{
                        display: showCard ? 'flex' : 'none',
                    }}
                >
                    <img className={styles.icon} src={iconUrl} />
                    <div className={styles.convertTipsWrapper} style={{ color: bgColor }}>
                        <div className={styles.tips}>
                            <SystemCorrectCircleFill style={{ color: btnColor }} />
                            {h5PageInspireCardText?.deepTitle1}
                        </div>
                        <div className={styles.tips}>
                            <SystemCorrectCircleFill style={{ color: btnColor }} />
                            {h5PageInspireCardText?.deepTitle2}
                        </div>
                        <div className={styles.line} style={{ borderColor: bgColor }}></div>
                    </div>
                </div>
            </div>
            <div className={styles.downloadWrapper}>
                <AppDownload
                    {...downloadProps}
                    btnText={btnText}
                    clickCallback={() => {
                        if (h5PageInspireCardText) {
                            setShowCard(true);
                        }
                    }}
                />
            </div>
            {showModel && (
                <Modal
                    style={{
                        zIndex: 999999,
                        alignItems: 'flex-end',
                    }}
                    parent={document.body}
                >
                    <div className={styles.modelContainer}>
                        <div
                            className={styles.close}
                            onClick={() => {
                                setShowModel(false);
                            }}
                        >
                            <CloseIcon />
                        </div>
                        <div className={styles.modelTitle}>规则说明</div>
                        <div className={styles.modelContent}>
                            <div>
                                1. 完成相关操作后，我们将在24小时内通过私信通知您金币到账情况；
                            </div>
                            <div>
                                2. 金币下发可能有延迟，我们将在7个工作日内完成金币发放，请注意查收；
                            </div>
                            <div>3. 每个账号一天内仅此参与一次，每日封顶5万金币；</div>
                            <div>4. 禁止恶意刷单等操作，发现后将关闭此次任务；</div>
                            <div>5. 活动真实有效，如有任何问题可联系客服</div>
                        </div>
                        <div
                            className={styles.confirmButton}
                            onClick={() => {
                                setShowModel(false);
                            }}
                        >
                            知道了
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
