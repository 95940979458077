/**
 * 错误边界捕获
 */

import { AppHoc } from 'omega-render';
import React from 'react';
import { sendPerfLogLog } from './adTrack/monitor';
import { getPageId } from './utils/getPageId';

export const errorHoc: AppHoc = (C: React.ComponentType<any>) => {
    return (props: any) => {
        return (
            <>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <ErrorBoundary>
                    <C {...props} />
                </ErrorBoundary>
            </>
        );
    };
};

class ErrorBoundary extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('这里是Error boundary捕获的报错', error, errorInfo);
        sendPerfLogLog({
            subtag: 'MOLI_PAGE_WHITE',
            extra1: getPageId(),
            extra2: JSON.stringify(error),
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    网络异常，请稍后重试
                </div>
            );
        }
        return this.props.children;
    }
}
