import * as React from 'react';
import ReactDOM from 'react-dom';

import cs from 'classnames';
import styles from './Modal.module.less';

interface ModalProps {
    parent?: HTMLElement;
    onClose?: () => void;
    style?: React.CSSProperties;
    edit?: boolean;
}

const Modal: React.FunctionComponent<ModalProps> = (props) => {
    const { children, parent = document.body, onClose, style, edit } = props;
    const [el] = React.useState(document.createElement('div'));
    React.useLayoutEffect(() => {
        parent.appendChild(el);
        // return () => {
        //     parent.removeChild(el);
        // };
    }, [el, parent]);
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
        document.body.style.overflow = 'hidden';
        document.body.style.userSelect = 'none';
        return () => {
            setMounted(false);
            document.body.style.overflow = 'visible';
            document.body.style.userSelect = 'auto';
        };
    }, []);

    return ReactDOM.createPortal(
        <div
            ref={ref}
            style={style}
            className={cs(styles.layer, { [styles.disable]: edit })}
            onClick={(e) => {
                if (e.target === ref.current && onClose) {
                    onClose();
                }
            }}
        >
            {mounted && children}
        </div>,
        el,
    );
};

export default Modal;
