/* eslint-disable react/no-array-index-key */
import cs from 'classnames';
import React from 'react';
import { preventWakeUpApp } from 'landingPage/common/utils/preventWakeUpApp';
import { Div } from '../Blank/Blank';
import styles from './style.module.less';

export interface TextProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    themeConfig?: any;
    text?: string;
    link?: string;
    loadend?: () => void;
}

const Text: React.FunctionComponent<TextProps> = (props) => {
    const { text, link, className, loadend, children, ...rest } = props;

    const onClick = link
        ? async () => {
              if (link.startsWith('javascript')) {
                  return;
              }
              await preventWakeUpApp();

              window.location.href = link;
          }
        : undefined;

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Div className={cs(styles.root, className)} onClick={onClick} {...rest}>
            {text?.split('\n').map((t, index) => (
                <React.Fragment key={index}>
                    {t}
                    <br />
                </React.Fragment>
            ))}
            {children}
        </Div>
    );
};

export default Text;
