import { SelectItem } from './components/IMComponent';
import { SelectDesc } from './XiansuoForm';

/** 对话表单 x 跳转个微场景中判断是否需要发送有效获客事件
 * @params formInfo  用户选择的表单选项list
 * @params formComps 表单选项配置list
 * @params isChatForm 是否是对话表单
 */
export const needSendEffectiveAction = (params: {
    isChatForm?: boolean;
    formInfo: SelectItem[];
    formComps: SelectDesc[];
}) => {
    const { formInfo, formComps, isChatForm } = params;

    let needSendAction = false;
    //如果用户未选择 or 没获取到表单选项配置 or 不是对话表单，都直接返回false，不需要上报有效获客
    if (!isChatForm || !formInfo?.length || !formComps?.length) {
        return needSendAction;
    }
    for (let i = 0; i < formInfo?.length; i++) {
        const { componentId, value } = formInfo[i];
        const comp = formComps.find((item) => item.componentId === componentId);
        const selectItem = comp?.options?.find((item) => item.id === value);
        if (selectItem?.ifValid) {
            needSendAction = true;
        }
    }
    return needSendAction;
};
