import * as React from 'react';
import styles from './style.module.less';
import { ShufflingImageProps } from './ShufflingImage';
import { useAnimation } from './Type1';
import { getPicCDNSource } from 'landingPage/common/utils/getUrlParam';
import { useComContext } from 'omega-render';

export const Type3: React.FunctionComponent<ShufflingImageProps> = (props) => {
    const { ratio } = props;
    const imgWidth = 272;
    const imgHeight = imgWidth / ratio;
    const opt = React.useMemo(() => {
        const len = imgWidth;
        const time = 400;
        const delay = 4000;
        return {
            len,
            time,
            delay,
        };
    }, []);
    const { moved, renderImgs, events } = useAnimation(props, opt);
    const offsetIndex = (renderImgs.length - 1) / 2;
    const { isSupportWebp } = useComContext();
    console.log('shuffle type3 ====', isSupportWebp);
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={styles.type3} {...events} style={{ height: imgHeight, margin: '16px 0' }}>
            {renderImgs.map((conf, index) => {
                const { imgUrl, targetUrl } = conf;
                const { x, rotateY } = calcStyle(moved, index, offsetIndex, opt.len);

                return (
                    <div
                        className={styles.img}
                        onClick={() => {
                            if (targetUrl) {
                                window.location.href = targetUrl;
                            }
                        }}
                        style={{
                            transformOrigin: rotateY < 0 ? 'left' : 'right',
                            transform: `perspective(500px) rotateY(${rotateY}deg) translate3d(${x}px, 0px,${-Math.abs(
                                rotateY * 8,
                            )}px)`,
                            backgroundImage: `url(${getPicCDNSource(imgUrl, isSupportWebp)})`,
                            width: imgWidth,
                            height: imgHeight,
                        }}
                    />
                );
            })}
        </div>
    );
};

function calcStyle(moved: number, index: number, offsetIndex: number, len: number) {
    const x = (index - offsetIndex) * len - moved + 52;
    let rotateY = 0;
    rotateY = x * -0.07352941176470588 + 3.8234;

    return {
        x,
        rotateY,
    };
}

// -220x+b=20
// 52x+b=0

// (272+52)x+b=-20;
//
