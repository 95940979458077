/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import ReactDOM from 'react-dom';
import cs from 'classnames';
import styles from '../wexinTooltip.module.less';
// 多转化事件 优化目标

interface IWeixinToolTipProps {
    tooltipType: 'bottom' | 'top';
    tooltipPosition: React.CSSProperties;

    onClick: () => void;
}
export function WeixinToolTip({ tooltipType, tooltipPosition, onClick }: IWeixinToolTipProps) {
    return ReactDOM.createPortal(
        <span
            className={cs(styles.toolTipBox, {
                [styles.bottom]: tooltipType === 'bottom',
                [styles.top]: tooltipType === 'top',
            })}
            style={{
                ...tooltipPosition,
            }}
        >
            <span className={styles.toolTipContent}>
                <span className={styles.toolTipArrow}>
                    <span className={styles.toolTipArrowContent} />
                </span>
                <span className={styles.toolTipText} onClick={onClick}>
                    <span>复制并前往微信</span>{' '}
                </span>
            </span>
        </span>,
        document.body,
    );
}
