import cs from 'classnames';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import AppDownload from '../AppDownloadDrag';
import styles from './style.module.less';

export interface IFictionMsg {
    imageUrl: string;
    name: string;
    tags: string[];
    description: string;
    btnColor: string;
}
interface IProps extends IFictionMsg {
    hasTopLine: boolean;
    style: {
        textColor: string;
        tagColor: string;
        descColor: string;
    };
    downloadConfig: any;
    index: number;
}
const Item: React.FunctionComponent<IProps> = (props) => {
    const { hasTopLine, imageUrl, name, tags, description, style, downloadConfig, index } = props;
    const getBorderColor = () => {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const rgbs = style.tagColor.match(/\d+/g) || [255, 255, 255];
        console.log(rgbs);
        return `rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.3)`;
    };
    return (
        <div
            className={styles.itemWrapper}
            style={{
                borderTop: hasTopLine ? `1px solid ${getBorderColor()}` : 'none',
                paddingTop: hasTopLine ? '12px' : 0,
            }}
        >
            <div className={styles.iconWrapper}>
                <img
                    src={
                        imageUrl ||
                        transformCDN2Escape(
                            'https://s2-10889.kwimgs.com/kos/nlav10889/site/enptyIcon.png',
                        )
                    }
                />
                <div
                    className={cs(styles.top, {
                        [styles.top1]: index === 0,
                        [styles.top2]: index === 1,
                        [styles.top3]: index === 2,
                    })}
                >
                    {`TOP${index + 1}`}
                </div>
            </div>
            <div>
                <div className={styles.infoWrapper}>
                    <div
                        style={{
                            minWidth: 190,
                        }}
                    >
                        <div
                            className={styles.name}
                            style={{
                                color: style.descColor,
                            }}
                        >
                            {name}
                        </div>
                        <div
                            className={styles.tag}
                            style={{
                                color: style.tagColor,
                                fontSize: 12,
                            }}
                        >
                            {tags.map((tag, index) => {
                                if (index % 2 === 0) {
                                    return (
                                        <div>
                                            <span
                                                style={{
                                                    borderRight: `1px solid ${style.tagColor}`,
                                                    paddingRight: 5,
                                                    paddingLeft: 0,
                                                }}
                                            >
                                                {tag}
                                            </span>
                                            <span
                                                style={{
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                }}
                                            >
                                                {tags[index + 1]}
                                            </span>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                    <AppDownload
                        linkType={downloadConfig?.linkType}
                        appInfo={downloadConfig?.appInfo}
                        iOSLink={downloadConfig?.iOSLink}
                        quickAppLink={downloadConfig?.quickAppLink}
                        buttonStyle={{
                            ...downloadConfig?.buttonStyle,
                            // color: style.tagColor,
                            // border: `solid 1px ${style.tagColor}`,
                            width: 72,
                            height: 28,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 28,
                            fontSize: 12,
                        }}
                        text="立即下载"
                    />
                </div>
                <div
                    className={styles.descWrapper}
                    style={{
                        color: style.descColor,
                        fontSize: 13,
                    }}
                >
                    {description}
                </div>
            </div>
        </div>
    );
};

export default Item;
