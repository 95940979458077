import * as React from 'react';
import gcoord from 'gcoord';

import { getPageId } from 'landingPage/common/utils/getPageId';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';

import { isSSR } from 'landingPage/common/utils/env';

import { getProduct } from 'landingPage/common/utils/getProduct';
import { Div } from '../Blank/Blank';
import styles from '../MapDrag/style.module.less';
import H5Map from './h5Map';

export interface MapProps {
    text: string;
    keyword: string;
    lng: number;
    lat: number;
}

// "text": "快手总部",
//           "layout": "vertical-map-title",
//           "keyword": "北京市海淀区上地西路6号",
//           "lng": 116.30540198499068,
//           "lat": 40.059847092455065,
//           "height": 150,
//           "componentId": 7
export const getClientOSType = () => {
    const uaString = navigator.userAgent;
    if (uaString.includes('Android')) {
        return 'Android';
    }
    if (/(iPhone|iPad)/.test(uaString)) {
        return 'iOS';
    }
    if (uaString.includes('Macintosh')) {
        return 'Mac';
    }
    return 'Windows';
};

// 转换百度坐标系为GCJ02端内腾讯地图坐标系
function convert(geo: [number, number]) {
    return gcoord.transform(geo, gcoord.BD09, gcoord.GCJ02);
}

const Map: React.FunctionComponent<MapProps> = (props) => {
    const { text, keyword, lng, lat } = props;
    const [showH5Map, setShowH5Map] = React.useState(false);
    const [convertedLng, convertedLat] = convert([lng, lat]);
    const onClick = React.useCallback(() => {
        document.documentElement.scrollTop = 0;
        if (getProduct() === 0) {
            setShowH5Map(true);
            return;
        }
        const urlBase = 'kwai://map/nav';
        // 这里必须传callback
        const callback = getUrlParam('callback') || 'preview';
        const params = {
            title: text,
            address: keyword,
            longitude: convertedLng,
            latitude: convertedLat,
            lpPageId: getPageId(),
            callback,
        };

        const openFrom = JSON.stringify({
            url: window.location.href,
            from: getClientOSType().toLowerCase(),
        });
        const param = { ...params, openFrom };
        const url = `${urlBase}?${(Object.keys(param) as (keyof typeof param)[])
            .map((key) => param[key] != null && `${key}=${encodeURIComponent(param[key])}`)
            .join('&')}`;

        window.location.href = url;
    }, [convertedLat, convertedLng, keyword, text]);
    return (
        <Div
            className={styles.root}
            data-ksad-track="map_click"
            data-ksad-track-data='{"componentId": "7"}'
            onClick={onClick}
        >
            <div className={styles.anchor} />
            <div className={styles.content}>
                <div className={styles.addressTitle}>{text}</div>
                <div className={styles.addressContent}>{keyword}</div>
            </div>
            <div className={styles.right} />
            {!isSSR && (
                <H5Map
                    show={showH5Map}
                    position={{
                        longitude: convertedLng,
                        latitude: convertedLat,
                    }}
                    title={text}
                    onClose={() => {
                        setShowH5Map(false);
                    }}
                />
            )}
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {props.children}
        </Div>
    );
};

export default Map;
