/** 兼容审核页面调用，moli域名增加获取判断 */
export const getMoliDomain = () => {
    if (window.location.origin.includes('beta-moli-admin')) {
        return 'https://beta-moli.kuaishou.com';
    }
    if (window.location.origin.includes('moli-admin')) {
        return 'https://chenzhongkj.com';
    }
    return '';
};

export const getMoliHost = () => {
    if (window.location.origin.includes('staging')) {
        return 'https://lp.staging.kuaishou.com';
    }
    if (window.location.origin.includes('prt')) {
        return 'https://moli.prt.kuaishou.com';
    }
    if (window.location.origin.includes('chenzhongkj')) {
        return 'https://moli.kuaishou.com';
    }
    return '';
};
