import * as React from 'react';
import ReactDOM from 'react-dom';
import { isDevelopment } from '../../common/utils/env';
import { getWindow } from '../../common/utils/getWindow';

export const Portal: React.FunctionComponent = (props) => {
    let node = document.body;
    // 测试环境，包都是直接引入的。所以这里获取body要获取iframe里的
    if (isDevelopment) {
        node = getWindow().document.body;
    }
    return ReactDOM.createPortal(props.children, node);
};
