/* eslint-disable */
import { regionLocationUsingPost } from '../api';
import { IMessageRegionView } from '../api/interface';
import {
    STORE_LOAD_STAG,
    trackGetLocationResult,
    trackStoreLoad,
    trackStoreNoCity,
    trackStoreNoLoc,
} from '../adTrack/monitor';
import { invoke } from '@yoda/bridge';
export interface ILocation {
    longitude: string;
    latitude: string;
}

const PERMISSION_RESULT = [-400, -401, -402];

// 调用参数
interface ILocaitonParams {
    /** 业务标识（文档中的 Biz Code） */
    biz: string;
    // 弹窗title
    title?: string;
    // 弹窗内容，如果为空，使用默认文案（为空的情况仅限同城、生产、POI使用），其他业务请必传
    content?: string;
    // 是否强制弹出前置弹窗 不受48小时控制
    force?: boolean;
    // 文档中申请的 Stat Key
    statKey?: string;
    // 文档中申请的 AlertScene
    alertScene?: string;
    // 文档中申请的 Scene
    updateLocationScene?: string;
    // 当受频控限制时，使用上次定位的缓存值（iOS 目前只有内存缓存，冷启 + 受频控时，即便之前定位过，也拿不到缓存信息）（1.1.3 开始支持）
    useLastLocationWhileLimited?: boolean;
}

const LocationParams = {
    biz: 'ks_ad',
    title: '获取您的位置信息',
    content: '将用于定位所在城市及位置，为您推荐附近的门店，供您选择。',
    statKey: 'moliSiteEditor',
};

/**
 * 获得经纬度
 * https://docs.corp.kuaishou.com/k/home/VJsJxabPw27I/fcAB8yGis9ayTHGqcrrPsYG3_#section=vodka.haeiertzgbr7  最下方有内部调用流程图
 * */
export async function getLocationCompliance(force?: boolean) {
    const params = {
        ...LocationParams,
        force,
    };
    const { latitude, longitude } = await invoke('yoda.getLocation', params);
    console.log('getLocationCompliance=====', latitude, longitude);
    trackGetLocationResult(true, 'success');
    const syncLocationStart = Date.now();
    trackStoreLoad(STORE_LOAD_STAG.syncLocation, Date.now() - syncLocationStart);
    console.log('latitude===', latitude, 'longitude====', longitude);
    return {
        latitude,
        longitude,
    };
}

/**
 * 获得经纬度和城市
 * https://docs.corp.kuaishou.com/d/home/fcABP8D_uM_3jhU_oF3nXXeZD
 * force: 强制每次弹框，不冷却48h
 * disableRequest: 当获取经纬度失败的时候，不请求系统定位权限
 * */
export async function getLocationCity(force?: boolean, disableRequest?: boolean) {
    let location: any = null;
    try {
        const newLocation = await getLocationCompliance(force);
        location = {
            latitude: `${newLocation?.latitude}`,
            longitude: `${newLocation?.longitude}`,
        };
    } catch (err) {
        console.error(
            'getLocation error',
            err,
            err?.result && !PERMISSION_RESULT.includes(err?.result),
        );
        trackStoreNoLoc();
    }
    const lon = location?.latitude;
    const lat = location?.longitude;
    let city: any = null;
    try {
        console.log('lat::::', lat, 'lon::::', lon);
        city = await regionLocationUsingPost({
            lat,
            lon,
        });
    } catch (e) {
        console.log('location error::::', e);
        trackStoreNoCity();
    }
    console.log('city===', city);
    if (city?.id) {
        return [city, location] as [IMessageRegionView, ILocation];
    }
    return [];
}
