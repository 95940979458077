import { invoke } from '@yoda/bridge';
/**
 * 动态组件回填逻辑
 */
import { ComponentJson } from 'omega-render';
import { getCity, IGetCityParam } from '../common/api';

import {
    trackGetDynamicFail,
    trackParseDynamicFail,
    // trackReplaceDynamicFail,
    trackReplaceDynamicSuccess,
    trackGetDynamicElementNumber,
} from './adTrack/monitor';
import { getAdBridge, importBridge } from './bridge';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dateFormat = require('dateformat');

export interface IDynamicElementValue {
    compId: string;
    compType: 'XIANSUO_FORM_DRAG' | 'DRAG_TEXT' | 'DRAG_IMAGE';
    dynamicElementType: 'TEXT_CONTENT' | 'IMAGE_CONTENT' | 'FORM_TITLE' | 'FORM_BUTTON';
    elementIds?: string[];
    elementDetails: IDetail;
}
interface IDetail {
    [key: string]: IDetailValue;
}
interface IDetailValue {
    value: string;
    wildcard?: any[];
}

/**
 * 从桥方法中拿到动态数据（因为是算法引擎下发的，不直接与后端交互，所以使用桥）
 */
const dynamicDataCache: {
    [key: string]: IDynamicElementValue[];
} = {};
const getDynamicData = async (pageId: string, sendPerf: boolean = true) => {
    if (dynamicDataCache[pageId] && dynamicDataCache[pageId].length > 0)
        return dynamicDataCache[pageId];
    await importBridge(); // TODO
    const adBridge = getAdBridge();
    let dynamicElements: IDynamicElementValue[] = [];
    try {
        const res: any = await adBridge?.getData();
        try {
            const h5Data = JSON.parse(res?.data?.ad?.adDataV2?.h5Data);
            dynamicElements = h5Data?.landingPageComponentData || [];
            dynamicDataCache[pageId] = dynamicElements;
            sendPerf && trackGetDynamicElementNumber(pageId, dynamicElements.length);
        } catch (e) {
            sendPerf && trackParseDynamicFail(pageId);
        }
    } catch (e) {
        sendPerf && trackGetDynamicFail(pageId, (e as Error).message);
    }
    return dynamicElements;
};
/**
 * 根据拿到的结果替换组件内容
 */
const dynamicTrans = async (components: ComponentJson[], pageId: string) => {
    let dynamicElements: IDynamicElementValue[] = [];
    dynamicElements = await getDynamicData(pageId);
    try {
        await trans(components, dynamicElements, pageId);
        trackReplaceDynamicSuccess(pageId);
    } catch (e) {
        console.log(e, pageId);
        // trackReplaceDynamicFail(pageId, e as string);
    }
};
const trans = async (
    components: ComponentJson[],
    dynamicElements: IDynamicElementValue[],
    pageId: string,
) => {
    for (const com of components) {
        const comConfig = dynamicElements
            ? dynamicElements.filter((dynamicElement) => {
                  return dynamicElement.compId === com.id;
              })
            : [];
        for (const config of comConfig) {
            let valueOpt: IDetailValue = {
                value: '',
            };
            // 由于历史原因，引擎下发字段包含冗余字段，默认取对象下的第一个elementId
            if (config?.elementDetails) {
                const keys = Object.keys(config?.elementDetails);
                keys && keys.length > 0 && (valueOpt = config?.elementDetails[keys[0]]);
            }
            if (config?.dynamicElementType === 'TEXT_CONTENT') {
                com.props && (com.props.text = valueOpt?.value);
            }
            if (config?.dynamicElementType === 'FORM_TITLE' && com.props) {
                // eslint-disable-next-line no-await-in-loop
                const custitle = await transTitle(valueOpt?.value, pageId);
                com.props.formOpt.mainTitle = custitle;
            }
            if (config.dynamicElementType === 'FORM_BUTTON') {
                const oldViewPropsDesc = com.props
                    ? JSON.parse(com.props.formOpt.viewPropsDesc)
                    : {};
                oldViewPropsDesc.submitText = valueOpt?.value;
                com.props && (com.props.formOpt.viewPropsDesc = JSON.stringify(oldViewPropsDesc));
            }
            if (config.dynamicElementType === 'IMAGE_CONTENT') {
                com.props && (com.props.imgUrl = valueOpt?.value);
            }
        }
        if (com.components && com.components.length > 0) {
            // eslint-disable-next-line no-await-in-loop
            await trans(com.components, dynamicElements, pageId);
        }
    }
};

/**
 * 替换表单组件的标题中的地域日期字段
 */
const transTitle = async (value: string, pageId: string) => {
    const flags = ['#{地域}', '#{日期}'];
    const city = await getCusCity(pageId);
    const date = getDate();
    // TODO 替换所有
    value = value?.replaceAll(flags[0], city?.cityName || '');
    value = value?.replaceAll(flags[1], date);
    return value;
};
// interface locationResponse {
//     /**
//      * 1: 成功
//      * -400: 弹窗后，用户未授权
//      * -401: 未授权，且在冷却期内
//      * -402: 未弹窗，且未授权
//      * 其他: 与之前保持一致
//      */
//     result: number;
//     // 是否走的新逻辑
//     withPermissionCheck?: boolean;
//     // 与之前保持一致
//     data: ILocation;
// }
interface ILocation {
    longitude: number;
    latitude: number;
}
interface ICity {
    cityName: string;
}
const getCusCity = async (pageId: string): Promise<ICity> => {
    const lngAndLat: ILocation = await getLngAngLat();
    let res: ICity;
    try {
        res = await getCity({
            lat: lngAndLat.latitude || 0,
            lon: lngAndLat.longitude || 0,
            pageId,
        } as IGetCityParam);
        console.log('city:', res);
        return res;
    } catch (e) {
        console.error(e);
        return {
            cityName: '',
        };
    }
};
// 获取经纬度
const getLngAngLat = async (): Promise<ILocation> => {
    const location = {
        latitude: 0,
        longitude: 0,
    };
    const res = await invoke('yoda.getLocation', {
        biz: 'ks_ad',
        title: '获取您的位置信息',
        statKey: 'moliSiteEditor',
    });
    console.log('syncLocation res:', res);
    if (res?.longitude) {
        return res;
    }
    return location;
};

const getDate = () => {
    return dateFormat(new Date(), 'mm.dd');
};

/**
 * 用于埋点数据
 */
export const getSendData = async (pageId: string) => {
    const dynamicElements = await getDynamicData(pageId, false);
    const ret: string[] = dynamicElements.map((element) => {
        const elementIds = Object.keys(element.elementDetails).join(',');
        return `${element.compType}_${element.compId}_${element.dynamicElementType}_${elementIds}`;
    });
    return ret;
};
export default dynamicTrans;
