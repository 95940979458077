import { Element, Leaf } from './CustomRender';

export function SimpleRender(props) {
    const { value } = props;

    return value.map((item, index) => {
        if (!item.text) {
            return (
                <Element element={item} key={index} attributes={null}>
                    {item.children ? <SimpleRender value={item.children} /> : null}
                </Element>
            );
        }
        return (
            <Leaf leaf={item} key={index} attributes={null}>
                {item.text}
            </Leaf>
        );
        // return
    });
}
