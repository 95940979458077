import * as React from 'react';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { callApp } from 'landingPage/common/utils/callApp';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { PopupType, WechatActionSceneEnum } from '../WeiXin';
// import { ReactComponent as Checked } from '../image/icon-yi-fu-zhi.svg';
import { ReactComponent as Checked } from '../image/success.svg';
import styles from '../style.module.less';
import { copy, wechatDataTrack } from './commonFun';
import { sendFormSubmitAndEffectiveCustomerReport } from '../util';
// 多转化事件 优化目标
const multiConversion = getUrlParam('multiConversion') === 'true';

export interface CopyDialogProps {
    info?: string;
    wechatId?: string;
    onClose?: () => void;
    itemId?: string;
    packId?: string;
    appId?: string;
    againstFlowPrefixSwitch?: boolean; // 是否分流
    againstFlowPrefix?: string; // 分流前缀（公众号名称）
    wechatStay?: boolean;
    needSendEffectiveAction?: boolean;
    renderType?: string;
}

export const CopyDialog: React.FunctionComponent<CopyDialogProps> = (props) => {
    const {
        info,
        wechatId = '',
        onClose,
        itemId,
        packId,
        againstFlowPrefixSwitch = false,
        againstFlowPrefix = '',
        appId,
        wechatStay,
        needSendEffectiveAction,
        renderType,
    } = props;

    /*
        微信埋点说明文档： https://wiki.corp.kuaishou.com/pages/viewpage.action?pageId=879364226
    */

    // 根据是否开启分流前缀，自动拼接字符
    const processedWechatId = useMemo(() => {
        return againstFlowPrefixSwitch ? `#公众号：${againstFlowPrefix}/${wechatId}` : wechatId;
    }, [againstFlowPrefix, againstFlowPrefixSwitch, wechatId]);
    React.useEffect(() => {
        wechatId &&
            dataTrack({
                eventType: 'EVENT_VIEW_WECHAT',
                wechatItemId: itemId,
                lp_page_type: 1,
                wechatActionScene: WechatActionSceneEnum.COPY_WECHAT_ID,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                }),
            });
        wechatId &&
            copy({
                text: processedWechatId,
                itemId,
                packId,
                wechatStay,
            });
    }, [info, itemId, packId, wechatId, processedWechatId]);
    const callWX = React.useCallback(
        debounce(
            () => {
                // 多转化事件优化目标，不打除表单转化的EVENT_FORM_SUBMIT点
                if (!multiConversion) {
                    // dataTrack({
                    //     eventType: 'EVENT_FORM_SUBMIT',
                    //     wechatItemId: itemId,
                    //     lp_page_type: 1,
                    //     WechatActionStatus: WechatActionSceneEnum.COPY_WECHAT_ID,
                    //     eventProps: {
                    //         actionName: 'EXTERNAL_ACTION904',
                    //     },
                    //     diyExtraInfo: JSON.stringify({
                    //         renderType: renderType || 'WEI_XIN_DRAG',
                    //         landing_page_infos: JSON.stringify({
                    //             page_module_type: getTransCompList(),
                    //         }),
                    //     }),
                    // });
                    sendFormSubmitAndEffectiveCustomerReport({
                        wechatActionStatus: WechatActionSceneEnum.COPY_WECHAT_ID,
                        wechatItemId: itemId,
                        enableSend: needSendEffectiveAction,
                        renderType: renderType,
                    });
                }
                callApp(
                    'weixin://',
                    'https://weixin.qq.com/cgi-bin/readtemplate?t=download/ver_voice',
                    () => {
                        wechatDataTrack({
                            itemId,
                            invokeSuccess: true,
                            sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                            wechatId,
                            appId,
                            popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                        });
                        onClose?.();
                    },
                    () => {
                        console.log('is fail callback');
                        wechatDataTrack({
                            itemId,
                            invokeSuccess: false,
                            sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                            wechatId,
                            appId,
                            popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                        });
                        onClose?.();
                    },
                );
            },
            300,
            { trailing: false, leading: true },
        ),
        [itemId, onClose],
    );

    return (
        <div className={styles.dialog}>
            <div className={styles.exit} data-target="wechat-close" onClick={onClose} />
            <Checked className={styles.icon} />
            <div className={styles.text}>微信号已复制</div>
            <div className={styles.account}>{processedWechatId || '请选择微信'}</div>
            <div className={styles.info}>{info}</div>
            <div className={styles.close} onClick={callWX}>
                去添加
            </div>
        </div>
    );
};
