/**
 * 获取url 的search里某个key的值     www.kuaishou.com?a=1  getUrlParam('a') ->1
 * @param name 要获取url参数的key ，支持数组
 */
// export function getUrlParam(name: string): string;

import {
    extractUrlFromBackgroundImage,
    formatBackgroundImageFromUrl,
} from '@/landingPage/components/Blank/utils/useContainerStatus';
import { getGlobalSupportWebp, transformCDN2Escape } from '../upgradeCDN';

// export function getUrlParam(name: string[]): string;
export function getUrlParam(name: any, url?: string): any {
    const search = new URLSearchParams(url ?? window.location.search);
    if (typeof name === 'string') {
        return search.get(name) ?? '';
    }
    return name.map((n: any) => search.get(n) ?? '');
}

export function paramsToUrl(params: { [index: string]: any }) {
    return (Object.keys(params) as (keyof typeof params)[])
        .filter((key) => params[key] != null && params[key] !== undefined)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
}

/**
 * 图片 CDN 处理方法，内置支持 webp 参数
 * @param url
 * @returns
 */
export function getPicCDNSource(url?: string, useWebp: boolean = false): string {
    const isURLFormat = url?.startsWith('url(');
    if (isURLFormat) {
        url = extractUrlFromBackgroundImage(url);
    }
    const supportWebp = useWebp || getGlobalSupportWebp();
    if (
        url &&
        supportWebp &&
        url.indexOf('x-oss-process=image') == -1 &&
        url.indexOf('data:image') == -1
    ) {
        url +=
            (url.indexOf('?') === -1 ? `?` : '&') +
            `x-oss-process=${encodeURIComponent('image/format,webp')}`;
    }
    return isURLFormat
        ? formatBackgroundImageFromUrl(transformCDN2Escape(url))
        : transformCDN2Escape(url);
}

/**
 * 获取渐进增强url链接
 * @param originalUrl 图片原始url
 * @param supportWebp 是否支持webp格式
 * @param enableResize 是否缩放
 * @param resizeWidth 缩放时设置的宽度
 * @returns
 */
export function getEnhanceImageUrl(
    originalUrl: string,
    supportWebp: boolean = false,
    enableResize: boolean = false,
    resizeWidth?: number,
): string {
    if (!originalUrl) return originalUrl;
    if (originalUrl.includes('x-oss-process=image')) return originalUrl;
    const queryComponents: string[] = ['x-oss-process=image'];
    if (supportWebp) {
        queryComponents.push('format,webp');
    }
    if (enableResize) {
        const width = resizeWidth || 100;
        queryComponents.push(`resize,w_${width}`);
    }
    const queryString = queryComponents.join('/');
    const separator = originalUrl.includes('?') ? '&' : '?';
    return transformCDN2Escape(`${originalUrl}${separator}${queryString}`);
}
