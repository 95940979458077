import * as React from 'react';
import ReactDOM from 'react-dom';
import { useComContext } from 'omega-render';
import { isSSR } from 'omega-shared/utils';

type ValueOf<T> = T[keyof T];

export const BlockPos = {
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
} as const;

type IBlockPos = ValueOf<typeof BlockPos>;

interface BlockPlaceholderProps {
    parent?: HTMLElement;
    height?: number;
    preview?: boolean;
    pos: IBlockPos;
}

/**  获取placeHolder挂载的容器信息 */
export const getParent = (edit: boolean) => {
    if (!edit) {
        return window?.document.body;
    }
    return document.querySelector('#preview-app');
};

/** 当组件全局顶部和底部浮动的时候，撑起落地页高度，避免遮挡 */
const BlockPlaceholder: React.FC<BlockPlaceholderProps> = (props) => {
    const { edit } = useComContext();
    const { height = 68, preview, pos } = props;
    const { parent = getParent(!!edit) } = props;
    const [el, setEl] = React.useState<HTMLDivElement>();
    // useLayoutEffect 同步，避免闪烁
    React.useLayoutEffect(() => {
        const el = document.createElement('div');
        setEl(el);
        if (pos === BlockPos.BOTTOM) {
            parent?.appendChild(el);
        }
        if (pos === BlockPos.TOP) {
            // prepend在ios9上面不支持
            // parent?.prepend(el);
            const firstElement = parent?.firstChild ?? null;
            parent?.insertBefore(el, firstElement);
        }
        return () => {
            parent?.removeChild(el);
        };
    }, [parent, pos]);
    // SSR渲染时跳过， TODO
    if (isSSR) {
        return null;
    }
    // TODO: 上线之后删除这行代码
    if (preview && pos === BlockPos.BOTTOM) {
        return null;
    }
    if (!el) {
        return null;
    }
    return ReactDOM.createPortal(
        <div
            className="block-placeholder"
            style={{ height: `${height}px` }}
            onClick={(e) => e.stopPropagation()}
        />,
        el,
    );
};

export default BlockPlaceholder;
