import { Div } from '../Blank/Blank';
import Item, { IFictionMsg } from './Item';
import styles from './style.module.less';

interface IProps {
    // 产品列表
    list: IFictionMsg[];
    // 背景色
    backgroundColor: string;
    // 文字颜色
    textColor: string;
    // 标签颜色
    tagColor: string;
    // 描述文字颜色
    descColor: string;
    // 下载按钮的配置
    downloadConfig: any;
}
const SuggestionForFiction: React.FunctionComponent<IProps> = (props) => {
    const { list, backgroundColor, textColor, tagColor, descColor, downloadConfig } = props;
    return (
        <Div className={styles.root} style={{ backgroundColor }}>
            <div className={styles.contentWrapper}>
                <div className={styles.suggestTitle} style={{ color: textColor }}>
                    猜你喜欢
                </div>
                {list.map((item, index) => {
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    return (
                        <Item
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...item}
                            hasTopLine={index !== 0}
                            style={{
                                textColor,
                                tagColor,
                                descColor,
                            }}
                            index={index}
                            downloadConfig={downloadConfig}
                        />
                    );
                })}
            </div>
        </Div>
    );
};

export default SuggestionForFiction;
