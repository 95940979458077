import React from 'react';

export default function Tel(props: { fill: string }) {
    const { fill } = props;
    return (
        <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_124_397"
                maskUnits="userSpaceOnUse"
                mask-type="alpha"
                x="0"
                y="0"
                width="13"
                height="12"
            >
                <path d="M0.5 0H12.5V12H0.5V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_124_397)">
                <path
                    d="M6.20922 2.89379L4.80623 1.4908C4.40892 1.09349 3.76262 1.12581 3.36071 1.52772L2.6456 2.24283C2.19134 2.69708 1.97634 3.33125 2.07547 3.93758L2.09032 4.01584C2.32191 5.58107 3.07371 7.06505 4.23942 8.23076L4.3746 8.36268L4.47784 8.45919C5.58696 9.47742 6.94566 10.1389 8.39461 10.3711L8.44865 10.3787L8.42369 10.3737C9.05886 10.5188 9.744 10.3079 10.2271 9.82485L10.982 9.0659L11.0159 9.02818C11.3488 8.61756 11.347 8.03161 10.9794 7.66395L9.57639 6.26096L9.55449 6.24034L9.49294 6.18579L9.42632 6.13974L9.40375 6.12503L9.35995 6.09965C8.96182 5.88246 8.46102 5.96774 8.13087 6.29789L7.16048 7.26864L7.14688 7.26212C6.74468 7.05934 6.38835 6.79454 6.032 6.43818L5.90949 6.31109L5.83703 6.23045C5.61948 5.98018 5.40186 5.65712 5.22353 5.32626L5.2101 5.30059L6.17219 4.33942C6.57421 3.9374 6.60653 3.2911 6.20922 2.89379ZM8.86374 6.98497L8.8791 6.97767L10.2675 8.36607L10.2663 8.36731L10.2605 8.37029L10.2511 8.38251L9.51905 9.11868C9.28899 9.34873 8.97635 9.4538 8.69558 9.4084L8.60694 9.39147L8.54562 9.38255C7.25888 9.17634 6.0456 8.57242 5.06842 7.64256L4.94212 7.5193C3.93189 6.50902 3.2795 5.22125 3.07592 3.84813L3.05993 3.76252C3.01611 3.49329 3.12216 3.18048 3.35271 2.94993L4.06781 2.23483L4.07995 2.22365L4.09703 2.21021C4.10281 2.2055 4.10406 2.20285 4.09912 2.19791L5.50211 3.6009C5.49717 3.59596 5.49452 3.59721 5.48981 3.60299L5.47637 3.62007C5.47324 3.62376 5.46957 3.62783 5.46519 3.63221L4.0062 5.09035L4.1476 5.40505C4.40385 5.97533 4.78543 6.57102 5.18365 6.99889L5.31861 7.1389C5.86244 7.68284 6.41932 8.06092 7.08198 8.3297L7.38917 8.45429L8.83804 7.00493C8.84764 6.99533 8.85655 6.98909 8.86374 6.98497Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
}
