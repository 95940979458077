import { trackBridgeError, trackBridgeSuccess } from 'landingPage/common/adTrack/monitor';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { addListener } from '@yoda/bridge';
import { sendTraceLog } from 'src/utils/adMonitor';

export const trackState = {
    // 客服组件是否被点击
    clicked: false,
};

addListener('native_leave', () => {
    if (trackState.clicked) {
        dataTrack({
            eventType: 'EVENT_PAGE_CLOSE_FIRST_SCREEN',
            diyExtraInfo: JSON.stringify({
                renderType: 'CUSTOMER_SERVICE_DRAG',
            }),
        });
    }
})
    .then(() => {
        trackBridgeSuccess('on:native_leave');
    })
    .catch((e) => {
        trackBridgeError('on:native_leave', JSON.stringify(e));
        sendTraceLog({
            message: `${JSON.stringify(e)}, on:native_leave_error`,
        });
    });
