/* eslint-disable @typescript-eslint/ban-ts-comment */
import { invoke } from '@yoda/bridge';
import { trackBridgeError, trackBridgeSuccess } from '../adTrack/monitor';

export enum DEVICE_TYPE {
    UNKNOWN = 0,
    IOS = 2,
    ANDROID = 1,
    PC = 3,
    HARMONY = 4,
}

/** 一个不是那么严谨的判断 */
export function isPC() {
    if (
        window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
        )
    ) {
        return false; // mobile terminal
    }
    if (window.innerWidth < 800) {
        return false; // PC
    }
    return true;
}
/** 审核页面 */
export function isAudit() {
    return window.location.origin.includes('moli-admin');
}

export function getDeviceType() {
    const ua = navigator?.userAgent;
    // 以下判断方法来自 ks-bridge内部代码实现
    const isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    const isAndroid = ua.includes('Android') || ua.includes('Adr');
    const isHarmony = ua.includes('Harmony');
    if (isIOS) {
        return DEVICE_TYPE.IOS;
    }
    if (isAndroid) {
        return DEVICE_TYPE.ANDROID;
    }
    if (isHarmony) {
        return DEVICE_TYPE.HARMONY;
    }
    if (/(Windows|Mac OS|Linux)/i.test(ua)) {
        return DEVICE_TYPE.PC;
    }
    return DEVICE_TYPE.UNKNOWN;
}

// 不依赖bridge的同步判断设备类型
export function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return 'unknown';
}

// 是否是WK webview
export function ifWK() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /WebViewType\/WK/.test(userAgent);
}

/* 判断是否是wifi, 未知返回undefined */
export async function isWIFI() {
    try {
        const netWork = await invoke('system.getNetworkType');
        trackBridgeSuccess('yoda-getNetworkType');
        console.log('netWork:', netWork);
        if (/wifi/i.test(netWork?.net)) {
            return true;
        }
    } catch (e) {
        trackBridgeError('yoda-getNetworkType', e);
        // 使用h5 api 判断网络状况
        //@ts-expect-error
        console.log('navigator?.connection?.type', navigator?.connection?.type);
        //@ts-expect-error
        if (/wifi/i.test(navigator?.connection?.type)) {
            return true;
        }
    }
}
/**
 * 是否是鸿蒙
 */
export const isHarmony = getDeviceType() === DEVICE_TYPE.HARMONY;
