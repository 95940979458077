import './style/index.less';
import 'core-js/modules/es.object.values';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import 'url-search-params-polyfill';
import './pluginEnv';
import { umdRenderEntry } from './umdRenderEntry';

export * from './umdRenderEntry';
console.log('this is ====== yoda2.0');

/** 兼容之前的版本 */

export const parser = umdRenderEntry;
