/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { InjectComponentProps, useComContext } from 'omega-render';
import './iconfont/iconfont.css';
import { Toast } from '@ad/sharpui';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { ClueFirstSubmitParam, submitIccCluePhaseOneUsingPOST } from 'landingPage/common/api';
import { bridgeGetDeviceInfo } from 'landingPage/common/bridge';
import { getUrlParam } from '../../common/utils/getUrlParam';
import { getPageId } from '../../common/utils/getPageId';
import { Div } from '../Blank/Blank';
import { trackerAd } from '../../common/utils/trackAd';
import { trackClick } from '../../common/adTrack/util/zt';
import { getStoreState } from '../Store/utils/locStore';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { UseType } from '../TelDrag/Tel';
import { isInApp } from '../../common/utils/env';

export interface ICallTelParam {
    telType: string | undefined;
    iccId?: string | undefined;
    loading?: boolean;
    setLoading?: (arg0: boolean) => void;
    comType: string;
    tel?: string | number | undefined;
    pageId?: string;
    customData?: {
        [index: string]: any;
    };
}
export const callTel = async (data: ICallTelParam) => {
    const { telType, iccId, loading, setLoading, comType, tel, customData, pageId } = data;
    if (telType !== 'AImobile' && !tel) {
        return;
    }
    if (loading) {
        return;
    }
    if (!loading) {
        setLoading?.(true);
    }
    let telNum = tel;

    if (telType === 'AImobile' && iccId) {
        // 兼容审核PC浏览器打开，UUID赋予默认值
        let uuid = '0';
        try {
            const deviceInfo = await bridgeGetDeviceInfo();
            uuid = deviceInfo?.data?.uuid;
        } catch (err) {
            console.error('getDeviceInfo 调用失败:', err);
        }
        const sourceUrl = document.location.href;
        const dspCallback = getUrlParam('callback');
        const iccClueFirstSubmitParam: ClueFirstSubmitParam = {
            /** 线索数据描述信息 */
            iccClueSourceDescView: {
                /** 设备描述符 */
                deviceId: uuid,
                /** DSP回调 */
                dspCallback,
                /** 发布资源id，如落地页id */
                pubSourceId: pageId || getPageId(),
                /** 线索收集方业务线 */
                publishBizId: 1003,
                /** 来源url */
                sourceUrl,
            },
            /** 智能电话id */
            iccId,
            extraInfo: {
                isPreLandingPage: window.isPreLandingPage || 0,
                // 落地页类型，落地页取1 咨询页取2
                lpPageType: 1,
            },
            storeId: getStoreState<'storeSelected'>('storeSelected')?.id,
            storeName: getStoreState<'storeSelected'>('storeSelected')?.name,
            orientedCity: getUrlParam('cityName') || '',
            reportRenderInfo: {
                // 新增加的字段
                renderType: comType, // renderType的值是字符串类型
            },
            iccUseTypeEnum: isInApp ? UseType.KUAISHOU : UseType.LIAN_MENG,
            ...customData,
        };
        try {
            const res = await submitIccCluePhaseOneUsingPOST({ iccClueFirstSubmitParam });
            setLoading?.(false);

            if (!res.successList || !res.successList.length) {
                throw new Error(res.errorDetailMap.errorResult);
            } else {
                telNum = res.successList[0];
            }
        } catch (e) {
            setLoading?.(false);

            Toast.error(e.message || '');
            return;
        }
    }
    const a = window.document.createElement('a');
    a.href = `tel: ${telNum}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setLoading?.(false);
    if (telType !== 'AImobile') {
        const { dataTrack } = trackerAd;
        dataTrack({ eventType: 'EVENT_MAKING_CALLS' });
    } else {
        // weblog 打点智能电话点击
        trackClick('EVENT_MAKING_AI_CALL');
    }
};
export type TelType = 'mobile' | 'phone' | 'AImobile';

export interface TelProps extends Omit<InjectComponentProps, 'type'> {
    /** 组件位置类型 */
    position: 'bottom' | 'base' | 'right-bottom';
    /** 电话号码 */
    tel: string;
    /** 按钮文案 */
    text: string;
    /** 电话的类型 */
    telType?: TelType;
    /** 按钮内文本样式 */
    textStyle?: {
        fontSize?: number;
        color?: string;
    };
    /** 按钮的基本样式 */
    buttonStyle?: {
        background?: string;
        backgroundImage?: string;
        backgroundSize?: string;
        borderRadius?: string;
        iconColor?: string;
        wrapBackground?: string;
        backgroundColor?: string;
    };
    /** 按钮的大小 */
    size?: {
        width?: string;
        height?: string;
    };
    /** 智能电话勾选id */
    iccId?: string | undefined;
    index?: number;
    iconSize?: number;
    /** 坐标，本期暂时不做，下期可能用 */
    // coordinate?: { // 坐标
    //     left?: string;
    //     top?: string;
    // };
}

const Tel: React.FunctionComponent<TelProps> = (props) => {
    const { position, tel, text, textStyle, buttonStyle, size, iccId, telType, iconSize } = props;
    const normalStyle = {
        width: `${size?.width}`,
        background: `${buttonStyle?.background}`,
        height: `${size?.height}`,
        lineHeight: `${size?.height}`,
        fontSize: `${textStyle?.fontSize}px`,
        color: `${textStyle?.color}`,
        borderRadius: `${buttonStyle?.borderRadius}`,
    };
    const wrapStyle = {
        background: `${buttonStyle?.wrapBackground}`,
        zIndex: position === 'base' ? undefined : 202,
    };
    const iconStyle = {
        fontSize: `${iconSize || textStyle?.fontSize}px`,
        color: buttonStyle?.iconColor,
    };
    const [loading, setLoading] = React.useState(false);
    const { edit } = useComContext();
    const onClick = () => {
        callTel({ telType, iccId, loading, setLoading, comType: 'TEL', tel });
    };

    return (
        <Div style={wrapStyle} className={styles[position]}>
            {position !== 'right-bottom' ? (
                <div
                    className={styles['normal-content']}
                    onClick={() => {
                        onClick();
                    }}
                    style={normalStyle}
                >
                    <i style={iconStyle} className="iconfont-tel icon-phone-24-tel" />
                    &nbsp;
                    {text}
                </div>
            ) : (
                <div>
                    <div
                        onClick={() => {
                            onClick();
                        }}
                        className={styles['right-bottom-content']}
                        style={{ background: `${buttonStyle?.background}` }}
                    >
                        <i
                            style={{
                                color: buttonStyle?.iconColor,
                                fontSize: '43px',
                            }}
                            className="iconfont-tel icon-phone-tel"
                        />
                    </div>
                    <p
                        style={{
                            fontSize: `${textStyle?.fontSize}px`,
                            color: `${textStyle?.color}`,
                        }}
                    >
                        {text.length > 4 ? `${text.slice(0, 4)}...` : text}
                    </p>
                </div>
            )}
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {position === 'base' && props.children}
            {position === 'bottom' && (
                <>
                    <BlockPlaceholder pos="BOTTOM" preview={edit} />
                    <SafeAreaPlace />
                </>
            )}
        </Div>
    );
};

export default Tel;
