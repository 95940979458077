import { getUrlParam } from './getUrlParam';

interface Params {
    url: string;
    macros: Record<string, string>;
    onError?: (key: string) => void;
}

/**
 *  替换URL中的宏参数
 * demo:  https://www.baidu.com?callback=__CALLBACK__ => https://www.baidu.com?callback=xxxxxx
 * @param params - The URL to replace.
 * @returns The modified URL.
 */
export const transformUrlWithMacros = (params: Params): string => {
    const { url, macros, onError } = params;
    let res = url;
    // const urlObj = new URL(url);
    // const params = new URLSearchParams(urlObj.search);
    const macroKeyWhiteList = Object.keys(macros);
    const matchedMacros = url.match(/__.*?__/g);
    if (!matchedMacros?.length) return url;
    // Display the key/value pairs
    for (const matchedMacro of matchedMacros) {
        // console.log(`${key}, ${value}`);
        // const matchMacro = rawMacros.find((rawMacro) => (value as string).includes(rawMacro));
        const inWhiteList = macroKeyWhiteList.includes(matchedMacro);
        if (inWhiteList) {
            const macroValue = getUrlParam(macros[matchedMacro]);
            console.log('[macroValue]', matchedMacro, macroValue);
            if (macroValue) {
                res = res.replace(matchedMacro, macroValue);
            } else {
                console.error(`获取宏参数${macros[matchedMacro]}失败`);
                // trackGetMacroError(matchedMacro, getPageId(), urlType);
                onError?.(matchedMacro);
            }
        }
    }
    return res;
};
