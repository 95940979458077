import React, { useCallback, useEffect, useState } from 'react';
import { Toast } from '@ad/sharpui';
import { couponDetailUsingPOST, getCountdownABRes } from 'landingPage/common/api/index';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { useComContext } from 'omega-render';
import BaseStyle from './component/BaseStyle';
import BottomStyle from './component/BottomStyle';
import RightBottomStyle from './component/RightBottomStyle';
import CouponModal from './component/CouponModal';

export interface CouponCardProps {
    position: Position;
    styleIndex?: number;
    couponId: string;
    couponTitle: string;
    couponTitleStyle?: React.CSSProperties;
    buttonText: string;
    buttonTextStyle?: React.CSSProperties;
    modalText?: string;
    modalTextStyle?: React.CSSProperties;
    modalIndex?: number;
    countText?: {
        main?: string;
        sub?: string;
    };
}

export enum Position {
    base = 'base',
    bottomBlank = 'bottomBlank',
    rightBottom = 'rightBottom',
}

export enum CouponStatus {
    '待生效' = 1,
    '生效中' = 2,
    '已过期' = 3,
}

export interface CouponDetail {
    couponId?: string; // 卡券id
    businessName?: string; // 商家名
    businessLogo?: string; // 商家logo
    couponName?: string; // 卡券名
    validity?: string; // 使用期限
    couponStatus?: CouponStatus; // 卡券状态，1待生效，2生效中，3为已过期，展示过期状态
    instructs?: string[]; // 说明
    storeNames?: string[]; // 关联门店名称
    storeType?: number; // 门店类型  0表示全部门店
}

const CouponCard: React.FunctionComponent<CouponCardProps> = (props) => {
    const {
        position,
        couponId,
        couponTitle,
        couponTitleStyle,
        buttonText,
        buttonTextStyle,
        modalText,
        modalTextStyle,
        countText,
    } = props;

    const [couponDetail, setCouponDetail] = useState<CouponDetail>();
    const { edit } = useComContext();
    const [timeInterval, setTimeInterval] = React.useState<number>(61);

    const [visible, setVisible] = React.useState(false);
    useEffect(() => {
        const fetchCouponDetail = async () => {
            try {
                const res = await couponDetailUsingPOST({ couponId });
                console.log('res----', res);
                if (res.data) {
                    setCouponDetail(res.data);
                }
            } catch (e) {
                console.log('e---', e);
            }
        };
        if (couponId) {
            fetchCouponDetail();
        }
    }, [couponId]);

    const getIntervalFromApi = async () => {
        // 获取AB实验
        const res = await getCountdownABRes({
            componentType: 10,
        });
        if (res?.data?.msgIntervalCount) {
            setTimeInterval(res.data.msgIntervalCount);
        }
    };

    React.useEffect(() => {
        getIntervalFromApi();
    }, []);

    const setModalVisible = useCallback(
        (visible: boolean) => {
            if (visible) {
                if (couponDetail?.couponStatus !== CouponStatus.已过期) {
                    setVisible(visible);
                    dataTrack({
                        eventType: 'EVENT_COUPONS_CLICK',
                        diyExtraInfo: JSON.stringify({
                            couponId,
                        }),
                    });
                } else {
                    Toast.info('活动已结束');
                }
            } else {
                setVisible(visible);
            }
        },
        [couponDetail?.couponStatus],
    );

    return (
        <>
            {position === Position.base && (
                <BaseStyle
                    couponTitle={couponTitle}
                    couponTitleStyle={couponTitleStyle}
                    buttonText={buttonText}
                    buttonTextStyle={buttonTextStyle}
                    validity={couponDetail?.validity ?? '暂未设置期限'}
                    setModalVisible={setModalVisible}
                />
            )}
            {position === Position.bottomBlank && (
                <BottomStyle
                    couponTitle={couponTitle}
                    couponTitleStyle={couponTitleStyle}
                    buttonText={buttonText}
                    buttonTextStyle={buttonTextStyle}
                    setModalVisible={setModalVisible}
                />
            )}
            {position === Position.bottomBlank && (
                <>
                    <BlockPlaceholder pos="BOTTOM" preview={edit} height={38} />
                    <SafeAreaPlace />
                </>
            )}
            {position === Position.rightBottom && (
                <RightBottomStyle setModalVisible={setModalVisible} />
            )}
            {visible && (
                <CouponModal
                    modalText={modalText}
                    modalTextStyle={modalTextStyle}
                    countText={countText}
                    setModalVisible={setModalVisible}
                    businessName={couponDetail?.businessName}
                    businessLogo={couponDetail?.businessLogo}
                    couponName={couponDetail?.couponName}
                    instructs={couponDetail?.instructs}
                    storeNames={couponDetail?.storeNames}
                    couponId={couponId}
                    validity={couponDetail?.validity}
                    storeType={couponDetail?.storeType}
                    timeInterval={timeInterval}
                />
            )}
        </>
    );
};

export default CouponCard;
