import { impressionTrack, leaveTrack, observeTrackElTrack, elTrack, dataTrack } from './util/track';

const trackerAd = {
    leaveTrack,
    elTrack,
    dataTrack,
    globalParams: {},
    canTrack: true,
    isDev: false,
    autoTrack: true,
};

window.trackerAd = trackerAd;

export const autoTrack = (isAuto = true) => {
    if (isAuto) {
        if (document.readyState !== 'loading') {
            // 页面加载完成后
            impressionTrack();
            // leaveTrack();
            observeTrackElTrack();
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                // 页面基础框架加载完成后
                impressionTrack();
                observeTrackElTrack();
            });
            // leaveTrack();
        }
    }
};

export default trackerAd;
