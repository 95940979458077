import * as React from 'react';
import { ComponentHoc, useComContext } from 'omega-render';
import { componentImpression } from '../adTrack/monitor';
import { ComponentType } from '../componentsInfo.gen';

export const trackHoc: ComponentHoc = (C) => {
    return (props) => {
        const { type } = useComContext();
        React.useEffect(() => {
            if (type) {
                // 统计组件使用
                componentImpression(type as ComponentType);
            }
        }, [type]);
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <C {...props} />;
    };
};
