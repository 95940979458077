/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createPortal } from 'react-dom';
import styles from './styles.module.less';
import cs from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { invoke } from '@yoda/bridge';
import { bridgeGetDeviceInfo, getAdBridge, importBridge } from 'landingPage/common/bridge';
import { aggregatePageParams } from 'landingPage/common/api/interface';
import { geAggregateAdInfo, getPageJSON, submitV2UsingPOST } from 'landingPage/common/api';
import { ComponentJson, componentsForEach } from 'omega-render';
import { getCallbackInfo } from 'landingPage/common/adTrack/util/sendData';
import { v4 as uuidv4 } from 'uuid';
import { getOtherSubmitData, XiansuoFormProps } from '../../XiansuoForm';
import { Toast } from '@ad/sharpui';
import {
    addParamsToURL,
    getFormData,
    getPhotoPageId,
    getUrlParams,
    matchPageId,
    openPage,
} from './utils';
import {
    AggregateItemData,
    AggregateItemProps,
    AggregatePageProps,
    EapiRequestParams,
    FeedBaseInfo,
    FeedView,
    IMPRESSION_SOURCE,
    PageStyle,
} from './types';
import { signApi } from 'landingPage/common/utils/encrypt';
import { getPageId } from 'landingPage/common/utils/getPageId';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { sendTraceLog } from 'landingPage/common/adTrack/monitor';

function hasFalsyValue(obj: Record<string, any>): boolean {
    for (const key in obj) {
        if (!obj[key]) {
            return true;
        }
    }
    return false;
}

/** 提交表单内容 */
export async function submitForm(data: {
    pageJSON: { components: ComponentJson[]; id?: string };
    value: {
        name: string;
        phone: string;
        h5Url: string;
        aggregateId: string;
    };
}) {
    const { pageJSON, value } = data;
    console.log('[subitForm]', data);
    let phoneId = '';
    let nameId = '';
    let formOptValue: XiansuoFormProps['formOpt'] | null = null;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    componentsForEach(pageJSON.components!, (com) => {
        if (com.type === 'XIANSUO_FORM_DRAG') {
            formOptValue = com.props?.formOpt;
        }
    });
    if (!formOptValue) return;
    const formOpt = formOptValue as XiansuoFormProps['formOpt'];
    formOpt.components.forEach((item) => {
        if (item.componentType === 'NAME_TEXT') {
            nameId = item.id;
        }
        if (item.componentType === 'PHONE_TEXT') {
            phoneId = item.id;
        }
    });
    let deviceId = '';
    try {
        const bridgeData = await bridgeGetDeviceInfo();
        if (bridgeData?.data) {
            deviceId = String(bridgeData.data.globalId);
        }
    } catch (err) {
        console.log('KS-briage信息获取失败');
    }
    const params: { formSubmitParam } = {
        formSubmitParam: {
            details: [
                {
                    componentId: phoneId,
                    value: JSON.stringify(value.phone),
                },
                {
                    componentId: nameId,
                    value: value.name,
                },
            ],
            clientActionLogs: [],
            requestId: uuidv4(),
            clueSource: {
                did: '',
                openId: '',
                adCallBack: getCallbackInfo(),
                deviceId,
                deviceType: 0,
                pubBizId: 1003,
                pubSourceId: pageJSON?.id,
                pubUserId: '',
                sourceUrl: window.location.href,
                sourceDesc: '{}',
                remoteCoordinates: '',
                aggregatePageId: value.aggregateId,
                preLpPageId: getPageId(),
                preCreativeId: getUrlParam('ksCreativeId'),
            },
            // clientActionLogs: behaviors,
            formId: formOpt.id,
            extraInfo: {
                phoneNumberAutoFilled: 1,
                appointmentFromGame: getUrlParams(value.h5Url, 'appointmentFromGame')
                    ? 'EVENT_APPOINT_FORM'
                    : '',
                /** 游戏预约跳转 */
                appointmentJumpFromGame: getUrlParams(value.h5Url, 'appointmentJumpFromGame')
                    ? 'EVENT_APPOINT_JUMP_CLICK'
                    : '',
                /** 是否是前置落地页 0未知 1前置 2非前置 */
                isPreLandingPage: 0,
            },
            validateCodes: [],
            reportRenderInfo: {
                // 新增加的字段
                renderType: 'XIANSUO_FORM_DRAG',
            },
            // eslint-disable-next-line
            // storeDetail: undefined,
            ...(getOtherSubmitData ? getOtherSubmitData() : {}),
            isSubFormClue: false,
        },
    };
    console.log('params', params);
    try {
        const res = await submitV2UsingPOST(params);
        console.log('[submitV2UsingPOST]', res);
        Toast.info('预约成功！');
        return true;
    } catch (e) {
        console.error(e);
        Toast.info('预约失败！请稍后重试');
    }
}

export function AggregatePage(props: AggregatePageProps) {
    const { preValue, preFormOpt } = props;
    /** 用户已预约的落地页 */
    const [submittedPageId, setSubmittedPageId] = useState<string[]>([]);
    /** 用户已填写的姓名电话 */
    const { phone, name } = getFormData(preValue, preFormOpt);
    const [show, setShow] = useState(true);
    /** 聚合落地页样式 */
    const [pageStyle, setPageStyle] = useState(PageStyle.POPUP);
    /** 请求eapi的设备参数，通过bridge getEapiRequestParams获得获得 */
    const [eapiBridgeParams, setEapiBridgeParams] = useState<EapiRequestParams | null>(null);
    /**  请求eapi的广告参数，通过bridge getData获得 */
    const [feedBaseInfo, setFeedBaseInfo] = useState<FeedBaseInfo | null>(null);
    /** 已请求的聚合落地页推荐广告信息 */
    const [recommendFeeds, setRecommendFeeds] = useState<FeedView[]>([]);
    const [source, setSource] = useState(IMPRESSION_SOURCE.AUTO);
    // const [exposed, setExposed] = useState(false);
    const [initd, setInitd] = useState(false);
    const [loadEnd, setLoadEnd] = useState(false);
    const [inWhiteList, setInWhiteList] = useState(false);
    /** 后端要求，uuid不含中横线 */
    const [aggregateId] = useState(uuidv4().replace(/-/g, ''));
    const [hasError, setHasError] = useState(false);
    // const result;

    // const recommendedItems: AggregateItemData[] = mockData;
    const recommendedItems: AggregateItemData[] = useMemo(() => {
        return recommendFeeds.map((item, index) => {
            console.log('[recommendedItems]', item);
            return {
                avatar: item.ad.adDataV2.coverActionBarInfo.icon,
                title: item.ad.adDataV2.coverActionBarInfo.title,
                subTitle: item.ad.adDataV2.coverActionBarInfo.subTitle,
                tags: item.ad.adDataV2.coverActionBarInfo.tags,
                productName: item.ad.baseInfo?.productName,
                // tags: undefined,
                reservationNum: item.ad.adDataV2.coverActionBarInfo.weakTitle,
                titleLabel:
                    pageStyle === PageStyle.POPUP ? (index < 3 ? '优质商户' : '') : '优质商户',
                h5Url: item.ad.adDataV2.h5Url
                    ? addParamsToURL(item.ad.adDataV2.h5Url, {
                          aggregatePageId: aggregateId,
                          preLpPageId: getPageId(),
                          preCreativeId: getUrlParam('ksCreativeId'),
                          preName: name,
                          prePhone: JSON.stringify(phone),
                      })
                    : '',
                preFormData: { phone, name },
                feedData: item,
            };
        });
    }, [recommendFeeds, pageStyle]);
    /** 请求eapi获取推荐广告 */
    const getData = async (data: { num: number }) => {
        const { num } = data;
        let bizSwitchInfo;
        let feedBaseInfoData = feedBaseInfo;
        let eapiBridgeParamsData = eapiBridgeParams;
        if (!eapiBridgeParamsData) {
            try {
                const res = await invoke('advertise.getEapiRequestParams');
                eapiBridgeParamsData = res as EapiRequestParams;
                setEapiBridgeParams(res as unknown as EapiRequestParams);
                console.log('eapi', res);
            } catch (e) {
                console.error(e);
            }
        }
        if (!feedBaseInfoData) {
            await importBridge();
            try {
                const adBridge = getAdBridge();
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const feedData = (await adBridge?.getData()) as unknown as
                    | {
                          data: {
                              feed: FeedView;
                          };
                      }
                    | undefined;
                console.log('[feedData]', feedData);
                if (feedData) {
                    feedBaseInfoData = {
                        productName: feedData.data.feed.ad.baseInfo?.productName,
                        firstIndustryId: feedData.data.feed.ad.baseInfo?.firstIndustryIdV3,
                        licenceIdNum: feedData.data.feed.ad.baseInfo?.licenceIdNum,
                        secondIndustryId: feedData.data.feed.ad.baseInfo?.secondIndustryIdV3,
                        accountId: feedData.data.feed.ad.baseInfo?.accountId,
                    } as any;
                    setFeedBaseInfo(feedBaseInfoData);
                }
                bizSwitchInfo = feedData?.data?.feed?.ad?.bizSwitchInfo;
                if (!feedData?.data?.feed?.ad?.bizSwitchInfo?.enableLeadsAggregation) {
                    props.onResult(false);
                    setInWhiteList(false);
                    return;
                }
            } catch (e) {
                console.error(e);
            }
        }

        const params: aggregatePageParams = {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...eapiBridgeParamsData!,
            existPageStyle: initd ? 1 : 0,
            impressionInfo: {
                adPosInfoList: [
                    {
                        ...getPhotoPageId(),
                        adNum: num,
                        adExtInfo: {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            ...feedBaseInfoData!,
                            impressionProductName: recommendedItems.map((item) => item.productName),
                            aggregatePageVersion: '1.0',
                        } as any,
                    },
                ],
            },
        };
        console.log('params', params);
        try {
            if (hasFalsyValue(feedBaseInfoData || {})) {
                sendTraceLog({
                    tags: {
                        specTag: 'aggregatePageFeed',
                        url: window.location.href,
                        aggregateId,
                        bizSwitchInfo,
                        feedBaseInfoData,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
        let res;
        try {
            const singRes = await signApi({
                query: { sigCatVer: 1 },
                body: JSON.stringify(params),
                cookie: document.cookie,
            });
            console.log('[geAggregateAdInfo]', singRes);
            res = await geAggregateAdInfo(params, {
                __NS_sig3: singRes,
                sigCatVer: 1,
            });
            console.log('res', res);
            if (!initd) {
                props.onResult(true);
                setInWhiteList(true);
            }
        } catch (e) {
            console.error(e);
            setHasError(true);
            // if (e.result === 161305) {
            props.onResult(false);
            setInWhiteList(false);
            return;
            // }
            // props.onResult(true);
            // setInWhiteList(true);
            // return;
        }
        if (!pageStyle && res?.aggregatePageStyle) {
            setPageStyle(res?.aggregatePageStyle);
        }
        if (!initd) {
            setInitd(true);
        } else if (recommendFeeds.length) {
            // 最多加载2屏
            setLoadEnd(true);
        }
        console.log('[setRecommendFeeds in]', recommendFeeds);
        setRecommendFeeds((recommendFeeds) => [
            ...recommendFeeds,
            ...res.photoFeedViews.map((item) => JSON.parse(item.feedView)),
        ]);
        setTimeout(() => {
            console.log('[setRecommendFeeds]', recommendFeeds);
        }, 1000);

        // console.log(res);
    };
    useEffect(() => {
        getData({ num: 6 });
    }, []);
    const loadMore = useCallback(() => {
        dataTrack({
            eventType: 'EVENT_ELEMENT_CLICK ',
            lp_element_type: 2,
            diyExtraInfo: JSON.stringify({
                aggregatePageId: aggregateId,
            }),
        });
        getData({ num: 6 });
    }, [getData]);
    const closePopup = () => {
        dataTrack({
            eventType: 'EVENT_ELEMENT_CLICK',
            lp_element_type: 3,
            diyExtraInfo: JSON.stringify({
                aggregatePageId: aggregateId,
            }),
        });
        setShow(false);
    };
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [show]);
    const popupRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (popupRef.current) {
            popupRef.current.addEventListener(
                'touchmove',
                (e) => {
                    e.stopPropagation();
                },
                { passive: false },
            );
        }
    }, [popupRef.current]);
    const popupPageRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (popupPageRef.current) {
            popupPageRef.current.addEventListener(
                'touchmove',
                (e) => {
                    e.preventDefault();
                },
                { passive: false },
            );
        }
    }, [popupPageRef.current]);
    if (!inWhiteList) return null;

    return createPortal(
        <>
            {!show ? (
                <div
                    className={styles.aggregateButton}
                    onClick={() => {
                        setShow(true);
                        setSource(IMPRESSION_SOURCE.BUTTON);
                        dataTrack({
                            eventType: 'EVENT_ELEMENT_CLICK',
                            lp_element_type: 5,
                            diyExtraInfo: JSON.stringify({
                                aggregatePageId: aggregateId,
                            }),
                        });
                    }}
                ></div>
            ) : pageStyle === PageStyle.POPUP ? (
                <div className={styles.aggregatePage} ref={popupPageRef}>
                    <div className={styles.popupWrapper}>
                        <div
                            // onTouchMove={stopPro}
                            ref={popupRef}
                            className={cs(
                                styles.popup,
                                show ? styles.inAnimation : styles.outAnimation,
                            )}
                        >
                            <div className={styles.header}>
                                <div className={styles.headerLeft}>
                                    {source === IMPRESSION_SOURCE.AUTO && (
                                        <div className={styles.successIcon}></div>
                                    )}
                                    <div className={styles.content}>
                                        <div className={styles.main}>
                                            {`${
                                                source === IMPRESSION_SOURCE.AUTO
                                                    ? '提交成功！'
                                                    : ''
                                            }更多优质店铺不容错过`}
                                        </div>
                                        <div className={styles.sub}>
                                            点击预约后，您提交的联系方式将发送相应服务商
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.closeIcon} onClick={closePopup}></div>
                            </div>
                            <div
                                className={styles.storeList}
                                style={{ minHeight: recommendedItems.length ? '500px' : 'auto' }}
                            >
                                {recommendedItems.map((item, index) => (
                                    <AggregatePopupItem
                                        data={item}
                                        key={index}
                                        index={index}
                                        submitted={submittedPageId.includes(item.h5Url)}
                                        aggregateId={aggregateId}
                                        feedData={item.feedData}
                                        onSubmitted={() => {
                                            console.log(
                                                '[onSubmitted]',
                                                submittedPageId,
                                                item.h5Url,
                                            );
                                            setSubmittedPageId([...submittedPageId, item.h5Url]);
                                        }}
                                        pageStyle={PageStyle.POPUP}
                                    />
                                ))}
                                {!loadEnd && recommendedItems?.length >= 6 && (
                                    <div className={styles.loadMoreWrapper} onClick={loadMore}>
                                        <span className={styles.moreText}>点击展开更多推荐</span>
                                        <span className={styles.dropDownIcon} />
                                    </div>
                                )}
                                {loadEnd && (
                                    <div className={styles.loadMoreWrapper}>
                                        <span className={styles.moreText}>
                                            更多优质商户即将入驻，敬请期待！
                                        </span>
                                    </div>
                                )}
                            </div>
                            {hasError && !recommendedItems.length ? (
                                <div className={cs(styles.emptyWrapper, styles.loading)}>
                                    <div className={styles.empty}>
                                        <div className={styles.failIcon}></div>
                                        <div className={styles.desc}>
                                            优质商户加载失败，请稍后重试
                                        </div>
                                        <div
                                            className={styles.retryButton}
                                            onClick={() => getData({ num: 6 })}
                                        >
                                            点击重试
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.aggregateDialogPage}>
                    <div className={styles.aggregateDialog}>
                        <div className={styles.dialogTitle}> 提交成功！更多优质商户不容错过 </div>
                        <div className={styles.dialogSubTitle}>
                            预约后您的联系方式将发送相应服务商
                        </div>
                        <div className={styles.storeList}>
                            {recommendedItems.slice(0, 4).map((item, index) => (
                                <AggregatePopupItem
                                    data={item}
                                    key={index}
                                    index={index}
                                    submitted={submittedPageId.includes(item.h5Url)}
                                    aggregateId={aggregateId}
                                    pageStyle={PageStyle.DIALOG}
                                    feedData={item.feedData}
                                    onSubmitted={() => {
                                        console.log('[onSubmitted]', submittedPageId, item.h5Url);
                                        setSubmittedPageId([...submittedPageId, item.h5Url]);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={styles.dialogCloseIcon} onClick={closePopup} />
                </div>
            )}
        </>,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        document.body!,
    );
}

export function AggregatePopupItem(props: AggregateItemProps) {
    const { avatar, title, subTitle, tags, reservationNum, titleLabel, h5Url, preFormData } =
        props.data;
    const { onSubmitted, pageStyle, aggregateId, feedData, index } = props;
    useEffect(() => {
        async function trackImpress() {
            try {
                await invoke('advertise.reportAdLog', {
                    reportType: 1,
                    feedData: JSON.stringify(feedData),
                    adPlacePosition: index,
                    llsid: feedData?.ad.llsid,
                    lpAggregatePageId: aggregateId,
                    enterAction: 7,
                } as any);
            } catch (e) {
                console.error(e);
            }
        }
        trackImpress();
    }, []);
    const goToPage = async () => {
        invoke('advertise.reportAdLog', {
            reportType: 2,
            feedData: JSON.stringify(feedData),
            clickType: 30,
            adPlacePosition: index + '',
            llsid: feedData?.ad.llsid,
            lpAggregatePageId: aggregateId,
        } as any);
        openPage(h5Url);
    };
    const submit = async () => {
        const pageId = matchPageId(h5Url);
        const pageJSON = await getPageJSON(pageId!);
        const res = await submitForm({
            pageJSON,
            value: {
                name: preFormData.name,
                phone: preFormData.phone,
                h5Url,
                aggregateId,
            },
        });
        console.log('[submitt res]', res);
        if (res) {
            onSubmitted(true);
        }
        // console.log()
    };
    return (
        <div className={styles.popupItem}>
            <div
                className={styles.left}
                onClick={() => {
                    goToPage();
                }}
            >
                <div
                    className={styles.avatar}
                    style={{ background: `url(${avatar}) no-repeat 0 0/cover` }}
                />
                <div className={styles.detail}>
                    <div className={styles.detailTop}>
                        {titleLabel ? <div className={styles.titleLabel}>{titleLabel}</div> : null}
                        <div className={styles.title}>{title}</div>
                    </div>
                    <div
                        className={cs(
                            styles.subTitle,
                            tags?.length && pageStyle === PageStyle.POPUP
                                ? styles.singleLineText
                                : styles.multiLine,
                        )}
                    >
                        {subTitle}
                    </div>
                    {pageStyle === PageStyle.POPUP && (
                        <div className={styles.tags}>
                            {tags?.map((tag) => (
                                <div className={cs(styles.tag, styles.singleLineText)} key={tag}>
                                    {tag}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.reservation}>
                <div className={styles.reservationNum}>
                    {+reservationNum >= 100 ? `${reservationNum}人预约` : ''}
                </div>
                <button
                    className={cs(styles.reservationButton, props.submitted ? styles.reserved : '')}
                    onClick={() => {
                        !props.submitted && submit();
                    }}
                >
                    {props.submitted ? '已预约' : '预约'}
                </button>
            </div>
        </div>
    );
}
