import { isStaging, isTest } from 'omega-shared/utils';

export function getPayUrl() {
    if (isStaging) {
        return 'https://local-life.staging.kuaishou.com/transaction/coupon/index.html';
    }
    if (isTest) {
        return 'https://local-life.test.gifshow.com/transaction/coupon/index.html';
    }
    return 'https://l.kuaishou.com/transaction/coupon/index.html';
}
