/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import Modal from 'landingPage/common/components/Modal/Modal';
import { Toast } from '@ad/sharpui';
import { comWrapperSelector, useComContext } from 'omega-render';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { wechatPkgSelect, wechatItemSelect } from 'landingPage/common/api/index';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { getClipboard } from 'landingPage/common/bridge';
import { isInApp } from 'landingPage/common/utils/env';
import { Div } from '../Blank/Blank';
import Button, { ButtonProps } from '../Button/Button';
import { ReactComponent as WxIcon } from './icon-wechat-24px.svg';
import { CopyDialog } from './CopyDialog';
import { LongPressDialog } from './LongPressDialog';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';

export enum DIALOG_STYLE {
    DEFAULT = 0,
    LONG_PRESS = 1,
}
export interface WeiXinProps extends Pick<ButtonProps, 'buttonStyle' | 'backgroundColor' | 'text'> {
    position?: 'bottom' | 'base' | 'rightBottom';
    info?: string;
    /** 右侧悬浮的样式 */
    rightBottomTextStyle: React.CSSProperties;
    /** 右侧按钮背景色 */
    rightBottomBackgroundColor: string;
    /** 右侧图片颜色 */
    rightBottomIconColor: string;
    /** 微信号码包id */
    funcId?: string;
    /** 微信号码包内容 */
    opt?: any;
    wechatType?: 1 | 2;
    /* 微信弹窗样式 */
    dialogStyleType: DIALOG_STYLE;
    /* 是否是页面离开前的挽留弹窗 */
    stayBeforeClose?: boolean;
}

const WeiXin: React.FunctionComponent<WeiXinProps> = (props) => {
    const {
        position,
        text,
        buttonStyle,
        backgroundColor,
        info,
        children,
        rightBottomIconColor,
        rightBottomTextStyle,
        opt,
        wechatType,
        dialogStyleType = DIALOG_STYLE.DEFAULT,
    } = props;
    const [state, setState] = React.useState({ show: false });
    // const dialogInfo = useRandomItem(props);

    const [dialogInfo, setDialogInfo] = React.useState(useRandomItem(props));

    console.log('dialogInfo', dialogInfo);
    const { id, edit } = useComContext();
    React.useEffect(() => {
        if (!isInApp) {
            const node = document.querySelector(comWrapperSelector(id));
            if (!node) {
                return;
            }
            const clip = getClipboard();
            // eslint-disable-next-line new-cap
            const clipboard = clip && new clip(node);
            return () => {
                clipboard?.destroy();
            };
        }
    }, [id]);

    const onClick = React.useCallback(() => {
        if (!opt) {
            return;
        }
        const fetchRadomNum = async () => {
            try {
                const res = await wechatPkgSelect(`${opt?.id}`);
                if (res.wechatId) {
                    const result = await wechatItemSelect(res.id);
                    if (result) {
                        setDialogInfo({
                            wechatId: res.wechatId,
                            packId: `${opt?.id}`,
                            itemId: res.id,
                            appId: res.wechatAppId,
                        });
                        setState({ show: true });
                    }
                }
            } catch (e) {
                console.log('err', e);
                Toast.error('暂无可复制的微信号');
                // return '暂无可复制的微信号';
                // return {};
            }
        };

        const canSingleIdFetch = async () => {
            if (dialogInfo.itemId) {
                try {
                    const result = await wechatItemSelect(dialogInfo.itemId);
                    if (result) {
                        setState({ show: true });
                    }
                } catch (e) {
                    console.log('err', e);
                    Toast.error('暂无可复制的微信号');
                }
            }
        };
        if (wechatType === 2) {
            fetchRadomNum();
        } else {
            canSingleIdFetch();
            // setState({ show: true });
        }
        dataTrack({
            eventType: 'EVENT_CLICK_WECHAT',
            diyExtraInfo: JSON.stringify({
                renderType: 'WEI_XIN',
            }),
        });
    }, [dialogInfo.itemId, opt, wechatType]);
    let content: any = null;
    if (position === 'rightBottom') {
        content = (
            <Div data-clipboard-text={dialogInfo.wechatId} className={styles.rightBottom}>
                <div
                    className={styles.iconWrapper}
                    style={{ backgroundColor: rightBottomIconColor }}
                >
                    <WxIcon className={styles.rightIcon} onClick={onClick} />
                </div>
                <p style={rightBottomTextStyle}>
                    {text.length > 4 ? `${text.slice(0, 4)}...` : text}
                </p>
            </Div>
        );
    } else {
        content = (
            <Button
                data-clipboard-text={dialogInfo?.wechatId}
                bottom={position === 'bottom'}
                icon={<WxIcon className={styles.buttonIcon} />}
                text={text}
                buttonStyle={buttonStyle}
                onClick={onClick}
                backgroundColor={backgroundColor}
            >
                {children}
                {position === 'bottom' && (
                    <>
                        <BlockPlaceholder pos="BOTTOM" preview={edit} />
                        <SafeAreaPlace />
                    </>
                )}
            </Button>
        );
    }
    return (
        <>
            {content}
            {state.show && dialogInfo.wechatId && (
                <Modal>
                    {dialogStyleType === DIALOG_STYLE.DEFAULT ? (
                        <CopyDialog
                            info={info}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...dialogInfo}
                            onClose={() => {
                                setState({ show: false });
                            }}
                        />
                    ) : (
                        <LongPressDialog
                            info={info}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...dialogInfo}
                            onClose={() => {
                                setState({ show: false });
                            }}
                        />
                    )}
                </Modal>
            )}
            {/* {state.show && !dialogInfo.wechatId && Toast.error('暂无可复制的微信号')} */}
        </>
    );
};

const useRandomItem = (props: WeiXinProps) => {
    const { opt, wechatType } = props;

    return React.useMemo(() => {
        // let result = {} as { wechatId: string,packId: string,itemId: string }

        if (wechatType !== 2) {
            const itemViews = opt?.itemViews;
            if (!itemViews) {
                if (opt?.wechatId) {
                    return {
                        wechatId: opt.wechatId,
                        packId: '0',
                        itemId: opt.id,
                        appId: opt.appId,
                    };
                }
                return {};
            }
            const randomIndex = Math.floor(Math.random() * itemViews.length);
            const conf = itemViews[randomIndex];
            return {
                wechatId: conf.wechatId,
                packId: '0',
                itemId: conf.id,
                appId: conf.appId,
            };
        }
        return {};
    }, [opt, wechatType]);
};
export default WeiXin;
