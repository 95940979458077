import * as React from 'react';
import cs from 'classnames';
import {
    CustomerServiceProps,
    openServiceUrl,
} from 'landingPage/components/CustomerServiceDrag/CustomerService';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { useComContext } from 'omega-render';
import { Ol } from '../Blank/Blank';
import { ReactComponent as CustomerServiceIcon } from '../MultiConsultDrag/img/onlineConsult.svg';
import { ReactComponent as FormIcon } from '../MultiConsultDrag/img/formSubmit.svg';
import { ReactComponent as TelIcon } from '../MultiConsultDrag/img/call.svg';
import { formClass } from '../XiansuoFormDrag/XiansuoForm';
import { TelProps, callTel } from '../Tel/Tel';
import { IM_TYPE } from '../CustomerServiceDrag/CustomerService';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';

export interface MultiConsultProps {
    tabs: { type: ConsultType; name?: string }[];
    /** 客服相关配置 */
    customerServiceOpt?: Pick<
        CustomerServiceProps,
        'appId' | 'funcId' | 'url' | 'uid' | 'deepLink' | 'csType'
    >;
    /** 电话号码 */
    telOpt?: Pick<TelProps, 'tel' | 'telType' | 'iccId'>;
    formOpt?: {
        /** 最开始用的表单id，但是后来一个页面可以多个表单了 */
        formId?: string;
        index?: number;
    };
    styleType?: 1 | 2;
    className?: string;
    /** 不需要空白占位 */
    noBottom?: boolean;
    backgroundColor?: string;
    // iconStyle?: {
    //     color?: string; //字体颜色
    //     fontSize?: string; //字体大小
    //     backgroundColor?: string; //字体颜色
    // };
    iconColor?: string;
    iconFontSize?: string;
    iconFontColor?: string;
}

export enum ConsultType {
    '在线咨询' = 1,
    '电话拨打',
    '表单提交',
}
const config = {
    [ConsultType.在线咨询]: {
        name: '在线咨询',
        Icon: CustomerServiceIcon,
    },
    [ConsultType.电话拨打]: {
        name: '电话拨打',
        Icon: TelIcon,
    },
    [ConsultType.表单提交]: {
        name: '表单提交',
        Icon: FormIcon,
    },
};
const MultiConsult: React.FunctionComponent<MultiConsultProps> = (props) => {
    const { noBottom, customerServiceOpt, telOpt, formOpt, className } = props;
    const { tabs } = props;
    const { styleType } = props;
    const [loading, setLoading] = React.useState(false);
    const { edit } = useComContext();

    const onClick = (type: ConsultType) => {
        if (type === ConsultType.在线咨询) {
            const url = customerServiceOpt?.url;
            const appId = customerServiceOpt?.appId;
            const funcId = customerServiceOpt?.funcId;
            const csType = appId === 10000 ? IM_TYPE.KUAI : IM_TYPE.OTHER;
            openServiceUrl({ comType: 'MULTI_CONSULT', url, funcId, csType });
        }
        if (type === ConsultType.电话拨打) {
            const tel = telOpt?.tel;
            const telType = telOpt?.telType;
            const iccId = telOpt?.iccId;

            callTel({ telType, iccId, loading, setLoading, comType: 'MULTI_CONSULT', tel });
        }
        if (type === ConsultType.表单提交) {
            const formId = formOpt?.formId;
            const index = formOpt?.index;
            if (index != null) {
                const node = document.querySelectorAll(`.${formClass}`)[index];
                node?.scrollIntoView();
                return;
            }
            if (!formId) {
                return;
            }
            const node = document.querySelector(`.${formClass}[data-form-id="${formId}"]`);
            node?.scrollIntoView();
            // const form =
        }
    };

    // const onClick = React.useCallback(
    //     (type: ConsultType) => {
    //         if (type === ConsultType.在线咨询) {
    //             const url = props.customerServiceOpt?.url;
    //             const appId = props.customerServiceOpt?.appId;
    //             openServiceUrl(url, appId);
    //         }
    //         if (type === ConsultType.电话拨打) {
    //             const tel = props.telOpt?.tel;
    //             const telType = props.telOpt?.telType;
    //             const iccId = props.telOpt?.iccId;

    //             callTel(telType, iccId, loading, setLoading, tel);
    //         }
    //         if (type === ConsultType.表单提交) {
    //             const formId = props.formOpt?.formId;
    //             const index = props.formOpt?.index;
    //             if (index != null) {
    //                 const node = document.querySelectorAll(`.${formClass}`)[index];
    //                 node?.scrollIntoView();
    //                 return;
    //             }
    //             if (!formId) {
    //                 return;
    //             }
    //             const node = document.querySelector(`.${formClass}[data-form-id="${formId}"]`);
    //             node?.scrollIntoView();
    //             // const form =
    //         }
    //     },
    //     [props.customerServiceOpt, props.formOpt, props.telOpt],
    // );
    return (
        <Ol className={cs(className, styles.root, { [styles.style2]: styleType === 2 })}>
            {tabs.map((t) => {
                const conf = config[t.type];
                return (
                    <Tab
                        key={t.type}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...conf}
                        name={t.name}
                        onClick={() => onClick(t.type)}
                        type={t.type}
                    />
                );
            })}
            {!noBottom && (
                <>
                    <BlockPlaceholder pos="BOTTOM" height={64} preview={edit} />
                    <SafeAreaPlace style={{ backgroundColor: '#ffffff' }} />
                </>
            )}
        </Ol>
    );
};

interface TabProps {
    name?: string;
    className?: string;
    style?: React.CSSProperties;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    type: ConsultType;
    onClick: () => void;
}
export const Tab: React.FunctionComponent<TabProps> = (props) => {
    const { className, style, name, Icon, type, onClick } = props;
    return (
        <>
            <li
                className={cs(styles.item, className)}
                data-type={type}
                style={style}
                onClick={onClick}
            >
                <Icon className={styles.icon} />
                <p>{name}</p>
            </li>
        </>
    );
};

export default MultiConsult;
