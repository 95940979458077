import { xianSuoCopy } from 'landingPage/common/api';
import { invoke } from '@yoda/bridge';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import trackerAd from 'landingPage/common/adTrack';
import { trackBridgeError, trackBridgeSuccess } from 'landingPage/common/adTrack/monitor';
import { PopupType, WechatActionSceneEnum } from '../WeiXinDrag/WeiXin';

export const copy = async (text: string, itemId?: string, packId?: string) => {
    try {
        await invoke('webview.setClipBoard', { text });
        trackBridgeSuccess('setClipBoard');
    } catch (e) {
        console.error('failed: ', JSON.stringify(e));
        trackBridgeError('setClipBoard', JSON.stringify(e));
    }
    callLog(itemId, packId);
};
const storageKey = 'moli_weixin_copy';

const callLog = (itemId?: string, packId?: string) => {
    const lastTime = window.localStorage.getItem(storageKey);
    if (lastTime && new Date().getTime() - Number(lastTime) < 5000) {
        return;
    }
    window.localStorage.setItem(storageKey, `${new Date().getTime()}`);
    packId &&
        itemId &&
        xianSuoCopy({
            deviceType: '0',
            dspCallback: getUrlParam('callback'),
            packId,
            packageId: packId,
            itemId,
            pubBizId: 1003,
            pubSourceId: getUrlParam('pageId'),
            sourceUrl: window.location.href,
        });
    const { dataTrack } = trackerAd;
    dataTrack({
        eventType: 'EVENT_COPY_WECHAT',
        wechatItemId: itemId,
        wechatActionScene: WechatActionSceneEnum.COPY_WECHAT_ID,
        diyExtraInfo: JSON.stringify({
            renderType: 'WEI_XIN',
            popupType: PopupType.COMPONENT,
        }),
    });
    // dataTrack({ eventType: 'EVENT_ADD_WECHAT' });
    // dataTrack({ eventType: 'EVENT_FORM_SUBMIT' });
};
