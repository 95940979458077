import {
    getPrivateLetterInfo,
    privateLetterPreviewUsingPOST,
    privateLetterPreviewUsingPOSTForC,
} from 'landingPage/services/apis';
import { IPrivateLetterPreviewView, IResponsePrivateLetter } from 'landingPage/services/models';
import { useCallback, useEffect, useState } from 'react';
import styles from './style.module.less';
import { ReactComponent as ExplainIcon } from '../icons/explain-icon.svg';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import { isLandingPage } from '@/landingPage/common/utils/env';

interface IProps {
    uid: number;
    positionMode?: 'fixed' | 'absolute';
    onUserDetailChange?: (detail: IResponsePrivateLetter['data']) => void;
    onLoadingStart?: () => void;
    onLoadingEnd?: () => void;
}
/**
 * 私信页预览组件，在咨询页编排和预览页中使用
 */
export function PrivatePreview(props: IProps) {
    const { positionMode = 'fixed' } = props;
    const [previewDetail, setPreviewDetail] = useState<IPrivateLetterPreviewView>();
    const [accountDetail, setAccountDetail] = useState<IResponsePrivateLetter['data']>();
    const [showFallback, setShowFallback] = useState(false);
    const getPreviewDetail = useCallback(async () => {
        // 根据pathname判断现在是b/c端服务，并调用不同的接口路由去获取私信链接
        const res = !isLandingPage
            ? await privateLetterPreviewUsingPOST({
                  req: { userId: props.uid },
              })
            : await privateLetterPreviewUsingPOSTForC({
                  req: { userId: props.uid },
              });
        setPreviewDetail(res.data);
        // 没有设置引导语和卡片标题时，显示默认的对话框
        if (!res.data.guideWords && !res.data.cardTitle) {
            setShowFallback(true);
        }
    }, [props.uid]);
    const getAccountDetail = useCallback(async () => {
        const res = await getPrivateLetterInfo({ userId: props.uid });
        setAccountDetail(res?.data);
        if (res?.data) {
            props.onUserDetailChange?.(res?.data);
        }
    }, [props.uid]);
    useEffect(() => {
        props.onLoadingStart?.();
        if (!props.uid) return;
        Promise.all([getPreviewDetail(), getAccountDetail()])
            .then(() => {
                if (positionMode === 'fixed') {
                    document.title = accountDetail?.nick || '';
                }
            })
            .finally(() => {
                props.onLoadingEnd?.();
            });
    }, [getPreviewDetail, getAccountDetail]);
    return (
        <div className="preview">
            <div className={styles.wrapper}>
                {previewDetail?.guideWords && (
                    <div className={styles.row}>
                        <img src={accountDetail?.avatar} alt="" className={styles.avatar} />
                        <div className={styles.message}>{previewDetail?.guideWords}</div>
                    </div>
                )}
                {previewDetail?.cardTitle && (
                    <div className={styles.row}>
                        <img src={accountDetail?.avatar} alt="" className={styles.avatar} />
                        <div className={styles.message} style={{ width: '248.188px' }}>
                            <div className={styles.cardTitle}>{previewDetail?.cardTitle}</div>
                            <div className={styles.explain}>
                                <div className={styles.explainItem}>
                                    <ExplainIcon
                                        style={{
                                            marginRight: '2.62px',
                                        }}
                                    />
                                    {previewDetail.rightsExplain}
                                </div>
                            </div>
                            <div className={styles.phone}>
                                <div className={styles.phoneLabel}>手机号</div>
                                <div>150****1234</div>
                            </div>
                            <div className={styles.button}>{previewDetail?.buttonWords}</div>
                            <div className={styles.agreement}>
                                预约标识已阅读并同意
                                <span className={styles.link}>个人信息授权与保护声明</span>
                            </div>
                        </div>
                    </div>
                )}
                {showFallback && (
                    <div className={styles.row}>
                        <img src={accountDetail?.avatar} alt="" className={styles.avatar} />
                        <div className={styles.message}>您好~请问有什么可以帮您？</div>
                    </div>
                )}
            </div>

            <div className={positionMode === 'fixed' ? styles.bottomFixed : styles.bottomAbsolute}>
                <div className={styles.bubbleWrapper}>
                    {previewDetail?.inputBox.map((item) => {
                        return (
                            <div className={styles.bubbleItem} key={item.title}>
                                {item.title}
                            </div>
                        );
                    })}
                </div>

                <img
                    src={transformCDN2Escape(
                        'https://h2.static.yximgs.com/kos/nlav10749/site/im-bottom.454e0a773dca50f0.png',
                    )}
                    className={styles.inputLine}
                />
            </div>
        </div>
    );
}

// export default PrivatePreview;
