(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("axios"), require("styled"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "axios", "styled"], factory);
	else if(typeof exports === 'object')
		exports["rolex"] = factory(require("React"), require("ReactDOM"), require("axios"), require("styled"));
	else
		root["rolex"] = factory(root["React"], root["ReactDOM"], root["axios"], root["styled"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__1024__, __WEBPACK_EXTERNAL_MODULE__30314__, __WEBPACK_EXTERNAL_MODULE__83300__, __WEBPACK_EXTERNAL_MODULE__39404__) {
return 