import * as React from 'react';
import { render } from 'react-dom';
import { ClickPosition, fetchGameInfo, openWechatGame } from '../utils';
import { fetchWechatGameInfoInPageJson } from 'landingPage/common/utils/fetchCompPropsInPageJson';
import { exitWebview } from 'landingPage/common/bridge';
import { ComponentZIndex } from 'omega-shared/utils';
import Modal from 'landingPage/common/components/Modal';
import styles from '../style.module.less';
import { GameInfo } from 'omega-render';
import { dataTrack } from 'landingPage/common/adTrack/util/track';

export const wechatGameStyleModal = async (gameInfo?: GameInfo) => {
    render(
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WechatStayModal gameInfo={gameInfo} />,
        window.document.body,
    );
};

export interface WechatGameDetail {
    gameId: string;
    originalId: string;
    path: string;
    brightOriginalId: string;
    iconUrl: string;
    name: string;
    description: string;
}

export interface defaultWechatGameDetail
    extends Pick<WechatGameDetail, 'iconUrl' | 'name' | 'description'> {}

interface WechatStayModalProps {
    gameInfo?: GameInfo;
}

export const WechatStayModal: React.FunctionComponent<WechatStayModalProps> = (
    props: WechatStayModalProps,
) => {
    const [gameInfo] = React.useState(props?.gameInfo || fetchWechatGameInfoInPageJson());

    React.useEffect(() => {
        if (!gameInfo?.wechatGameId) {
            exitWebview();
        }
    }, [gameInfo]);

    const [wechatGameDetail, setWechatGameDetail] = React.useState<WechatGameDetail>(
        {} as WechatGameDetail,
    );

    React.useEffect(() => {
        const fetch = async () => {
            if (gameInfo?.wechatGameId) {
                await fetchGameInfo({
                    gameInfo,
                    defaultWechatDetail: {
                        iconUrl:
                            'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-12-03/1701605122023.187c2fc2c32679ba.png',
                        name: '小游戏',
                        description:
                            gameInfo?.description || '新玩法新挑战，12w+玩家已入局，等你来战！',
                    },
                    updateWechatDetail: setWechatGameDetail,
                    failOperate: exitWebview,
                });
            } else {
                exitWebview();
            }
        };
        fetch();
    }, []);

    React.useEffect(() => {
        if (wechatGameDetail?.originalId) {
            dataTrack({
                eventType: 'EVENT_POPUP_WINDOW_IMPRESSION',
                lp_popup_window_type: 4,
            });
        }
    }, [wechatGameDetail]);

    return (
        <Modal
            style={{ zIndex: ComponentZIndex.WechatZIndex }}
            parent={document.getElementById('preview-app') ?? document.body}
        >
            <div className={styles.dialog}>
                <img src={wechatGameDetail.iconUrl} className={styles.wechatIcon} alt="" />
                <div className={styles.wechatName}>{wechatGameDetail.name}</div>
                <div className={styles.wechatDesc}>{wechatGameDetail.description}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        className={styles.exit}
                        style={{ marginRight: 16 }}
                        onClick={() => {
                            dataTrack({
                                eventType: 'EVENT_ELEMENT_CLICK',
                                lp_element_type: 1,
                            });
                            exitWebview();
                        }}
                    >
                        退出
                    </div>
                    <div
                        className={styles.exit}
                        style={{ border: 'none', background: '#FE3666', color: '#fff' }}
                        onClick={() => {
                            openWechatGame({
                                gameInfo: gameInfo as GameInfo,
                                renderClickArea: ClickPosition.stayModal,
                            });
                        }}
                    >
                        微信试玩
                    </div>
                </div>
            </div>
        </Modal>
    );
};
