/* eslint-disable */
import { useCallback, useState } from 'react';
import { geminiStoreListUsingPost } from 'landingPage/common/api';
import { RecoType, IStoreView } from 'landingPage/common/api/interface';

const useStoreList = () => {
    /* 推荐门店 */
    const [recoStoreList, setRecoStoreList] = useState<IStoreView[]>([]);
    /* 其他门店 */
    const [otherStoreList, setOtherStoreList] = useState<IStoreView[]>([]);
    const [totalNum, setTotalNum] = useState(0);
    /* 获得门店列表 */
    const getStoreList = useCallback(
        async (opt: {
            type?: RecoType;
            coords?: {
                latitude: string;
                longitude: string;
            };
            city: string;
            pageNum?: number;
            packageId: string;
        }) => {
            // if (!opt.packageId) {
            //     // 添加埋点，上报没有packageId的门店组件落地页信息
            //     try {
            //         getSentry()?.captureEvent({
            //             timestamp: +new Date() / 1000,
            //             message: `Error: get store list, params have no packageId`,
            //             extra: {
            //                 url: window.location.href,
            //                 cookie: document.cookie,
            //             },
            //         });
            //     } catch (e) {
            //         console.error(e);
            //     }
            // }
            const storeList = await geminiStoreListUsingPost({
                city: opt.city,
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                lat: opt?.coords?.latitude!,
                lon: opt?.coords?.longitude!,
                packageId: opt.packageId,
                type: opt?.type,
                pagingInfo: {
                    pageNum: opt?.pageNum || 1,
                    pageSize: 10,
                    totalNum: 10,
                },
            });
            if (Number(opt?.pageNum) > 1) {
                setRecoStoreList((val) => {
                    return storeList?.list ? [...val, ...storeList.list] : val;
                });
                setOtherStoreList((val) => {
                    return storeList?.backup ? [...val, ...storeList.backup] : val;
                });
            } else {
                setRecoStoreList(storeList?.list || []);
                setOtherStoreList(storeList?.backup || []);
            }
            setTotalNum(storeList.pagingInfo.totalNum);
            return storeList;
        },
        [],
    );
    type IGetStoreList = typeof getStoreList;
    return [recoStoreList, otherStoreList, getStoreList, totalNum] as [
        IStoreView[],
        IStoreView[],
        IGetStoreList,
        number,
    ];
};

export { useStoreList };
