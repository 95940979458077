import { ListItem } from '../DragInteractionRecord';
import { getRandomAvatar, getRandomInfo } from './getRandom';

let uid = 0;
export function generateItem(list: ListItem[], text: string) {
    const newItem: ListItem = {} as ListItem;
    let avatar = getRandomAvatar();
    const isrRepeat = (item: ListItem) => item.avatar === avatar;
    while (list.some(isrRepeat)) {
        avatar = getRandomAvatar();
    }
    newItem.avatar = avatar;
    newItem.desc = getRandomInfo(text);
    newItem.id = uid++;
    return newItem;
}
