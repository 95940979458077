import { AdFrontTrace, SUBTAG, IPerfLog, ITraceLog } from '@ad/ad-front-trace';
import { isProduction } from './env';

let initialized = false;
let logger: AdFrontTrace;
function initBaseLog() {
    logger = new AdFrontTrace({
        env: isProduction ? 'production' : 'development',
        bizName: isProduction ? 'frontend.ad.moli' : 'frontend.ad.moli.dev',
    });
    initialized = true;
}

export function sendPerfLogLog(params: IPerfLog) {
    if (!initialized) {
        initBaseLog();
    }
    if (isProduction) {
        logger.perf({ millis: -1, ...params });
    }
}

export function sendTraceLog(params: ITraceLog) {
    if (!initialized) {
        initBaseLog();
    }
    logger.trace(params);
}

/** API调用 */
export function trackApi(api: string) {
    sendPerfLogLog({
        subtag: 'API_COUNT',
        extra1: 'MOLI_EDITOR_API',
        extra2: api,
    });
}

/** API错误 */
export function trackApiError(api: string, message?: string) {
    sendPerfLogLog({
        subtag: SUBTAG.API_ERROR,
        extra1: 'MOLI_EDITOR_API_ERROR',
        extra2: api,
        extra3: message,
    });
}

/** 各页面流量 */
export function trackPageImpression(path: string) {
    sendPerfLogLog({
        subtag: SUBTAG.PV_STATISTIC,
        extra1: 'MOLI_EDITOR_PAGE_FLOW',
        extra2: path,
    });
}

/** 重点功能监控 */
export function trackKeyFeaturesUsage(method: string) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_KEY_FEATURE',
        extra2: method,
    });
}

/** 监控使用图片裁切功能情况 */
export function trackUseImgCropper() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_IMG_CROPPER',
    });
}

/** 监控使用批量生成落地页情况 */
export function trackUseNovelCreate(step: string) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_NOVEL_CREATE',
        extra2: step,
    });
}

/** 监控异常保存情况 */
export function trackSaveError(auditStatus: number, publishStatus: number) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_SAVE_ERROR',
        extra2: String(auditStatus),
        extra3: String(publishStatus),
    });
}

/** 监控组件编辑时长 (有保存或发布行为的用户的所有编辑动作的汇总) */
export function trackEditDurationByComponent(componentType: string, duration: number) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_DURATION_NEW',
        extra2: componentType,
        millis: duration,
    });
}

/** 监控新建页面编辑时长 */
export function trackPageNewDuration(duration: number, source: 'blank' | 'template', sourceType) {
    console.log({ duration });
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_PAGE_DURATION_NEW',
        extra2: source,
        extra3: sourceType + '',
        millis: duration,
    });
}
/** 监控新建但是不保存用户数（这个需要用trace） */
export function trackNewButNotSaveUser() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_PAGE_NOT_SAVE_NEW',
        millis: 1,
        // extra1: userId,
    });
}

/** 记录组件编辑类型、时间 (这个不应该统计到perf) */
export function trackComponentEditBehavior(componentType: string, optionType: string) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_COMPONENT_EDIT_BEHABIOR_NEW',
        extra2: componentType,
        extra3: optionType,
    });
}

type UpgradeSource = 'editor' | 'give';

/** 组件升级成功 */
export function trackUpgradeSuccess(source: UpgradeSource) {
    sendPerfLogLog({
        subtag: 'MOLI_EDITOR_UPGRADE',
        extra1: 'success',
        extra2: source,
        extra3: 'new',
    });
}

/** 组件升级失败 */
export function trackUpgradeError(source: UpgradeSource) {
    sendPerfLogLog({
        subtag: 'MOLI_EDITOR_UPGRADE',
        extra1: 'error',
        extra2: source,
        extra3: 'new',
    });
}

/** 组件升级失败 */
export function trackUpgradeErrorInfo(
    pageId: string,
    accountId: string,
    source: UpgradeSource,
    e: string,
) {
    sendTraceLog({
        message: JSON.stringify({
            pageId,
            accountId,
            source,
            error: e,
            extra3: 'new',
        }),
    });
}

/** 保存或者发布按钮点击 */
export function trackPageSubmitButtonClick(source: 'save' | 'publish') {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_SUBMIT_BUTTON_CLICK',
        extra2: source,
        // extra1: userId,
    });
}

/** 落地页保存或者发布成功 */
export function trackPageSubmitSuccess(source: 'save' | 'publish') {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_SUBMIT_SUCCESS',
        extra2: source,
    });
}

/** 提交前端校验失败 */
export function trackPageSubmitFrontErrorDetail(accountId: number, type: string) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_SUBMIT_FRONT_ERROR',
        extra2: type,
        // extra1: userId,
    });
    sendTraceLog({
        message: JSON.stringify({
            tag: 'MOLI_EDITOR_SUBMIT_FRONT_ERROR',
            accountId,
            type,
        }),
    });
}

/** 提交服务端校验失败 */
export function trackPageSubmitServerErrorDetail(
    source: 'save' | 'publish',
    accountId: number,
    error: string,
) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_SUBMIT_SERVER_ERROR',
        extra2: source,
        extra3: error,
        // extra1: userId,
    });
    sendTraceLog({
        message: JSON.stringify({
            tag: 'MOLI_EDITOR_SUBMIT_SERVER_ERROR',
            source,
            accountId,
            error,
        }),
    });
}

/** 记录保存或者发布的原始JSON信息 */
export function trackPageSubmitRawJson(params: {
    source: 'save' | 'publish';
    pageJson: any;
    accountId: number;
}) {
    sendTraceLog({
        message: JSON.stringify({
            type: 'MOLI_EDITOR_SUBMIT_RAW_JSON',
            url: window.location.href,
            ...params,
        }),
    });
}

/** 记录保存或者发布的最终提交JSON信息 */
export function trackPageSubmitFinalJson({
    ...params
}: {
    source: 'save' | 'publish';
    pageJson: any;
    accountId: number;
}) {
    sendTraceLog({
        message: JSON.stringify({
            type: 'MOLI_EDITOR_SUBMIT_FINAL_JSON',
            url: window.location.href,
            ...params,
        }),
    });
}

/** 模块收藏使用情况 */
export function trackModuleStar(step: string) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_MODULE_STAR',
        extra2: step,
    });
}

/** 页面复制频次 */
export function trackPageCopy() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_PAGE_COPY_NEW',
    });
}

/** 新手引导使用次数 */
export function trackBeginnerGuide() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_BEGINNER_GUIDE',
    });
}

/** 快捷键帮助展示情况 */
export function trackHotKeyHelp() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_HOT_KEY_HELP',
    });
}

/** 快捷键使用情况 */
export function trackHotKeyUse(key: string) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_HOT_KEY_HELP',
        extra2: key,
    });
}
export enum createType {
    blank = 'blank', // 空白页创建
    template = 'template', // 模板创建
    batchNovel = 'batchNovel', // 批量小说创建
    halfPage = 'halfPage', // 半屏创建
    url = 'url', // url搬迁创建
    keyword = 'keyword', // 关键词创建
    keywordUse = 'keywordUse', //关键词创建使用数
    aiCreatePage = 'aiCreatePage', //ai智能建站
}
/** 创建页面方式 */
export function trackCreatePageUsingTemplate(key: createType) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_CREATE_PAGE_USE_TEMPLATE_NEW',
        extra2: key,
    });
}
/** 关键词创建筛选出的模板使用情况 */
export function trackKeywordCreatePageUsing(key: createType) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_USE_KEYWORD_TEMPLATE',
        extra2: key,
    });
}

/** 使用空白模板和既定模板创建落地页的情况 */
export function trackGrabThirdPage() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_GRAB_THIRD_PAGE',
    });
}

/** 组件新样式使用情况 */
export function trackComponentNewStyleUsing(component: string, style: string) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_COMPONENT_NEW_STYLE_USING',
        extra2: component,
        extra3: style,
    });
}

/** 新建页面且未发布的数量 */
export function trackCreatePageNotPublish() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_CREATE_PAGE_NOT_PUBLISH',
    });
}

/** 新建页面且发布的数量（和上面的埋点一起算出存为草稿的占比） */
export function trackCreatePageWithPublish() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_CREATE_PAGE_WITH_PUBLISH',
    });
}

/** 保存页面时使用了表单锚点 */
export function trackCreatePageUsingAnchorForm() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_CREATE_PAGE_USING_ANCHOR_FORM',
    });
}

/** 保存页面时未使用表单锚点 */
export function trackCreatePageNotUseAnchorForm() {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_CREATE_PAGE_NOT_USE_ANCHOR_FORM',
    });
}

export enum FORM_COLOR_TYPE {
    MAIN_TITLE_COLOR = 'mainTitleColor',
    MAIN_BACKGROUND_COLOR = 'mainBackgroundColor',
    BTN_COLOR = 'btnColor',
    BTN_BACKGROUND_COLOR = 'btnBackgroundColor',
    BORDER_COLOR = 'borderColor',
    BACKGOUND_COLOR = 'backgroundColor',
    PLACEHOLDER_COLOR = 'placeholderColor',
    TITLE_COLOR = 'titleColor',
    INPUT_COLOR = 'inputColor',
    OPTION_COLOR = 'optionColor',
    ACTIVE_COLOR = 'activeColor',
    ACTIVE_BORDER_COLOR = 'activeBorderColor',
    ACTIVE_BACKGOUND_COLOR = 'activeBackgroundColor',
}
export function trackFormColorEdit(type: string) {
    sendPerfLogLog({
        subtag: SUBTAG.CUSTOM,
        extra1: 'MOLI_EDITOR_FORM_COLOR_EDIT',
        extra2: type,
    });
}
