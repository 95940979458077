import { useComContext } from 'omega-render';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cs from 'classnames';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import styles from '../../style.module.less';
import Button from '../../../ButtonDrag/Button';
import ShufflingImage from '../../../ShufflingImage/ShufflingImage';
import style from './style.module.less';
import { DownloadTypeProps } from '../PicDownload';
import { getPicCDNSource } from '@/landingPage/common/utils/getUrlParam';
import { Div } from '@/landingPage/components/Blank/Blank';

const PageFloat: React.FunctionComponent<DownloadTypeProps> = (props) => {
    const {
        picFloatInfo,
        picFloatStyle,
        buttonStyle,
        appInfo,
        onClick,
        bgImgs,
        dynamicStatus,
        appType,
        buttonText,
        children,
        status,
    } = props;
    const { edit } = useComContext();
    const [ratio, setRatio] = useState(1 / 2);
    const curImgs = useCallback(() => {
        if (bgImgs && bgImgs?.length > 0) return bgImgs;
        return [transformCDN2Escape('https://p2-yx.adkwai.com/kos/nlav10749/site/fullDefault.png')];
    }, [bgImgs]);

    useEffect(() => {
        if (!bgImgs || bgImgs.length < 0 || !bgImgs[0]) return;
        const img = new Image();
        // eslint-disable-next-line prefer-destructuring
        img.src = bgImgs[0]; // 以第一张图片的比例为准
        img.onload = () => {
            const ratio = img.width / img.height;
            setRatio(ratio);
        };
    }, [bgImgs]);
    const packageSize = useMemo(() => {
        if (!appType) return appInfo?.packageSize;
        if (appType === 'legacy') return appInfo?.packageSize;
        return ((appInfo?.packageSize ?? 0) / 1024 / 1024).toFixed(1);
    }, [appType, appInfo?.packageSize]);
    return (
        <Div
            className={style.wrap}
            style={{
                height: edit ? '603px' : '100vh',
            }}
        >
            <ShufflingImage
                randomHeight
                imgs={
                    curImgs()?.map((img) => {
                        return {
                            imgUrl: img,
                        } as any;
                    }) ?? ['']
                }
                // imgs={[
                //     {imgUrl:'https://s2-10889.kwimgs.com/kos/nlav10889/tamllVideo/logo.png'},
                //     {imgUrl:'https://s2-10889.kwimgs.com/kos/nlav10889/tamllVideo/headImg-ios.png'},
                //     {imgUrl:'https://s2-10889.kwimgs.com/kos/nlav10889/tamllVideo/guide-1-ios.png'},
                // ]}
                renderType={1}
                ratio={ratio}
                playInterval={4}
                autoplay
            />
            <div className={style.contentWrapper}>
                <div className={style.infoWrapper}>
                    <div
                        className={style.iconWrapper}
                        style={{
                            backgroundImage: `url(${
                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                getPicCDNSource(appInfo?.appIconUrl) ??
                                transformCDN2Escape(
                                    'https://s2-10889.kwimgs.com/kos/nlav10889/site/image6.png',
                                )
                            })`,
                            backgroundSize: 'contain',
                        }}
                    />
                    <div className={style.detailWrapper}>
                        <div
                            className={style.titleWrapper}
                            style={{
                                color: picFloatStyle?.appNameColor ?? '#222222',
                            }}
                        >
                            {appInfo?.appName ?? '应用名称'}
                        </div>
                        <div
                            className={style.versionWrapper}
                            style={{
                                color: picFloatStyle?.appDesColor ?? '#9c9c9c',
                            }}
                        >
                            版本:
                            {appInfo?.appVersion ?? '-'}
                            {appInfo?.platform === 'android'
                                ? // eslint-disable-next-line no-useless-concat
                                  `   大小:${packageSize ?? 0}MB`
                                : ''}
                        </div>
                    </div>
                </div>
                <div
                    className={style.descWrapper}
                    style={{
                        color: picFloatInfo?.color ?? '#666666',
                    }}
                >
                    {picFloatInfo?.appDescription ?? '应用描述'}
                </div>
                <div className={style.buttonWrapper}>
                    <Button
                        className={cs({
                            [styles.progress]: status === 2,
                            [styles.dynamic]: !!dynamicStatus,
                        })}
                        text={buttonText ?? ''}
                        buttonStyle={buttonStyle}
                        onClick={onClick}
                    >
                        {children}
                    </Button>
                </div>
            </div>
            <div className={style.floatWrapper} />
        </Div>
    );
};
export default PageFloat;
