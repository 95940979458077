import * as React from 'react';
import styles from './style.module.less';

interface SafeAreaPlaceProps {
    style?: React.CSSProperties;
}

const SafeAreaPlace: React.FunctionComponent<SafeAreaPlaceProps> = (props: SafeAreaPlaceProps) => {
    const { style } = props;
    return <div className={styles.safeAreaHeight} style={style} />;
};

export default SafeAreaPlace;
