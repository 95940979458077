import * as React from 'react';
import styles from './style.module.less';
import { useRenderState } from 'omega-render';
import { plcContainerState } from 'landingPage/components/Blank/utils/registerPlcContainerState';

interface ScreenTopBarProps {}

const ScreenTopBar: React.FunctionComponent<ScreenTopBarProps> = () => {
    const { plcContainerState } = useRenderState((state) => {
        return { plcContainerState: state.plcContainer as plcContainerState };
    });
    return (
        <>
            {plcContainerState?.showTopBar && (
                <div className={styles.topBar}>
                    <img
                        className={styles.img}
                        src="https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-12-21/1703140721056.936125ec7a74a332.png"
                    />
                </div>
            )}
        </>
    );
};

export default ScreenTopBar;
