/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-floating-promises */
import * as React from 'react';
import { Toast } from '@ad/sharpui';
import { debounce } from 'lodash';
import { invoke } from '@yoda/bridge';
import { callApp } from 'landingPage/common/utils/callApp';
import { getDeviceType, DEVICE_TYPE } from 'landingPage/common/utils/getDeviceInfo';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { isInApp, isStaging } from 'landingPage/common/utils/env';
import { getAdBridge } from 'landingPage/common/bridge';
import { xianSuoCopy } from 'landingPage/common/api';
import { trackBridgeError, trackBridgeSuccess } from 'landingPage/common/adTrack/monitor';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import { Type1 } from '../../ShufflingImageDrag/Type1';
import { PopupType, WechatActionSceneEnum, WechatActionStatusEnum } from '../WeiXin';
import styles from '../style.module.less';
import { wechatDataTrack } from './commonFun';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { isIOS } from 'omega-shared/utils';
import { sendFormSubmitAndEffectiveCustomerReport } from '../util';

// 多转化事件 优化目标
const multiConversion = getUrlParam('multiConversion') === 'true';

export interface QrCodeDialogProps {
    info?: string;
    wechatId?: string;
    appId?: string;
    onClose?: () => void;
    itemId?: string;
    packId?: string;
    qrcode?: string;
    wechatStay?: boolean;
    needSendEffectiveAction?: boolean;
    renderType?: string;
}

const onlineImg = [
    {
        imgUrl: transformCDN2Escape(
            'https://ali2.a.kwimgs.com/udata/pkg/b2e799f549af47779e852a2cef580db6.png',
        ),
    },
    {
        imgUrl: transformCDN2Escape(
            'https://ali2.a.kwimgs.com/udata/pkg/bace83e720ba4365a94bfec5e1dde82a.png',
        ),
    },
];

const stagingImg = [
    {
        imgUrl: 'http://blobstore-nginx.staging.kuaishou.com/udata/pkg/45bc36ac899547099dfaa167608bc4a5.png',
    },
    {
        imgUrl: 'http://blobstore-nginx.staging.kuaishou.com/udata/pkg/5be5826895394dbbaca019f80753a6cc.png',
    },
];

export const QrCodeDialog: React.FunctionComponent<QrCodeDialogProps> = (props) => {
    const {
        info,
        wechatId,
        onClose,
        itemId,
        packId,
        qrcode,
        appId,
        wechatStay,
        needSendEffectiveAction,
        renderType,
    } = props;

    React.useEffect(() => {
        wechatId &&
            dataTrack({
                eventType: 'EVENT_VIEW_WECHAT',
                wechatItemId: itemId,
                lp_page_type: 1,
                wechatActionScene: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                }),
            });
    }, [info, itemId, packId, wechatId]);

    return (
        <div className={styles.qrCodedialog}>
            <div className={styles.exit} data-target="wechat-close" onClick={onClose} />
            <div className={styles.qrtext}>{info}</div>
            <div className={styles.shuffling}>
                <Type1
                    ratio={16 / 9}
                    imgs={isStaging ? stagingImg : onlineImg}
                    autoplay
                    playInterval={1000}
                    width={232}
                    renderType={1}
                    progressClass={styles.progressClass}
                />
            </div>
            <div className={styles.qrImgPart}>
                <img src={qrcode} className={styles.qrcode} alt="" />
                <div className={styles.qrdesc}>
                    1.点击下方按钮保存二维码到相册，自动打开扫一扫 2.从相册选取二维码扫描
                </div>
            </div>
            <div
                className={styles.close}
                onClick={() => {
                    wakeupWechatScan({
                        imgUrl: qrcode,
                        itemId,
                        packId,
                        onClose,
                        wechatId,
                        appId,
                        wechatStay,
                        needSendEffectiveAction,
                        renderType,
                    });
                }}
            >
                保存并添加微信
            </div>
        </div>
    );
};

export const wakeupWechatScan = debounce(
    async (params: {
        imgUrl: string | undefined;
        itemId: string | undefined;
        wechatId: string | undefined;
        appId: string | undefined;
        packId?: string | undefined;
        onClose?: () => void;
        wechatStay?: boolean;
        needSendEffectiveAction?: boolean;
        renderType?: string;
    }) => {
        console.log('params', params);
        const {
            imgUrl,
            itemId,
            packId,
            onClose,
            wechatId,
            appId,
            wechatStay,
            needSendEffectiveAction,
            renderType,
        } = params;

        const deviceType = getDeviceType();

        qrCodeWakeup({
            imgUrl,
            packId,
            itemId,
            onClose,
            deviceType,
            wechatId,
            appId,
            wechatStay,
            needSendEffectiveAction,
            renderType,
        });
    },
    300,
    { trailing: false, leading: true },
);

const qrCodeWakeup = (props: {
    imgUrl?: string;
    itemId?: string;
    wechatId?: string;
    appId?: string;
    packId?: string;
    onClose?: () => void;
    deviceType: DEVICE_TYPE;
    wechatStay?: boolean;
    needSendEffectiveAction?: boolean;
    renderType?: string;
}) => {
    const {
        imgUrl,
        itemId,
        packId,
        onClose,
        deviceType,
        wechatId,
        appId,
        wechatStay,
        needSendEffectiveAction,
        renderType,
    } = props;
    const image = new Image();
    image.crossOrigin = '*'; // 支持跨域图片
    let base64 = '';
    const canvas = document.createElement('canvas');
    console.log('执行onload方法');
    if (image.complete) {
        console.log('----complete image');
    }
    image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(image, 0, 0, image.width, image.height);
        base64 = canvas.toDataURL('image/png');

        const imgData = base64.split(';base64,');
        // console.log('call post.save=====base64', base64);

        const saveImageSuccess = (res) => {
            trackBridgeSuccess('saveImage');
            console.log('saveImage result-----', res);
            let result = res;
            if (typeof res === 'string') {
                result = JSON.parse(res);
            }
            if (result?.data?.data?.result === 0) {
                Toast.error({ content: '图片保存失败，请稍后重试', duration: 1000 });
                onClose?.();
                return;
            }
            dataTrack({
                eventType: 'EVENT_COPY_WECHAT',
                wechatItemId: itemId,
                wechatActionScene: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                WechatActionStatus: WechatActionStatusEnum.WECHAT_SAVE_QR_CODE_SUCCESS,
                lp_page_type: 1,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                }),
            });
            onClose?.();
            Toast.info({
                content: '打开微信扫一扫，点右下角相册选图扫码',
                duration: 3000,
            });

            packId &&
                itemId &&
                xianSuoCopy({
                    deviceType: '0',
                    dspCallback: getUrlParam('callback'),
                    packId,
                    packageId: packId,
                    itemId,
                    pubBizId: 1003,
                    pubSourceId: getUrlParam('pageId'),
                    sourceUrl: window.location.href,
                });

            // 多转化事件优化目标，不打除表单转化的EVENT_FORM_SUBMIT点
            if (!multiConversion) {
                // dataTrack({
                //     eventType: 'EVENT_FORM_SUBMIT',
                //     wechatItemId: itemId,
                //     lp_page_type: 1,
                //     WechatActionStatus: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                //     eventProps: {
                //         actionName: 'EXTERNAL_ACTION904',
                //     },
                //     diyExtraInfo: JSON.stringify({
                //         renderType: renderType || 'WEI_XIN_DRAG',
                //         landing_page_infos: JSON.stringify({
                //             page_module_type: getTransCompList(),
                //         }),
                //     }),
                // });
                sendFormSubmitAndEffectiveCustomerReport({
                    wechatActionStatus: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                    wechatItemId: itemId,
                    enableSend: needSendEffectiveAction,
                    renderType: renderType,
                });
            }

            window.setTimeout(() => {
                if (deviceType === DEVICE_TYPE.IOS) {
                    callApp(
                        'weixin://scanqrcode',
                        'https://weixin.qq.com/cgi-bin/readtemplate?t=download/ver_voice',
                        () => {
                            wechatDataTrack({
                                itemId,
                                invokeSuccess: true,
                                sence: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                                wechatId,
                                appId,
                                popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                            });
                        },
                        () => {
                            wechatDataTrack({
                                itemId,
                                invokeSuccess: false,
                                sence: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                                wechatId,
                                appId,
                                popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                            });
                        },
                    );
                } else {
                    const openWechatScanSuccess = (res: any) => {
                        trackBridgeSuccess('openWechatQRScan');
                        console.log('success open wechat res-----', res);
                        wechatDataTrack({
                            itemId,
                            invokeSuccess: true,
                            sence: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                            wechatId,
                            appId,
                            popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                        });
                    };
                    const openWechatScenError = (e: any) => {
                        trackBridgeError('openWechatQRScan', e);
                        console.log('open wechat scan err --', e);
                        wechatDataTrack({
                            itemId,
                            invokeSuccess: false,
                            sence: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                            wechatId,
                            appId,
                            popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                        });
                        window.location.href =
                            'https://weixin.qq.com/cgi-bin/readtemplate?t=download/ver_voice';
                    };
                    if (!isInApp) {
                        bridge
                            ?.openWechatQRScan()
                            .then((res: any) => {
                                openWechatScanSuccess(res);
                            })
                            .catch((e: any) => {
                                openWechatScenError(e);
                            });
                    } else {
                        (getAdBridge() as any)
                            ?.openWechatQRScan()
                            .then((res: any) => {
                                openWechatScanSuccess(res);
                            })
                            .catch((e: any) => {
                                openWechatScenError(e);
                            });
                    }
                }
            }, 3000);
        };

        const saveImageError = (e) => {
            trackBridgeError('saveImage', e);
            console.log('------save image error', e);
            dataTrack({
                eventType: 'EVENT_COPY_WECHAT',
                wechatItemId: itemId,
                wechatActionScene: WechatActionSceneEnum.SCAN_WECHAT_QR_CODE,
                WechatActionStatus: WechatActionStatusEnum.WECHAT_SAVE_QR_CODE_FAILED,
                lp_page_type: 1,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                }),
            });
            Toast.error({ content: '图片保存失败，请稍后重试', duration: 1000 });
            onClose?.();
        };

        if (!isInApp) {
            bridge
                ?.saveImage({ base64String: isIOS ? imgData[1] : base64 })
                .then((res) => {
                    saveImageSuccess(res);
                })
                .catch((e) => {
                    saveImageError(e);
                });
        } else {
            invoke('post.saveImage', {
                base64String: imgData[1],
            })
                .then((res) => {
                    saveImageSuccess(res);
                })
                .catch((e) => {
                    saveImageError(e);
                });
        }
    };
    image.src = imgUrl ?? '';
};
