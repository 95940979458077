import { getUrlParam } from './getUrlParam';

// 获取动态商品库携带参数信息
export const getDynamicBrandData = () => {
    const recoProductIdsStr = getUrlParam('ksRecoProductIds');
    const ksIsDynamic = getUrlParam('ksIsDynamic');
    let recoProductIds = [];
    if (recoProductIdsStr) {
        recoProductIds = recoProductIdsStr.split(','); // 获取推荐商品列表
    }
    const libraryId = getUrlParam('ksLibraryId');
    return {
        ksIsDynamic,
        recoProductIds,
        libraryId,
    };
};
