import * as React from 'react';
import gcoord from 'gcoord';
import cs from 'classnames';
import { getPageId } from 'landingPage/common/utils/getPageId';

import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { isSSR } from 'landingPage/common/utils/env';

import { getProduct } from 'landingPage/common/utils/getProduct';
import { Div } from '../Blank/Blank';
import H5Map from './h5Map';
import styles from './style.module.less';
import { IMapContainer, initMap, initMapPackage } from './util';

export enum StyleType {
    ONE = '01',
    TWO = '02',
    THREE = '03',
    FOUR = '04',
}
export interface MapProps {
    text: string;
    keyword: string;
    lng: number;
    lat: number;
    displayType: StyleType;
}

const getClientOSType = () => {
    const uaString = navigator.userAgent;
    if (uaString.includes('Android')) {
        return 'Android';
    }
    if (/(iPhone|iPad)/.test(uaString)) {
        return 'iOS';
    }
    if (uaString.includes('Macintosh')) {
        return 'Mac';
    }
    return 'Windows';
};

// 转换百度坐标系为GCJ02端内腾讯地图坐标系
function convert(geo: [number, number]) {
    return gcoord.transform(geo, gcoord.BD09, gcoord.GCJ02);
}

const Map: React.FunctionComponent<MapProps> = (props) => {
    const { text, keyword, lng, lat, displayType } = props;
    const [showH5Map, setShowH5Map] = React.useState(false);
    const [convertedLng, convertedLat] = convert([lng, lat]);
    const onClick = React.useCallback(() => {
        document.documentElement.scrollTop = 0;
        if (getProduct() === 0) {
            setShowH5Map(true);
            return;
        }
        const urlBase = 'kwai://map/nav';
        // 这里必须传callback
        const callback = getUrlParam('callback') || 'preview';
        const params = {
            title: text,
            address: keyword,
            longitude: convertedLng,
            latitude: convertedLat,
            lpPageId: getPageId(),
            callback,
        };

        const openFrom = JSON.stringify({
            url: window.location.href,
            from: getClientOSType().toLowerCase(),
        });
        const param = { ...params, openFrom };
        const url = `${urlBase}?${(Object.keys(param) as (keyof typeof param)[])
            .map((key) => param[key] != null && `${key}=${encodeURIComponent(param[key])}`)
            .join('&')}`;

        window.location.href = url;
    }, [convertedLat, convertedLng, keyword, text]);

    const container = React.useRef<HTMLDivElement | null>(null);
    const [mapContainer, setMapContainer] = React.useState<IMapContainer | null>(null);
    React.useEffect(() => {
        if (displayType === StyleType.THREE && container) {
            initMapPackage(() => {
                const mapper = initMap(
                    (mapContainer ?? container?.current) as IMapContainer,
                    convertedLat,
                    convertedLng,
                );
                if (mapper) {
                    setMapContainer(mapper);
                }
            });
        }
    }, [displayType, container, convertedLat, convertedLng, mapContainer]);
    return (
        <Div
            className={cs(styles.root, styles.drag)}
            data-ksad-track="map_click"
            data-ksad-track-data='{"componentId": "7"}'
            onClick={onClick}
        >
            {(!displayType || displayType === StyleType.ONE) && (
                <div className={styles.wrapper} style={{ margin: '12px 17px' }}>
                    <div className={styles.anchor} style={{ marginRight: '12px' }} />
                    <div className={styles.content}>
                        <div className={cs(styles.addressTitle, styles.drag)}>{text}</div>
                        <div className={styles.addressContent}>{keyword}</div>
                    </div>
                    <div className={styles.right} />
                </div>
            )}
            {displayType === StyleType.TWO && (
                <div className={styles.wrapper} style={{ margin: '12px 17px' }}>
                    <div className={styles.content}>
                        <div className={cs(styles.addressTitle, styles.drag)}>{text}</div>
                        <div className={styles.addressContent}>{keyword}</div>
                    </div>
                    <div className={styles.anchor} />
                </div>
            )}
            {displayType === StyleType.THREE && (
                <div className={styles.wrapper} style={{ margin: '16px 17px' }}>
                    <div className={styles.content}>
                        <div className={cs(styles.addressTitle, styles.drag)}>{text}</div>
                        <div className={cs(styles.mapContainer)}>
                            <div ref={container} className={cs(styles.mapBlock)} />
                        </div>
                        <div className={styles.addressContent}>{keyword}</div>
                    </div>
                </div>
            )}
            {displayType === StyleType.FOUR && (
                <div className={styles.wrapper} style={{ margin: '16px 17px' }}>
                    <div className={styles.content}>
                        <div className={cs(styles.addressTitle, styles.drag)}>{text}</div>
                        <div className={styles.addressContent}>{keyword}</div>
                    </div>
                </div>
            )}

            {!isSSR && (
                <H5Map
                    show={showH5Map}
                    position={{
                        longitude: convertedLng,
                        latitude: convertedLat,
                    }}
                    title={text}
                    onClose={() => {
                        setShowH5Map(false);
                    }}
                />
            )}
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {props.children}
        </Div>
    );
};

export default Map;
