import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.less';
import { useRenderState } from 'omega-render';
import { CustomerState, ModalStatus, useCustomerServiceActions } from './redux';
import { connectToChild } from 'penpal';
import { invoke } from '@yoda/bridge';
import { usePrevious } from 'landingPage/common/hooks/usePrevious';

/**
 * 咨询页面的浮层弹窗
 */
const ConsultIframeModal = () => {
    const { customerState } = useRenderState((state) => {
        return { customerState: state.customerService as CustomerState };
    });
    const actions = useCustomerServiceActions();
    /**  弹窗iframe高度 */
    const [height, setHeight] = useState('0');
    /** 弹窗位移，用来做动画 */
    const [translateY, setTranslateY] = useState('0');
    const iframeRef = React.useRef<HTMLIFrameElement>(null);
    useEffect(() => {
        if (!iframeRef.current || !customerState.currentUrl) return;
        /**
         * 因为快聊页在iframe中加载，无法访问yoda对象来调用bridge
         * 因此，通过父级的建站落地页来做一个bridge的通信代理
         */
        console.log('[connectToChild]');
        const connection = connectToChild({
            // The iframe to which a connection should be made.
            iframe: iframeRef.current,
            debug: true,
            // timeout: 1000,
            // Methods the parent is exposing to the child.
            methods: {
                async getSidToken() {
                    console.log('[connectToChild getSidToken]');
                    const data = await invoke('merchant.getSidToken', {
                        sid: 'kuaishou.customer.service.im',
                        forceRefresh: true,
                    });
                    console.log('[connectToChild getSidToken out res]', data);
                    return data;
                },
                async sayHello() {
                    console.log('parent say hello');
                },
                async invoke(method, params) {
                    console.log('[connectToChild invoke]', method, params);
                    const data = await invoke(method, params);
                    console.log('[connectToChild invoke out res]', data);
                    return data;
                },
            },
        });
        return () => connection.destroy();
    }, [customerState.currentUrl]);
    const preStatus = usePrevious(customerState?.status);

    const [startY, setStartY] = useState(null);

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchEnd = (e) => {
        const endY = e.changedTouches[0].clientY;
        if (!startY) return;
        if (startY > endY) {
            if (customerState?.status === ModalStatus.HALF) {
                actions.setModelState({
                    status: ModalStatus.EIGHTY,
                });
            }
        } else if (startY < endY) {
            if (customerState?.status === ModalStatus.HALF) {
                actions.setModelState({
                    status: ModalStatus.HIDDEN,
                });
            }
            if (customerState?.status === ModalStatus.EIGHTY) {
                actions.setModelState({
                    status: ModalStatus.HALF,
                });
            }
        }
        setStartY(null);
    };
    /** 处理modal弹窗的高度变化 */
    useEffect(() => {
        /** 到8分屏 */
        if (customerState?.status === ModalStatus.EIGHTY) {
            setHeight('80vh');
            setTranslateY('-80');
        }
        /** 从8分屏到35分屏幕 */
        if (customerState?.status === ModalStatus.HALF && preStatus === ModalStatus.EIGHTY) {
            setTranslateY('-35');
            setTimeout(() => {
                setHeight('35vh');
            }, 300);
        }
        if (customerState?.status === ModalStatus.HIDDEN) {
            setHeight('80vh');
            setTranslateY('0');
        }
    }, [customerState?.status]);
    /** modal弹窗展示的时候的全局禁止滑动 */
    useEffect(() => {
        if ([ModalStatus.EIGHTY, ModalStatus.HALF].includes(customerState.status)) {
            document.body.classList.add(styles.disableScroll);
        } else {
            document.body.classList.remove(styles.disableScroll);
        }
        return () => {
            document.body.classList.remove(styles.disableScroll);
        };
    }, [customerState.status]);
    return createPortal(
        <>
            {[ModalStatus.EIGHTY, ModalStatus.HALF].includes(customerState.status) && (
                <div
                    className={styles['cs-overlay']}
                    onClick={actions.closeCustomerServiceModal}
                ></div>
            )}

            <div
                className={styles.container}
                style={{
                    height,
                    transform: `translate(0px, ${translateY}vh`,
                }}
            >
                <div
                    className={styles.titleBar}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                >
                    <div
                        className={styles.toggleButton}
                        onClick={() => {
                            actions.toggleCustomerServiceModal();
                        }}
                    >
                        <div className={styles.statusBar}></div>
                    </div>

                    <div
                        className={styles.closeIconWrapper}
                        onClick={actions.closeCustomerServiceModal}
                    />
                </div>
                <div className={styles.iframeContainer}>
                    <iframe
                        ref={iframeRef}
                        src={customerState.currentUrl}
                        title="IM"
                        width="100%"
                        height="100%"
                        className="cs-iframe"
                        style={{ border: 'none' }}
                    />
                </div>
            </div>
        </>,
        document.body,
    );
};

export default ConsultIframeModal;
