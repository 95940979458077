import React, { useRef } from 'react';
import { useComContext } from 'omega-render';
import styles from '../style.module.less';

export interface RightBottomStyleProps {
    setModalVisible: (param: boolean) => void;
}

export enum Position {
    base = 'base',
    bottomBlank = 'bottomBlank',
    rightBottom = 'rightBottom',
}

const RightBottomStyle: React.FunctionComponent<RightBottomStyleProps> = (props) => {
    const { setModalVisible } = props;

    const ref = useRef<HTMLDivElement>(null);
    const { magic } = useComContext();
    console.log(magic?.globalDragFree, ref.current);
    const [width, setWidth] = React.useState(
        ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree.width,
    );
    React.useEffect(() => {
        setWidth(ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree.width);
    }, [magic?.globalDragFree.width]);

    return (
        <div
            onClick={() => {
                setModalVisible(true);
            }}
        >
            <div className={styles.rightBottom} style={{ width, height: width * 0.9 }} />
        </div>
    );
};

export default RightBottomStyle;
