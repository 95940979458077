import { useLazyImg } from 'landingPage/common/utils/useLazyImg';
import React, { RefObject, useMemo } from 'react';

export const EnhanceImage = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
    const { src } = props;
    const { lazyImgs, domRef } = useLazyImg([{ imgUrl: src as string }]);
    const imgSrc = useMemo(() => {
        if (src) {
            return lazyImgs[src];
        }
        return '';
    }, [lazyImgs]);
    return <img {...props} src={imgSrc} ref={domRef as RefObject<HTMLImageElement>}></img>;
};
