import React from 'react';
import { IMemberInfo } from 'landingPage/common/api/interface';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import styles from './style.module.less';

export function Members(props: { members: IMemberInfo[] }) {
    const { members } = props;
    const memberItems = members.sort((first, second) => first.order - second.order);
    return (
        <div className={styles.storeMembers}>
            <div className={styles.title}>团队成员</div>
            <div className={styles.detail}>
                {memberItems.map((member) => {
                    return (
                        <div className={styles.memberItem} key={member.name}>
                            <div className={styles.avatar}>
                                <img src={transformCDN2Escape(member.head)} alt={member.name} />
                            </div>
                            <div className={styles.memberDetail}>
                                <div className={styles.memberName}>{member.name}</div>
                                <div className={styles.memberTitle}>{member.title}</div>
                                <div className={styles.memberTag}>{member.tag}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
