/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { AgreementModal } from '../../Agreement/Agreement';
import { ICustmPrivacyAgreement } from '../XiansuoForm';

import styles from './styles.module.less';

interface IProps extends ICustmPrivacyAgreement {
    showCustom: boolean;
    hasCunstomContent: boolean;
    color?: string; // 灰色文本颜色
    buttonColor?: string; //高亮颜色
    showAgreementByOut?: boolean;
    onClose: () => void;
}
export const CUSTOM_PRIVACY_AGREEMENT_FLAG = 'custom_privacy_agreement';
export default function FormUserAgreement(props: IProps) {
    /** 展示用户协议modal */
    const [showAgreement, setShowAgreement] = useState(false);
    const { showCustom, color, buttonColor, ...rest } = props;
    useEffect(() => {
        setShowAgreement(showCustom ?? false);
    }, []);
    useEffect(() => {
        if (props.showAgreementByOut) {
            setShowAgreement(true);
        }
    }, [props]);
    useEffect(() => {
        if (showCustom) {
            setTimeout(() => {
                const ele = document.getElementById(CUSTOM_PRIVACY_AGREEMENT_FLAG);
                ele?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }, 0);
        }
    }, [showCustom]);
    const onClose = () => {
        setShowAgreement(false);
        props?.onClose?.();
    };
    return (
        <div className={styles.agreement}>
            <span className={styles.text} style={{ color }}>
                查看
            </span>
            <span
                className={cs(styles.agree, 'privacy-flag')}
                onClick={() => setShowAgreement(true)}
                style={{ color: buttonColor }}
            >
                《个人信息授权与保护声明》
            </span>
            <span className={styles.text} style={{ color }}>
                授权范围
            </span>

            <AgreementModal show={showAgreement} onChange={onClose} {...rest} />
        </div>
    );
}
