import { fetchInteractionComment, getABResult, getPopupInfo } from '@/landingPage/common/api';
import { useMount } from 'react-use';
import { useState } from 'react';
import { needShowInteractionCommentInPageJson } from '@/landingPage/common/utils/fetchCompPropsInPageJson';
import styles from './index.module.less';
import { RowStyleList } from './rowStyleList';
import { ColumnStyleList } from './columnStyleList';
import { getRadar } from '@/landingPage/common/weblog';
import { getUrlParam } from 'omega-shared/utils';
import { getPageId } from '@/landingPage/common/utils/getPageId';

enum StyleTypeEnum {
    row = 1,
    column = 2,
}

const ABParamsValue = {
    1: {
        style: StyleTypeEnum.row,
        animationTimer: 2,
    },
    2: {
        style: StyleTypeEnum.column,
        animationTimer: 2,
    },
    3: {
        style: StyleTypeEnum.row,
        animationTimer: 4,
    },
    4: {
        style: StyleTypeEnum.column,
        animationTimer: 4,
    },
};

export interface CommentInfoListProps {
    icon?: string;
    text: string;
    bkgColor?: string;
}

const InteractionComment = () => {
    const [showBarrage, setShowBarrage] = useState(false);

    const [styleType, setStyleType] = useState(0);
    const [commentInfoList, setCommentInfoList] = useState<CommentInfoListProps[]>([]);

    const handleText = (text: string) => {
        return text.length > 12 ? text.slice(0, 12) + '...' : text;
    };

    const handleCommentRes = async (result: any) => {
        setShowBarrage(true);
        let commentInfoList: CommentInfoListProps[] = [];
        for (let keys in result) {
            switch (keys) {
                case 'comments': {
                    result[keys].forEach((item) => {
                        commentInfoList.push({
                            icon: item.headUrl,
                            text: `${item.userName}：${handleText(item.commentText)}`,
                        });
                    });

                    break;
                }
                case 'marketing': {
                    result[keys].forEach((item) => {
                        commentInfoList.unshift({
                            icon: item.iconUrl,
                            text: handleText(item.marketContent),
                            bkgColor: '#FE3666',
                        });
                    });
                    break;
                }
                default:
                    commentInfoList.push({ text: handleText(result[keys]) });
                    break;
            }
        }
        (await getRadar())?.event({
            name: 'interaction_comment', // 必填
            event_type: 'interaction_comment_show', // 【可选】
            category: 'traceLog', // 【可选】
            extra_info: JSON.stringify({
                formType: 1,
                accountId: getUrlParam('ksSiteAccountId'),
                campaignId: getUrlParam('ksCampaignId'),
                unitId: getUrlParam('ksUnitId'),
                creativeId: getUrlParam('ksCreativeId'),
                url: window.location.href,
            }),
        });
        setCommentInfoList(commentInfoList);
    };
    useMount(() => {
        (async () => {
            try {
                const needShowComment = needShowInteractionCommentInPageJson();
                if (!needShowComment) {
                    return;
                }
                const res = await getABResult({
                    adTypes: ['interaction_comment_style'],
                });
                if (res.data.interaction_comment_style) {
                    setStyleType(res.data.interaction_comment_style);
                } else {
                    return;
                }
                const result = await fetchInteractionComment();
                if (result) {
                    //如果有互动弹窗，则需要等互动弹窗动画完毕后再进行展示
                    const data = await getPopupInfo({ pageId: getPageId() });
                    if (data) {
                        setTimeout(() => {
                            handleCommentRes(result);
                        }, 3800);
                    } else {
                        handleCommentRes(result);
                    }
                }
            } catch (e) {
                console.log('error', e);
            }
        })();
    });

    return (
        <div className={styles.modal}>
            {showBarrage && (
                <>
                    {ABParamsValue[styleType]?.style === StyleTypeEnum.row && (
                        <RowStyleList
                            commentInfoList={commentInfoList}
                            animationTimer={ABParamsValue[styleType]?.animationTimer}
                        />
                    )}
                    {ABParamsValue[styleType]?.style === StyleTypeEnum.column && (
                        <ColumnStyleList
                            commentInfoList={commentInfoList}
                            animationTimer={ABParamsValue[styleType]?.animationTimer}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default InteractionComment;
