import { outlinksUsingGET } from '../api/index';
import { getAdBridge } from '../bridge';
import { trackBridgeError, trackBridgeSuccess } from '../adTrack/monitor';
import { isSSR } from './env';
import { DEVICE_TYPE, getDeviceType } from './getDeviceInfo';

let whiteList: any[] = [];
let fetched = false;
export async function getoutLinkWhiteList() {
    if (!fetched) {
        const res = await outlinksUsingGET();
        fetched = true;
        if (res && res.length > 0) {
            whiteList = res;
        }
        return whiteList;
    }
    return whiteList;
}
if (!isSSR) {
    setTimeout(() => getoutLinkWhiteList(), 1000);
}
function isDeepLink(url) {
    // 匹配以 "://" 开头的 URL，但不以 "http://" 或 "https://" 开头
    const pattern = /^(?!https?:\/\/)[a-z]+:\/\//;
    return pattern.test(url);
}
/** 在ios中，handleDeeplink如果传入了http链接，再跳转该http链接时，该http链接将会被解析成淘宝链接，因此handleDeeplink需要过滤掉http链接 */
const filterHttpURL = (whitelists: string[]) => {
    return whitelists.filter((url) => isDeepLink(url));
};

export const preventWakeUpApp = async () => {
    try {
        const isIos = getDeviceType() === DEVICE_TYPE.IOS;
        let whitelist = await getoutLinkWhiteList();
        if (isIos) {
            whitelist = filterHttpURL(whitelist);
        }
        console.log('[handleDeeplink]', JSON.stringify(whiteList));
        // @ts-expect-error adBridge 类型不完善
        getAdBridge()?.handleDeeplink({
            enable: false,
            whiteList: whitelist,
        });
        trackBridgeSuccess('handleDeeplink');
    } catch (e) {
        trackBridgeError('handleDeeplink', e);
        console.error(e);
        // ignore
    }
};
