import { Position } from '../../components/XiansuoFormDrag/XiansuoForm';

export const isForm = (type: string, position?: Position) => {
    let isForm = type === 'FORM' || type === 'XIANSUO_FORM' || type === 'XIANSUO_FORM_DRAG';
    if (!position) {
        return isForm;
    }
    return isForm && position !== Position.bottomBlank;
};

export const allForm = (type: string, position?: Position) => {
    let allForm =
        type === 'FORM' ||
        type === 'XIANSUO_FORM' ||
        type === 'XIANSUO_FORM_DRAG' ||
        type === 'XIANSUO_FORM_PAY';
    if (!position) {
        return allForm;
    }
    return allForm && position !== Position.bottomBlank;
};
