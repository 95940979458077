import React from 'react';
import { GameInfo, useComContext } from 'omega-render';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { DynamicType } from '../ButtonDrag/Button';
import ButtonStyle from './components/buttonStyle';
import DescIconStyle from './components/descIconStyle';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import PicBtnStyle from './components/picBtnStyle';
import { IHotArea } from '../AppDownloadDrag/AppDownload';
import HotArea from '../AppDownloadDrag/components/HotArea';
import { ClickPosition, openWechatGame } from './utils';

export enum BtnType {
    '线框按钮' = 'line',
    '实心按钮' = 'solid',
    '图片填充' = 'image',
}
export enum gameStyle {
    button = 'button',
    descIcon = 'descIcon',
    picBtn = 'picBtn',
    hotArea = 'hotArea',
}

export enum BackgroundType {
    img = 'img',
    color = 'color',
}

export interface WechatGameProps {
    // 小游戏id信息
    gameInfo: GameInfo;
    // 小游戏名称
    name: string;
    // 小游戏名称的css属性
    nameStyle: React.CSSProperties;
    // 小游戏描述
    description: string;
    // 小游戏描述的css属性
    descStyle: React.CSSProperties;
    // 按钮文案
    buttonText: string;
    // 按钮样式
    buttonStyle: React.CSSProperties;
    // 小游戏图标
    gameIcon: string;
    // 样式类型
    style?: gameStyle;
    // 组件布局位置
    position: Position;
    // 背景填充模式
    backgroundFillMode?: boolean;
    // 按钮类型
    btnType?: BtnType;
    // 按钮圆角类型
    radiusStyle?: string;
    // 呼吸态状态
    dynamicStatus?: DynamicType;
    // 背景类型（分图片和颜色）
    backgroundType?: BackgroundType;
    // 卡片样式，背景颜色
    cardBackgroundColor?: string;
    // 卡片样式，背景图片
    cardBackgroundImg?: string;
    // 图片按钮样式背景图片
    picBtnImage?: string;
    //热区设置
    hotAreaInfo?: {
        picUrl: string;
        hotAreas: IHotArea[];
        defaultBgColor?: string;
        defaultBorderColor?: string;
    };
}

export enum Position {
    base = 'base',
    bottomBlank = 'bottomBlank',
    topBlank = 'topBlank',
}

export const fixedPositionHeight = 72;

// let startOpenWechatGametime = new Date().getTime();

const WechatGame: React.FunctionComponent<WechatGameProps> = (props) => {
    const {
        style,
        gameInfo,
        buttonText,
        buttonStyle,
        dynamicStatus,
        position,
        picBtnImage,
        hotAreaInfo,
        ...rest
    } = props;

    const { preview, edit } = useComContext();

    return (
        <>
            {style === gameStyle.button && (
                <ButtonStyle
                    gameInfo={gameInfo}
                    buttonText={buttonText}
                    buttonStyle={buttonStyle}
                    dynamicStatus={dynamicStatus}
                    position={position}
                />
            )}
            {style === gameStyle.descIcon && (
                <DescIconStyle
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                    dynamicStatus={dynamicStatus}
                    buttonText={buttonText}
                    buttonStyle={buttonStyle}
                    gameInfo={gameInfo}
                    position={position}
                />
            )}
            {style === gameStyle.picBtn && (
                <PicBtnStyle
                    gameInfo={gameInfo}
                    buttonText={buttonText}
                    buttonStyle={buttonStyle}
                    picBtnImage={picBtnImage}
                    dynamicStatus={dynamicStatus}
                    position={position}
                />
            )}
            {style === gameStyle.hotArea && (
                <HotArea
                    hotAreaInfo={hotAreaInfo}
                    onClick={() => {
                        openWechatGame({
                            gameInfo,
                            preview,
                            renderClickArea: ClickPosition.hotArea,
                        });
                    }}
                />
            )}
            {position === Position.bottomBlank && (
                <>
                    <BlockPlaceholder pos="BOTTOM" preview={edit} height={fixedPositionHeight} />
                    <SafeAreaPlace
                        style={{
                            backgroundColor:
                                style === gameStyle.descIcon
                                    ? rest.cardBackgroundColor || '#fff'
                                    : 'transparent',
                        }}
                    />
                </>
            )}
            {position === Position.topBlank && (
                <BlockPlaceholder pos="TOP" preview={edit} height={fixedPositionHeight} />
            )}
        </>
    );
};

export default WechatGame;
