import React from 'react';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
// import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { useComContext } from 'omega-render';
import { WechatActionSceneEnum, Applets, PopupType } from '../WeiXin';
import styles from '../style.module.less';
import { jumpToMiniApp } from './commonFun';

// 多转化事件 优化目标
// const multiConversion = getUrlParam('multiConversion') === 'true';
export interface JumpWxAppletProps {
    info?: string;
    onClose?: () => void;
    applets: Applets;
    wechatStay?: boolean;
    // schemaRes?: { schema: string; message: string };
}
export const JumpWxApplet: React.FC<JumpWxAppletProps> = (props) => {
    const { info, onClose, applets, wechatStay } = props;

    React.useEffect(() => {
        dataTrack({
            eventType: 'EVENT_VIEW_WECHAT',
            wechatItemId: '0',
            wechatActionScene: WechatActionSceneEnum.JUMP_WECHAT_APPLET,
            lp_page_type: 1,
            diyExtraInfo: JSON.stringify({
                renderType: 'WEI_XIN_DRAG',
                popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
            }),
        });
    }, []);

    const { preview } = useComContext();

    return (
        <div className={styles.jumpWxApplet}>
            <div className={styles.closeIcon} data-target="wechat-close" onClick={onClose} />
            <i className={styles.appletIcon} />
            <div className={styles.text}>点击前往微信小程序</div>
            <div className={styles.moreInfo}>{info}</div>
            <div
                className={styles.addBtn}
                onClick={() => {
                    jumpToMiniApp({ applets, preview, wechatStay });
                }}
            >
                去微信
            </div>
        </div>
    );
};
