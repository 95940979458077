import Modal from 'landingPage/common/components/Modal';
import styles from './styles.module.less';
import cs from 'classnames';
import { SubmitTurnConfig } from '../transformFormSetting';
import React, { useCallback } from 'react';
import { getUrlParam } from 'omega-shared/utils';
import { getProduct } from 'landingPage/common/utils/getProduct';
import { DEVICE_TYPE, getDeviceType } from 'landingPage/common/utils/getDeviceInfo';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { bridgeGetDeviceInfo, getAdBridge } from 'landingPage/common/bridge';
import { IOrderParams } from 'landingPage/common/api/interface';
import { getOrderId } from 'landingPage/common/api';
import { Toast } from '@ad/sharpui';
import { useComContext } from 'omega-render';
import { getCallbackInfo } from 'landingPage/common/adTrack/util/sendData';

interface AppointmentModalProps {
    show?: boolean;
    modalSetting?: SubmitTurnConfig;
    isAppointmentUser?: boolean;
    onCloseAppointment?: () => void;
    isEdit?: boolean;
}
export const AppointmentModal = (props: AppointmentModalProps) => {
    const { show, modalSetting, onCloseAppointment, isEdit } = props;
    const data = useComContext();
    const appInfo = data?.childComponents?.[0]?.props?.appInfo;
    const onAppointmentSure = useCallback(async () => {
        dataTrack({
            eventType: 'EVENT_APPOINT_BUTTON_CLICK',
            diyExtraInfo: JSON.stringify({
                renderType: 'XIANSUO_FORM_DRAG',
                callback: getUrlParam('callback'),
                openSMS: false,
            }),
        });
        if (!modalSetting?.appointmentInfo) return;
        let deviceId = '';
        const deviceInfo = await bridgeGetDeviceInfo();
        deviceId = deviceInfo?.data?.globalId;
        // 优先取子组件中的应用信息
        const param: IOrderParams = {
            deviceId,
            appId:
                appInfo?.appId ||
                (modalSetting?.appointmentInfo?.appInfo?.appId as unknown as string) ||
                '',
            appName: appInfo?.appName || modalSetting?.appointmentInfo?.appInfo?.appName || '',
            appointTime: new Date().getTime(),
            noticeType: 'NONE',
            callback: getCallbackInfo(),
            packageId:
                appInfo?.packageId || (modalSetting?.appointmentInfo?.appInfo?.packageId as string),
            componentType: 'XIANSUO_FORM_DRAG',
            isH5: !!getUrlParam('isH5'),
            isMK: getUrlParam('downgradeSource') ? true : getUrlParam('isH5') ? false : '',
            isDowngrade: getUrlParam('downgradeSource'),
            appVersion: deviceInfo?.data?.appVersion,
            lpPageId: getUrlParam('pageId'),
        };
        try {
            const res = await getOrderId(param);
            // 该桥方法未登录可自动唤起登录
            (getAdBridge() as any)
                ?.adReserveApp({
                    state: 1, // 1-确认预约  2-取消预约
                    orderId: res.data,
                })
                .then((res) => {
                    console.log('adReserveApp then', res);
                    if (res.result === 1) {
                        Toast.success('预约成功');
                        onCloseAppointment?.();
                    } else {
                        Toast.error(`预约失败: ${res.message || res.msg}`);
                    }
                })
                .catch((e) => {
                    console.log('adReserveApp catch', e);
                    Toast.error('预约失败');
                });
        } catch (e) {
            console.log(e);
            Toast.error(e.message);
        }
    }, [modalSetting, appInfo]);
    const modalParent = isEdit
        ? document.getElementById('preview-app') ?? document.body
        : undefined;
    const isAppointment = React.useMemo(() => {
        // step1: 白名单  根据是否是预约下载用户
        if (!props.isAppointmentUser) {
            return false;
        }
        // step2:  是否预约下载链路
        if (getUrlParam('appointmentFromGame') !== 'EVENT_APPOINT_FORM') {
            return false;
        }
        // step3: 是否在快手和极速版
        if (getProduct() === 0) {
            return false;
        }
        // step4: 是否是安卓系统
        if (getDeviceType() !== DEVICE_TYPE.ANDROID) {
            return false;
        }
        // step5: 判断是否登录及获取登录信息
        return true;
    }, []);
    if (!show) return null;
    if (!isAppointment && !isEdit) return null;
    return (
        <Modal parent={modalParent} style={{ zIndex: 10000 }}>
            <div className={styles.wrapper}>
                <div className={styles.success}></div>
                <div className={styles.title}>预约成功</div>
                <div className={styles.text}>确认游戏上线后仅在WiFi环境下 自动进行下载？</div>
                <div className={styles.opWrapper}>
                    <div
                        className={cs(styles.cancelBtnWrapper, styles.commonBtn)}
                        onClick={() => {
                            onCloseAppointment?.();
                        }}
                    >
                        取消
                    </div>
                    <div
                        className={cs(styles.sureBtnWrapper, styles.commonBtn)}
                        onClick={onAppointmentSure}
                    >
                        确认
                    </div>
                </div>
            </div>
        </Modal>
    );
};
