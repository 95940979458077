import { CustomerServiceProps } from './CustomerService';
import Button from '../ButtonDrag/Button';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import styles from './style.module.less';
import cs from 'classnames';
import { useComContext } from 'omega-render';
import { Div } from '../Blank/Blank';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSafeArea } from './utils';
// import defaultSingleImage from '../defaultSingleImage.png';
interface IRichProps {
    onClick: (props?: { initialInputValue?: string }) => void;
}

/** 咨询组件-底部丰富样式 */
export function BottomRichStyle(props: CustomerServiceProps & IRichProps) {
    const {
        buttonStyle,
        backgroundColor,
        text,
        dynamicStatus,
        position = 'base',
        onClick,
        bottomRichConfig,
    } = props;

    const { edit } = useComContext();
    const instanceRef = useRef<HTMLDivElement | null>(null);
    /** 临时hack的底部安全区逻辑，等通用方案之后删除 */
    useSafeArea(instanceRef);
    /**  客服问题列表 */
    const questions = useMemo(() => {
        return bottomRichConfig?.questions?.filter(Boolean);
    }, [bottomRichConfig?.questions]);
    /**  当前展示的客服问题*/
    const [currentQuestion, setCurrentQuestion] = useState(questions?.[0]);
    return (
        <>
            <Div className={styles.bottomRichRoot} ref={instanceRef}>
                <div className={styles.leftWrapper}>
                    <div
                        className={bottomRichConfig?.avatarAnimation ? styles.avatarAnimation : ''}
                        style={{
                            marginRight: '8px',
                        }}
                    >
                        <img
                            className={styles.avatarImg}
                            src={
                                bottomRichConfig?.avatarUrl ||
                                'https://h2.static.yximgs.com/kos/nlav10749/defaultSingleImage.png'
                            }
                            alt=""
                        />
                    </div>
                    <div className="content">
                        <div className={styles.bottomRichTitle}>
                            {bottomRichConfig?.title || '专属客服提您解答'}
                        </div>
                        {edit ? (
                            <div className={styles.bottomRichQuestion}> {questions?.[0]}</div>
                        ) : (
                            <TextScroller
                                text={questions}
                                onChange={(text) => {
                                    setCurrentQuestion(text);
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className={styles.rightWrapper} style={{}}>
                    <Button
                        bottom={position === 'bottomBlank'}
                        text={text}
                        buttonStyle={buttonStyle}
                        onClick={() => onClick({ initialInputValue: currentQuestion })}
                        backgroundColor={backgroundColor}
                        className={cs({ [styles.dynamic]: dynamicStatus === 1 })}
                    >
                        {position === 'bottomBlank' && (
                            <>
                                <BlockPlaceholder pos="BOTTOM" preview={edit} height={72} />
                            </>
                        )}
                    </Button>
                </div>
            </Div>
        </>
    );
}

interface IProps {
    /**
     * 要在文本滚动器中显示的字符串数组。
     */
    text?: string[];
    /**
     * 当所选文本项更改时调用的回调函数。
     * @param text 新的所选文本项。
     */
    onChange?: (text: string) => void;
}

/**
 * 显示文本项滚动列表的组件。
 * @param props 组件属性。
 * @returns 渲染的组件。
 */
const TextScroller = (props: IProps) => {
    const { text, onChange } = props;
    const [list, setList] = useState<string[]>(text || []);
    const [startAnimation, setStartAnimation] = useState(false);
    const loopList = useCallback(() => {
        setList((pre) => {
            const res = pre.slice(1);
            res.push(pre[0]);
            return res;
        });
    }, [text]);
    useEffect(() => {
        onChange?.(list[0]);
    }, [list, onChange]);
    useEffect(() => {
        let subTimer: number;
        const timer = window.setInterval(() => {
            setStartAnimation(true);
            subTimer = window.setTimeout(() => {
                setStartAnimation(false);
                loopList();
            }, 400);
        }, 3000 + 400);
        return () => {
            clearInterval(timer);
            clearTimeout(subTimer);
        };
    }, [loopList]);
    if (!text) return <></>;
    return (
        <div className={styles.root}>
            {list.map((item, i) => (
                <div
                    key={i}
                    className={cs(styles.wrapper, {
                        [styles.fadeout]: i === 0 && startAnimation,
                        [styles.fadeIn]: i !== 0 && startAnimation,
                    })}
                >
                    {item}
                </div>
            ))}
        </div>
    );
};

export default TextScroller;
