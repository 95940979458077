import { useComContext } from 'omega-render';
import { useCallback, useEffect, useState } from 'react';
import cs from 'classnames';
import styles from '../../style.module.less';
import Button from '../../../ButtonDrag/Button';
import ShufflingImage from '../../../ShufflingImage/ShufflingImage';
import Tag from './Tag';
import style from './style.module.less';
import { DownloadTypeProps } from '../PicDownload';
import { Div } from '@/landingPage/components/Blank/Blank';

const PicBook: React.FunctionComponent<DownloadTypeProps> = (props) => {
    const {
        buttonStyle,
        onClick,
        bgImgs,
        picTags,
        picContent,
        dynamicStatus,
        buttonText,
        children,
        status,
    } = props;
    const { edit } = useComContext();
    const [ratio, setRatio] = useState(1 / 2);
    const curImgs = useCallback(() => {
        if (bgImgs && bgImgs?.length > 0) return bgImgs;
        return ['https://p2-yx.adkwai.com/kos/nlav10749/site/fullDefault.png'];
    }, [bgImgs]);

    useEffect(() => {
        if (!bgImgs || bgImgs.length < 0 || !bgImgs[0]) return;
        const img = new Image();
        // eslint-disable-next-line prefer-destructuring
        img.src = bgImgs[0]; // 以第一张图片的比例为准
        img.onload = () => {
            const ratio = img.width / img.height;
            setRatio(ratio);
        };
    }, [bgImgs]);
    return (
        <Div
            className={style.Wrap}
            style={{
                height: edit ? '601px' : '100vh',
            }}
        >
            <ShufflingImage
                randomHeight
                imgs={
                    curImgs()?.map((img) => {
                        return {
                            imgUrl: img,
                        } as any;
                    }) ?? ['']
                }
                renderType={1}
                ratio={ratio}
                playInterval={4}
                autoplay
                isVerticalCenter
            />
            <div className={style.ContentWrapper}>
                <div className={style.TitleWrapper}>
                    <div
                        className={style.TitleContent}
                        style={{
                            color: picContent?.titleColor ?? '#FFFFFF',
                        }}
                    >
                        {picContent?.title ?? '图片名称'}
                    </div>
                    <div>
                        <Button
                            className={cs({
                                [styles.progress]: status === 2,
                                [styles.dynamic]: !!dynamicStatus,
                            })}
                            text={buttonText ?? ''}
                            buttonStyle={buttonStyle}
                            onClick={onClick}
                        >
                            {children}
                        </Button>
                    </div>
                </div>
                <div
                    className={style.DescWrapper}
                    style={{
                        color: picContent?.descColor ?? 'rgba(235,236,236,1)',
                    }}
                >
                    {picContent?.desc ?? '图片描述'}
                </div>
            </div>
            {
                // eslint-disable-next-line react/jsx-props-no-spreading
                picTags && <Tag {...picTags} />
            }
        </Div>
    );
};
export default PicBook;
