import { getAdBridge } from '../bridge';

export const isDevelopment = process.env.NODE_ENV === 'development';
export const isProduction = (process.env.PRODUCTION_BUILD as unknown as boolean) === true;
// export const isTest =
//     process.env.NODE_ENV === 'production' && process.env.REACT_APP_STAGE === 'true';
export const isTest =
    window.location.origin.includes('test') || window.location.origin.includes('jinx');
export const isBeta =
    window.location.origin.includes('beta') || window.location.origin.includes('prt');
export const isStaging = window.location.origin.includes('staging');
export const isPrt = window.location.origin.includes('prt');
export const isOnline = window.location.origin.includes('moli.kuaishou.com');
export const isLocalhost = window.location.origin.includes('localhost');
export const isAudit = window.location.pathname === '/rest/lp/admin/page/getHtml';
export const isLandingPage = window.location.pathname === '/rest/n/lp/page/getHtml';
export const isSSR = process.env.RUNTIME === 'server';
export const isSSRHydrate = !!window.INIT;
/** 页面基础路径 */
export const basePath = process.env.REACT_APP_BASE_PATH ?? '';

export function getCookie(objName: string) {
    // 获取指定名称的cookie的值
    const arrStr = document.cookie.split('; ');
    let temp;

    for (let i = 0; i < arrStr.length; i++) {
        temp = arrStr[i].split('=');

        if (temp[0] === objName) {
            return unescape(temp[1] || '');
        }
    }

    return '';
}
export const ua = navigator?.userAgent;
enum IPreLandingPage {
    UNKNOWN = 0,
    PRE = 1,
    NOT_PRE = 2,
}
enum IPreLandingPageStatus {
    // 成功
    SUCCESS = 0,
    // ad-bridge 异步加载失败
    BRIDGE_ERROR_ASYNC = 1,
    // 调用Bridge失败
    BRIDGE_ERROR_CALL = 2,
    // 参数为undefined
    BRIDGE_PRE_UNDEFINED = 3,
}

// isPreLandingPage值
let { isPreLandingPage } = window;
let getIsPreDone = false;
let status = IPreLandingPageStatus.SUCCESS;
export const getIsPreLandingPage = async (): Promise<[IPreLandingPage, IPreLandingPageStatus]> => {
    // 已获得前置落地页参数，直接返回
    if (getIsPreDone) {
        return [isPreLandingPage, status];
    }
    // html的inline-script 已计算得到，直接使用
    if (Number(isPreLandingPage) > 0) {
        getIsPreDone = true;
        return [isPreLandingPage, status];
    }
    const adBridge = getAdBridge();
    if (adBridge) {
        try {
            // @ts-expect-error adBridge类型定义补全
            const { isPreLandingPage: isPre } = await adBridge.getClientData();
            if (isPre) {
                isPreLandingPage = isPre;
            } else {
                // bridge获取的参数为undefind
                status = IPreLandingPageStatus.BRIDGE_PRE_UNDEFINED;
                isPreLandingPage = IPreLandingPage.UNKNOWN;
            }
        } catch (e) {
            // bridge调用失败
            isPreLandingPage = IPreLandingPage.UNKNOWN;
            status = IPreLandingPageStatus.BRIDGE_ERROR_CALL;
        }
        getIsPreDone = true;
    } else {
        // ad-bridge 异步加载未完成
        status = IPreLandingPageStatus.BRIDGE_ERROR_ASYNC;
    }
    return [isPreLandingPage, status];
    // getAdBridge().getClientData()
};

export const isInApp = isSSR
    ? false
    : / (Kwai|Kwai_Lite|Kwai_Pro|ksthanos|ksNebula|livemate|kswv)\//i.test(ua) ||
      getCookie('appver').length > 0; // isInApp 代替 isInKwai，后者表义不再合适，需要逐渐被废弃

/** 渲染器状态环境 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class RenderEnv {
    /** 是否为预览 */
    static preview: boolean = false;

    /** 是否为编辑 */
    static edit: boolean = false;

    static init(preview?: boolean, edit?: boolean) {
        preview && (this.preview = preview);
        edit && (this.edit = edit);
    }
}

/**
 * 判断给定的值是否为假值。
 *
 * @param value - 要判断的值。
 * @returns 如果值为假值，则返回 `true`，否则返回 `false`。
 */
export function isFalsy(value) {
    return value === '0' || !value;
}

export function getMoliHost() {
    // 如果host是chenzhongkj.com 返回 https://moli.kuaishou.com, 否则返回当前协议和host
    const { protocol, host } = window.location;
    if (host === 'chenzhongkj.com') {
        return 'https://moli.kuaishou.com';
    }
    return `${protocol}//${host}`;
}
