import { getPicCDNSource } from '@/landingPage/common/utils/getUrlParam';
import cs from 'classnames';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import { useMemo } from 'react';
import Button from '../../../ButtonDrag/Button';
import { AppDownloadProps } from '../../AppDownload';
import styles from '../../style.module.less';
import style from './index.module.less';
import { Div } from '@/landingPage/components/Blank/Blank';

export interface DownloadTypeProps extends AppDownloadProps {
    buttonText?: string;
    status?: number;
}
const PicDownload: React.FunctionComponent<DownloadTypeProps> = (props) => {
    const {
        picFloatInfo,
        buttonStyle,
        appInfo,
        onClick,
        dynamicStatus,
        picFloatStyle,
        appType,
        buttonText,
        children,
        status,
    } = props;
    // const getPkgSize = (size: number) => {
    //     if (!size) return '0.0MB';
    //     return `${(size / 1024 / 1024).toFixed(1)}MB`;
    // };
    const packageSize = useMemo(() => {
        if (!appType) return appInfo?.packageSize;
        if (appType === 'legacy') return appInfo?.packageSize;
        return ((appInfo?.packageSize ?? 0) / 1024 / 1024).toFixed(1);
    }, [appType, appInfo?.packageSize]);
    return (
        <Div
            className={style.Wrap}
            style={{
                background: picFloatStyle?.bgColor ?? '#f0f0f0',
            }}
        >
            <div
                className={style.IconWrapper}
                style={{
                    backgroundImage: `url(${
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        getPicCDNSource(appInfo?.appIconUrl) ??
                        transformCDN2Escape(
                            'https://s2-10889.kwimgs.com/kos/nlav10889/site/download5_image.png',
                        )
                    })`,
                    backgroundSize: 'contain',
                }}
            />
            <div
                className={style.NameWrapper}
                style={{
                    color: picFloatStyle?.appNameColor ?? '#222222',
                }}
            >
                {appInfo?.appName ?? '应用名称'}
            </div>
            <div
                className={style.InfoWrapper}
                style={{
                    color: picFloatStyle?.appDesColor ?? '#9c9c9c',
                }}
            >
                版本:
                {appInfo?.appVersion ?? '-'}
                {appInfo?.platform === 'android'
                    ? // eslint-disable-next-line no-useless-concat
                      `   大小:${packageSize ?? 0}MB`
                    : ''}
            </div>
            <div
                className={style.DescWrapper}
                style={{
                    color: picFloatInfo?.color ?? 'rgba(102,102,102,1)',
                    textAlign: (picFloatInfo?.textAlign ?? 'center') as any,
                }}
            >
                {picFloatInfo?.appDescription ?? '应用描述'}
            </div>
            <div className={style.BtnWrapper}>
                <Button
                    className={cs({
                        [styles.progress]: status === 2,
                        [styles.dynamic]: !!dynamicStatus,
                    })}
                    text={buttonText ?? ''}
                    buttonStyle={{ ...buttonStyle }}
                    onClick={onClick}
                >
                    {children}
                </Button>
            </div>
        </Div>
    );
};
export default PicDownload;
