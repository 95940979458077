import * as React from 'react';
// import { ShufflingImageProps } from './ShufflingImage';
import { useSetState } from 'react-use';
// import { Progress } from './Progress';
import cs from 'classnames';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import styles from './style.module.less';

export interface SlidesProps {
    imgs: string[];
    playInterval?: number;
    width?: number;
    autoplay?: boolean;
}

// export interface Type1Props
//     extends Omit<
//         ShufflingImageProps,
//         'groupImageConfig' | 'labelStyles' | 'labelActiveStyles' | 'groupStyles' | 'shufflingType'
//     > {
//     progressClass?: string;
// }

export const Slides: React.FunctionComponent<SlidesProps> = (props) => {
    const { imgs = [], width, playInterval, autoplay = false } = props;
    /** 转换图片CDN域名为逃生域名 */
    const transformedImgs = React.useMemo(() => {
        return imgs.map((img) => transformCDN2Escape(img));
    }, [imgs]);
    const len = width ?? 375;
    const opt = React.useMemo(() => {
        const time = 400;
        const delay = playInterval ?? 4000;
        return {
            len,
            time,
            delay,
        };
    }, [len, playInterval]);

    const { moved, renderImgs, events } = useAnimation({ autoplay, imgs: transformedImgs }, opt);

    return (
        <div
            className={styles.slides}
            onTouchStart={events.onTouchStart}
            onTouchMove={events.onTouchMove}
            onTouchEnd={events.onTouchEnd}
        >
            {renderImgs.map((imgUrl, index) => {
                // const { imgUrl, targetUrl } = conf;
                return (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={cs(styles.img)}
                        // onClick={() => {
                        //     if (targetUrl) {
                        //         window.location.href = targetUrl;
                        //     }
                        // }}
                        style={{
                            transform: `translate(${
                                (index - (renderImgs.length - 1) / 2) * opt.len - moved
                            }px,${0}px)`,
                            backgroundImage: `url(${imgUrl})`,
                        }}
                    />
                );
            })}
        </div>
    );
};
let hasError = false;
function getEle<T>(arr: T[], index: number) {
    if (arr.length === 0) {
        console.error('数组长度不能为0');
        // 果然没有一行代码是多余的
        if (!hasError) {
            hasError = true;
        }
    }
    if (arr.length === 1) {
        return arr[0];
    }
    if (index >= arr.length) {
        // 这里有可能算出-1，所以需要后面小于0的逻辑
        index = ((index + 1) % arr.length) - 1;
    }
    if (index < 0) {
        // 这里有可能算出arr.length，所以需要后面小于0的逻辑
        index = (index % arr.length) + arr.length;
    }
    return arr[index];
}
interface AnimationOpt {
    len: number;
    time: number;
    delay: number;
}
export function useAnimation(props: { autoplay: boolean; imgs: string[] }, opt: AnimationOpt) {
    const { imgs } = props;
    const { len, time, delay } = opt;
    const step = len / (time / 16);
    const [state, setState] = useSetState({ moved: 0, index: 0 });
    const that = React.useRef<{
        timmer?: number;
        delayTimer?: number;
        moved: number;
        index: number;
        autoplay?: boolean;
        touchState: {
            startXY: number[];
            moved?: number;
            moveOffset: number;
        };
    }>({
        index: 0,
        touchState: {
            startXY: [],
            moveOffset: 0,
        },
        moved: 0,
    });
    that.current.autoplay = props.autoplay;
    that.current.moved = state.moved;
    that.current.index = state.index;
    const stopAnimation = React.useCallback(() => {
        if (that.current.timmer != null) {
            cancelAnimationFrame(that.current.timmer);
        }
        if (that.current.delayTimer) {
            clearTimeout(that.current.delayTimer);
        }
    }, []);
    /**
     * reverse 是否倒着来
     */
    const startAnimation = React.useCallback(
        (reverse?: boolean) => {
            let { moved } = that.current;
            if (reverse) {
                moved -= step;
            } else {
                moved += step;
            }
            if (Math.abs(moved) >= len) {
                // 到头了
                const { index } = that.current;
                setState({
                    moved: 0,
                    index: reverse ? index - 1 : index + 1,
                });
                if (!that.current.autoplay) {
                    return;
                }
                that.current.delayTimer = window.setTimeout(() => {
                    startAnimation(false);
                }, delay);
                return;
            }
            setState({
                moved,
            });
            that.current.timmer = requestAnimationFrame(() => startAnimation(reverse));
        },
        [delay, len, setState, step],
    );

    React.useEffect(() => {
        let timmer: number;
        if (props.autoplay) {
            timmer = window.setTimeout(() => {
                startAnimation();
            }, delay);
        }

        return () => {
            stopAnimation();
            window.clearTimeout(timmer);
        };
    }, [delay, props.autoplay, startAnimation, stopAnimation]);

    const renderImgs = React.useMemo(() => {
        const { index } = state;
        return [getEle(imgs, index - 1), getEle(imgs, index), getEle(imgs, index + 1)];
    }, [imgs, state.index]);
    // 触摸控制
    const onTouchStart = React.useCallback(
        (e: React.TouchEvent) => {
            const { pageX, pageY } = e.touches[0];
            that.current.touchState.startXY = [pageX, pageY];
            const { moved } = that.current;
            that.current.touchState = { startXY: [pageX, pageY], moved, moveOffset: 0 };
            stopAnimation();
        },
        [stopAnimation],
    );
    const onTouchMove = React.useCallback(
        (e: React.TouchEvent) => {
            const { pageX, pageY } = e.touches[0];
            const { startXY, moved, moveOffset } = that.current.touchState;
            if (startXY.length === 0) {
                return;
            }
            const [offsetX] = [pageX - startXY[0], pageY - startXY[1]];

            setState({
                moved: Number(moved) - offsetX + moveOffset,
            });
        },
        [setState],
    );
    const onTouchEnd = React.useCallback(() => {
        if (that.current.touchState.startXY.length) {
            const { moved } = that.current;
            that.current.touchState = {
                startXY: [],
                moveOffset: 0,
            };
            startAnimation(moved < 0);
        }
    }, [startAnimation]);
    // 触摸的时候，修正状态
    React.useEffect(() => {
        if (that.current.touchState.startXY.length) {
            const { index } = that.current;
            if (state.moved >= len) {
                that.current.touchState.moveOffset -= len;
                setState({
                    index: index + 1,
                    moved: state.moved - len,
                });
            } else if (state.moved <= -len) {
                that.current.touchState.moveOffset += len;

                setState({
                    index: index - 1,
                    moved: state.moved + len,
                });
            }
        }
    }, [len, setState, state.moved]);
    return {
        moved: state.moved,
        index: state.index,
        renderImgs,
        stopAnimation,
        events: {
            onTouchStart,
            onTouchMove,
            onTouchEnd,
        },
    };
}
