const DEBUG_KEY = 'ksdebug';

export const AD_LOG = {
    debug: 'https://ad-callback-api-dfbeta.test.gifshow.com/api/v2/diy/log?token=7e4318ed4e0b4e57b96d810f1007216a',
    release: 'https://ad.partner.gifshow.com/api/v2/diy/log?token=7e4318ed4e0b4e57b96d810f1007216a',
    back: '/rest/web/clue/log?token=7e4318ed4e0b4e57b96d810f1007216a',
};

/**
 * 获取 url 上的参数
 * @param {string} key url上的参数 key
 */
export function getUrlParams(key) {
    const reg = new RegExp(`[&?]${key}=([^&?#]*)`, 'ig');
    const url = window.location.href.match(reg) ? window.location.href : document.referrer;
    const matchRet = reg.exec(url);
    return matchRet ? matchRet[1] : '';
}

/**
 * 获取 url 上的参数
 * @param {string} key url上的参数 key
 */
export function getUrlParamsWithoutReferer(key) {
    const reg = new RegExp(`[&?]${key}=([^&?#]*)`, 'ig');
    const url = window.location.href;
    const matchRet = reg.exec(url);
    return matchRet ? matchRet[1] : '';
}

export function isDebugMode() {
    return getUrlParams(DEBUG_KEY) === '1';
}
