import * as React from 'react';
import cs from 'classnames';
import { Div } from '../Blank/Blank';
import styles from './style.module.less';

import { ListItem } from './DragInteractionRecord';
import { initList } from './utils/initList';
import { generateItem } from './utils/generateItem';

export interface DragInteractionRecordInstanceProp {
    text: string;
    type: number;
}
const DragInteractionRecordInstance: React.FunctionComponent<DragInteractionRecordInstanceProp> = (
    props,
) => {
    const { text, type } = props;
    const opt = type === 1 ? { limit: 2, height: '28px' } : { limit: 4, height: '80px' };
    const [list, setList] = React.useState<ListItem[]>(initList(opt.limit, text));
    const [startAnimation, setStartAnimation] = React.useState(false);
    React.useEffect(() => {
        setList(initList(opt.limit, text));
    }, [opt.limit, text]);
    const loopList = React.useCallback(() => {
        setList((pre) => {
            const res = pre.slice(1);
            res.push(generateItem(pre, text));
            return res;
        });
    }, [text]);
    React.useEffect(() => {
        let subTimer: number;
        const timer = window.setInterval(() => {
            setStartAnimation(true);
            subTimer = window.setTimeout(() => {
                setStartAnimation(false);
                loopList();
            }, 400);
        }, 2000 + 400);
        return () => {
            clearInterval(timer);
            clearTimeout(subTimer);
        };
    }, [loopList]);
    return (
        <Div
            className={styles.root}
            style={{
                height: opt.height,
            }}
        >
            {list.map((item, i) => (
                <div
                    key={item.id}
                    className={cs(styles.wrapper, {
                        [styles.fadeout]: i === 0 && startAnimation,
                        [styles.fadeIn]: i !== 0 && startAnimation,
                    })}
                >
                    <img className={styles.avatar} src={item.avatar} alt="头像" />
                    <div className={styles.desc}>{item.desc}</div>
                </div>
            ))}
        </Div>
    );
};

export default DragInteractionRecordInstance;
