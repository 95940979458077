import { invoke } from '@yoda/bridge';

interface AdInfoByBridgeCache {
    adCallbackParam?: string;
    fansTopAttributeParams?: string;
}

/** 服务号简易直播间场景，由于服务号历史原因，未拼接callback，需要从bridge中获取callback信息 */
let callbackInfoCache: AdInfoByBridgeCache = {};
export async function getCallbackInfoByBridge() {
    try {
        callbackInfoCache = await invoke('advertise.getAdInfo');
    } catch (e) {
        console.log('fetch callback fail', e);
    }
}

export function getCallbackInfoCache() {
    return callbackInfoCache;
}
