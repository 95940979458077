declare const TMap: any;

const MAP_KEY = 'YNRBZ-QPPLP-SAYDA-LP6UC-OP3GT-LLF5N';
export function initMapPackage(callback: () => void) {
    if (typeof TMap === 'undefined') {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${MAP_KEY}`;
        document.body.appendChild(script);
        script.onload = callback;
    } else {
        callback();
    }
}

function initMapBlock(container: HTMLDivElement, lat: number, lng: number) {
    return new TMap.Map(container, {
        center: new TMap.LatLng(lat, lng), // 设置地图中心点坐标
        zoom: 16, // 设置地图缩放级别
        rotation: 0, // 设置地图旋转角度
        viewMode: '2D',
        showControl: false,
    });
}

function setMapAnchor(marker: any, mapBlock: any, lat: number, lng: number) {
    if (marker) {
        marker.setMap(null);
    }
    return new TMap.MultiMarker({
        map: mapBlock, // 指定地图容器
        // 样式定义
        styles: {
            // 创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
            myStyle: new TMap.MarkerStyle({
                width: 15, // 点标记样式宽度（像素）
                height: 21, // 点标记样式高度（像素）
                // 焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 10, y: 19 },
            }),
        },
        // 点标记数据数组
        geometries: [
            {
                id: 'map-anchor', // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                styleId: 'myStyle', // 指定样式id
                position: new TMap.LatLng(lat, lng), // 点标记坐标位置
                properties: {
                    // 自定义属性
                    title: 'marker',
                },
            },
        ],
    });
}

export interface IMapContainer {
    mapBlock: any;
    marker: any;
}
export function initMap(container: HTMLDivElement | IMapContainer, lat: number, lng: number) {
    if ((container as IMapContainer).mapBlock && (container as IMapContainer).marker) {
        (container as IMapContainer).mapBlock.setCenter(new TMap.LatLng(lat, lng));
        setMapAnchor(
            (container as IMapContainer).marker,
            (container as IMapContainer).mapBlock,
            lat,
            lng,
        );
    } else {
        const mapBlock = initMapBlock(container as HTMLDivElement, lat, lng);
        return {
            mapBlock,
            marker: setMapAnchor(null, mapBlock, lat, lng),
        };
    }
}
