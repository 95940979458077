import React from 'react';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { IStoreView } from '../../common/api/interface';
import { dataTrack } from '../../common/adTrack/util/track';
import StoreIcon from './icon/StoreIcon';
import NavIcon from './icon/NavIcon';
import { ReactComponent as ShowIcon } from './icon/showIcon.svg';
import { ReactComponent as LocateIcon } from './icon/locateIcon.svg';
import styles from './style.module.less';
import { IPosition, LOC_ERROR } from './Store';
import { getDefaultCopyWriting } from './utils';
// import { Poi } from './Store';
import { STORE_TYPE } from './hooks/useNewStoreList';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';

export interface StoreLightProps {
    position: IPosition;
    storeType: STORE_TYPE;
    // funcId: string;
    // version: string;
    /** 是否是编辑模式 */
    edit?: boolean;
    storeSelected?: IStoreView;
    // storeSelectedPoi?: Poi;
    located: boolean;
    style: {
        /** 门店名称字号 */
        nameSize?: number;
        /** 门店名称字色 */
        nameColor?: string;
        /** 门店地址字号 */
        addressSize?: number;
        /** 门店地址字色 */
        addressColor?: string;
        /** 导航icon颜色 */
        iconColor?: string;
        /** 组件高度 */
        height?: number;
    };
    locError: LOC_ERROR;
    goToStoreLIst: (e: React.MouseEvent) => void;
    goToMap: (item: IStoreView) => void;
    updateLocation: (e: any) => void;
}
export default function StoreLightStyle(props: StoreLightProps) {
    const { position, style, storeSelected, edit, locError } = props;
    const { goToStoreLIst, goToMap, updateLocation, located, storeType } = props;
    const { nameSize, nameColor, addressSize, addressColor, iconColor, height } = style;
    return (
        <div
            className={styles.root}
            onClick={(e) => {
                storeType !== STORE_TYPE.SINGLE && (located || edit) && goToStoreLIst(e);
            }}
        >
            <div className={styles.storeDetailWrapper}>
                <div className={styles.storeDetail}>
                    <div className={styles.storeNameLine}>
                        <StoreIcon className={styles.storeIcon} fill={iconColor as string} />
                        <div
                            className={styles.nameText}
                            style={{
                                color: nameColor,
                                fontSize: nameSize,
                            }}
                        >
                            <span>
                                {storeSelected?.name ?? getDefaultCopyWriting(locError).title}
                            </span>
                            {storeType !== STORE_TYPE.SINGLE && (located || edit) && (
                                <ShowIcon className={styles.showIcon} />
                            )}
                        </div>
                    </div>
                    <div
                        className={styles.address}
                        style={{
                            color: addressColor,
                            fontSize: addressSize,
                        }}
                    >
                        {storeSelected?.address ?? getDefaultCopyWriting(locError).content}
                    </div>
                </div>
                {(!!edit || storeSelected?.id) && (
                    <div
                        className={styles.navIconWrapper}
                        onClick={(e) => {
                            e.stopPropagation();
                            dataTrack({
                                eventType: 'EVENT_ELEMENT_NAVIGATE_CLICK',
                                diyExtraInfo: JSON.stringify({
                                    storeId: storeSelected?.id,
                                }),
                            });
                            goToMap(storeSelected as IStoreView);
                        }}
                    >
                        <NavIcon className={styles.navIcon} fill={iconColor as string} />
                        <div className={styles.desc} style={{ color: iconColor }}>
                            导航
                        </div>
                    </div>
                )}

                {[IPosition.TOP, IPosition.EX_TOP].includes(position) && (
                    <BlockPlaceholder pos="TOP" preview={edit} height={height} />
                )}
                {[IPosition.BOTTOM, IPosition.EX_BOTTOM].includes(position) && (
                    <>
                        <BlockPlaceholder pos="BOTTOM" preview={edit} height={height} />
                        <SafeAreaPlace
                            style={{
                                backgroundColor: '#fff',
                            }}
                        />
                    </>
                )}
            </div>
            <div className={styles.updateLocation}>
                {storeType !== STORE_TYPE.SINGLE && (located || edit) && (
                    <div className={styles.locateWrapper} onClick={(e) => updateLocation(e)}>
                        <LocateIcon className={styles.locateIcon} />
                        <div className={styles.requestButton}>获取定位</div>
                    </div>
                )}
            </div>
        </div>
    );
}
