import { useComContext } from 'omega-render';
/**
 * 在编辑模式，返回undefined。实现的效果就是编辑模式或者没有图层组件，相当于没有这个属性，但是实际渲染的时候，会使用这个editHeight
 * @param props 组件的props
 * @param onlyDragFree 只在有图层的页面有值
 */
export const useEditHeight = (onlyDragFree?: boolean) => {
    const { editHeight, edit, hasDragFree } = useComContext();

    if (edit) {
        return {
            editHeight: undefined,
        };
    }
    if (onlyDragFree && !hasDragFree) {
        return {
            editHeight: undefined,
        };
    }
    return {
        editHeight,
    };
};
