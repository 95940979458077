/**
 * @description 自定义性能监控上报，与radar数据做校准
 */

import { sendTraceLog } from '../monitor';

/**
 * 获取文档开始解析到解析完毕的时间
 * @returns
 */
const getDocParseTime = (): number => {
    let timing = window.performance.timing;
    return timing.domInteractive - timing.domLoading;
};
/**
 * 获取网页内资源加载耗时,从DOM解析完成到网页内资源加载完成（如JS脚本加载执行完毕）的时间
 */
const getContentLoadTime = (): number => {
    const timing = window.performance.timing;
    return timing.domComplete - timing.domInteractive;
};

/**
 * 获取网页各个资源加载的时间超过1s的数据
 * @returns
 */
const getResourceLoadTime = (): {
    name: string;
    duration: number;
}[] => {
    return window.performance
        .getEntries()
        .filter((item) => {
            return item.duration > 1000;
        })
        .map((item) => {
            return {
                name: item.name,
                duration: item.duration,
            };
        });
};

/**
 * 这里的白屏可能会不准确，因为css阻塞页面解析后，也是白屏，但是那时已经开始解析dom了
 * @returns
 */
const getWhiteScreenTime = (): number => {
    const timing = window.performance.timing;
    return timing.domLoading - timing.fetchStart;
};

/**
 * 上报自定义性能埋点数据
 */
export function reportPerformanceTime() {
    // 需要在load事件触发后上报，否则拿不到domComplete时间
    window.addEventListener('load', () => {
        let docParseTime = getDocParseTime();
        let contentLoadTime = getContentLoadTime();
        let resourceListTime = getResourceLoadTime();
        let whiteScreenTime = getWhiteScreenTime();
        sendTraceLog({
            tags: {
                docParseTime,
                contentLoadTime,
                resourceListTime: JSON.stringify(resourceListTime),
                whiteScreenTime,
                ssrTraceId: window.SSR_TRACE_ID,
            },
        });
    });
}
