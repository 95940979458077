import { Reducer } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

interface Action<T = undefined> {
    type: string;
    payload?: T;
}
type Reducers<State> = {
    [key in string]: (state: State, payload: any) => State;
};
type Effects = {
    [k in string]: (
        ...arr: any[]
    ) => (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => Promise<any>;
};
export interface ModelConfig<
    State,
    N extends string,
    R extends Omit<Reducers<State>, 'setModelState'>,
    E extends Effects,
> {
    /**  action的命名空间 */
    readonly namespace: N;
    /** model的默认状态 */
    defaultState: State;
    /**  纯reducer。默认包含一个setModelState，方便修改model的state。修改方式类似react的setState */
    reducers: R;
    /**  利用redux thunk实现的异步或多个action同时调用。 */
    effects?: E;
}

/**
 * 应用的model部分
 * @param config
 */
export function createModel<State, N extends string, R extends Reducers<State>, E extends Effects>(
    config: ModelConfig<State, N, R, E>,
) {
    const { namespace, defaultState, effects } = config;

    const { reducers } = config;
    type SetModelStateType<T> = (state: T, payload: Partial<T>) => T;
    const setModelState = <T>(state: T, payload: Partial<T>) => {
        return {
            ...state,
            ...payload,
        };
    };
    // @ts-expect-error TODO完善一下动态设置reducer的类型
    reducers.setModelState = setModelState;
    type ReducerConfigs = typeof reducers & { setModelState: SetModelStateType<State> };

    type ReducerKeys = keyof ReducerConfigs;
    type Effects = Exclude<typeof effects, undefined>;
    type EffectKeys = keyof Effects;
    type syncActions = {
        [key in ReducerKeys]: (
            payload?: Parameters<ReducerConfigs[key]>[1],
        ) => Action<Parameters<ReducerConfigs[key]>[1]>;
    };
    type asyncActions = {
        [key in EffectKeys]: (
            ...arr: Parameters<Effects[key]>
        ) => (
            dispatch: ThunkDispatch<any, any, any>,
            getState: () => any,
        ) => ReturnType<ReturnType<Effects[key]>>;
    };
    const actions: syncActions & asyncActions = {} as any;
    const genReducers = {} as { [key in string]: Reducer<State> };

    Object.keys(reducers).forEach((key) => {
        const actionType = `${namespace}/${key}`;

        // @ts-expect-error TODO：完善一下动态添加类型
        actions[key] = (payload) => ({ type: actionType, payload });
        // @ts-expect-error TODO：完善一下动态添加类型
        genReducers[actionType] = reducers[key];
    });
    if (effects) {
        Object.keys(effects).forEach((key) => {
            // @ts-expect-error TODO：完善一下动态添加类型
            actions[key] = (...arr) => effects[key](...arr);
        });
    }
    const result = {
        // eslint-disable-next-line @typescript-eslint/default-param-last
        reducer(s = defaultState, action: { type: string; payload: any }) {
            const { type } = action;
            if (genReducers[type]) {
                const r = genReducers[type](s, action.payload);
                return r;
            }
            return s;
        },
        namespace,
        actions,
    };
    return result;
}
