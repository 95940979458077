import styles from './index.module.less';
import { CommentInfoListProps } from './interactionComment';
import { useState, useCallback, useEffect } from 'react';
import cs from 'classnames';

interface ColumnStyleListProps {
    commentInfoList: CommentInfoListProps[];
    animationTimer: number;
}

export const ColumnStyleList = (props: ColumnStyleListProps) => {
    const { commentInfoList, animationTimer = 1 } = props;

    const [list, setList] = useState<CommentInfoListProps[]>(commentInfoList);
    const [startAnimation, setStartAnimation] = useState(false);
    useEffect(() => {
        setList(commentInfoList);
    }, [commentInfoList]);
    const loopList = useCallback(() => {
        setList((pre) => {
            const res = pre.slice(1);
            res.push(pre[0]);
            return res;
        });
    }, [list]);
    useEffect(() => {
        let subTimer: number;
        const timer = window.setInterval(
            () => {
                setStartAnimation(true);
                subTimer = window.setTimeout(() => {
                    setStartAnimation(false);
                    loopList();
                }, 400);
            },
            animationTimer * 1000 + 400,
        );
        return () => {
            clearInterval(timer);
            clearTimeout(subTimer);
        };
    }, [loopList]);

    return (
        <div className={styles.columnStyle}>
            {list.map((item, index) => {
                return (
                    <div
                        className={cs(styles.child, {
                            [styles.fadeout]: index === 0 && startAnimation,
                            [styles.fadeIn]: index !== 0 && startAnimation,
                        })}
                        style={{
                            backgroundColor: item?.bkgColor,
                            marginRight: '100px',
                        }}
                        key={index}
                    >
                        {item.icon && <img src={item.icon} className={styles.icon} />}
                        <span>{item.text}</span>
                    </div>
                );
            })}
        </div>
    );
};
