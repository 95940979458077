import { XiansuoFormProps } from '@/landingPage/components/XiansuoFormDrag/XiansuoForm';
import { GameInfo, componentsForEach } from 'omega-render';

let pageJSON: any;

export const setPageJsonCache = () => {
    try {
        pageJSON = JSON.parse(window.pageJsonString);
    } catch (e) {}
};

export const getPageJson = () => {
    if (pageJSON) {
        return pageJSON;
    }
    try {
        pageJSON = JSON.parse(window.pageJsonString);
        return pageJSON;
    } catch (e) {
        return;
    }
};

/** 从pageJson中获取小游戏信息 */
export const fetchWechatGameInfoInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let wechatGameInfo: GameInfo = {} as GameInfo;

    componentsForEach(pageJSONCache.components, (com) => {
        if (com.type === 'WECHAT_GAME') {
            wechatGameInfo = com.props?.gameInfo;
        }
    });

    return wechatGameInfo;
};

/** 从pageJson中获取表单信息 */
export const needShowInteractionCommentInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let needShowComment = false;
    let hasInteractionRecord = false;
    componentsForEach(pageJSONCache.components, (com) => {
        if (['XIANSUO_FORM', 'XIANSUO_FORM_DRAG'].includes(com.type) && !hasInteractionRecord) {
            needShowComment = true;
        }
        if (['DRAG_INTERACTION_RECORD'].includes(com.type)) {
            needShowComment = false;
            hasInteractionRecord = true;
        }
    });
    return needShowComment;
};

export const fetchXiansuoFormInfoInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let flag = false;
    let XiansuoFormInfo: XiansuoFormProps = {} as XiansuoFormProps;
    componentsForEach(pageJSONCache.components, (com) => {
        if (
            !flag &&
            com.type === 'XIANSUO_FORM_DRAG' &&
            com.props?.formOpt.components.length === 2
        ) {
            XiansuoFormInfo = com.props as XiansuoFormProps;
            flag = true;
        }
    });

    return XiansuoFormInfo;
};
