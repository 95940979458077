import * as React from 'react';

import cs from 'classnames';
import {
    CustomerServiceProps,
    IM_TYPE,
    openServiceUrl,
} from 'landingPage/components/CustomerServiceDrag/CustomerService';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { useComContext, useRenderState } from 'omega-render';
import Modal from 'landingPage/common/components/Modal/Modal';
import { getPrivateLetterInfo } from 'landingPage/services/apis';
import { formClass } from '../XiansuoFormDrag/XiansuoForm';
import { IFormProps, FormOption } from '../ButtonDrag/Button';
import { showAnchorFormIcon } from 'landingPage/common/utils/showAnchorFormIcon';
import { callTel, TelProps, ICallTelParam } from '../TelDrag/Tel';
import { FormDialog } from '../ButtonDrag/formDialog';
import { PayFormDialog } from '../ButtonDrag/payFormDialog';
import { Ol } from '../Blank/Blank';
import { ReactComponent as TelIcon } from './img/newTel.svg';
import { ReactComponent as FormIcon } from './img/newForm.svg';
import { ReactComponent as CustomerServiceIcon } from './img/newConsult.svg';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { useTheme } from '@/landingPage/common/utils/useTheme';
import { isInApp } from '@/landingPage/common/utils/env';
import { fetchConsultPageUrl } from '../CustomerServiceDrag/utils';

export interface ITab {
    type: ConsultType;
    name?: string;
}
export enum StyleType {
    NORMAL = 1,
    SQUARE = 2,
    ROUND = 3,
}
// export enum StyleType {
//     NORMAL = 'NORMAL',
//     SQUARE = 'SQUARE',
//     ROUND = 'ROUND'
// }
export interface MultiConsultProps {
    tabs: ITab[];
    /** 客服相关配置 */
    customerServiceOpt?: CustomerServiceProps;
    /** 电话号码 */
    telOpt?: Pick<TelProps, 'tel' | 'telType' | 'iccId'>;
    formOpt?: {
        /** 最开始用的表单id，但是后来一个页面可以多个表单了 */
        formId?: string;
        index?: number;
    };
    styleType?: StyleType;
    className?: string;
    /** 不需要空白占位 */
    noBottom?: boolean;
    backgroundColor?: string;
    // iconStyle?: {
    //     color?: string; //字体颜色
    //     fontSize?: string; //字体大小
    //     backgroundColor?: string; //字体颜色
    // };
    iconColor?: string;
    iconFontSize?: number;
    iconFontColor?: string;
    formProps?: Omit<IFormProps, 'formType'>;
    formOption?: FormOption;
    dynamicStatus?: boolean;
    isFromStorePage?: boolean;
}

export enum ConsultType {
    onlineConsult = 1,
    call,
    submitForm,
}
const config = {
    [ConsultType.onlineConsult]: {
        name: '在线咨询',
        Icon: CustomerServiceIcon,
    },
    [ConsultType.call]: {
        name: '电话拨打',
        Icon: TelIcon,
    },
    [ConsultType.submitForm]: {
        name: '表单提交',
        Icon: FormIcon,
    },
};
const MultiConsult: React.FunctionComponent<MultiConsultProps> = (props) => {
    const {
        tabs,
        backgroundColor,
        noBottom,
        styleType,
        iconColor,
        iconFontColor,
        iconFontSize,
        formProps,
        formOption,
        customerServiceOpt,
        telOpt,
        formOpt,
        className,
        dynamicStatus,
        isFromStorePage = false,
    } = props;
    const { edit } = useComContext();
    const pageJson = useRenderState((state) => state.rolex.opt?.originalPageJson);
    const [loading, setLoading] = React.useState(false);
    const [showFormLabel, setShowFormLabel] = React.useState(false);
    const [consultPageUrl, setConsultPageUrl] = React.useState(customerServiceOpt?.url);
    const [privateLetterDeepLink, setPrivateLetterDeepLink] = React.useState<string>();
    const [openH5, setOpenH5] = React.useState(false);
    const [linkSwitch, setLinkSwitch] = React.useState<boolean>(false);

    const getPrivateLetterDeepLink = async () => {
        const res = await getPrivateLetterInfo({
            userId: customerServiceOpt?.uid as number,
        });
        if (isInApp) {
            return res?.data?.deepLink;
        } else {
            return res?.data?.h5Url;
        }
    };
    React.useEffect(() => {
        if (customerServiceOpt?.csType === IM_TYPE.PRIVATE_LETTER && customerServiceOpt?.uid) {
            getPrivateLetterDeepLink().then((res) => {
                setPrivateLetterDeepLink(res);
            });
        }
    }, [customerServiceOpt?.uid]);
    const onClick = React.useCallback(
        (type: ConsultType) => {
            if (type === ConsultType.onlineConsult) {
                const appId = customerServiceOpt?.appId;
                const funcId = customerServiceOpt?.funcId;
                const csType =
                    customerServiceOpt?.csType || (appId === 10000 ? IM_TYPE.KUAI : IM_TYPE.OTHER);
                openServiceUrl({
                    comType: isFromStorePage ? 'STORE_PAGE' : 'MULTI_CONSULT_DRAG',
                    url: consultPageUrl,
                    funcId,
                    csType,
                    privateDeepLink: privateLetterDeepLink || customerServiceOpt?.deepLink,
                    openH5,
                    linkSwitch,
                    // extraUrlParams: {
                    //     jumpSource: 0,
                    // },
                });
            }
            if (type === ConsultType.call) {
                const tel = telOpt?.tel;
                const telType = telOpt?.telType;
                const iccId = telOpt?.iccId;
                const callParam: ICallTelParam = {
                    telType,
                    iccId,
                    loading,
                    setLoading,
                    comType: isFromStorePage ? 'STORE_PAGE' : 'MULTI_CONSULT_DRAG',
                    tel,
                    pageId: pageJson?.outPageId,
                };
                callTel(callParam);
            }
            if (type === ConsultType.submitForm) {
                if (formOption === FormOption.NewCommon || formOption === FormOption.NewPay) {
                    setShowFormLabel(true);
                } else {
                    const index = formProps?.index ?? formOpt?.index;
                    const formId = formOpt?.formId;

                    // let componentType = 'XIANSUO_FORM_DRAG';
                    // if (formOption === FormOption.ExistPay) {
                    //     componentType = 'XIANSUO_FORM_PAY';
                    // }
                    if (index != null) {
                        const node =
                            document.querySelectorAll(
                                `div[data-component-type^="${'XIANSUO_FORM_DRAG'}"]`,
                            )[index] ||
                            document.querySelectorAll(
                                `div[data-component-type^="${'XIANSUO_FORM'}"]`,
                            )[index];
                        // const node = document.querySelectorAll(`.${formClass}`)[index];
                        node?.scrollIntoView();

                        showAnchorFormIcon({ targetNode: node });
                        return;
                    }
                    if (!formId) {
                        return;
                    }
                    const node =
                        document.querySelector(`.${formClass}[data-form-id="${formId}"]`) ??
                        undefined;

                    node?.scrollIntoView();
                    showAnchorFormIcon({ targetNode: node });
                }
            }
        },
        [consultPageUrl, openH5, privateLetterDeepLink, linkSwitch],
    );
    // 由于咨询页支持修改，修改后咨询页的页面url也会改变，所以在c端实时读取咨询页的url
    // 考虑到用户体验问题，获取url的操作前置而不是点击的时候请求
    React.useEffect(() => {
        if (!customerServiceOpt?.funcId) return;
        fetchConsultPageUrl(customerServiceOpt?.funcId).then((res) => {
            const { switchStatus, consultPageUrl, isH5Url } = res;
            setLinkSwitch(switchStatus);
            setConsultPageUrl(consultPageUrl);
            setOpenH5(isH5Url);
        });
    }, [customerServiceOpt?.funcId]);
    // console.log('tabs---->12', tabs);
    return (
        <>
            <Ol
                className={cs(className, styles.root, 'no-adaptor', {
                    [styles.style2]: styleType === StyleType.SQUARE,
                    [styles.style3]: styleType === StyleType.ROUND,
                    [styles.animation]: dynamicStatus,
                    [styles.animationInclineSweepLight]: dynamicStatus,
                })}
                style={
                    {
                        backgroundColor: styleType === StyleType.NORMAL ? backgroundColor : '#fff',
                        color: iconFontColor,
                        fontSize: iconFontSize,
                        '--varIncline':
                            tabs.length === 3 ? '400px' : tabs.length === 2 ? '600px' : '800px',
                    } as React.CSSProperties
                }
            >
                {tabs.map((t, index) => {
                    const conf = config[t.type];
                    return (
                        <Tab
                            index={index}
                            key={t.type}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...conf}
                            name={t.name}
                            onClick={() => onClick(t.type)}
                            type={t.type}
                            backgroundColor={backgroundColor}
                            styleType={styleType}
                            tabs={tabs}
                            iconColor={iconColor}
                            iconFontColor={iconFontColor}
                            iconFontSize={iconFontSize}
                        />
                    );
                })}
            </Ol>
            {!noBottom && (
                <>
                    <BlockPlaceholder pos="BOTTOM" height={64} preview={edit} />
                    <SafeAreaPlace
                        style={{
                            backgroundColor:
                                styleType === StyleType.NORMAL ? backgroundColor : '#fff',
                        }}
                    />
                </>
            )}
            {showFormLabel && !!formProps && (
                <Modal
                    style={{
                        zIndex: 999,
                    }}
                >
                    {formOption === FormOption.NewPay ? (
                        <PayFormDialog
                            formProps={formProps}
                            onClose={() => {
                                setShowFormLabel(false);
                                const el = document.getElementById('middle-editor');
                                if (el) {
                                    el.style.overflowY = 'auto';
                                }
                            }}
                        />
                    ) : (
                        <FormDialog
                            formProps={formProps}
                            onClose={() => {
                                setShowFormLabel(false);
                                const el = document.getElementById('middle-editor');
                                if (el) {
                                    el.style.overflowY = 'auto';
                                }
                            }}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};

interface TabProps {
    index?: number;
    name?: string;
    className?: string;
    style?: React.CSSProperties;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    type: ConsultType;
    onClick: () => void;
    backgroundColor?: string;
    // iconStyle?: React.CSSProperties;
    styleType?: StyleType;
    tabs?: { type: ConsultType; name?: string }[];
    iconColor?: string;
    iconFontSize?: number;
    iconFontColor?: string;
}
export const Tab: React.FunctionComponent<TabProps> = (props) => {
    const {
        className,
        style,
        name,
        Icon,
        type,
        onClick,
        // backgroundColor,
        styleType,
        index,
        tabs,
        // iconColor,
        iconFontColor,
        iconFontSize,
    } = props;
    const { color: themeIconColor } = useTheme({
        path: 'iconColor',
    });
    const { color: themeBackgroundColor } = useTheme({
        path: 'backgroundColor',
    });
    const tabsLen = (tabs?.length as number) - 1;
    if (styleType !== StyleType.NORMAL) {
        return (
            <li
                className={cs(styles.item, className)}
                data-type={type}
                style={{
                    ...style,
                    backgroundColor: index === tabsLen ? themeBackgroundColor : '',
                }}
                onClick={onClick}
            >
                <Icon
                    className={styles.icon}
                    style={{ fill: index === tabsLen ? themeIconColor : '' }}
                />
                <p
                    style={{
                        color: index === tabsLen ? iconFontColor : '#666666',
                        fontSize: index === tabsLen ? iconFontSize : '12px',
                        fontWeight: index === tabsLen ? 600 : 'normal',
                    }}
                >
                    {name}
                </p>
            </li>
        );
    }

    return (
        <li
            className={cs(styles.item, className)}
            data-type={type}
            style={{
                ...style,
                // backgroundColor: themeBackgroundColor,
            }}
            onClick={onClick}
        >
            <Icon className={styles.icon} style={{ fill: themeIconColor }} />
            <p
                style={{
                    color: iconFontColor,
                    fontSize: iconFontSize,
                }}
            >
                {name}
            </p>
        </li>
    );
};

export default MultiConsult;
