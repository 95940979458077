import { AppDownloadProps } from '../../AppDownload';
import styles from './index.module.less';
import Button from '../../../ButtonDrag/Button';
import style from '../../style.module.less';
import cs from 'classnames';
import { getPicCDNSource } from '@/landingPage/common/utils/getUrlParam';
import { Div } from '@/landingPage/components/Blank/Blank';

export interface CardDownloadProps extends AppDownloadProps {
    buttonText?: string;
    status?: number;
}

type IntelligentAppIconUrl = {
    pictureId: number;
    imgUrl: string;
};

const CardDownload: React.FunctionComponent<CardDownloadProps> = (props) => {
    const { appInfo, status, dynamicStatus, buttonText, buttonStyle, onClick, children } = props;
    return (
        <Div className={styles.cardRoot}>
            <img
                src={getPicCDNSource(
                    (appInfo?.appIconUrl as unknown as IntelligentAppIconUrl)?.imgUrl ||
                        appInfo?.appIconUrl ||
                        '',
                )}
                alt=""
                className={styles.appIcon}
            />
            <div className={styles.appName}>{appInfo?.appName || ''}</div>
            <div>
                <Button
                    className={cs(styles.downloadBtn, {
                        [style.progress]: status === 2,
                        [style.dynamic]: !!dynamicStatus,
                    })}
                    text={buttonText ?? ''}
                    buttonStyle={buttonStyle}
                    onClick={onClick}
                >
                    {children}
                </Button>
            </div>
        </Div>
    );
};

export default CardDownload;
