import { RootState } from 'src/store';
import { UserInfo } from 'src/store/userModel';
import { useSelector } from 'react-redux';

/** 获取建站用户信息，包括accountId，白名单等信息 */
export const useLpUserInfo = <T extends keyof UserInfo['lpUser']>(field: T) => {
    const lpUser = useSelector((state) => {
        return (state as RootState).adUser?.lpUser;
    });
    if (lpUser) return lpUser[field] as UserInfo['lpUser'][T];
};
