import { ComponentJson, componentsForEach } from 'omega-render';

/** 版本升级逻辑
 *
 *  升级门店组件，父级blank高度增加16px
 *
 */
export default function updateComponent(components: ComponentJson[]) {
    componentsForEach(components, (component) => {
        if (component?.type === 'STORE' && !component?.props?.version) {
            const parentBlank = components.find((c) => {
                return c.components?.some((child) => child.id === component.id);
            });
            if (parentBlank?.props?.style?.height) {
                console.log('clear store component height');
                parentBlank.props.style.height = (parentBlank.props.style.height as number) + 16;
            }
            if (component?.props?.style) {
                component.props.style.height =
                    ((component?.props?.style?.height as number) || 73) + 16;
            }
        }
    });
}
