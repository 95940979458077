/* eslint-disable */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useComContext } from 'omega-render';
import { FormOption } from '../ButtonDrag/Button';
// import { dataTrack } from '../common/adTrack/util/track';
import {
    IConsulPageInfo,
    IFormInfoView,
    IGoodsInfo,
    IMemberInfo,
} from '../../common/api/interface';
import MultiConsult from '../MultiConsultDrag';
import { MultiConsultProps, ConsultType } from '../MultiConsultDrag/MultiConsult';
import Store from '../Store';
import { STORE_TYPE } from '../Store/hooks/useNewStoreList';
import { DisplayType, IPosition, newStoreProps, RichStore } from '../Store/Store';
import { setStoreState, STORE_COMPONENT_TYPE } from '../Store/utils/locStore';
import { transformCDN2Escape } from '../../common/upgradeCDN';
import { Goods } from './components/Goods/Goods';
import { Members } from './components/Members/Members';
import styles from './style.module.less';

export interface StorePageProps {
    /**
     * 门店类型
     * 0: 全部门店
     * 1: 门店包
     * 2: 单门店
     * */
    storeType: STORE_TYPE;
    /** 新门店包id，仅当storeType === 1时，有值 */
    packageId: string;
    /** 门店id，仅当storeType === 2时，有值 */
    storeId: string;
    /** 页面内容模块是否展示 */
    sectionSwitch: {
        /** 服务产品 */
        goods: boolean;
        /** 团队成员 */
        members: boolean;
    };
    clueAccountId: number;
    conversionTabs: [
        {
            name: string;
            type: ConsultType; // 组件类型：customerService: 0; tel: 1; xiansuoForm: 2
        },
    ];
    consultStyle: Pick<
        MultiConsultProps,
        'styleType' | 'backgroundColor' | 'iconColor' | 'iconFontSize' | 'iconFontColor'
    >;
}

const MEMBER_PLACEHOLDER = {
    head: transformCDN2Escape('https://h2.static.yximgs.com/kos/nlav10749/lpsite/emptyMember.png'),
    name: '',
    tag: '',
    title: '',
};
/** 当成员少于3人时，补充占位图到3人 */
const formatMembers = (members: IMemberInfo[]) => {
    const { length } = members;
    if (length === 0) {
        return [];
    }
    if (length < 3) {
        members.push({
            ...MEMBER_PLACEHOLDER,
            order: length + 1,
        });
    }
    return members;
};
/** 格式化咨询页数据 */
function formatCustomerServiceOpt(data?: IConsulPageInfo): MultiConsultProps['customerServiceOpt'] {
    if (!data) return;
    return {
        appId: Number(data.consulPageId),
        funcId: data.consulPageId,
        url: data.consultPageUrl,
    } as any;
}

const StorePage: React.FunctionComponent<StorePageProps> = (props) => {
    const { sectionSwitch, conversionTabs, consultStyle } = props;
    const [storeId, setStoreId] = useState<string>();
    const [goods, setGoods] = useState<IGoodsInfo[]>([]);
    const [members, setMembers] = useState<IMemberInfo[]>([]);
    const [customerServiceOpt, setCustomerServiceOpt] = useState<IConsulPageInfo>();
    const [formInfo, setFormInfo] = useState<IFormInfoView>();
    const [iccId, setIccId] = useState<string>();
    const { edit } = useComContext();
    const filterConversionTabs = useMemo(() => {
        return conversionTabs.filter((tab) => {
            switch (tab.type) {
                case ConsultType.submitForm:
                    if (!edit && !formInfo) return false;
                    break;
                case ConsultType.onlineConsult:
                    if (!edit && !customerServiceOpt) return false;
                    break;
                case ConsultType.call:
                    if (!edit && !iccId) return false;
                    break;
            }
            return true;
        });
    }, [conversionTabs, edit, customerServiceOpt, formInfo, iccId]);
    // useEffect(() => {
    //     dataTrack({
    //         eventType: 'EVENT_DEEPLINK_INVOKED',
    //         diyExtraInfo: JSON.stringify({
    //             storeId: 1,
    //         }),
    //     })
    // }, [])
    const storeProps: newStoreProps = useMemo(() => {
        return {
            position: IPosition.BASE,
            version: '3.0.0',
            displayType: DisplayType.RICH,
            packageId: props.packageId,
            storeId: props.storeId,
            storeType: props.storeType,
            clueAccountId: props.clueAccountId,
            page: true,
            richStyle: {
                /** 门店名称字号 */
                nameSize: 17,
                /** 门店名称字色 */
                nameColor: '#222222',
                /** 标题名称字号 */
                titleSize: 14,
                /** 标题名称字色 */
                titleColor: '#666666',
                /** 门店地址字号 */
                addressSize: 12,
                /** 门店地址字色 */
                addressColor: '#666666',
                /** 导航icon颜色 */
                iconColor: '#3089FF',
                /** 背景颜色 */
                bgColor: '#FFF',
                /** 组件高度 */
            },
        };
    }, [props.clueAccountId, props.packageId, props.storeId, props.storeType]);
    useEffect(() => {
        setStoreState({ componentType: STORE_COMPONENT_TYPE.STORE_PAGE });
    }, []);
    const multiConsultProps: MultiConsultProps = useMemo(() => {
        return {
            tabs: filterConversionTabs,
            /** 客服相关配置 */
            customerServiceOpt: formatCustomerServiceOpt(customerServiceOpt),
            /** 电话号码 */
            telOpt: {
                telType: 'AImobile',
                tel: '',
                iccId,
            },
            // formOpt?: {
            //     /** 最开始用的表单id，但是后来一个页面可以多个表单了 */
            //     formId?: string;
            //     index?: number;
            // };
            styleType: 2,
            formOption: FormOption.NewCommon,
            formProps: { formOpt: formInfo, showLabel: 'show' },
            ...consultStyle,
            // formProps: {}
            // className?: string;
            /** 不需要空白占位 */
            // noBottom?: boolean;
            // backgroundColor?: string;
            // iconStyle?: {
            //     color?: string; //字体颜色
            //     fontSize?: string; //字体大小
            //     backgroundColor?: string; //字体颜色
            // };
            // iconColor?: string;
            // iconFontSize?: string;
            // iconFontColor?: string;
        };
    }, [consultStyle, customerServiceOpt, filterConversionTabs, formInfo, iccId]);
    const onStoreDetailChange = React.useCallback((detail: RichStore) => {
        if (!detail.storeId) return;
        console.log('onStoreDetailChange', detail);
        setStoreId(detail.storeId);
        setGoods(detail.goodsList);
        setMembers(formatMembers(detail.members));
        setCustomerServiceOpt(detail.consulPageInfo);
        setIccId(detail.iccId);
        setFormInfo(detail.formInfo);
    }, []);
    console.log('multiConsultProps:', multiConsultProps);

    // const { edit } = useComContext();

    console.log('---edit', edit);
    return (
        <div className={styles.root}>
            <Store {...storeProps} onStoreDetailChange={(e) => onStoreDetailChange(e as any)} />
            {sectionSwitch?.goods && Boolean(goods?.length) && <Goods goods={goods} />}
            {sectionSwitch?.members && Boolean(members?.length) && <Members members={members} />}
            {storeId && multiConsultProps.tabs && (
                <div
                    className={styles.bottomWrapper}
                    style={{ position: edit ? 'sticky' : 'fixed' }}
                >
                    <MultiConsult {...multiConsultProps} isFromStorePage={true} />
                </div>
            )}
        </div>
    );
};

export default StorePage;
