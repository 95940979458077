import * as React from 'react';
import { useComContext } from 'omega-render';
import modelStyle from 'landingPage/common/components/Modal/Modal.module.less';
import { useEditHeight } from 'landingPage/common/hook/useEditHeight';
import { Div } from '../Blank/Blank';
import { BaseForm } from '../xiansuoFormPay/commonFun';
import { getPayUrl } from '../xiansuoFormPay/utils';
import { usePayFormConfig, getSkuId, getJumpUrl } from '../xiansuoFormPay/xiansuoFormPay';
import { IFormProps } from './Button';
import close from './close.png';
import styles from './style.module.less';

export interface CopyDialogProps {
    onClose?: () => void;
    formProps: IFormProps;
}

export const formClass = 'com-form';
export const PayFormDialog: React.FunctionComponent<CopyDialogProps> = (props) => {
    const { onClose, formProps } = props;
    const {
        formOpt,
        showLabel,
        theme,
        // children,
        funcId,
        placeholderColor,
        inputStyle,
        titleStyle,
        formStyle,
        submitStyle,
        formPayProps,
    } = formProps;

    const { itemId = '', activityId = '' } = formPayProps ?? {};

    const { edit, preview } = useComContext();

    const [payUrl, setPayUrl] = React.useState(() => getPayUrl());
    const [skuId, setSkuId] = React.useState<string>();

    React.useEffect(() => {
        getSkuId(itemId, setSkuId);
    }, [itemId]);

    React.useEffect(() => {
        getJumpUrl(setPayUrl);
    }, []);

    const { config } = usePayFormConfig({
        formOpt,
        edit,
        preview,
        showLabel,
        theme,
        formStyle,
        inputStyle,
        submitStyle,
        titleStyle,
        activityId,
        itemId,
        skuId,
        setSkuId,
        payUrl,
    });

    const { editHeight } = useEditHeight(true);
    const modelHeight = document.getElementsByClassName(modelStyle.layer)[0]?.clientHeight;

    return (
        <div className={styles.dialog} id="button-form" style={{ maxHeight: modelHeight * 0.75 }}>
            {/* <div className={styles.titlePart}> */}
            {/* <p>{formProps?.showLabel==='show' && formProps?.formOpt?.mainTitle}</p> */}
            <img
                style={{
                    width: 24,
                    height: 24,
                    position: 'absolute',
                    right: 12,
                    top: 12,
                }}
                src={close}
                onClick={onClose}
                alt=""
                data-target="formClose"
            />
            {/* </div> */}
            <Div
                className={formClass}
                data-form-id={funcId}
                style={{ maxHeight: editHeight ?? modelHeight * 0.75 - 48, overflowY: 'auto' }}
            >
                <BaseForm
                    placeholderColor={placeholderColor}
                    formClass={formClass}
                    reRender={false}
                    config={config}
                    formStyle={formStyle}
                />
            </Div>
        </div>
    );
};
