import cs from 'classnames';
import { transformCDN2Escape } from 'landingPage/common/upgradeCDN';
import styles from './index.module.less';

export default function Tag(props: { tagName?: string; x: number; y: number }) {
    const { tagName, x = 0, y = 0 } = props;
    return (
        <div
            className={styles.Wrap}
            style={{
                top: x,
                left: y,
            }}
        >
            <div className={styles.Circle}>
                <div className={styles.OutCircle} />
            </div>
            {/* <S.Msg>{tagName ?? '标签'}</S.Msg> */}
            <div className={cs(styles.tagsWrapper)}>
                <img
                    src={transformCDN2Escape(
                        'https://p3-yx.adkwai.com/kos/nlav10749/site/arrowleft.png',
                    )}
                />
                <div className={cs(styles.msgWrapper)}>{tagName ?? '标签'}</div>
            </div>
        </div>
    );
}
