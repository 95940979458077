import * as React from 'react';
import styles from '../style.module.less';

export interface BaseStyleProps {
    couponTitle: string;
    couponTitleStyle?: React.CSSProperties;
    buttonText: string;
    buttonTextStyle?: React.CSSProperties;
    validity?: string;
    setModalVisible: (param: boolean) => void;
}

const BaseStyle: React.FunctionComponent<BaseStyleProps> = (props) => {
    const {
        couponTitle,
        couponTitleStyle,
        buttonText,
        buttonTextStyle,
        validity,
        setModalVisible,
    } = props;
    return (
        <div
            className={styles.baseStyle}
            onClick={() => {
                setModalVisible(true);
            }}
        >
            <div className={styles.title}>
                <div className={styles.couponTitle} style={couponTitleStyle}>
                    {couponTitle}
                </div>
                <div className={styles.validate}>
                    使用期限：
                    {validity}
                </div>
            </div>
            <div className={styles.button}>
                <div className={styles.buttonText} style={buttonTextStyle}>
                    {buttonText}
                </div>
            </div>
        </div>
    );
};

export default BaseStyle;
