import { dataTrack } from 'landingPage/common/adTrack/util/track';
import Modal from 'landingPage/common/components/Modal/Modal';
import { BtnType } from '../WechatGame/WechatGame';
import { DynamicType } from '../ButtonDrag/Button';
import {
    trackCopyActivityCodeSuccess,
    trackCopyActivityCodeFail,
} from 'landingPage/common/adTrack/monitor';
import { Toast } from '@ad/sharpui';
import React from 'react';
import { invoke } from '@yoda/bridge';
import cs from 'classnames';
import styles from './style.module.less';
import { isInApp } from 'omega-shared/utils';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { useButtonTheme } from '@/landingPage/common/utils/useTheme';

export interface ActivityCodeProps {
    activityCode: string; //礼包码内容
    buttonText: string; //按钮文案
    buttonStyle: React.CSSProperties; //按钮样式
    backgroundFillMode?: boolean; // 背景填充模式
    // 按钮类型
    btnType?: BtnType;
    // 按钮圆角类型
    radiusStyle?: string;
    // 呼吸态状态
    dynamicStatus?: DynamicType;
}

const ActivityCode: React.FunctionComponent<ActivityCodeProps> = (props) => {
    const { activityCode, buttonText, buttonStyle, dynamicStatus } = props;
    const [isFirstCopy, setIsFirstCopy] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const buttonTheme = useButtonTheme();
    const copyCode = async () => {
        dataTrack({
            eventType: 'EVENT_LP_USER_CLICK_GIFT_BAG',
            diyExtraInfo: JSON.stringify({
                renderType: 'ACTIVITY_CODE',
                cdKey: activityCode,
            }),
        });

        try {
            if (isInApp) {
                await invoke('webview.setClipBoard', { text: activityCode });
            } else {
                await bridge.setClipBoard({ text: activityCode });
            }
            if (isFirstCopy) {
                Toast.success('领取成功，安装应用后兑换');
                setIsFirstCopy(false);
            } else {
                Toast.success('已领取，请进入应用兑换');
            }
            trackCopyActivityCodeSuccess();
        } catch (err) {
            console.log(err);
            setShowModal(true);
            trackCopyActivityCodeFail();
        }
    };

    const onClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <div
                className={cs(styles.button, {
                    [styles.dynamic]: dynamicStatus === 1,
                })}
                style={{ ...buttonStyle, ...buttonTheme }}
                onClick={copyCode}
            >
                {buttonText}
            </div>
            {showModal && (
                <Modal>
                    <div className={styles.modal}>
                        <div className={styles.exit} data-target="wechat-close" onClick={onClose} />
                        <div className={styles.title}>礼包码</div>
                        <div className={styles.content}>{activityCode}</div>
                        <div className={styles.desc}>长按复制礼包码，安装应用后兑换</div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ActivityCode;
